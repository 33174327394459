import { Chip } from "@mui/material";

type Props = {
  label: string;
  onClick: () => void;
};

export const ClickableChip = ({ label, onClick }: Props) => {
  return (
    <Chip
      label={label}
      onClick={onClick}
      size="small"
      sx={{
        cursor: "pointer",
        textDecoration: "underline",
        fontWeight: "bold",
      }}
    />
  );
};
