import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";

export type Props = {
  title?: string;
  actionButtonTitle?: string;
  actionButtonOnClick?: () => void;
  actionButtonLoading?: boolean;
  children?: React.ReactNode;
};

export const AccountPageLayout = ({
  title,
  actionButtonTitle,
  actionButtonOnClick,
  actionButtonLoading,
  children,
}: Props) => {
  return (
    <Grid container sx={{ px: 3, py: 8, height: "100%" }}>
      <Grid item xs={1} />
      <Grid
        item
        xs={10}
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          {title == null ? null : <Typography variant="h1">{title}</Typography>}
          {actionButtonTitle ? (
            <LoadingButton
              onClick={actionButtonOnClick}
              variant="contained"
              size="large"
              loading={actionButtonLoading}
            >
              {actionButtonTitle}
            </LoadingButton>
          ) : null}
        </Box>
        {children}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};
