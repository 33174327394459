import { Box, Stack } from "@mui/material";
import { InputModalContentLayout } from "../common/InputModalContentLayout";
import {
  SearchMaterialPhysics,
  SearchMaterials,
} from "contexts/search/context";
import { Loaded, liftLoadedState } from "models/loaded";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { ObtainableBundleItem } from "store/api/generatedApi";
import { AvailabilitiesEstimator } from "./AvailabilitiesEstimator";
import { ImportAvailabilityFromFileButton } from "./ImportFromFile";
import { ImportAvailabilityFromSearchButton } from "./ImportFromSearch";
import { AvailabilitiesTable } from "./AvailabilitiesTable";
import { useState } from "react";
import { DeleteAvailabilitiesButton } from "./DeleteAvailabilitiesButton";
import { FeatureFlag } from "src/constants";
import { useIsBehindFeatureFlag } from "hooks/useFeatureFlag";
import {
  useMaterialPhysics,
  useMaterials,
  useObtainableBundles,
} from "contexts/search/provider";
import { usePeriodIndex } from "hooks/periodIndex";

export const AvailabilitiesEditor = () => {
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const hideImportAvailability = useIsBehindFeatureFlag(
    FeatureFlag.MaterialAvailabilityImport
  );

  return (
    <LoadedContent data={useDependencies()}>
      {({ materials, materialPhysics, obtainableBundles }) => (
        <InputModalContentLayout
          topPanel={
            <Stack gap={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Stack spacing={1} direction="row">
                  {selectedItemIds.length > 0 && (
                    <DeleteAvailabilitiesButton
                      materials={materials}
                      selectedItemIds={selectedItemIds}
                      setSelectedItemIds={setSelectedItemIds}
                    />
                  )}
                  <AvailabilitiesEstimator />
                  {hideImportAvailability ? null : (
                    <ImportAvailabilityFromFileButton />
                  )}
                  <ImportAvailabilityFromSearchButton />
                </Stack>
              </Box>
            </Stack>
          }
          leftPanel={
            <AvailabilitiesTable
              materials={materials}
              materialPhysics={materialPhysics}
              obtainableBundleItems={obtainableBundles}
              selectedItemIds={selectedItemIds}
              setSelectedItemIds={setSelectedItemIds}
            />
          }
          fullWidthLeftPanel
          removeLeftPanelOutline
        />
      )}
    </LoadedContent>
  );
};

const useDependencies = (): Loaded<{
  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
  obtainableBundles: ObtainableBundleItem[];
}> => {
  const materials = useMaterials();
  const materialPhysics = useMaterialPhysics();
  const periodTab = usePeriodIndex();
  const [obtainableBundles] = useObtainableBundles(periodTab);

  return liftLoadedState({ materials, materialPhysics, obtainableBundles });
};
