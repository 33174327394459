import { usePlan } from "contexts/plan";
import {
  useChemistryGroups,
  useMaterials,
  useProductGroups,
  useSteelGrades,
} from "contexts/search/provider";
import {
  SearchChemistryGroups,
  SearchMaterials,
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import { Loaded, liftLoadedState } from "models/loaded";
import { Plan } from "store/api/generatedApi";

export type PlanTableDependencies = {
  plan: Plan;
  chemistryGroups: SearchChemistryGroups;
  productGroups: SearchProductGroups | null;
  steelGrades: SearchSteelGrades;
  materials: SearchMaterials;
};

export const usePlanTableDependencies = (
  planId: number
): Loaded<PlanTableDependencies> => {
  const plan = usePlan(planId).plan;

  const chemistryGroups = useChemistryGroups();
  const productGroups = useProductGroups();
  const steelGrades = useSteelGrades();
  const materials = useMaterials();

  return liftLoadedState({
    plan,
    chemistryGroups,
    productGroups,
    steelGrades,
    materials,
  });
};
