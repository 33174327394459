import { Alert, Box, Button, Typography } from "@mui/material";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { ReactNode, useMemo, useState } from "react";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";

export const Table = <
  Row extends string | number,
  Column extends string | number,
>({
  rows,
  columns,
  render,
  append,
  remove,
}: {
  rows: Row[];
  columns: Column[];
  render: (rowId: Row, columnId: Column) => ReactNode;
  append?: (rowId: string) => void;
  remove?: (rowId: Row) => void;
}) => {
  const serialiser = useStringSerialiser();
  const [newRow, setNewRow] = useState<string | null>(null);

  return (
    <>
      {rows.length > 0 ? (
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column}>
                  <Typography
                    sx={{
                      backgroundColor: "lightgrey",
                      textAlign: "left",
                      px: 1,
                      fontWeight: 800,
                    }}
                  >
                    {column}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row} style={{ padding: "0px", margin: "0px" }}>
                {columns.map((column) => (
                  <Cell
                    key={column}
                    render={render}
                    row={row}
                    column={column}
                  />
                ))}

                {remove ? (
                  <td>
                    <Button onClick={() => remove(row)}>Remove</Button>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Alert severity="warning">
          <Typography sx={{ px: 1 }}>No rows</Typography>
        </Alert>
      )}

      {append === undefined ? null : (
        <Box>
          {newRow === null ? (
            <Button onClick={() => setNewRow("")}>Add row</Button>
          ) : (
            <ValidatedTextField
              serialiser={serialiser}
              value={newRow}
              setValue={setNewRow}
              onEditConfirmedWithKeyboard={(text) => {
                setNewRow(null);
                append(text);
              }}
              textFieldProps={{
                onBlur: () => setNewRow(null),
                autoFocus: true,
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};

const Cell = <Row, Column>({
  render,
  row,
  column,
}: {
  render: (row: Row, column: Column) => string | ReactNode;
  row: Row;
  column: Column;
}) => {
  const content = useMemo(() => render(row, column), [render, row, column]);

  return (
    <td>
      {typeof content === "string" ? (
        <Typography sx={{ px: 1, m: 0, py: 0 }}>{content}</Typography>
      ) : (
        <Box sx={{ px: 1, m: 0, py: 0 }}>{content}</Box>
      )}
    </td>
  );
};
