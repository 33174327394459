import { DeployProcess } from "src/constants";
import { useDeployProcess } from "./useDeployProcess";

export const useCanDeploy = () => {
  const deployProcess = useDeployProcess();
  return [
    DeployProcess.SyncAndSMS,
    DeployProcess.Email,
    DeployProcess.Message,
  ].includes(deployProcess);
};
