import { Skeleton, Stack, Switch, Typography } from "@mui/material";
import { usePeriod } from "contexts/search/provider";
import { useTenantTranslation } from "hooks/formatters";
import { Period } from "hooks/periodIndex";
import { LoadedContent } from "src/components/common/loading/loadedContent";

export const SuppressConstraintsEditor = ({
  periodIndex,
}: {
  periodIndex: Period;
}) => {
  const [period, setPeriod] = usePeriod(periodIndex);
  const { t } = useTenantTranslation();

  return (
    <Stack sx={{ mt: 0.5 }} gap={0.5}>
      <LoadedContent
        data={period}
        loading={<SwitchSkeleton />}
        waiting={<SwitchSkeleton />}
      >
        {(loaded) => (
          <LabelledSwitch
            label={t("mixMaterialExclusivity")}
            value={loaded.suppress_mix_material_exclusivity_constraints}
            setValue={(update) =>
              setPeriod((current) => ({
                ...current,
                suppress_mix_material_exclusivity_constraints: update(
                  current.suppress_mix_material_exclusivity_constraints
                ),
              }))
            }
          />
        )}
      </LoadedContent>

      <LoadedContent
        data={period}
        loading={<SwitchSkeleton />}
        waiting={<SwitchSkeleton />}
      >
        {(loaded) => (
          <LabelledSwitch
            label={t("minimumFeasibleMass")}
            value={loaded.suppress_min_feasible_mass_constraints}
            setValue={(update) =>
              setPeriod((current) => ({
                ...current,
                suppress_min_feasible_mass_constraints: update(
                  current.suppress_min_feasible_mass_constraints
                ),
              }))
            }
          />
        )}
      </LoadedContent>
    </Stack>
  );
};

export const LabelledSwitch = ({
  label,
  value,
  setValue,
}: {
  label: string;
  value: boolean;
  setValue: (update: (value: boolean) => boolean) => void;
}) => {
  const toggle = () => setValue((current) => !current);

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      onClick={toggle}
      sx={{ cursor: "pointer" }}
    >
      <Switch size="small" checked={value} />
      <Typography sx={{ userSelect: "none" }}>{label}</Typography>
    </Stack>
  );
};

export const SwitchSkeleton = () => <Skeleton sx={{ width: 35 }} />;
