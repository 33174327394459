import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Typography sx={{ p: 2, mt: 5 }} textAlign="center">
      {t("pageNotFound")}
    </Typography>
  );
};
