import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Decimal } from "decimal.js";
import { useSearch } from "contexts/search/provider";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useIsAdmin, useTenant } from "hooks/settings";
import { loadedEndpoint, mapLoadedUnpack } from "models/loaded";
import { useState } from "react";
import { UnitType } from "src/constants";
import {
  MaterialLimitCoefficientCreate,
  useListMaterialConstraintClassesQuery,
} from "store/api/generatedApi";
import { Stack } from "@mui/system";
import { clamp, useNumberSerialiser } from "hooks/serialisers/numbers";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { LoadedContent } from "components/common/loading/loadedContent";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { MixMaterialLimitId } from "contexts/search/context";
import {
  CreateReduxMixMaterialLimit,
  mixMaterialActions,
  ReduxMixMaterialLimit,
  useChefGroupMixMaterialLimits,
} from "src/store/slices/mixMaterialLimits";
import { useAppDispatch } from "src/store/store";

/**
 * Shows each of a series of chemistry constraints in small panels.
 */

export const buildMixMaterialName =
  (materialConstraintClassNameLookUp: Record<number, string>) =>
  (coefficients: MaterialLimitCoefficientCreate[]): string => {
    return coefficients
      .map((item) => {
        const coefficient = new Decimal(item.coefficient);
        return (
          (coefficient.equals(1)
            ? ""
            : coefficient.toDecimalPlaces(3).toString()) +
          (materialConstraintClassNameLookUp[
            item.material_constraint_class_id
          ] ?? "")
        );
      })
      .join(" + ");
  };

export const MixMaterialLimits = ({ chefGroupId }: { chefGroupId: number }) => {
  const mixMaterialLimits = useChefGroupMixMaterialLimits(chefGroupId);
  const isAdmin = useIsAdmin();

  const tenant = useTenant();
  const contextIds = mapLoadedUnpack(
    useSearch().context,
    ({ materialPhysicsId, materialsId }) => ({ materialsId, materialPhysicsId })
  );
  const materialConstraintClasses = loadedEndpoint(
    useListMaterialConstraintClassesQuery(
      contextIds === null
        ? skipToken
        : {
            tenantName: tenant,
            materialSetId: contextIds.materialsId,
            materialPhysicsSetId: contextIds.materialPhysicsId,
          }
    )
  );

  const names = Object.fromEntries(
    (mapLoadedUnpack(materialConstraintClasses, (loaded) => loaded) ?? []).map(
      (item) => [item.id, item.name]
    )
  );

  return (
    <Box>
      {Object.values(mixMaterialLimits).map((mixMaterialLimit, index) => (
        <MixMaterialLimitChip
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          id={mixMaterialLimit.id}
          name={mixMaterialLimit.name}
          coefficients_signature={mixMaterialLimit.coefficients_signature}
          hardness={mixMaterialLimit.hardness}
          maxMass={mixMaterialLimit.max_mass}
          minMass={mixMaterialLimit.min_mass}
          softMinMass={mixMaterialLimit.soft_min_mass}
          softMaxMass={mixMaterialLimit.soft_max_mass}
          coefficients={mixMaterialLimit.coefficients}
          chefGroupId={chefGroupId}
        />
      ))}
      {isAdmin ? (
        <AddMixMaterialLimit
          chefGroupId={chefGroupId}
          buildMixMaterialName={buildMixMaterialName(names)}
        />
      ) : null}
    </Box>
  );
};

const MixMaterialLimitChip = ({
  coefficients,
  minMass,
  maxMass,
  softMaxMass,
  softMinMass,
  hardness,
  id,
  name,
  coefficients_signature,
  chefGroupId,
}: {
  coefficients: MaterialLimitCoefficientCreate[];
  id: MixMaterialLimitId;
  chefGroupId: number;
  minMass: number | null;
  maxMass: number | null;
  softMinMass: number | null;
  softMaxMass: number | null;
  hardness: number;
  name: string;
  coefficients_signature: string;
}) => {
  const units = useUnitsFormatter(false);
  const { t } = useTenantTranslation();
  const expression = formatMixMaterialLimits({ minMass, maxMass }, units);
  const label = `${t(name)} ${expression}`;

  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <>
      <Chip
        onClick={() => setOpen(true)}
        sx={{ mr: 1, mb: 1 }}
        label={label}
        size="small"
        key={label}
      />

      {open ? (
        <MixMaterialLimitEditor
          close={() => setOpen(false)}
          id={id}
          chefGroupId={chefGroupId}
          value={{
            name,
            coefficients_signature,
            id: id,
            max_mass: maxMass,
            min_mass: minMass,
            soft_max_mass: softMaxMass,
            soft_min_mass: softMinMass,
            hardness: hardness,
            coefficients,
          }}
          edit={(mixMaterialLimit) => {
            dispatch(mixMaterialActions.edit(mixMaterialLimit));
          }}
          remove={(mixMaterialLimitId) =>
            dispatch(mixMaterialActions.delete(mixMaterialLimitId))
          }
        />
      ) : null}
    </>
  );
};

export const formatMixMaterialLimits = (
  {
    minMass,
    maxMass,
  }: { minMass: number | undefined | null; maxMass: number | undefined | null },
  units: (type: UnitType) => string
): string => {
  const mass_part =
    minMass != undefined &&
    minMass > 0 &&
    maxMass != undefined &&
    minMass !== maxMass
      ? `${minMass.toFixed(0)}-${maxMass.toFixed(0)}${units("mass")}`
      : maxMass != undefined && minMass === maxMass
      ? `= ${maxMass.toFixed(0)}${units("mass")}`
      : maxMass != undefined
      ? `≤ ${maxMass.toFixed(0)}${units("mass")}`
      : minMass != undefined && minMass > 0
      ? `≥ ${minMass.toFixed(0)}${units("mass")}`
      : null;

  return mass_part ?? "";
};

/**
 * Whether or not two mix material limits cover the same material constraint classes. */

type AddMixMaterialLimitProps = {
  chefGroupId: number;
  buildMixMaterialName: (
    coefficients: MaterialLimitCoefficientCreate[]
  ) => string;
};

const AddMixMaterialLimit = ({
  chefGroupId,
  buildMixMaterialName,
}: AddMixMaterialLimitProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Chip
        sx={{ mr: 1, mb: 1 }}
        label="+"
        size="small"
        color="primary"
        onClick={() => setOpen(true)}
      />

      {open ? (
        <MixMaterialLimitEditor
          close={() => setOpen(false)}
          create={(mixMaterialLimit) =>
            dispatch(mixMaterialActions.add(mixMaterialLimit))
          }
          chefGroupId={chefGroupId}
          buildMixMaterialName={buildMixMaterialName}
        />
      ) : null}
    </>
  );
};

type MixMaterialLimitEditorProps =
  | {
      id: MixMaterialLimitId;
      chefGroupId: number;
      value: Omit<ReduxMixMaterialLimit, "chef_group_id">;
      close: () => void;
      edit: (mixMaterialLimit: ReduxMixMaterialLimit) => void;
      remove: (mixMaterialLimitId: MixMaterialLimitId) => void;
    }
  | {
      chefGroupId: number;
      close: () => void;
      create: (mixMaterialLimit: CreateReduxMixMaterialLimit) => void;
      buildMixMaterialName: (
        coefficients: MaterialLimitCoefficientCreate[]
      ) => string;
    };

const MixMaterialLimitEditor = ({
  close,
  chefGroupId,
  ...props
}: MixMaterialLimitEditorProps) => {
  const tenant = useTenant();
  const units = useUnitsFormatter(false);

  const contextIds = mapLoadedUnpack(
    useSearch().context,
    ({ materialsId, materialPhysicsId }) => ({
      materialsId,
      materialPhysicsId,
    })
  );
  const classes = loadedEndpoint(
    useListMaterialConstraintClassesQuery(
      contextIds === null
        ? skipToken
        : {
            tenantName: tenant,
            materialSetId: contextIds.materialsId,
            materialPhysicsSetId: contextIds.materialPhysicsId,
          }
    )
  );
  const { t } = useTenantTranslation();

  const [name, setName] = useState(() => {
    if ("value" in props) {
      return props.value.name;
    } else {
      return "";
    }
  });

  const [coefficients, setCoefficients] = useState<Record<number, number>>(
    "value" in props
      ? Object.fromEntries(
          props.value.coefficients.map((item) => [
            item.material_constraint_class_id,
            item.coefficient,
          ])
        )
      : {}
  );

  const [limits, setLimits] = useState(
    "value" in props
      ? {
          minimum: props.value.min_mass,
          maximum: props.value.max_mass,
          softMinimum: props.value.soft_min_mass,
          softMaximum: props.value.soft_max_mass,
          hardness: props.value.hardness,
        }
      : {
          minimum: null,
          maximum: null,
          softMaximum: null,
          softMinimum: null,
          hardness: 1.0,
        }
  );

  const coefficientSerialiser = useNumberSerialiser({
    default: { value: null, text: "" },
    decimalPlaces: 3,
  });
  const limitSerialiser = useNumberSerialiser({
    min: 0,
    default: { value: null, text: "" },
  });

  const resultingMixMaterialLimitData: Omit<
    CreateReduxMixMaterialLimit,
    "chef_group_id" | "name"
  > = {
    coefficients: Object.entries(coefficients)
      .filter(([, coefficient]) => coefficient !== null)
      .map(([id, coefficient]) => ({
        material_constraint_class_id: parseInt(id),
        coefficient: coefficient ?? 0,
      })),
    min_mass: limits.minimum,
    max_mass: limits.maximum,
    soft_min_mass: limits.softMinimum,
    soft_max_mass: limits.softMaximum,
    hardness: limits.hardness,
  };

  const label = `${t(name)} ${formatMixMaterialLimits(
    { minMass: limits.minimum, maxMass: limits.maximum },
    units
  )}`;

  const canSubmit = (() => {
    const hasName = name !== "";
    const hasCoefficient = Object.values(coefficients).length > 0;
    return hasName && hasCoefficient;
  })();

  return (
    <Dialog open onClose={close}>
      <DialogTitle>{t("editMixMaterialLimit")}</DialogTitle>
      <DialogContent>
        <Typography variant="h5">{t("label")}</Typography>
        <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
          <Typography>{t("name")}</Typography>
          <TextField
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            size="small"
          />
          {name !== "" ? <Chip label={label} size="small" key={label} /> : null}
        </Stack>
        <Typography variant="h5">{t("limits")}</Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>{t("min")}</Typography>
          <ValidatedTextField
            value={limits.minimum}
            setValue={(value) =>
              setLimits((current) => ({
                minimum: value,
                softMinimum:
                  current.softMinimum === null
                    ? null
                    : clamp(current.softMinimum, value, null),
                softMaximum:
                  current.softMaximum === null
                    ? null
                    : clamp(current.softMaximum, value, null),
                maximum:
                  current.maximum === null
                    ? null
                    : clamp(current.maximum, value, null),
                hardness: current.hardness,
              }))
            }
            serialiser={limitSerialiser}
          />
          <Typography>{t("max")}</Typography>
          <ValidatedTextField
            value={limits.maximum}
            setValue={(value) =>
              setLimits((current) => ({
                minimum:
                  current.minimum === null
                    ? null
                    : clamp(current.minimum, null, value),
                softMinimum:
                  current.softMinimum === null
                    ? null
                    : clamp(current.softMinimum, null, value),
                softMaximum:
                  current.softMaximum === null
                    ? null
                    : clamp(current.softMaximum, null, value),
                maximum: value,
                hardness: current.hardness,
              }))
            }
            serialiser={limitSerialiser}
          />
        </Stack>

        <Typography variant="h5" sx={{ mt: 2 }}>
          {t("softLimits")}
        </Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>{t("min")}</Typography>
          <ValidatedTextField
            value={limits.softMinimum}
            setValue={(value) =>
              setLimits((current) => ({
                minimum:
                  current.minimum === null
                    ? null
                    : clamp(current.minimum, null, value),
                softMinimum: value,
                softMaximum:
                  current.softMaximum === null
                    ? null
                    : clamp(current.softMaximum, value, null),
                maximum:
                  current.maximum === null
                    ? null
                    : clamp(current.maximum, value, null),
                hardness: current.hardness,
              }))
            }
            serialiser={limitSerialiser}
          />
          <Typography>{t("max")}</Typography>
          <ValidatedTextField
            value={limits.softMaximum}
            setValue={(value) =>
              setLimits((current) => ({
                minimum:
                  current.minimum === null
                    ? null
                    : clamp(current.minimum, null, value),
                softMinimum:
                  current.softMinimum === null
                    ? null
                    : clamp(current.softMinimum, null, value),
                softMaximum: value,
                maximum:
                  current.maximum === null
                    ? null
                    : clamp(current.maximum, value, null),
                hardness: current.hardness,
              }))
            }
            serialiser={limitSerialiser}
          />
          <Typography>{t("hardness")}</Typography>
          <ValidatedTextField
            value={limits.hardness}
            setValue={(value) =>
              setLimits((current) => ({
                minimum: current.minimum,
                softMinimum: current.softMinimum,
                softMaximum: current.softMaximum,
                maximum: current.maximum,
                hardness: value ?? 1.0,
              }))
            }
            serialiser={coefficientSerialiser}
          />
        </Stack>

        <Typography variant="h5" sx={{ mt: 2 }}>
          {t("coefficients")}
        </Typography>
        <LoadedContent data={classes}>
          {(loaded) => (
            <Stack gap={0.5}>
              {loaded.map((materialClass) => (
                <Stack
                  key={materialClass.id}
                  direction="row"
                  gap={2}
                  alignItems="center"
                >
                  <Typography sx={{ width: 150 }}>
                    {materialClass.name}
                  </Typography>
                  <ValidatedTextField
                    value={coefficients[materialClass.id] ?? null}
                    setValue={(value) =>
                      setCoefficients((current) => {
                        if (value !== null) {
                          return {
                            ...current,
                            [materialClass.id]: value,
                          };
                        } else {
                          delete current[materialClass.id];
                          return { ...current };
                        }
                      })
                    }
                    serialiser={coefficientSerialiser}
                  />
                </Stack>
              ))}
            </Stack>
          )}
        </LoadedContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary">
          {t("cancel")}
        </Button>
        {"remove" in props ? (
          <Button
            variant="contained"
            onClick={() => {
              props.remove(props.value.id);
              close();
            }}
            color="warning"
          >
            {t("delete")}
          </Button>
        ) : null}
        <Button
          variant="contained"
          onClick={() => {
            if ("edit" in props) {
              props.edit({
                ...resultingMixMaterialLimitData,
                name,
                coefficients_signature: props.value.coefficients_signature,
                id: props.id,
                chef_group_id: chefGroupId,
              });
            } else if ("create" in props) {
              props.create({
                ...resultingMixMaterialLimitData,
                chef_group_id: chefGroupId,
                name: name,
              });
            }
            close();
          }}
          disabled={!canSubmit}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
