import { useTenant } from "hooks/settings";
import { Setter } from "hooks/state/syncing";
import { useCallback } from "react";
import { usePublishChargeScheduleMutation } from "store/api/generatedApi";
import { ExportPlanState } from "./state";
import { LayeringKey, LayeringSummary } from "./layerings";

export const usePublish = (
  state: ExportPlanState,
  setState: Setter<ExportPlanState>
) => {
  const [publishChargeSchedules] = usePublishChargeScheduleMutation();
  const tenant = useTenant();

  return useCallback(() => {
    setState((current) => ({
      ...current,
      stage: "awaitingConfirmation",
      published: null,
      confirmed: [],
    }));

    publishChargeSchedules({
      tenantName: tenant,
      body: state.approved.map(({ key, summary: { layering } }) => ({
        plan_id: key.planId,
        period: state.periodIndex,
        chef_group_id: key.groupId,
        mix_number: key.mixNumber,
        layering,
      })),
    })
      .unwrap()
      .then((result) =>
        setState((current) => ({
          ...current,
          published: result,
          confirmed: [],
        }))
      )
      .catch(() =>
        setState((current) => ({
          ...current,
          stage: "awaitingConfirmationError",
        }))
      );
  }, [publishChargeSchedules, setState, state.approved, tenant]);
};

export const useApprove =
  (setState: Setter<ExportPlanState>) =>
  (key: LayeringKey, summary: LayeringSummary) => {
    setState((current) => ({
      ...current,
      approved: [...current.approved, { key, summary }],
    }));
  };

export const useUnapprove = (setState: Setter<ExportPlanState>) => () =>
  setState((current) => ({
    ...current,
    approved: current.approved.slice(0, current.approved.length - 1),
  }));
