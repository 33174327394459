import { Stack, Theme, Typography, useTheme } from "@mui/material";
import { useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

type Props = {
  value: number;
  decimalPlaces: number;
  fontWeight: Theme["typography"]["fontWeightBold"];
};

export const MassCell = ({ value, decimalPlaces, fontWeight }: Props) => {
  const theme = useTheme();
  const { format } = useNumberSerialiser({
    decimalPlaces,
    min: 0,
  });
  const unit = useUnitsFormatter(false);
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        px: 1,
      }}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Typography variant="body2Mono" fontWeight={fontWeight}>
        {format(value)}
        <Typography
          variant="body2Mono"
          sx={{ color: theme.palette.text.disabled }}
        >
          {unit("mass")}
        </Typography>
      </Typography>
    </Stack>
  );
};
