import { Stack } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { SummaryStat } from "src/components/common/summaryStat";
import { MaterialSummary } from "store/api/generatedApi";

type Props = {
  summary?: MaterialSummary;
};

export const Overview = ({ summary }: Props) => {
  const { t } = useTenantTranslation();

  const {
    price,
    consumption_planned,
    consumption_forecast,
    inventory_post_planned,
  } = (() => {
    if (summary) {
      return summary;
    } else {
      return {
        price: null,
        consumption_planned: null,
        consumption_forecast: null,
        inventory_post_planned: null,
      };
    }
  })();

  return (
    <Stack direction="row" spacing={4} sx={{ pt: 2, pb: 1 }}>
      {price !== null ? (
        <SummaryStat
          label={t("price")}
          value={price}
          decimalPlaces={2}
          units="specific_cost"
        />
      ) : null}
      <SummaryStat
        label={`${t("consumption")} (${t("inSchedule")})`}
        value={consumption_planned}
        decimalPlaces={0}
        units="mass"
      />
      <SummaryStat
        label={`${t("consumption")} (${t("inForecast")})`}
        value={consumption_forecast}
        decimalPlaces={0}
        units="mass"
      />
      <SummaryStat
        label={`${t("inventoryRemaining")} (${t("afterSchedule")})`}
        value={inventory_post_planned}
        decimalPlaces={0}
        units="mass"
      />
    </Stack>
  );
};
