import { Box, Stack, Typography } from "@mui/material";
import { Layout } from "../layout";
import { useTenantTranslation } from "hooks/formatters";
import {
  ChefGroup,
  ChemicalConstraint,
  ChemicalElement,
  ProductGroup,
} from "src/store/api/generatedApi";
import { ProductGroupChip } from "../chips/productGroup";
import { ChemistryGroupChip } from "../chips/chemistryGroup";
import { MixMaterialLimits } from "../chefgroup/materialConstraints";
import { chemicalExpressionFromConstraintCoefficients } from "components/constraints/chemicalExpressionFromConstraintCoefficients";

type Props = {
  productGroups: ProductGroup[];
  chemistryGroups: ChefGroup[];
  chemicalConstraints: ChemicalConstraint[];
  elements: { [id: number]: ChemicalElement };
  title: string;
  open: boolean;
  doClose: () => void;
};

export const ProductPanel = ({
  productGroups,
  chemistryGroups,
  chemicalConstraints,
  elements,
  title,
  open,
  doClose,
}: Props) => {
  const { t } = useTenantTranslation();
  return (
    <Layout title={title} open={open} doClose={doClose} width={500}>
      <Stack gap={3}>
        <Stack gap={2}>
          <Typography variant="h6">{t("chemicalTolerances")}</Typography>
          <Stack gap={1} flexDirection="column">
            {chemicalConstraints.map(({ coefficients, weight_percent_max }) => {
              const expression = chemicalExpressionFromConstraintCoefficients(
                coefficients,
                elements
              );
              return (
                <Typography key={expression}>
                  {expression} ≤ {weight_percent_max}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h6">{t("productGroups")}</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {productGroups.map((productGroup) =>
              productGroup.id !== null && productGroup.id !== undefined ? (
                <ProductGroupChip
                  key={productGroup.id}
                  name={productGroup.name}
                  steelGradeIds={productGroup.steel_grades.map(({ id }) => id)}
                />
              ) : null
            )}
          </Box>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h6">{t("chemistryGroups")}</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {chemistryGroups.map((chemistryGroup) =>
              chemistryGroup.id !== undefined && chemistryGroup.id !== null ? (
                <ChemistryGroupChip
                  name={chemistryGroup.name}
                  id={chemistryGroup.id}
                  key={chemistryGroup.id}
                />
              ) : null
            )}
          </Box>
        </Stack>
        {chemistryGroups.map((chemistryGroup) => (
          <Stack gap={2} key={chemistryGroup.id}>
            <Typography variant="h6">{t("materialLimits")}</Typography>
            <MixMaterialLimits chefGroupId={chemistryGroup.id!} />
          </Stack>
        ))}
      </Stack>
    </Layout>
  );
};
