import { useImportMixAllocationFromFileMutation } from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import { useInputs } from "../provider";
import { getBase64 } from "helpers";
import { doLoaded } from "../helpers";

export const useImportMixAllocationFromSpreadsheet = (sheetName: string) => {
  const [inputs, setInputs] = useInputs();
  const [importFromFile, { isLoading: loading }] =
    useImportMixAllocationFromFileMutation();
  const tenant = useTenant();

  return [
    (file: File) => {
      doLoaded(inputs, (loaded) => {
        void (async () => {
          const content = await getBase64(file);
          if (typeof content === "string") {
            importFromFile({
              tenantName: tenant,
              importMixAllocationBody: {
                file_content: content,
                sheet_name: sheetName,
                production: loaded.production,
              },
            })
              .unwrap()
              .then((result) =>
                setInputs((current) => ({
                  ...current,
                  production: current.production.map((production) => ({
                    ...production,
                    steel_grades: production.steel_grades.map((item) => ({
                      ...item,
                      mixes:
                        result.find(
                          (allocation) =>
                            allocation.production_uuid === production.uuid &&
                            allocation.steel_grade_name ===
                              item.steel_grade_name
                        )?.mixes ?? item.mixes,
                    })),
                  })),
                }))
              )
              .catch(console.error);
          }
          // Should really show an error otherwise
        })();
      });
    },
    { loading },
  ] as const;
};
