import { GridSortingInitialState } from "@mui/x-data-grid-premium";
import { SearchMaterials } from "contexts/search/context";

export const useSortDataGridByMaterial = (
  materials: SearchMaterials
): {
  materialNameColumnSortComparator: (v1: string, v2: string) => 0 | 1 | -1;
  materialNameSortModel: GridSortingInitialState;
} => {
  return {
    materialNameColumnSortComparator:
      getMaterialNameColumnSortComparator(materials),
    materialNameSortModel: {
      sortModel: [{ field: "material_name", sort: "asc" }],
    },
  };
};

const getMaterialNameColumnSortComparator = (
  materials: SearchMaterials
): ((v1: string, v2: string) => 0 | 1 | -1) => {
  const materialOptions = materials.byIndex.map((material) => material.name);
  return (v1: string, v2: string) => {
    const index1 = materialOptions.indexOf(v1);
    const index2 = materialOptions.indexOf(v2);

    if (index1 < index2) return -1;
    if (index1 > index2) return 1;
    return 0;
  };
};
