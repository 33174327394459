import { Serialiser, StringSerialiserOptions } from "./types";
import { useMemo } from "react";

export const useStringSerialiser = (
  defaultOptions?: StringSerialiserOptions
): Serialiser<string, StringSerialiserOptions> => {
  return useMemo(() => {
    return {
      format: (value) => value,
      parse: (text, optionsOverride) => {
        const allowEmpty =
          optionsOverride?.allowEmpty ?? defaultOptions?.allowEmpty ?? false;
        const trim = optionsOverride?.trim ?? defaultOptions?.trim ?? false;

        const trimmedText = trim ? text.trim() : text;

        if (trimmedText.length === 0 && !allowEmpty) {
          return { valid: false };
        } else {
          return { valid: true, value: trimmedText };
        }
      },
    };
  }, [defaultOptions]);
};
