import { Tooltip, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";

export const useDefaultPlanName = () => {
  const { t } = useTenantTranslation();
  return (index: number | null) =>
    index == null ? t("plan") : `${t("plan")} ${defaultPlanName(index)}`;
};

export const defaultPlanName = (index: number) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numChars =
    index == 0 ? 1 : Math.ceil(Math.log(index + 1) / Math.log(alphabet.length));
  const chars = [];
  for (let i = numChars - 1; i >= 0; i--) {
    const digit =
      (Math.floor(index / Math.pow(alphabet.length, i)) % alphabet.length) -
      (i > 0 ? 1 : 0);
    chars.push(alphabet[digit]);
  }
  return chars.join("");
};

export const PlanName = ({
  planName,
  defaultName,
  variant,
  color,
  maxWidth,
}: {
  planName: string | null;
  defaultName: string;
  variant: "h2" | "h5" | "body2";
  color?: "text.secondary";
  maxWidth?: number;
}) => {
  return (
    <Tooltip title={maxWidth ? planName ?? defaultName : undefined}>
      <Typography
        variant={variant}
        color={color}
        sx={
          maxWidth
            ? {
                maxWidth,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }
            : undefined
        }
      >
        {planName ?? defaultName}
      </Typography>
    </Tooltip>
  );
};
