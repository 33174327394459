import { Box, Stack, Typography } from "@mui/material";
import { Layout } from "../layout";
import { useTenantTranslation } from "hooks/formatters";
import { SteelGrade } from "src/store/api/generatedApi";
import { liftLoadedState } from "models/loaded";
import { useProductGroups, useSteelGrades } from "contexts/search/provider";
import { LoadedContent } from "components/common/loading/loadedContent";
import { SteelGradeChip } from "components/common/chips/steelGrade";

type Props = {
  steelGrades: SteelGrade[];
  title: string;
  open: boolean;
  doClose: () => void;
};

export const LoadedProductGroupPanel = ({
  id,
  open,
  doClose,
}: {
  id: number;
  open: boolean;
  doClose: () => void;
}) => {
  return (
    <LoadedContent
      data={liftLoadedState({
        productGroups: useProductGroups(),
        steelGrades: useSteelGrades(),
      })}
    >
      {(loaded) => {
        const productGroup = loaded.productGroups.byId[id]!;
        return (
          <ProductGroupPanel
            steelGrades={productGroup.steel_grades.map(
              ({ id }) => loaded.steelGrades.byId[id]!
            )}
            title={productGroup.name}
            open={open}
            doClose={doClose}
          />
        );
      }}
    </LoadedContent>
  );
};

export const ProductGroupPanel = ({
  steelGrades,
  title,
  open,
  doClose,
}: Props) => {
  const { t } = useTenantTranslation();
  return (
    <Layout title={title} open={open} doClose={doClose} width={500}>
      <Stack gap={1}>
        <Typography variant="h6">{t("steelGrades")}</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {steelGrades.map(
            (steelGrade) =>
              steelGrade.id !== undefined &&
              steelGrade.id !== null && (
                <SteelGradeChip
                  key={steelGrade.id}
                  id={steelGrade.id}
                  name={steelGrade.name}
                  chemicalConstraints={steelGrade.chemical_constraints}
                />
              )
          )}
        </Box>
      </Stack>
    </Layout>
  );
};
