import {
  useListSearchesQuery,
  useSendSearchNotificationMutation,
} from "src/store/api/generatedApi";
import { useSearch } from "./provider";
import { useEffect } from "react";
import { useTenant } from "hooks/settings";
import { useNavigateTo } from "hooks/navigation";
import { loadedEndpoint, mapLoadedUnpack } from "models/loaded";

export const useSearchViewedNotification = () => {
  const tenant = useTenant();
  const [sendNotification] = useSendSearchNotificationMutation();
  const { clientSearchId } = useSearch();

  useEffect(() => {
    if (clientSearchId != null) {
      void sendNotification({ tenantName: tenant, searchId: clientSearchId });
    }
  }, [clientSearchId]);
};

export const useTrackSearchIdInUrl = () => {
  /* When navigating automatically - as the side effect of an edit instead of in
    response to the user specifically clicking on a link to a new location - the
    new URL should not be added to history.  This makes the history button work
    in a more intuitive way. */
  const navigateTo = useNavigateTo(true);

  const serverSearchId = mapLoadedUnpack(
    useSearch().parameters.server,
    ({ id }) => id
  );

  useEffect(() => {
    if (serverSearchId != null) {
      navigateTo({ params: { search: serverSearchId.toString() } });
    }
  }, [serverSearchId]);
};

export type MostRecentSearch = {
  searchId: number | null;
  currentlyLoaded: boolean;
};

export const useLoadMostRecentSearch = (): MostRecentSearch => {
  const context = useSearch();

  const searchId: number | null = mapLoadedUnpack(
    loadedEndpoint(useListSearchesQuery({ tenantName: useTenant(), limit: 1 })),
    (result) => result[0]?.search_id ?? null
  );

  useEffect(() => {
    if (searchId != null) {
      context.setSearchId(searchId);
    }
  }, [searchId]);

  const currentlyLoaded =
    searchId !== null && searchId === context.clientSearchId;

  return { searchId, currentlyLoaded };
};
