import { useLocation, useNavigate } from "react-router-dom";
import { useTenant } from "./settings";
import { useCreateSearchMutation } from "src/store/api/generatedApi";
import { useFormatLocalDate } from "hooks/useFormatLocalDate";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "./formatters";

export const useNavigateTo = (replace?: boolean) => {
  const currentLocation = useLocation();
  const navigate = useNavigate();

  return ({
    location,
    params,
  }: {
    location?: string;
    params?: { [name: string]: string | null | undefined };
  }) => {
    const currentParams = new URLSearchParams(currentLocation.search);

    Object.entries(params ?? {}).forEach(([name, value]) => {
      if (value != null) {
        return currentParams.set(name, value);
      } else {
        currentParams.delete(name);
      }
    });

    navigate(
      {
        pathname: location ?? currentLocation.pathname,
        search: currentParams.toString(),
      },
      { replace }
    );
  };
};

export const useNavigateToTenantHome = () => {
  const navigateTo = useNavigateTo();

  return (tenant: string) => navigateTo({ location: `/${tenant}` });
};

export const useNavigateToHome = () => {
  const navigateTo = useNavigateTo();
  const tenant = useTenant();

  return () => navigateTo({ location: `/${tenant}/v3` });
};

export const useNavigateToNewSearch = () => {
  const tenant = useTenant();
  const [createSearch, { isLoading }] = useCreateSearchMutation();
  const navigate = useNavigateToSearch();
  const formatDate = useFormatLocalDate("datetime");
  const notifyStatus = useNotifyStatus();
  const { t } = useTenantTranslation();

  return [
    () =>
      createSearch({
        tenantName: tenant,
        createSearch: {
          name: formatDate(new Date()),
          search_type: "purchasing",
          search: null,
        },
      })
        .unwrap()
        .then(({ search_id }) => navigate(search_id))
        .catch(() => notifyStatus({ type: "error", text: t("unknownError") })),
    { isLoading },
  ] as const;
};

export const useNavigateToSearch = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();

  return (searchId: number, period = 1) =>
    navigateTo({
      location: `/${tenant}/v3/search`,
      params: {
        search: searchId.toString(),
        plan: null,
        period: period.toString(),
      },
    });
};

export const useNavigateToDebugSearch = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();

  return (searchId: number) =>
    navigateTo({
      location: `/${tenant}/v3/debug-search`,
      params: { search: searchId.toString(), plan: null },
    });
};

export const useNavigateToPlan = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();

  return (planId: number, searchId: number, period = 1) => {
    navigateTo({
      location: `/${tenant}/v3/plan`,
      params: {
        plan: planId.toString(),
        search: searchId.toString(),
        period: period.toString(),
      },
    });
  };
};

export const useSearchContextGroupListRoute = () => {
  const tenantName = useTenant();
  return `/${tenantName}/v3/search_context_group`;
};

export const useNavigateToSearchContextGroupList = () => {
  const location = useSearchContextGroupListRoute();
  const navigateTo = useNavigateTo();

  return () => {
    navigateTo({
      location,
    });
  };
};

export const useNavigateToSearchContextGroup = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();

  return ({
    searchContextGroupId,
    searchContextId,
  }: {
    searchContextGroupId: number;
    searchContextId: number;
  }) => {
    navigateTo({
      location: `/${tenant}/v3/search_context_group/${searchContextGroupId}/search_context/${searchContextId}`,
    });
  };
};

export const useNavigateToSearchContextMixMaterialLimitSet = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();

  return ({
    searchContextGroupId,
    searchContextId,
    mixMaterialLimitSetId,
  }: {
    searchContextGroupId: number;
    searchContextId: number;
    mixMaterialLimitSetId: number;
  }) => {
    navigateTo({
      location: `/${tenant}/v3/search_context_group/${searchContextGroupId}/search_context/${searchContextId}/mix_material_limit_set/${mixMaterialLimitSetId}`,
    });
  };
};

export const useNavigateToSearchPlanPeek = () => {
  const tenant = useTenant();
  const navigateTo = useNavigateTo();

  return (searchId: number, planId: number) => {
    navigateTo({
      location: `/${tenant}/v3/search`,
      params: { search: searchId.toString(), plan: planId.toString() },
    });
  };
};
