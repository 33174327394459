import { Chip, Tooltip } from "@mui/material";
import { SummaryTag } from "store/api/generatedApi";
import { useTenantTranslation } from "hooks/formatters";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

export const PlanTagChip = ({ tag }: { tag: SummaryTag }) => {
  const { t } = useTenantTranslation();
  const tooltip = tag.description_key ? (
    <div>
      {t(tag.description_key)}
      <ul>
        {tag.details.map((detail) => (
          <li key={`${tag.tag.join("")}-${detail}`}>{t(detail)}</li>
        ))}
      </ul>
    </div>
  ) : null;
  const tags: string[] = tag.tag.map((item) => `${t(item)}`);
  return (
    <Chip
      sx={{
        "& .MuiChip-deleteIcon": {
          color: "action.active",
          "&:hover": {
            color: "action.active",
          },
        },
      }}
      label={tags.join(" ")}
      size="small"
      onDelete={() => null}
      deleteIcon={
        tooltip ? (
          <Tooltip title={tooltip}>
            <InfoRoundedIcon />
          </Tooltip>
        ) : (
          <span />
        )
      }
    />
  );
};
