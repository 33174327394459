import React from "react";
import { Link, useTheme } from "@mui/material";
import { MaterialDetailDrawer } from "components/common/panels/material";

type Props = {
  id: number;
  name: string;
};

export const MaterialPropertyViewCell = ({ name, id }: Props) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      <Link
        variant="body2"
        color={theme.palette.text.primary}
        fontWeight="bold"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenModal(true)}
      >
        {name}
      </Link>
      <MaterialDetailDrawer
        title={name}
        id={id}
        open={openModal}
        doClose={() => setOpenModal(false)}
      />
    </>
  );
};
