import { ChemicalConstraint } from "src/store/api/generatedApi";
import {
  useChemicalElements,
  useChemistryGroups,
  useProductGroups,
} from "contexts/search/provider";
import React from "react";
import { ClickableChip } from "./clickable";
import { filterForSteelGrade } from "src/utils/filterForSteelGrades";
import { ProductPanel } from "../panels/product";

type Props = {
  id: number;
  name: string;
  chemicalConstraints: ChemicalConstraint[];
};

export const SteelGradeChip = ({ name, chemicalConstraints, id }: Props) => {
  const [openPanel, setOpenPanel] = React.useState(false);
  const productGroups = useProductGroups();
  const chemicalElements = useChemicalElements();
  const chemistryGroups = useChemistryGroups();
  const isLoaded =
    productGroups.status === "success" &&
    chemicalElements.status === "success" &&
    chemistryGroups.status === "success";
  return (
    <>
      <ClickableChip label={name} onClick={() => setOpenPanel(true)} />
      {isLoaded ? (
        <ProductPanel
          open={openPanel}
          doClose={() => setOpenPanel(false)}
          title={name}
          productGroups={filterForSteelGrade(id, productGroups.data)}
          chemicalConstraints={chemicalConstraints}
          chemistryGroups={filterForSteelGrade(id, chemistryGroups.data)}
          elements={chemicalElements.data.byId}
        />
      ) : null}
    </>
  );
};
