import { ContentCopy, ContentPaste } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { SyncedContextData } from "contexts/search/context";
import { useSearch } from "contexts/search/provider";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import { doLoaded, liftLoadedState } from "models/loaded";
import {
  InventoryItem,
  MiscParams,
  ObtainableBundleItem,
  ProductGroupPlanItem,
  SearchType,
  SteelGradePlanItem,
  SyncedSearchParameters,
  SyncedSearchPeriod,
  TargetBasketItem,
  TargetInventoryItem,
} from "src/store/api/generatedApi";

type CopiedSearch = {
  search_type: SearchType;
  misc_params: MiscParams;
  inventory: (InventoryItem & { material_name: string })[];
  target_baskets: (TargetBasketItem & { chef_group_name: string })[];
  periods: CopiedPeriod[];
};

type CopiedPeriod = {
  name: string | null;
  start_timestamp: string | null;
  end_timestamp: string | null;
  production_plan: CopiedProductionPlan;
  obtainable_bundles: (ObtainableBundleItem & { material_name: string })[];
  target_inventory: (TargetInventoryItem & { material_name: string })[];
  material_exclusivity: { material_names: string[] }[];
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  optimisation_objective_weighting: number;
};

type CopiedProductionPlan = {
  product_group_items:
    | (ProductGroupPlanItem & { product_group_name: string })[]
    | null;
  steel_grade_items:
    | (SteelGradePlanItem & { steel_grade_name: string })[]
    | null;
};

const copySearch = (
  search: SyncedSearchParameters,
  context: SyncedContextData
): CopiedSearch => {
  return {
    search_type: search.search_type,
    misc_params: search.misc_params,
    inventory: search.inventory.map((item) => ({
      ...item,
      material_name: context.materials.byId[item.material_id]!.name,
    })),
    target_baskets: search.target_baskets.map((item) => ({
      ...item,
      chef_group_name: context.chemistryGroups.byId[item.chef_group_id]!.name,
    })),
    periods: search.periods.map((period) => copyPeriod(period, context)),
  };
};

const copyPeriod = (
  period: SyncedSearchPeriod,
  context: SyncedContextData
): CopiedPeriod => {
  return {
    name: period.name ?? null,
    start_timestamp: period.start_timestamp ?? null,
    end_timestamp: period.end_timestamp ?? null,
    production_plan: {
      product_group_items:
        period.production_plan.product_group_items?.map((item) => ({
          ...item,
          product_group_name:
            context.productGroups.byId[item.product_group_id]!.name,
        })) ?? null,
      steel_grade_items:
        period.production_plan.steel_grade_items?.map((item) => ({
          ...item,
          steel_grade_name: context.steelGrades.byId[item.steel_grade_id]!.name,
        })) ?? null,
    },
    obtainable_bundles: period.obtainable_bundles.map((item) => ({
      ...item,
      material_name: context.materials.byId[item.material_id]!.name,
    })),
    target_inventory: period.target_inventory.map((item) => ({
      ...item,
      material_name: context.materials.byId[item.material_id]!.name,
    })),
    material_exclusivity: period.material_exclusivity.map((item) => ({
      material_names: item.material_ids.map(
        (id) => context.materials.byId[id]!.name
      ),
    })),
    suppress_mix_material_exclusivity_constraints:
      period.suppress_mix_material_exclusivity_constraints,
    suppress_min_feasible_mass_constraints:
      period.suppress_min_feasible_mass_constraints,
    optimisation_objective_weighting: period.optimisation_objective_weighting,
  };
};

const pasteSearch = (
  current: SyncedSearchParameters,
  search: CopiedSearch,
  context: SyncedContextData
) => {
  return {
    ...current,
    search_type: search.search_type,
    misc_params: search.misc_params,
    inventory: search.inventory.map(({ material_name, ...item }) => ({
      ...item,
      material_id: context.materials.byName[material_name]!.id,
    })),
    target_baskets: search.target_baskets.map(
      ({ chef_group_name, ...item }) => ({
        ...item,
        chef_group_id: context.chemistryGroups.byName[chef_group_name]!.id,
      })
    ),
    periods: search.periods.map((period) => pastePeriod(period, context)),
  };
};

const pastePeriod = (
  period: CopiedPeriod,
  context: SyncedContextData
): SyncedSearchPeriod => {
  return {
    name: period.name,
    start_timestamp: period.start_timestamp,
    end_timestamp: period.end_timestamp,
    production_plan: {
      product_group_items:
        period.production_plan.product_group_items?.map(
          ({ product_group_name, ...item }) => ({
            ...item,
            product_group_id:
              context.productGroups.byName[product_group_name]!.id,
          })
        ) ?? null,
      steel_grade_items:
        period.production_plan.steel_grade_items?.map(
          ({ steel_grade_name, ...item }) => ({
            ...item,
            steel_grade_id: context.steelGrades.byName[steel_grade_name]!.id,
          })
        ) ?? null,
    },
    obtainable_bundles: period.obtainable_bundles.map(
      ({ material_name, ...item }) => ({
        ...item,
        material_id: context.materials.byName[material_name]!.id,
      })
    ),
    target_inventory: period.target_inventory.map(
      ({ material_name, ...item }) => ({
        ...item,
        material_id: context.materials.byName[material_name]!.id,
      })
    ),
    material_exclusivity: period.material_exclusivity.map((item) => ({
      material_ids: item.material_names.map(
        (name) => context.materials.byName[name]!.id
      ),
    })),
    suppress_mix_material_exclusivity_constraints:
      period.suppress_mix_material_exclusivity_constraints,
    suppress_min_feasible_mass_constraints:
      period.suppress_min_feasible_mass_constraints,
    optimisation_objective_weighting: period.optimisation_objective_weighting,
  };
};

export const CopySearchButton = () => {
  const { t } = useTenantTranslation();
  const notifyStatus = useNotifyStatus();

  const {
    parameters: { client: search },
    context,
  } = useSearch();

  const dependencies = liftLoadedState({ search, context });

  return (
    <Box>
      <LoadingButton
        variant="outlined"
        color="secondary"
        startIcon={<ContentCopy />}
        loading={dependencies.status !== "success"}
        onClick={() =>
          doLoaded(dependencies, (loaded) =>
            navigator.clipboard
              .writeText(
                JSON.stringify(copySearch(loaded.search, loaded.context))
              )
              .then(() =>
                notifyStatus({
                  type: "success",
                  text: t("productionCopiedToClipboard"),
                })
              )
          )
        }
      >
        {t("copyVerb")}
      </LoadingButton>
    </Box>
  );
};

export const PasteSearchButton = () => {
  const { t } = useTenantTranslation();
  const notifyStatus = useNotifyStatus();

  const {
    parameters: { setClient: setSearch, client: search },
    context,
  } = useSearch();

  const dependencies = liftLoadedState({ search, context });

  return (
    <Box>
      <LoadingButton
        variant="outlined"
        color="secondary"
        startIcon={<ContentPaste />}
        loading={dependencies.status !== "success"}
        onClick={() =>
          doLoaded(dependencies, (loaded) => {
            return navigator.clipboard
              .readText()
              .then((text) => {
                const parsedSearch = pasteSearch(
                  loaded.search,
                  JSON.parse(text) as CopiedSearch,
                  loaded.context
                );

                setSearch(() => parsedSearch);
              })
              .catch(() =>
                notifyStatus({
                  type: "error",
                  text: t("failedToReadProduction"),
                })
              );
          })
        }
      >
        {t("pasteVerb")}
      </LoadingButton>
    </Box>
  );
};
