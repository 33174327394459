import { Stack, Typography, Button, useTheme } from "@mui/material";

export const Confirm = ({ confirm }: { confirm: () => void }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        justifyContent: "center",
        height: "100%",
        margin: "0 auto",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Stack sx={{ gap: 1, alignItems: "center" }}>
        <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
          Are you sure want to deploy this plan to production?
        </Typography>
      </Stack>
      <Button variant="contained" onClick={confirm}>
        Confirm
      </Button>
    </Stack>
  );
};
