import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  Group,
  Measure,
  usePeriodOption,
  useGroupOption,
  useGrouping,
  useGroups,
  useMeasureOption,
  useMixes,
  useSteelGradesOption,
  usePeriodMaterialSummaries,
} from "./ProductionContext";
import { MaterialRead, ProductGroup } from "src/store/api/generatedApi";
import { PlanGroup, PlanMix } from "./productionContent";
import { useHeatTextFormatter } from "hooks/heats";
import {
  DataGridPremium,
  GridColDef,
  GridPinnedRowsProp,
} from "@mui/x-data-grid-premium";
import { TitleColumnHeader } from "../common/TitleColumnHeader";
import { HeatRowHeader } from "./HeatRowHeader";
import { ScrapColumnHeader } from "./ScrapColumnHeader";
import { SxProps, Theme, Typography } from "@mui/material";
import { AggregationCell, VisualisedCell } from "./ProductionCell";

type RowData = Record<number, PlanMix & { id: number }>;

type AggregationRowId = "total_mass" | "total_cost";

type Heat = {
  heats: number;
  name: string;
  mix: number;
  group: number;
  multipleMixes: boolean;
  hasDetails: boolean;
};

type HeatRow = {
  id: number;
  heat: Heat;
  data: RowData;
};

type AggregationRow = { id: AggregationRowId; data: Record<number, number> };

type Row = HeatRow | AggregationRow;

type Props = {
  materials: MaterialRead[];
  sx: SxProps<Theme>;
};

const useLayout = (materials: MaterialRead[]) => {
  const groupOption = useGroupOption();
  const periodOption = usePeriodOption();
  const measureOption = useMeasureOption();
  const periodMaterialSummaries = usePeriodMaterialSummaries();
  const groups = useGroups();
  const mixes = useMixes();
  const grouping = useGrouping();
  const steelGrades = useSteelGradesOption();
  const { t } = useTenantTranslation();
  const unit = useUnitsFormatter(true);

  const haveDetails = groupOption !== Group.Product && periodOption === 1;

  const groupings: [ProductGroup, PlanGroup][] = groups
    .map((group): [ProductGroup, PlanGroup] => [
      grouping.byId[group.group]!,
      group,
    ])
    .filter(
      ([grouping]) =>
        steelGrades.size === 0 ||
        grouping.steel_grades.some(({ name }) => steelGrades.has(name))
    );
  const heatTextFormatter = useHeatTextFormatter();

  const heatColumn: GridColDef<Row> = {
    field: "heat",
    minWidth: 200,
    headerClassName: "group--header",
    cellClassName: "group--header",
    renderHeader: () => <TitleColumnHeader title={t("heat")} />,
    valueGetter: (_, row: Row) => {
      switch (row.id) {
        case "total_mass":
          return `${t("totalMass")}${unit("mass")}`;
        case "total_cost":
          return `${t("totalPrice")}${unit("cost")}}`;
        default: {
          return heatTextFormatter(
            row.heat.multipleMixes,
            row.heat.name,
            row.heat.mix
          );
        }
      }
    },
    renderCell: ({ row }) => {
      switch (row.id) {
        case "total_mass": {
          return (
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              {`${t("totalMass")}${unit("mass")}`}
            </Typography>
          );
        }
        case "total_cost": {
          return (
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              {`${t("totalPrice")}${unit("cost")}`}
            </Typography>
          );
        }
        default: {
          return (
            <HeatRowHeader
              heats={row.heat.heats}
              mix={row.heat.mix}
              multipleMixes={row.heat.multipleMixes}
              name={row.heat.name}
              group={row.heat.group}
              groupOption={groupOption}
            />
          );
        }
      }
    },
  };

  const materialColumns: GridColDef<Row>[] = materials.map(
    (material, index) => ({
      field: "group_" + index.toString(),
      sortable: false,
      minWidth: 132,
      headerClassName: "group--header",
      renderHeader: () => {
        return <ScrapColumnHeader name={material.name} id={material.id} />;
      },
      valueGetter: (_, { id: rowId, data }) => {
        switch (rowId) {
          case "total_mass":
          case "total_cost": {
            return null;
          }
          default: {
            const datum = data[material.id];
            if (datum) {
              const { materials, total, id } = datum;
              const value = materials[id];
              if (value !== undefined) {
                switch (measureOption) {
                  case Measure.Mass: {
                    return value;
                  }
                  case Measure.Percentage: {
                    return value / total;
                  }
                }
              } else {
                return null;
              }
            } else {
              return null;
            }
          }
        }
      },
      renderCell: ({ row }) => {
        switch (row.id) {
          case "total_mass": {
            const total = row.data[material.id]!;
            return <AggregationCell value={total} variant="mass" />;
          }
          case "total_cost": {
            const cost = row.data[material.id]!;
            return <AggregationCell value={cost} variant="cost" />;
          }
          default: {
            const {
              materials,
              total,
              id: materialId,
              maximum,
            } = row.data[material.id]!;
            const value = materials[materialId];
            if (value) {
              return (
                <VisualisedCell
                  value={materials[materialId]!}
                  total={total}
                  maximum={maximum}
                />
              );
            } else {
              return null;
            }
          }
        }
      },
    })
  );

  const rows: HeatRow[] = groupings.map(([{ name }, group], index) => {
    const data = materials.reduce((row, material) => {
      const summary = mixes[group.group]![group.mix];
      return {
        ...row,
        [material.id]: { ...summary, id: material.id },
      };
    }, {});
    return {
      id: index,
      heat: {
        ...group,
        hasDetails: haveDetails,
        name,
      },
      data,
    };
  });

  const columns = [heatColumn, ...materialColumns];

  const pinnedRows: GridPinnedRowsProp<AggregationRow> = {
    bottom: rows[0]
      ? [
          {
            id: "total_mass",
            data: materials.reduce((mapping, material) => {
              const materialSummary = periodMaterialSummaries.find(
                (summary) => summary.material === material.name
              );
              return {
                ...mapping,
                [material.id]: materialSummary
                  ? materialSummary.mass_consumed
                  : 0,
              };
            }, {}),
          },
          {
            id: "total_cost",
            data: materials.reduce((mapping, material) => {
              const materialSummary = periodMaterialSummaries.find(
                (summary) => summary.material === material.name
              );
              return {
                ...mapping,
                [material.id]: materialSummary
                  ? materialSummary.cost_consumed
                  : 0,
              };
            }, {}),
          },
        ]
      : [],
  };
  return {
    columns,
    rows,
    pinnedRows,
    pinnedColumns: {
      left: ["heat"],
    },
    rowHeight: 52,
    columnHeaderHeight: 32,
  };
};

export const ScrapXHeatYTable = ({ materials, sx }: Props) => {
  const {
    pinnedColumns,
    columns,
    pinnedRows,
    rows,
    columnHeaderHeight,
    rowHeight,
  } = useLayout(materials);

  return (
    <DataGridPremium
      sx={sx}
      pinnedColumns={pinnedColumns}
      columns={columns}
      pinnedRows={pinnedRows}
      rows={rows}
      columnHeaderHeight={columnHeaderHeight}
      rowHeight={rowHeight}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};
