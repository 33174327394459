import { Box, DialogContent, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Table } from "../table";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { Setter, TimelineSettings } from "./timeline";
import { MixDetailButton } from "./mixes";
import {
  ExperimentalInputs,
  ProductionBlock,
} from "src/store/api/generatedApi";
import { BlockDetail, BlockHeader, BlockView } from "../blocks/block";
import { takeHeats } from "../blocks/production";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";

export const ProductionBlockView = ({
  production,
  timeline,
  setCustomerInputs,
}: {
  production: ProductionBlock;
  timeline: TimelineSettings;
  setCustomerInputs: Setter<ExperimentalInputs>;
}) => {
  return (
    <BlockView
      block={production}
      timeline={timeline}
      timelineContent={
        <Box sx={{ padding: 1 }}>
          <Typography
            fontSize={18}
            fontWeight={800}
            sx={{ userSelect: "none" }}
          >
            {production.name ??
              production.steel_grades
                .map((item) => item.steel_grade_name)
                .join(", ")}
          </Typography>
        </Box>
      }
      detailContent={
        <ProductionBlockDetail
          production={production}
          setCustomerInputs={setCustomerInputs}
        />
      }
      offset={400}
    />
  );
};

const ProductionBlockDetail = ({
  production,
  setCustomerInputs,
}: {
  production: ProductionBlock;
  setCustomerInputs: Setter<ExperimentalInputs>;
}) => {
  const data = Object.fromEntries(
    production.steel_grades.map((item) => [item.steel_grade_name, item])
  );
  const heatsSerialiser = useNumberSerialiser({
    decimalPlaces: 0,
    min: 0,
  });

  const setProduction = useCallback(
    (update: (production: ProductionBlock) => ProductionBlock) =>
      setCustomerInputs((current) => ({
        ...current,
        production: [
          ...current.production.filter((item) => item.uuid !== production.uuid),
          update(production),
        ],
      })),
    [setCustomerInputs]
  );

  const setHeats = useCallback(
    (rowId: string, heats: number) =>
      setProduction((current) => ({
        ...current,
        steel_grades: current.steel_grades.map((item) =>
          item.steel_grade_name === rowId ? { ...item, heats } : item
        ),
      })),
    [setProduction]
  );

  const render = useCallback(
    (rowId: string, columnId: string) => {
      const item = data[rowId];
      if (item === undefined) {
        return;
      }
      switch (columnId) {
        case "steelGrade":
          return rowId;
        case "heats":
          return (
            <ValidatedTextField
              value={item.heats}
              setValue={(heats) => setHeats(rowId, heats)}
              serialiser={heatsSerialiser}
            />
          );
        case "mixes":
          return (
            <MixDetailButton
              mixes={item.mixes ?? []}
              setMixes={(update) => {
                setProduction((current) => {
                  return {
                    ...current,
                    steel_grades: current.steel_grades.map((steelGradeItem) =>
                      steelGradeItem.steel_grade_name === rowId
                        ? {
                            ...steelGradeItem,
                            mixes: takeHeats(
                              steelGradeItem.heats,
                              update(steelGradeItem.mixes ?? [])
                            )[0],
                          }
                        : steelGradeItem
                    ),
                  };
                });
              }}
              steelGradeName={data[rowId]!.steel_grade_name}
            />
          );
      }
    },
    [production, setHeats]
  );

  const rows = useMemo(() => {
    return production.steel_grades.map((item) => item.steel_grade_name);
  }, [production]);

  const append = useCallback(
    (rowId: string) => {
      if (
        // Don't add multiple rows with the same steel grade
        !production.steel_grades.some((item) => item.steel_grade_name === rowId)
      ) {
        setProduction((current) => ({
          ...current,
          steel_grades: [
            ...current.steel_grades,
            { steel_grade_name: rowId, heats: 0, mixes: null },
          ],
        }));
      }
    },
    [setProduction]
  );

  const remove = useCallback(
    (rowId: string) =>
      setProduction((current) => ({
        ...current,
        steel_grades: current.steel_grades.filter(
          (item) => item.steel_grade_name !== rowId
        ),
      })),
    [setProduction]
  );

  return (
    <BlockDetail>
      <BlockHeader
        block={production}
        setBlock={(update) =>
          setProduction((current) => ({ ...current, ...update(production) }))
        }
        name={
          production.name ??
          production.steel_grades
            .map((item) => item.steel_grade_name)
            .join(", ")
        }
      />
      <DialogContent>
        <Stack gap={2}>
          <Table
            rows={rows}
            columns={["steelGrade", "heats", "mixes"]}
            render={render}
            append={append}
            remove={remove}
          />
        </Stack>
      </DialogContent>
    </BlockDetail>
  );
};
