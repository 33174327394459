import { Typography } from "@mui/material";
import { useTenantTranslation } from "src/hooks/formatters";
import { AccountPageLayout } from "components/common/AccountPageLayout";
import { useSessions } from "../search/SearchSelector/hooks";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { useNavigateToNewSearch } from "hooks/navigation";
import { SearchesTable } from "./SearchesTable";

export const SearchesPage = () => {
  const [navigateToNewSearch, { isLoading }] = useNavigateToNewSearch();
  const { t } = useTenantTranslation();

  return (
    <AccountPageLayout
      title={t("searches")}
      actionButtonTitle={t("newSearch")}
      actionButtonOnClick={() => navigateToNewSearch()}
      actionButtonLoading={isLoading}
    >
      <Typography color="text.secondary" sx={{ mb: 5 }}>
        {t("searchesDescription")}
      </Typography>
      <LoadedContent data={useSessions()} disableFetching>
        {(sessions) => <SearchesTable sessions={sessions} />}
      </LoadedContent>
    </AccountPageLayout>
  );
};
