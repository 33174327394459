import { Box, Slide } from "@mui/material";
import { PlanPage } from "../../plan/PlanPage";
import { usePlanId, useSearchId } from "hooks/search";
import { PlanIdBoundary } from "../../common/boundary/PlanId";
import { Navigate } from "react-router-dom";
import { useTenant } from "hooks/settings";
import { usePeriodIndex } from "hooks/periodIndex";

type Props = {
  width: string;
};

export const PlanPanel = ({ width }: Props) => {
  const planId = usePlanId();
  const tenant = useTenant();
  const searchId = useSearchId();
  const period = usePeriodIndex();
  return (
    <Slide
      in={planId === null ? false : true}
      direction="left"
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          height: "100%",
          width,
          borderLeft: 1,
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "#ffffff",
          zIndex: 1,
          overflowY: "auto",
        }}
      >
        {planId ? (
          <PlanIdBoundary
            fallback={
              <Navigate
                to={
                  searchId !== null
                    ? `/${tenant}/v3/search?search=${searchId}&period=${period}`
                    : `/${tenant}/v3`
                }
              />
            }
          >
            <PlanPage />
          </PlanIdBoundary>
        ) : null}
      </Box>
    </Slide>
  );
};
