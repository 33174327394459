import {
  FormControl,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { PlanItem, PlanItemName } from "./types";
import { useFormattedTotalHeats } from "hooks/formatters";
import { sort } from "d3";
import { useRef } from "react";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { usePercentageSerialiser } from "hooks/serialisers/percentages";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";

/**
 * Input for the number of heats in a particular plan item.
 *
 * Displays the current number, alongside the percentage of the total number of
 * heats that makes up, and a progress bar filled to also indicate that
 * proportion.
 */
export const HeatsInput = <T,>({
  name,
  item,
  setItem,
  totalHeats,
  disabled,
  focusNextHeatsInput,
}: {
  name: PlanItemName;
  item: PlanItem<T>;
  setItem: (item: PlanItem<T>) => void;
  totalHeats: number;
  disabled?: boolean;
  focusNextHeatsInput: (currentHeatsInput: Element) => void;
}) => {
  const theme = useTheme();
  const numberSerialiser = useNumberSerialiser({ min: 0, decimalPlaces: 0 });
  const { format } = usePercentageSerialiser(1);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <FormControl fullWidth>
      <div ref={ref} className="heats-input">
        <ValidatedTextField
          value={item.heats}
          setValue={(heats) => setItem({ ...item, heats: heats })}
          serialiser={numberSerialiser}
          disabled={disabled}
          onEditConfirmedWithKeyboard={() => {
            if (ref.current) {
              focusNextHeatsInput(ref.current);
            }
          }}
          onEditStartTransform={(text) => (text === "0" ? "" : text)}
          textFieldProps={{
            id: "heats-input-" + name(item).replace(/ /g, "-"),
            fullWidth: true,
            sx: {
              "& .MuiInputBase-input": {
                color: item.heats === 0 ? "text.disabled" : "text.primary",
                backgroundColor: "white",
                borderRadius: 1,
              },
            },
            InputProps: {
              sx: {
                fontSize: theme.typography.body1Mono.fontSize,
                fontWeight: theme.typography.body1Mono.fontWeight,
                lineHeight: theme.typography.body1Mono.lineHeight,
                fontFamily: theme.typography.body1Mono.fontFamily,
              },
            },
          }}
        />
      </div>

      {item.heats ? (
        <LinearProgress
          variant="determinate"
          value={(item.heats / totalHeats) * 100}
          sx={{
            position: "absolute",
            bottom: 1,
            left: 1,
            right: 1,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            backgroundColor: "grey.100",
          }}
        />
      ) : null}

      {item.heats === 0 ? null : (
        <Typography
          variant="body1Mono"
          color="text.disabled"
          sx={{ position: "absolute", right: 0, bottom: 7, paddingRight: 2 }}
        >
          {format(totalHeats === 0 ? null : item.heats / totalHeats)}%
        </Typography>
      )}
    </FormControl>
  );
};

/**
 * Input for the total number of heats.
 *
 * Displays the current total of other elements, along with a formatted
 * piece of text (e.g. the equivalent number of days of production).
 * When modified, scales the rest of the elements without modifying the
 * percentages (up to rounding).
 */
export const TotalHeatsInput = <T,>({
  items,
  setItems,
  scaleItems,
  setScaleItems,
  disabled,
  tappedMassPerHeat,
}: {
  items: PlanItem<T>[];
  setItems: (items: PlanItem<T>[]) => void;
  scaleItems: PlanItem<T>[] | null;
  setScaleItems: (items: PlanItem<T>[] | null) => void;
  disabled?: boolean;
  tappedMassPerHeat: number;
}) => {
  const theme = useTheme();
  const serialiser = useNumberSerialiser({ min: 0, decimalPlaces: 0 });
  const totalHeats = items
    .map((item) => item.heats)
    .reduce((left, right) => left + right, 0);
  const tappedMass = totalHeats * tappedMassPerHeat;

  return (
    <FormControl fullWidth>
      <ValidatedTextField
        value={totalHeats}
        setValue={(newTotalHeats) => {
          const newItems = scaleToNewTotal(
            items,
            totalHeats,
            newTotalHeats,
            scaleItems
          );
          const newScaleItems = scaleItems ?? items;
          setItems(newItems);
          setScaleItems(newScaleItems);
        }}
        serialiser={serialiser}
        disabled={disabled}
        textFieldProps={{
          sx: {
            "& .MuiInputBase-input": {
              color: totalHeats === 0 ? "text.disabled" : "text.primary",
              backgroundColor: "white",
              borderRadius: 1,
            },
          },
          InputProps: {
            sx: {
              fontSize: theme.typography.body1Mono.fontSize,
              fontWeight: theme.typography.body1Mono.fontWeight,
              lineHeight: theme.typography.body1Mono.lineHeight,
              fontFamily: theme.typography.body1Mono.fontFamily,
            },
          },
        }}
      />
      <Typography
        variant="body1Mono"
        color="text.disabled"
        sx={{
          position: "absolute",
          right: 0,
          bottom: 7,
          paddingRight: 2,
          fontWeight: 800,
        }}
      >
        {useFormattedTotalHeats(totalHeats, tappedMass)}
      </Typography>
    </FormControl>
  );
};

/**
 * This scales the items to a new total number of heats; if not null,
 * then the scaleItems argument is used for the proportions (it should
 * be set to null whenever anything but the total number of heats is
 * changed). Otherwise, the current items are used, and then scaleItems is
 * set to them, making the scaling "sticky", in the sense that it will be
 * preserved even in the face of changes due to rounding.
 */
const scaleToNewTotal = <T,>(
  initialItems: PlanItem<T>[],
  initialTotalHeats: number,
  newTotalHeats: number,
  initialScaleItems: PlanItem<T>[] | null
) => {
  /* If there are initially zero heats, quietly give each item in the plan one
    heat before proceeding.  This allows an empty plan to be populated all at
    once, by first adding the groups and then simply setting a total number of
    heats.  Without this, the output will always end up being empty irrespective
    of `newTotalHeats`. */
  const totalHeats =
    initialTotalHeats === 0 ? initialItems.length : initialTotalHeats;
  const items =
    initialTotalHeats === 0
      ? initialItems.map((item) => ({ ...item, heats: 1 }))
      : initialItems;
  const scaleItems =
    initialScaleItems === null
      ? null
      : initialScaleItems
          .map((item) => item.heats)
          .reduce((left, right) => left + right, 0) === 0
      ? initialScaleItems.map((item) => ({ ...item, heats: 1 }))
      : initialScaleItems;

  const scaleTotalHeats =
    scaleItems === null
      ? totalHeats
      : scaleItems
          .map((item) => item.heats)
          .reduce((left, right) => left + right, 0);
  const flooredHeats = (scaleItems ?? items).map((item) =>
    scaleTotalHeats === 0
      ? 0
      : Math.floor((item.heats * newTotalHeats) / scaleTotalHeats)
  );
  const missingHeats =
    scaleTotalHeats === 0
      ? 0
      : newTotalHeats - flooredHeats.reduce((left, right) => left + right, 0);

  const orderByDiscrepancy = sort(
    (scaleItems ?? items).map((item, index) => ({
      originalFraction: item.heats / scaleTotalHeats,
      newFraction: flooredHeats[index]! / newTotalHeats,
      index: index,
    })),
    (a, b) =>
      b.originalFraction - b.newFraction - (a.originalFraction - a.newFraction)
  ).map((item) => item.index);
  const indexToOrderMap = new Map(
    (scaleItems ?? items).map((_, index) => [orderByDiscrepancy[index]!, index])
  );
  return items.map((item, index) => ({
    ...item,
    heats:
      indexToOrderMap.get(index)! < missingHeats
        ? flooredHeats[index]! + 1
        : flooredHeats[index]!,
  }));
};
