import { useTenantTranslation } from "hooks/formatters";
import { useFormatLocalDateString } from "./useFormatLocalDate";

export const useExcelName = () => {
  const { t } = useTenantTranslation();
  const format = useFormatLocalDateString("date");
  return `${t("plan")}-${format(new Date().toDateString())}.xlsx`
    .toLowerCase()
    .split("/")
    .join("");
};
