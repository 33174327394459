import { Skeleton, Stack, Typography } from "@mui/material";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import {
  InventoryItem,
  ObtainableBundleItem,
  SyncedProductionPlan,
  TargetBasketItem,
  TargetInventoryItem,
} from "store/api/generatedApi";
import { liftLoadedState } from "models/loaded";
import { InputFieldItem, InputFieldSection } from "./InputFieldItem";
import { SearchMaterials } from "contexts/search/context";
import {
  useInventory,
  useMaterials,
  useObtainableBundles,
  useTargetBaskets,
  useTargetInventory,
  useProductionPlan,
  useMiscParams,
  usePeriod as usePeriodParameters,
} from "contexts/search/provider";
import { Panel } from "../SearchPage";
import {
  PeriodVariant,
  usePeriodIndex,
  usePeriodVariant,
} from "hooks/periodIndex";
import { LoadedContent } from "../../common/loading/loadedContent";
import { ValidatedTextField } from "../../common/inputs/validatedTextField";
import { useShowElectricalEnergyPrice } from "hooks/tenantPermissions";
import { SuppressConstraintsEditor } from "./inputEditors/suppressConstraints";
import { useIsAdmin } from "hooks/settings";
import { IntegerSolutionEditor } from "./inputEditors/integerSolution";

type Props = {
  setPanel: (panel: Exclude<Panel, Panel.None | Panel.Constraints>) => void;
};

export const InputFields = ({ setPanel }: Props) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);
  const formatMass = useNumberSerialiser({ decimalPlaces: 0 }).format;

  const periodTab = usePeriodIndex();
  const periodVariant = usePeriodVariant();
  const isAdmin = useIsAdmin();

  const materials = useMaterials();
  const [inventory] = useInventory();
  const [obtainableBundles] = useObtainableBundles(periodTab);
  const [productionPlan] = useProductionPlan(periodTab);
  const [targetInventory] = useTargetInventory(periodTab);
  const [targetBaskets] = useTargetBaskets();

  const getTotalNumHeats = (schedule: SyncedProductionPlan) =>
    (schedule.product_group_items ?? [])
      .map((item) => item.num_heats)
      .concat((schedule.steel_grade_items ?? []).map((item) => item.num_heats))
      .reduce((a: number, b: number) => a + b, 0);
  const productionRequirementsValid = (schedule: SyncedProductionPlan) =>
    getTotalNumHeats(schedule) > 0;
  const productionRequirementsSummarize = (schedule: SyncedProductionPlan) =>
    `${getTotalNumHeats(schedule)} ${t("heats").toLowerCase()}`;

  const getTotalCurrentInventory = (inventory: InventoryItem[]) =>
    inventory
      .map(({ quantity }) => quantity ?? 0)
      .reduce((a: number, b: number) => a + b, 0);
  const currentInventorySummarize = (inventory: InventoryItem[]) =>
    `${formatMass(getTotalCurrentInventory(inventory))} ${units(
      "mass"
    ).trim()}`;

  const currentInventoryValid = (inventory: InventoryItem[]) => {
    const total = getTotalCurrentInventory(inventory);
    return total > 0;
  };

  const getTotalAvailableScrap = (availabilitySet: ObtainableBundleItem[]) =>
    availabilitySet
      .map((item) => item.max_obtainable ?? item.min_obtainable ?? 0)
      .reduce((left, right) => left + right, 0);

  // TODO: Is there a validation check required for prices? For min obtainable?
  // If so, add it to the `availableScrapValidator`
  const availableScrapValid = (availabilitySet: ObtainableBundleItem[]) =>
    getTotalAvailableScrap(availabilitySet) > 0;
  const availableScrapSummarize = (availabilitySet: ObtainableBundleItem[]) =>
    `${formatMass(getTotalAvailableScrap(availabilitySet))} ${units(
      "mass"
    ).trim()}`;

  const targetInventoryValid = ({
    targetInventory,
  }: {
    targetInventory: TargetInventoryItem[];
  }) =>
    targetInventory.filter(({ min_quantity, max_quantity }) =>
      max_quantity == null
        ? min_quantity != null
        : max_quantity >= (min_quantity ?? 0)
    ).length > 0;
  const targetInventorySummarize = ({
    materials,
    targetInventory,
  }: {
    materials: SearchMaterials;
    targetInventory: TargetInventoryItem[];
  }) => {
    const constrainedMaterials = targetInventory
      .filter(
        ({ min_quantity, max_quantity }) =>
          (min_quantity ?? 0) > 0 || max_quantity != null
      )
      .map(({ material_id }) => materials.byId[material_id]!.name);

    if (constrainedMaterials.length === 0) {
      return "-";
    } else if (constrainedMaterials.length <= 2) {
      return constrainedMaterials.join(", ");
    } else {
      return `${formatMass(constrainedMaterials.length)} ${t(
        "materialsLowercase"
      )}`;
    }
  };

  const targetBasketsSummarise = ({
    targetBaskets,
  }: {
    targetBaskets: TargetBasketItem[];
  }) => {
    const targetNumBaskets = targetBaskets
      .map(
        ({ chef_group_id }) =>
          targetBaskets.find((item) => item.chef_group_id === chef_group_id)
            ?.target_num_baskets ?? null
      )
      .filter((count) => count != null) as number[];

    if (targetNumBaskets.length === 0) {
      return "-";
    } else {
      const minimum = Math.min(...targetNumBaskets);
      const maximum = Math.max(...targetNumBaskets);

      return minimum === maximum
        ? formatMass(minimum)
        : `${formatMass(minimum)} - ${formatMass(maximum)}`;
    }
  };

  const showElectricalEnergyPrice = useShowElectricalEnergyPrice();

  return (
    <Stack spacing={2} sx={{ px: 3, py: 2 }}>
      <InputFieldItem
        title={t("production")}
        data={productionPlan}
        onClick={() => setPanel(Panel.Production)}
        valid={productionRequirementsValid}
        summarize={productionRequirementsSummarize}
      />
      {periodVariant === PeriodVariant.Schedule && (
        <InputFieldItem
          title={t("inventoryInHand")}
          data={inventory}
          onClick={() => setPanel(Panel.Inventory)}
          valid={currentInventoryValid}
          summarize={currentInventorySummarize}
        />
      )}
      <InputFieldItem
        title={t("availableScrap")}
        data={obtainableBundles}
        onClick={() => setPanel(Panel.Availabilities)}
        valid={availableScrapValid}
        summarize={availableScrapSummarize}
      />
      <InputFieldItem
        title={t("targetInventory")}
        data={liftLoadedState({ materials, targetInventory })}
        onClick={() => setPanel(Panel.TargetInventory)}
        valid={targetInventoryValid}
        summarize={targetInventorySummarize}
      />
      {periodVariant === PeriodVariant.Schedule && (
        <InputFieldItem
          title={t("productionBaskets")}
          data={liftLoadedState({ targetBaskets })}
          onClick={() => setPanel(Panel.TargetBaskets)}
          valid={() => true}
          summarize={targetBasketsSummarise}
        />
      )}

      {periodVariant === PeriodVariant.Schedule
        ? showElectricalEnergyPrice && (
            <InputFieldSection title={t("electricalEnergyPrice")}>
              <ElectricalEnergyPriceEditor />
            </InputFieldSection>
          )
        : null}

      {isAdmin ? (
        <>
          {periodVariant === PeriodVariant.Forecast ? (
            <InputFieldSection title={t("suppressConstraints")}>
              <SuppressConstraintsEditor periodIndex={periodTab} />
            </InputFieldSection>
          ) : null}

          <InputFieldSection title={t("optimisationObjectiveWeighting")}>
            <OptimisationObjectiveWeightingEditor />
          </InputFieldSection>
        </>
      ) : null}

      {isAdmin && periodVariant === PeriodVariant.Schedule ? (
        <InputFieldSection title={t("integerSolution")}>
          <IntegerSolutionEditor />
        </InputFieldSection>
      ) : null}
    </Stack>
  );
};

const ElectricalEnergyPriceEditor = () => {
  const [miscParams, setMiscParams] = useMiscParams();
  const serialiser = useNumberSerialiser({
    min: 0,
    decimalPlaces: 2,
    default: { value: undefined, text: "" },
  });
  const units = useUnitsFormatter(false);

  return (
    <Stack direction="row" alignItems="center" sx={{ pt: 0.5 }} gap={1}>
      <LoadedContent
        data={miscParams}
        loading={<Skeleton sx={{ width: 100, p: 0.5 }} />}
        waiting={<Skeleton sx={{ width: 100, p: 0.5 }} />}
      >
        {(loadedMiscParams) => (
          // Prices in the db are per kWh, but here we want to enter as price per MWh
          <ValidatedTextField
            value={
              loadedMiscParams.electrical_energy_price === undefined
                ? undefined
                : loadedMiscParams.electrical_energy_price * 1000
            }
            setValue={(value) =>
              setMiscParams((current) => ({
                ...current,
                electrical_energy_price: value === undefined ? 0 : value / 1000,
              }))
            }
            serialiser={serialiser}
            textFieldProps={{
              inputProps: {
                sx: {
                  textAlign: "right",
                  p: 0.5,
                  px: 0.75,
                  width: 100,
                  fontFamily: "monospace",
                },
              },
            }}
          />
        )}
      </LoadedContent>
      <Typography variant="body1Mono">
        {units("specific_energy_cost").trim()}
      </Typography>
    </Stack>
  );
};

const OptimisationObjectiveWeightingEditor = () => {
  const [period, setPeriod] = usePeriodParameters(usePeriodIndex());
  const serialiser = useNumberSerialiser({ min: 0, decimalPlaces: 1 });

  return (
    <Stack direction="row" alignItems="center" sx={{ pt: 0.5 }} gap={1}>
      <LoadedContent data={period}>
        {(loadedPeriod) => (
          <ValidatedTextField
            value={loadedPeriod.optimisation_objective_weighting}
            setValue={(value) =>
              setPeriod((current) => ({
                ...current,
                optimisation_objective_weighting: value,
              }))
            }
            serialiser={serialiser}
            textFieldProps={{
              inputProps: {
                sx: {
                  textAlign: "right",
                  p: 0.5,
                  px: 0.75,
                  width: 100,
                  fontFamily: "monospace",
                },
              },
            }}
          />
        )}
      </LoadedContent>
    </Stack>
  );
};
