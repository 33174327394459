export enum LocalStorageKeys {
  language = "language",
}

export const dumpLanguage = (language: string) => {
  localStorage.setItem(LocalStorageKeys.language, language);
};

export const loadLanguage = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.language);
};
