import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { MixAllocation } from "src/store/api/generatedApi";
import { useState } from "react";
import { Setter } from "./timeline";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";

export const MixDetailButton = ({
  mixes,
  setMixes,
  steelGradeName,
}: {
  mixes: MixAllocation[];
  setMixes: Setter<MixAllocation[]>;
  steelGradeName: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {mixes.length} {mixes.length === 1 ? "mix" : "mixes"}
      </Button>

      {open ? (
        <MixDetail
          steelGradeName={steelGradeName}
          mixes={mixes}
          setMixes={setMixes}
          close={() => setOpen(false)}
        />
      ) : null}
    </>
  );
};

const MixDetail = ({
  steelGradeName,
  mixes,
  setMixes,
  close,
}: {
  steelGradeName: string;
  mixes: MixAllocation[];
  setMixes: Setter<MixAllocation[]>;
  close: () => void;
}) => {
  return (
    <Dialog
      open
      onClose={close}
      maxWidth="md"
      fullWidth
      onDoubleClick={(event) => {
        event.stopPropagation();
      }}
    >
      <DialogTitle>{steelGradeName} mixes</DialogTitle>
      <DialogContent>
        <ValidatedTextField<MixAllocation[]>
          value={mixes}
          setValue={(value) => setMixes(() => value)}
          serialiser={{
            format: (value) => JSON.stringify(value, null, 2),
            parse: (text) => ({
              valid: true,
              value: JSON.parse(text) as MixAllocation[],
            }),
          }}
          textFieldProps={{ multiline: true }}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
          gap={2}
        >
          <Button onClick={close}>Close</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
