import { Typography } from "@mui/material";
import { SearchSummary } from "store/api/generatedApi";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";

type Props = {
  search: SearchSummary;
};

export const HistoryChildItem = ({ search }: Props) => {
  const formattedCreatedAt = useFormatLocalDateString("datetime")(
    search.created_at
  );
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ pl: 1, py: 0.5, borderLeft: 1 }}
    >
      {formattedCreatedAt}
      {" • "}
      {search.user_email}
    </Typography>
  );
};
