import { WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, SxProps, Typography } from "@mui/material";
import { useSearch, useServerIds } from "contexts/search/provider";

import { useTenantTranslation } from "hooks/formatters";
import { useNavigateToSearch } from "hooks/navigation";
import { useTenant } from "hooks/settings";
import { useUpdateSearchMixMaterialLimitSetMutation } from "src/store/api/generatedApi";
import { useMixMaterialLimitsArray } from "src/store/slices/mixMaterialLimits";

type Props = {
  sx?: SxProps;
};

const defaultSx = {};

export const MixMaterialLimitsAlert = ({ sx = defaultSx }: Props) => {
  const { t } = useTenantTranslation();
  const tenantName = useTenant();
  const { searchId } = useServerIds();

  const [
    doUpdateSearchMixMaterialLimitsMutation,
    updateSearchMixMaterialLimitsMutation,
  ] = useUpdateSearchMixMaterialLimitSetMutation();
  const mixMaterialLimitsArray = useMixMaterialLimitsArray();

  const { setSearchId } = useSearch();
  const navigateToSearch = useNavigateToSearch();

  const handleOnClick = async () => {
    if (searchId !== null) {
      const newSearchId = await doUpdateSearchMixMaterialLimitsMutation({
        searchId,
        tenantName,
        body: mixMaterialLimitsArray.map(
          ({
            max_mass,
            min_mass,
            soft_max_mass,
            soft_min_mass,
            chef_group_id,
            coefficients,
            hardness,
            name,
          }) => {
            return {
              max_mass,
              min_mass,
              soft_max_mass,
              soft_min_mass,
              hardness,
              coefficients,
              chef_group_id,
              name,
              reason: null,
            };
          }
        ),
      }).unwrap();

      setSearchId(newSearchId);
      navigateToSearch(newSearchId);
    }
  };

  return (
    <Alert
      sx={{
        ...sx,
        alignItems: "center",
        ".MuiAlert-action": {
          paddingTop: "0px !important",
        },
      }}
      severity="error"
      icon={<WarningAmber />}
      action={
        <LoadingButton
          loading={!updateSearchMixMaterialLimitsMutation.isUninitialized}
          onClick={handleOnClick}
          color="secondary"
        >
          {t("updateMixMaterialLimits")}
        </LoadingButton>
      }
    >
      <Typography variant="body1">
        {t("mixMaterialLimitsHaveChanged")}
      </Typography>
    </Alert>
  );
};
