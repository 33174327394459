import {
  SearchChemicalElements,
  SearchChemistryGroups,
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import {
  ChefGroup,
  ChemicalConstraint,
  FormattedBasketConstraint,
  ProductGroup,
  SteelGrade,
} from "src/store/api/generatedApi";
import { partition } from "helpers";
import { chemicalExpressionFromConstraintCoefficients } from "./chemicalExpressionFromConstraintCoefficients";
import { formatMixMaterialLimits } from "src/components/common/panels/chefgroup/materialConstraints";
import React from "react";
import { ReduxMixMaterialLimit } from "src/store/slices/mixMaterialLimits";

export type FormattedProductLimit = {
  id: number; // For data grid purposes

  chemistryGroup: string;
  productGroup: ProductGroup | null;
  steelGrades: SteelGrade[];

  maximumFailureRate: string;
  chemicalTolerances: { [name: string]: string };

  mixMaterialLimits: Record<string, string>;

  matchesFilter: boolean;
};

export const useProductConstraintRows = (
  chemistryGroups: SearchChemistryGroups,
  productGroups: SearchProductGroups | null,
  chemicalElements: SearchChemicalElements,
  steelGrades: SearchSteelGrades,
  mixMaterialLimits: ReduxMixMaterialLimit[],
  filterSteelGrades: string[]
): FormattedProductLimit[] => {
  const formatChemistryGroup = useFormatChemistryGroup(
    productGroups,
    chemicalElements,
    steelGrades,
    mixMaterialLimits
  );

  const constraints = chemistryGroups.byIndex.map(formatChemistryGroup);

  const [matching, nonMatching] = partition(
    (constraint) =>
      filterSteelGrades.some((grade) =>
        constraint.steelGrades
          .map((steelGrade) => steelGrade.name)
          .includes(grade)
      ),
    constraints
  );

  return [
    ...matching.map((constraint) => ({ ...constraint, matchesFilter: true })),
    ...nonMatching,
  ];
};

const useFormatChemistryGroup = (
  productGroups: SearchProductGroups | null,
  chemicalElements: SearchChemicalElements,
  steelGrades: SearchSteelGrades,
  mixMaterialLimits: ReduxMixMaterialLimit[]
) => {
  const { format } = useNumberSerialiser();
  const units = useUnitsFormatter(false);

  const formatMixMaterialLimit = useFormatMixMaterialLimit();
  const formatChemicalConstraint =
    useFormatChemicalConstraint(chemicalElements);

  return (chemistryGroup: ChefGroup) => ({
    matchesFilter: false,
    id: chemistryGroup.id!,
    chemistryGroup: chemistryGroup.name,
    productGroup:
      (productGroups?.byIndex ?? []).find((group) =>
        group.steel_grades
          .map(({ name }) => name)
          .includes(chemistryGroup.steel_grades[0]?.name ?? "")
      ) ?? null,
    steelGrades: chemistryGroup.steel_grades.map(
      ({ id }) => steelGrades.byId[id]!
    ),

    maximumFailureRate:
      format(chemistryGroup.max_failure_rate) + units("yield"),
    mixMaterialLimits: Object.fromEntries(
      mixMaterialLimits
        .filter(({ chef_group_id }) => chemistryGroup.id === chef_group_id)
        .map((mixMaterialLimit) => formatMixMaterialLimit(mixMaterialLimit))
    ),
    chemicalTolerances: Object.fromEntries(
      chemistryGroup.chemical_constraints
        .filter((constraint) => constraint.weight_percent_max < 100)
        .map(formatChemicalConstraint)
    ),
  });
};

const useFormatMixMaterialLimit = () => {
  const units = useUnitsFormatter(false);
  const { t } = useTenantTranslation();

  // TODO: the numbers need to be localised here
  const doFormat = React.useCallback(
    (mixMaterialLimit: ReduxMixMaterialLimit): [string, string] => {
      return [
        t(mixMaterialLimit.name),
        formatMixMaterialLimits(
          {
            minMass: mixMaterialLimit.min_mass,
            maxMass: mixMaterialLimit.max_mass,
          },
          units
        ),
      ];
    },
    [formatMixMaterialLimits, units]
  );
  return doFormat;
};

const useFormatChemicalConstraint = (
  chemicalElements: SearchChemicalElements
) => {
  const { format } = useNumberSerialiser({ decimalPlaces: 3 });
  const units = useUnitsFormatter(false);

  return (constraint: ChemicalConstraint): [string, string] => {
    return [
      chemicalExpressionFromConstraintCoefficients(
        constraint.coefficients,
        chemicalElements.byId
      ),
      format(constraint.weight_percent_max) + units("yield"),
    ];
  };
};

export const useBasketConstraintRows = (
  constraints: FormattedBasketConstraint[]
): (FormattedBasketConstraint & { id: string })[] =>
  constraints.map((constraint) => ({
    ...constraint,
    id: constraint.basket_number.toString(),
  }));
