import React from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Stack } from "@mui/system";
import { Box, IconButton } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { useSearchId } from "hooks/search";
import { PlanSummary } from "./summary";
import { usePlan } from "contexts/plan";
import { useDefaultPlanName } from "./name";
import { useTabName } from "../common/TabTitle";
import { TitledTabs } from "../common/titledTabs";
import { ProductionTab } from "./productionTab";
import { InventoryTab } from "./inventoryTab";
import { LoadedContent } from "../common/loading/loadedContent";
import { LoadingResults } from "../search/output/LoadingResults";
import { useNavigateToHome, useNavigateToSearch } from "hooks/navigation";
import { usePlanPageTabIndexHotKeys, usePlanPeekHotKeys } from "hooks/hotkeys";
import { mapLoadedUnpack } from "models/loaded";
import { useSearchName, useSearch } from "contexts/search/provider";
import { usePlanId } from "../common/boundary/PlanId";

export const PlanPage = () => <LoadedPlanPage planId={usePlanId()} />;

export const LoadedPlanPage = ({ planId }: { planId: number }) => {
  usePlanTabName(planId);
  const { t } = useTenantTranslation();
  const {
    results: { results },
  } = useSearch();
  const navigateToSearch = useNavigateToSearch();
  const navigateToHome = useNavigateToHome();
  const searchId = useSearchId();
  const [tabIndex, setTabIndex] = usePlanPageTabIndexHotKeys();
  usePlanPeekHotKeys();

  const [showExtendedSummary, setShowExtendedSummary] = React.useState(true);

  const toggleShowExtendedSummary = () =>
    setShowExtendedSummary((showExtendedSummary) => !showExtendedSummary);

  return (
    <LoadedContent
      data={results}
      loading={<LoadingResults />}
      waiting={<LoadingResults />}
      disableFetching
    >
      {(result) => {
        if (!result || !result.plans.some((plan) => plan.id === planId)) {
          if (searchId !== null) {
            navigateToSearch(searchId);
          } else {
            navigateToHome();
          }
          return null;
        }
        return (
          <Stack sx={{ height: "100%", minHeight: "0px" }}>
            <Stack gap={2} sx={{ px: 3, pt: 2, pb: 1 }}>
              <PlanSummary
                planId={planId}
                showExtendedSummary={showExtendedSummary}
              />
            </Stack>

            <Box
              sx={{
                px: 3,
                flex: 1,
                minHeight: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TitledTabs
                tabIndex={tabIndex}
                onChange={setTabIndex}
                tabSpecs={[
                  {
                    title: t("production"),
                    key: "production",
                    content: <ProductionTab planId={planId} />,
                  },
                  {
                    title: t("inventory"),
                    key: "inventory",
                    content: <InventoryTab planId={planId} />,
                  },
                ]}
                rightComponent={
                  showExtendedSummary ? (
                    <IconButton onClick={toggleShowExtendedSummary}>
                      <KeyboardDoubleArrowUpIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={toggleShowExtendedSummary}>
                      <KeyboardDoubleArrowDownIcon />
                    </IconButton>
                  )
                }
              />
            </Box>
          </Stack>
        );
      }}
    </LoadedContent>
  );
};

const usePlanTabName = (planId: number) => {
  const { plan: loadedPlan } = usePlan(planId);
  const defaultPlanName = useDefaultPlanName()(
    mapLoadedUnpack(loadedPlan, (plan) => plan.plan_index)
  );
  const searchName = useSearchName();

  const name = mapLoadedUnpack(searchName, (name) => {
    return defaultPlanName == null ? name : `${defaultPlanName} - ${name}`;
  });

  useTabName(name);
};
