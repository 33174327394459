import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { Layout } from "components/common/panels/layout";
import { useTenant } from "hooks/settings";

import {
  SearchContextGroupSummary,
  useCreateSearchContextGroupMutation,
  useListSearchContextGroupsQuery,
  useListSearchesQuery,
  useListSessionsQuery,
} from "src/store/api/generatedApi";
import { SearchStatusChip } from "components/search/common/SearchStatus";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { ChevronRight, OpenInNew } from "@mui/icons-material";
import { useNavigateToSearchContextGroup } from "hooks/navigation";

type SearchesPeekProps = {
  open: boolean;
  doClose: () => void;
  width: number;
};

type SessionProps = {
  sessionId: number;
  name: string;
  setContextId: (contextId: number) => void;
};

type CreateSearchGroupContextDialogProps = {
  contextId: number;
  onCancel: () => void;
  onSuccessfulSubmission: () => void;
};

const CreateSearchGroupContextDialog = ({
  contextId,
  onCancel,
  onSuccessfulSubmission,
}: CreateSearchGroupContextDialogProps) => {
  const tenantName = useTenant();
  const [doCreateSearchContextGroupMutation, createSearchContextGroupMutation] =
    useCreateSearchContextGroupMutation();

  const [name, setName] = React.useState("");

  const handleOnSubmit = async () => {
    if (name !== "") {
      await doCreateSearchContextGroupMutation({
        tenantName,
        searchContextGroupCreate: { context_id: contextId, name },
      });
      onSuccessfulSubmission();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  return (
    <Dialog open>
      <DialogTitle>
        Name search context group with context {contextId}
      </DialogTitle>
      <DialogContent>
        <TextField sx={{ width: "100%" }} onChange={handleOnChange} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={name === "" || createSearchContextGroupMutation.isLoading}
          onClick={handleOnSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Session = ({ sessionId, name, setContextId }: SessionProps) => {
  const [skip, setSkip] = React.useState(true);

  const tenantName = useTenant();
  const searches = useListSearchesQuery({ tenantName, sessionId }, { skip });

  const handleOnClick = (contextId: number) => {
    setContextId(contextId);
  };

  const formattedCreatedAt = useFormatLocalDateString("datetime");
  return (
    <Accordion onChange={(_, expanded) => setSkip(!expanded)}>
      <AccordionSummary>
        <Typography variant="h4">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={0.5}>
          {searches.data
            ? searches.data.map((search) => (
                <Stack
                  key={search.search_id}
                  direction="row"
                  alignItems="center"
                  gap={1}
                >
                  <SearchStatusChip searchStatus={search.status} />
                  <Typography variant="body2">
                    {formattedCreatedAt(search.created_at)}
                  </Typography>
                  <Typography variant="body2">{search.user_email}</Typography>
                  <IconButton onClick={() => handleOnClick(search.context_id)}>
                    <OpenInNew />
                  </IconButton>
                </Stack>
              ))
            : null}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const SearchesPeek = ({ open, doClose, width }: SearchesPeekProps) => {
  const tenantName = useTenant();
  const sessions = useListSessionsQuery({ tenantName });
  const [contextId, setContextId] = React.useState<number | null>(null);
  return (
    <>
      <Layout
        open={open}
        doClose={doClose}
        width={width}
        title="Select from search"
      >
        {sessions.data
          ? sessions.data.map((session) => {
              return (
                <Session
                  key={session.session_id}
                  sessionId={session.session_id}
                  name={session.name}
                  setContextId={setContextId}
                />
              );
            })
          : null}
      </Layout>
      {contextId ? (
        <CreateSearchGroupContextDialog
          contextId={contextId}
          onCancel={() => setContextId(null)}
          onSuccessfulSubmission={() => {
            setContextId(null);
            doClose();
          }}
        />
      ) : null}
    </>
  );
};

type NameCellProps = {
  name: string;
  id: number;
  contextId: number;
};

const NameCell = ({ id, name, contextId }: NameCellProps) => {
  const navigateToSearchContextGroup = useNavigateToSearchContextGroup();
  return (
    <ButtonBase
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onClick={() =>
        navigateToSearchContextGroup({
          searchContextGroupId: id,
          searchContextId: contextId,
        })
      }
    >
      <Typography variant="button">{name}</Typography>
      <ChevronRight />
    </ButtonBase>
  );
};

const nameColumn: GridColDef<SearchContextGroupSummary> = {
  field: "name",
  renderHeader: () => <>Name</>,
  display: "flex",
  renderCell: ({ row: { name, id, latest_search_context_id } }) => (
    <NameCell name={name} id={id} contextId={latest_search_context_id} />
  ),
};
const createdAtColumn: GridColDef<SearchContextGroupSummary> = {
  field: "created_at",
  renderHeader: () => <>Created at</>,
  valueFormatter: (_, { created_at }) => new Date(created_at).toLocaleString(),
  display: "flex",
};
const updatedAtColumn: GridColDef<SearchContextGroupSummary> = {
  field: "updated_at",
  renderHeader: () => <>Updated at</>,
  valueFormatter: (_, { updated_at }) => new Date(updated_at).toLocaleString(),
  display: "flex",
};
const createdByColumn: GridColDef<SearchContextGroupSummary> = {
  field: "user_email",
  renderHeader: () => <>Created by</>,
  display: "flex",
};

const columns: GridColDef<SearchContextGroupSummary>[] = [
  nameColumn,
  createdAtColumn,
  updatedAtColumn,
  createdByColumn,
];

export const SearchContextGroupsPage = () => {
  const tenantName = useTenant();
  const { data: searchContextGroups } = useListSearchContextGroupsQuery({
    tenantName,
  });
  const [isPeekOpen, setIsPeekOpen] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          marginX: 6,
          marginY: 3,
          display: "grid",
          gridTemplateColumns: "1fr max-content",
          columnGap: 0.5,
          rowGap: 1,
        }}
      >
        <Typography variant="h1">Search contexts groups</Typography>
        <Button variant="contained" onClick={() => setIsPeekOpen(true)}>
          Create from search
        </Button>
        <DataGridPremium<SearchContextGroupSummary>
          columns={columns}
          rows={searchContextGroups ?? []}
          autosizeOnMount
          autosizeOptions={{
            includeOutliers: true,
            expand: true,
          }}
          sx={{ gridColumn: "1/-1" }}
        />
      </Box>
      <SearchesPeek
        open={isPeekOpen}
        doClose={() => setIsPeekOpen(false)}
        width={800}
      />
    </>
  );
};
