import { SearchChemistryGroups } from "contexts/search/context";
import { Setter } from "hooks/state/syncing";
import { useCallback, useMemo, useState } from "react";
import {
  ConfirmedChargeScheduleMeta,
  LayeringFudgeFactors,
  Plan,
  PublishedChargeScheduleRead,
} from "store/api/generatedApi";
import { LayeringKey, LayeringSummary } from "./layerings";
import equal from "fast-deep-equal";
import { sorted } from "helpers";
import { usePeriodIndex } from "hooks/periodIndex.tsx";

export type ExportPlanState = {
  plan: Plan;
  periodIndex: number;

  chemistryGroups: SearchChemistryGroups;

  stage:
    | "preview"
    | "awaitingConfirmation"
    | "awaitingConfirmationSuccess"
    | "awaitingConfirmationError"
    | "sendSms"
    | "sendSmsSuccess"
    | "sendSmsError";

  planned: LayeringKey[];
  fudgeFactor: { key: LayeringKey; fudgeFactor: LayeringFudgeFactors }[];
  approved: { key: LayeringKey; summary: LayeringSummary }[];
  published: PublishedChargeScheduleRead[] | null;
  confirmed: ConfirmedChargeScheduleMeta[];
};

export const useExportPlanState = (
  plan: Plan,
  chemistryGroups: SearchChemistryGroups
): [ExportPlanState, Setter<ExportPlanState>] => {
  const periodIndex = usePeriodIndex();
  const planned = plan.summary.mix_summary
    .filter((summary) => summary.period === periodIndex)
    .map((summary) => ({
      planId: plan.id,
      period: summary.period,
      groupId: chemistryGroups.byName[summary.chef_group_name]!.id,
      mixNumber: summary.mix_number,
    }));

  return useState<ExportPlanState>({
    plan,
    periodIndex,
    chemistryGroups,

    stage: "preview",

    planned,
    fudgeFactor: [],
    approved: [],
    published: null,
    confirmed: [],
  });
};

export const isApproved = (state: ExportPlanState, key: LayeringKey) =>
  state.approved.some((item) => equal(item.key, key));

export const useFudgeFactor = (
  state: ExportPlanState,
  setState: Setter<ExportPlanState>,
  key: LayeringKey | null
) => {
  const mostConsumedMaterial = sorted(
    state.plan.summary.material_summary,
    (summary) => -summary.consumption_planned
  )[0]!.material_id;

  const defaultFudgeFactor = useMemo(
    () => ({ replacement_material_id: mostConsumedMaterial, materials: [] }),
    [mostConsumedMaterial]
  );

  const fudgeFactor: LayeringFudgeFactors =
    state.fudgeFactor.find((item) => equal(item.key, key))?.fudgeFactor ??
    defaultFudgeFactor;

  const setFudgeFactor = useCallback(
    (update: (fudgeFactor: LayeringFudgeFactors) => LayeringFudgeFactors) => {
      setState((current) => ({
        ...current,
        fudgeFactor:
          key === null
            ? current.fudgeFactor
            : [
                ...current.fudgeFactor.filter((item) => !equal(item.key, key)),
                { key, fudgeFactor: update(fudgeFactor) },
              ],
      }));
    },
    [setState, key, fudgeFactor]
  );

  return [fudgeFactor, setFudgeFactor] as const;
};
