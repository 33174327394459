import {
  Loaded,
  liftLoadedState,
  loadedEndpoint,
  mapLoaded,
  mapLoadedUnpack,
} from "models/loaded";
import { useTenant } from "hooks/settings";
import {
  ObtainableBundleItem,
  UploadedSearchParameters,
  useGetParsedSearchParametersQuery,
} from "store/api/generatedApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { getMicrosecondTimestamp } from "src/utils/getMicrosecondTimestamp";
import { v4 as UUID } from "uuid";
import { useMaterialsId, useSteelGradesId } from "../provider";

export const useParsedSearchParameters = (
  fileId: number | null
): Loaded<UploadedSearchParameters> => {
  const tenant = useTenant();

  const ids = mapLoadedUnpack(
    liftLoadedState({
      materialsId: useMaterialsId(),
      steelGradesId: useSteelGradesId(),
    }),
    ({ materialsId, steelGradesId }) => ({
      materialSetId: materialsId,
      steelGradeSetId: steelGradesId,
    })
  );

  return loadedEndpoint(
    useGetParsedSearchParametersQuery(
      fileId == null || ids == null
        ? skipToken
        : { tenantName: tenant, uploadedFileId: fileId, ...ids }
    )
  );
};

export const useParsedAvailability = (
  fileId: number | null
): Loaded<ObtainableBundleItem[]> =>
  mapLoaded(useParsedSearchParameters(fileId), ({ availabilities }) =>
    (availabilities ?? []).map((item) => ({
      uuid: UUID(),
      material_id: item.material_id,
      label: "",
      min_obtainable: item.obtainable_min ?? null,
      max_obtainable: item.obtainable_max ?? null,
      specific_price: item.price ?? null,
      created_at: getMicrosecondTimestamp(),
      arrival_probability: null,
    }))
  );
