import { loadedEndpoint, mapLoaded } from "models/loaded";
import { useTenant } from "hooks/settings";
import { Loaded } from "models/loaded";
import {
  DeployedPlanRead,
  Plan,
  useListDeployedPlansQuery,
} from "store/api/generatedApi";
import { useSearch } from "./search/provider";

export const usePlan = (
  planId: number | null
): {
  plan: Loaded<Plan>;
} => {
  const {
    results: { results },
  } = useSearch();

  const plan = planFromResult(
    mapLoaded(results, (result) => result?.plans ?? null),
    planId
  );

  return { plan };
};

const planFromResult = (
  plans: Loaded<Plan[] | null>,
  planId: number | null
): Loaded<Plan> => {
  if (planId == null) {
    return { status: "waiting" };
  } else if (plans.status === "success") {
    const plan = plans.data?.find((plan) => plan.id === planId);
    return plan == null
      ? { status: "error" }
      : {
          status: "success",
          data: plan,
          fetching: false,
        };
  } else {
    return plans;
  }
};

export const useDeployedPlans = (): Loaded<DeployedPlanRead[]> =>
  loadedEndpoint(useListDeployedPlansQuery({ tenantName: useTenant() }));
