import React from "react";
import { Link, Tooltip, useTheme } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

import { ChefGroupDetailView } from "../chefgroup";
import { usePlanId } from "../../boundary/PlanId";
import { usePeriodIndex } from "hooks/periodIndex";

type Props = {
  name: string;
  multipleMixes: boolean;
  mix: number;
  group: number;
};

export const ChemistryGroupCell = ({
  name,
  multipleMixes,
  mix,
  group,
}: Props) => {
  const theme = useTheme();
  const planId = usePlanId();
  const period = usePeriodIndex();

  const { t } = useTenantTranslation();
  const { format } = useNumberSerialiser();

  const [showPanel, setShowPanel] = React.useState(false);

  const title = `${name} ${
    multipleMixes ? ` (${t("mix")} ${format(mix)})` : ""
  }`;

  return (
    <>
      <Tooltip title={title}>
        <Link
          variant="body2"
          color={theme.palette.text.primary}
          fontWeight="bold"
          sx={{ cursor: "pointer" }}
          onClick={() => setShowPanel(true)}
        >
          {title}
        </Link>
      </Tooltip>
      <ChefGroupDetailView
        group={group}
        open={showPanel}
        setOpen={setShowPanel}
        mix={mix}
        planId={planId}
        period={period}
      />
    </>
  );
};
