import { AutoFixHigh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Popover, Tooltip, Typography } from "@mui/material";
import { useEstimateAvailability } from "contexts/search/wizards/estimateAvailability";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useState } from "react";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";

export const AvailabilitiesEstimator = () => {
  const { t } = useTenantTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const serialiser = useNumberSerialiser({
    decimalPlaces: 0,
    min: 0,
    default: { text: "", value: null },
  });

  const [estimate, { running }] = useEstimateAvailability();
  const notifyStatus = useNotifyStatus();

  const [heats, setHeats] = useState<number | null>(null);

  const disabled = heats === null || estimate.status !== "success";
  const handleEstimate = () => {
    if (!disabled) {
      void estimate
        .data(heats)
        .then(() => {
          setHeats(null);
          handleClose();
        })
        .catch(() => notifyStatus({ text: t("unknownError"), type: "error" }));
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<AutoFixHigh />}
        onClick={handleOpen}
      >
        {t("quickFill")}
      </Button>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          mt: 1,
        }}
      >
        <Box sx={{ maxWidth: 320, border: 1, borderRadius: 1 }}>
          <Box
            sx={{
              display: "flex",
              borderBottom: 1,
              px: 2,
              py: 0.5,
              alignItems: "center",
            }}
          >
            <AutoFixHigh
              sx={{
                mr: 1,
                fill: (theme) => theme.palette.text.secondary,
                width: 18,
                height: 18,
              }}
            />
            <Typography color="textSecondary" variant="body2">
              {t("quickFill")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mb: 2 }} color="textSecondary">
              {t("estimateMaxObtainable")}
            </Typography>
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <ValidatedTextField
                  value={heats}
                  setValue={setHeats}
                  serialiser={serialiser}
                  textFieldProps={{
                    fullWidth: false,
                    placeholder: t("totalHeats"),
                    color: "secondary",
                    sx: {
                      maxWidth: 120,
                    },
                  }}
                  liveUpdate
                  onEditConfirmedWithKeyboard={handleEstimate}
                />
              </Grid>
              <Grid item>
                <Tooltip
                  title={disabled ? t("estimateForMeTooltip") : ""}
                  arrow
                  placement="bottom"
                  sx={{ maxWidth: 220 }}
                >
                  <Box>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      disabled={disabled}
                      loading={running}
                      onClick={handleEstimate}
                    >
                      {t("estimateForMe")}
                    </LoadingButton>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
