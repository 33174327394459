import { useMemo } from "react";
import { Serialiser } from "./types";

type DateSerialiserOptions<T> = {
  default?: { value: T; text: string };
};

export const useDateSerialiser = <T extends null = never>(
  defaultOptions?: DateSerialiserOptions<T>
): Serialiser<Date | T, DateSerialiserOptions<T>> => {
  return useMemo(
    () => ({
      format: (value, optionsOverride) => {
        if (value === null) {
          return (
            optionsOverride?.default ??
            defaultOptions?.default ?? { text: "" }
          ).text;
        } else {
          return value.toISOString();
        }
      },
      parse: (text, optionsOverride) => {
        const trimmedText = text.trim();

        if (trimmedText.length === 0) {
          const options = optionsOverride?.default ?? defaultOptions?.default;
          return options === undefined
            ? { valid: false }
            : { valid: true, value: options.value };
        }

        const value = new Date(text);
        return value.toString() === "Invalid Date"
          ? { valid: false }
          : { valid: true, value };
      },
    }),
    [defaultOptions]
  );
};
