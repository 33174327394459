import { SearchMaterials } from "contexts/search/context";
import { mapLoaded } from "models/loaded";
import { Loaded, liftLoadedState } from "models/loaded";
import { ObtainableBundleItem } from "store/api/generatedApi";

export const removeMaterialsFromOtherSets = (
  materials: Loaded<SearchMaterials>,
  availability: Loaded<ObtainableBundleItem[]>
): Loaded<ObtainableBundleItem[]> =>
  /* Filter out any materials that aren't in the current set.  Material IDs 
    never change, so we don't need to convert them; only filter them */
  mapLoaded(
    liftLoadedState({
      materials,
      availability,
    }),
    ({ materials: loadedMaterials, availability: loadedAvailability }) =>
      loadedAvailability.filter(
        ({ material_id }) => material_id in loadedMaterials.byId
      )
  );
