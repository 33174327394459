import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import {
  AddItemSettings,
  PlanItem,
  PlanItemDetail,
  PlanItemName,
} from "./types";
import { ReactNode, useMemo, useRef, useState } from "react";
import { PlanEditorItem } from "./itemEditor";
import { AddItem } from "./addItem";
import { HeatsInput, TotalHeatsInput } from "./heatsInput";
import { useTenantTranslation } from "hooks/formatters";
import { sorted } from "helpers";

enum SortAlpha {
  None,
  Ascending,
}

const nextSortState = (sortState: SortAlpha) => {
  switch (sortState) {
    case SortAlpha.None:
      return SortAlpha.Ascending;
    case SortAlpha.Ascending:
      return SortAlpha.None;
  }
};

export const PlanEditor = function <T = Record<string, never>>({
  items,
  setItems,
  name,
  detail,
  heatsDisabled,
  addItem,
  additionalItemEditor,
  tappedMassPerHeat,
  setSelectedItem,
  onSecondaryClick,
}: {
  items: PlanItem<T>[];
  onSecondaryClick?: (item: PlanItem<T>, target: HTMLButtonElement) => void;
  setItems: (items: PlanItem<T>[]) => void;
  name: PlanItemName;
  detail?: PlanItemDetail<T>;
  heatsDisabled?: boolean;
  addItem?: AddItemSettings<T>;
  setSelectedItem?: (item: PlanItem<T>) => void;
  additionalItemEditor?: (item: PlanItem<T>) => ReactNode;
  tappedMassPerHeat: number;
}) {
  const { t } = useTenantTranslation();
  const fixedRows = !addItem;

  const [sortAlpha, setSortAlpha] = useState(SortAlpha.None);
  const sortedItems = useMemo(
    () => sorted(items, (item) => name(item)),
    [items, name]
  );

  const totalHeats = items
    .map((item) => item.heats)
    .reduce((left, right) => left + right, 0);
  const [scaleItems, setScaleItems] = useState<PlanItem<T>[] | null>(null);

  const setItem = (newItem: PlanItem<T>) => {
    const newItems = items.map((baseItem) =>
      baseItem.groupId === newItem.groupId ? newItem : baseItem
    );
    setItems(newItems);
    setScaleItems(null);
  };

  const removeItemFactory = (item: PlanItem<T>) => () => {
    const newItems = items.filter(
      (baseItem) => baseItem.groupId !== item.groupId
    );
    setItems(newItems);
    setScaleItems(null);
  };

  const planEditorRef = useRef<HTMLDivElement>(null);
  const [adding, setAdding] = useState(false);

  const focusNextHeatsInput = (currentHeatsInput: Element | null) => {
    const planEditor = planEditorRef.current;
    if (!planEditor) {
      return;
    }

    const allHeatsInputs = [...planEditor.querySelectorAll(".heats-input")];
    const targetHeatsInput =
      currentHeatsInput === null
        ? allHeatsInputs[allHeatsInputs.length - 1]
        : allHeatsInputs[allHeatsInputs.indexOf(currentHeatsInput) + 1];

    if (targetHeatsInput !== undefined) {
      targetHeatsInput.querySelector("input")?.focus();
    } else {
      // The currently selected input is the last one in the list
      setAdding(true);
    }
  };
  const firstItem = items[0];

  return (
    <div ref={planEditorRef}>
      <Stack
        sx={{
          height: "100%",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.100",
        }}
      >
        <Box
          sx={{
            backgroundColor: "grey.50",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Grid
            container
            sx={{
              // height: 30,
              borderBottom: 1,
              borderColor: "grey.100",
              borderTopLeftRadius: 1,
              borderTopRightRadius: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={1} />
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ ml: 0 }}
                onClick={() => setSortAlpha(nextSortState)}
              >
                <SortByAlphaIcon
                  sx={{
                    width: 20,
                    height: 20,
                    color: sortAlpha ? undefined : "lightgrey",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="info">
                {t("heats")}
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {firstItem !== undefined && (
            <PlanEditorItem<T>
              key="total"
              item={firstItem}
              removeItem={undefined}
              name={() => t("total")}
              detail={undefined}
            >
              <TotalHeatsInput
                items={items}
                setItems={setItems}
                scaleItems={scaleItems}
                setScaleItems={setScaleItems}
                disabled={heatsDisabled}
                tappedMassPerHeat={tappedMassPerHeat}
              />
            </PlanEditorItem>
          )}
        </Box>

        {(sortAlpha === SortAlpha.Ascending ? sortedItems : items).map(
          (item) => (
            <PlanEditorItem<T>
              key={item.groupId}
              item={item}
              removeItem={fixedRows ? undefined : removeItemFactory(item)}
              onSecondaryClick={onSecondaryClick}
              name={name}
              detail={detail}
              setSelectedItem={setSelectedItem}
            >
              <>
                <HeatsInput
                  name={name}
                  item={item}
                  setItem={setItem}
                  totalHeats={totalHeats}
                  disabled={heatsDisabled}
                  focusNextHeatsInput={focusNextHeatsInput}
                />

                {additionalItemEditor ? additionalItemEditor(item) : null}
              </>
            </PlanEditorItem>
          )
        )}
        {!fixedRows && (
          <AddItem
            adding={adding}
            setAdding={setAdding}
            addItem={(item) =>
              setItems([...items, addItem.populateDefaults(item)])
            }
            name={name}
            groupIds={
              addItem.uniqueRowIds
                ? addItem.groupIds.filter(
                    (id) => !items.map((item) => item.groupId).includes(id)
                  )
                : addItem.groupIds
            }
            parentRef={planEditorRef}
          />
        )}
      </Stack>
    </div>
  );
};
