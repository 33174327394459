import { IconButton, Stack, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useCallback } from "react";

export const NumberSelector = ({
  text,
  value,
  setValue,
  minimum,
  maximum,
}: {
  text: string;
  value: number;
  setValue: (value: number) => void;
  minimum: number | null;
  maximum: number | null;
}) => {
  const decrement = useCallback(() => {
    if (minimum === null || value > minimum) {
      setValue(value - 1);
    }
  }, [value, minimum, setValue]);
  const increment = useCallback(() => {
    if (maximum === null || value < maximum) {
      setValue(value + 1);
    }
  }, [value, maximum, setValue]);

  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        size="small"
        onClick={decrement}
        disabled={minimum !== null && value <= minimum}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography>{text}</Typography>
      <IconButton
        onClick={increment}
        disabled={maximum !== null && value >= maximum}
      >
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};
