import { useNumberSerialiser } from "hooks/serialisers/numbers";

import { PlainCell, StyledCell } from "../common/Cell";

import { PlanConsumptionAggregation } from "./inventoryContent";
import { Theme, useTheme } from "@mui/material";
import { obtainableRemainingLessThanError } from "./constants";

type Aggregation = keyof PlanConsumptionAggregation;

type MaterialCellProps = {
  value: number;
  maximum: number;
  aggregation: Aggregation;
  hasError: boolean;
};

type ValueOf<T> = T[keyof T];

const useColor = (
  aggregation: Aggregation
): ValueOf<Theme["palette"]["data"]> => {
  const theme = useTheme();
  switch (aggregation) {
    case "consumption":
      return theme.palette.data.lightblue;
    case "inventoryFinal":
      return theme.palette.data.red;
    case "inventoryInitial":
      return theme.palette.data.pink;
    case "obtainableRequired":
      return theme.palette.data.green;
    case "obtainableRemaining":
      return theme.palette.data.orange;
  }
};

export const MaterialCell = ({
  value,
  maximum,
  aggregation,
  hasError,
}: MaterialCellProps) => {
  const backgroundColor = useColor(aggregation);
  const { format } = useNumberSerialiser({ decimalPlaces: 0 });

  return (
    <StyledCell
      color={backgroundColor}
      value={format(value)}
      alpha={value >= 0 && maximum >= 0 ? value / maximum : 0}
      hasError={hasError}
    />
  );
};

type Props =
  | {
      type: "aggregation";
      variant: "mass" | "price";
      value: number;
    }
  | {
      type: "material";
      maximum: number;
      value: number;
      aggregation: Aggregation;
    };

export const InventoryCell = (props: Props) => {
  const { format: massFormat } = useNumberSerialiser({ decimalPlaces: 0 });
  const { format: priceFormat } = useNumberSerialiser({ decimalPlaces: 2 });

  const { type } = props;
  switch (type) {
    case "material": {
      const { value, maximum, aggregation } = props;

      const hasError =
        aggregation === "obtainableRemaining" &&
        value < obtainableRemainingLessThanError;

      return (
        <MaterialCell
          value={value}
          maximum={maximum}
          aggregation={aggregation}
          hasError={hasError}
        />
      );
    }
    case "aggregation": {
      const { value, variant } = props;
      const renderValue = (() => {
        switch (variant) {
          case "mass":
            return massFormat(value);
          case "price":
            return priceFormat(value);
        }
      })();

      return <PlainCell value={renderValue} />;
    }
  }
};
