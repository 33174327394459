import React from "react";

import { ChefGroupSummary, SimilarChargedRecipesTab } from "./components";
import { useTenantTranslation } from "hooks/formatters";
import { ChefGroupProperties } from "./properties";
import { useTenantHash } from "hooks/settings";
import { LayeringTab } from "./layering";
import { TabSpec, TitledTabs } from "src/components/common/titledTabs";
import { SteelGradesId } from "contexts/search/context";
import {
  OptimisationResultSetSummary,
  ChemicalElement,
  MixBasketSummary,
  MixMaterialBasketSummary,
  ChemicalConstraint,
  SteelGrade,
} from "src/store/api/generatedApi";
import { BasketsTab } from "./basketsTab";
import { ImpuritiesTab } from "./impuritiesTab";
import { PlanId } from "components/common/boundary/PlanId";
import { Period } from "hooks/periodIndex";

export type Props = {
  mix: number;
  name: string;
  summary: OptimisationResultSetSummary;
  steelGrades: (SteelGrade & { id: SteelGradesId })[];
  elements: Record<number, ChemicalElement>;
  planId: PlanId;
  period: Period;
  chemistryGroupId: number;
  defaultTargetNumberOfBaskets: number;
  maximumFailureRate: number;
  chemicalConstraints: ChemicalConstraint[];
};

const filterForChefGroup =
  (chefGroupName: string, period: Period, mix?: number) =>
  (item: MixBasketSummary | MixMaterialBasketSummary) => {
    return (
      item.chef_group_name === chefGroupName &&
      period === item.period &&
      (mix === undefined || item.mix_number === mix)
    );
  };

export const MixDetails = ({
  chemistryGroupId,
  name,
  mix,
  summary,
  steelGrades,
  elements,
  planId,
  defaultTargetNumberOfBaskets,
  maximumFailureRate,
  chemicalConstraints,
  period,
}: Props) => {
  const { t } = useTenantTranslation();
  const tenantHash = useTenantHash();

  const [tabIndex, setTabIndex] = React.useState(0);

  const tabSpecs: TabSpec[] = [
    {
      title: t("Baskets"),
      key: "baskets",
      content:
        tenantHash === "484b06cf-2b5a-5b62-abb9-4cab237eba06" ? (
          <LayeringTab
            planId={planId}
            groupId={chemistryGroupId}
            mixNumber={mix}
          />
        ) : (
          <BasketsTab
            mixBasketsSummary={summary.mix_basket_summary.filter(
              filterForChefGroup(name, period, mix)
            )}
            mixMaterialBasketsSummary={summary.mix_material_basket_summary.filter(
              filterForChefGroup(name, period, mix)
            )}
          />
        ),
    },
    {
      title: t("impurities"),
      key: "impurities",
      content: (
        <ImpuritiesTab
          planId={planId}
          chefGroupId={chemistryGroupId}
          mixNumber={mix}
          period={period}
        />
      ),
    },
    {
      title: t("similarChargedRecipes"),
      key: "similarChargedRecipes",
      content: (
        <SimilarChargedRecipesTab
          planId={planId}
          mixNumber={mix}
          period={period}
          chefGroupId={chemistryGroupId}
          chefGroupName={name}
          steelGrades={steelGrades}
          mixMaterialBasketSummary={summary.mix_material_basket_summary}
          mixMaterialSummary={summary.mix_material_summary}
          mixSummary={summary.mix_summary}
        />
      ),
    },
    {
      title: t("properties"),
      key: "properties",
      content: (
        <ChefGroupProperties
          maximumFailureRate={maximumFailureRate}
          defaultTargetNumberOfBaskets={defaultTargetNumberOfBaskets}
          chemicalConstraints={chemicalConstraints}
          steelGrades={steelGrades}
          elements={elements}
          chefGroupId={chemistryGroupId}
        />
      ),
    },
  ];

  return (
    <>
      <ChefGroupSummary
        mixSummary={summary.mix_summary}
        chefGroupName={name}
        period={period}
      />

      <TitledTabs
        tabIndex={tabIndex}
        onChange={setTabIndex}
        tabSpecs={tabSpecs}
      />
    </>
  );
};
