import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAuth } from "firebase/auth";

export const NoTenantPage = () => {
  const { t } = useTranslation();
  const auth = getAuth();

  return (
    <Container maxWidth="md" sx={{ height: "100%" }}>
      <Paper
        sx={{
          marginTop: "50%",
        }}
        elevation={2}
      >
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ pl: 8, pr: 8, pt: 4, pb: 4 }}
        >
          <Typography variant="h2" sx={{ mt: 2, margin: "auto" }}>
            {t("scrapChef")}
          </Typography>
          <Divider />
          <Alert sx={{ m: 2, p: 2 }} severity="warning">
            <AlertTitle>{t("noTenantsAvailable")}</AlertTitle>
            {t("contactSupport")}{" "}
            <a href="mailto:support@scrapchef.ai">support@scrapchef.ai</a>
          </Alert>
          <Divider />
          <Button
            sx={{ mt: 2, ml: "auto", mr: "auto" }}
            type="submit"
            variant="outlined"
            onClick={() => void auth.signOut()}
          >
            {t("signOut")}
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};
