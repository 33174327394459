import { Typography, useTheme } from "@mui/material";

type Props = {
  children: string;
};

export const UnitSuffix = ({ children }: Props) => {
  const theme = useTheme();
  return (
    <Typography variant="body2Mono" sx={{ color: theme.palette.text.disabled }}>
      {children}
    </Typography>
  );
};
