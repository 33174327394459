import {
  liftLoadedState,
  loadedEndpoint,
  mapLoadedUnpack,
} from "models/loaded";
import { LoadedContent } from "../common/loading/loadedContent";
import { useBasketConstraintRows, useProductConstraintRows } from "./rows";
import {
  SearchChemistryGroups,
  SearchProductGroups,
  SearchChemicalElements,
  SearchSteelGrades,
} from "contexts/search/context";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import {
  useBasketConstraintColumns,
  useProductConstraintColumns,
} from "./columns";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { AccountPageLayout } from "../common/AccountPageLayout";
import { useTenantTranslation } from "hooks/formatters";
import {
  FormattedBasketConstraint,
  useGetFormattedBasketConstraintsQuery,
} from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import {
  BackToCurrentConstraintsButton,
  SelectSearchButton,
} from "./selectSearch";
import { useState } from "react";
import {
  useChemicalElements,
  useChemistryGroups,
  useProductGroups,
  useSteelGrades,
  useSearch,
  useMaterialsId,
  useMaterialPhysicsId,
} from "contexts/search/provider";
import { useLoadMostRecentSearch } from "contexts/search/effects";
import { TitledTabs } from "../common/titledTabs";
import React from "react";
import { MultiSelect } from "../common/inputs/multiSelect";
import { useMixMaterialLimitsArray } from "src/store/slices/mixMaterialLimits";

export const ConstraintsPage = () => {
  const mostRecentSearch = useLoadMostRecentSearch();
  const {
    parameters: { client },
  } = useSearch();
  const { t } = useTenantTranslation();

  const name =
    mapLoadedUnpack(client, ({ session_name }) =>
      mostRecentSearch.currentlyLoaded ? null : session_name
    ) ?? t("currentConstraints");

  return (
    <AccountPageLayout title={t("constraints")}>
      <Stack sx={{ pb: 12 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>{`${t("currentlyViewing")}: ${name}`}</Typography>
          <Box>
            {mostRecentSearch.currentlyLoaded ? (
              <SelectSearchButton />
            ) : (
              <BackToCurrentConstraintsButton {...mostRecentSearch} />
            )}
          </Box>
        </Stack>

        <ConstraintsPageContent />
      </Stack>
    </AccountPageLayout>
  );
};

export const ConstraintsPageContent = () => {
  return (
    <LoadedContent
      data={liftLoadedState({
        chemistryGroups: useChemistryGroups(),
        productGroups: useProductGroups(),
        chemicalElements: useChemicalElements(),
        steelGrades: useSteelGrades(),
        materialsId: useMaterialsId(),
        materialPhysicsId: useMaterialPhysicsId(),
        basketConstraints: loadedEndpoint(
          useGetFormattedBasketConstraintsQuery({ tenantName: useTenant() })
        ),
      })}
      loading={<LinearProgress />}
      waiting={<LinearProgress />}
      disableFetching
    >
      {(lookups) => <LoadedConstraintsPage {...lookups} />}
    </LoadedContent>
  );
};

const LoadedConstraintsPage = ({
  chemistryGroups,
  productGroups,
  chemicalElements,
  steelGrades,
  basketConstraints,
}: {
  chemistryGroups: SearchChemistryGroups;
  productGroups: SearchProductGroups | null;
  chemicalElements: SearchChemicalElements;
  steelGrades: SearchSteelGrades;
  basketConstraints: FormattedBasketConstraint[];
}) => {
  const { t } = useTenantTranslation();

  const [filterSteelGrades, setFilterSteelGrades] = useState<string[]>([]);
  const mixMaterialLimits = useMixMaterialLimitsArray();

  const productConstraintRows = useProductConstraintRows(
    chemistryGroups,
    productGroups,
    chemicalElements,
    steelGrades,
    mixMaterialLimits,
    filterSteelGrades
  );
  const productConstraintColumns = useProductConstraintColumns(
    productConstraintRows
  );

  const basketConstraintRows = useBasketConstraintRows(basketConstraints);
  const basketConstraintColumns = useBasketConstraintColumns();

  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <TitledTabs
      tabIndex={tabIndex}
      onChange={setTabIndex}
      tabSpecs={[
        {
          title: t("products"),
          key: "products",
          content: (
            <Box
              sx={{
                height: "100%",
                display: "grid",
                gridTemplateRows: "auto 1fr",
                gridTemplateColumns: "1fr min-content",
                gap: 1,
                marginTop: 2,
              }}
            >
              <MultiSelect
                sx={{ width: 500, gridColumn: 2 }}
                options={steelGrades.byIndex.map((grade) => grade.name)}
                values={filterSteelGrades}
                setValues={setFilterSteelGrades}
                label={t("searchForSteelGrade")}
                small
              />
              <DataGridPremium
                getRowHeight={() => "auto"}
                sx={{
                  gridColumnStart: 1,
                  gridColumnEnd: -1,
                  minWidth: "0px",
                }}
                rows={productConstraintRows}
                columns={productConstraintColumns}
              />
            </Box>
          ),
        },
        {
          title: t("baskets"),
          key: "baskets",
          content: (
            <DataGridPremium
              sx={{ marginTop: 2 }}
              rows={basketConstraintRows}
              columns={basketConstraintColumns}
              autoHeight
            />
          ),
        },
      ]}
    />
  );
};
