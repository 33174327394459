import { InventoryItem } from "src/store/api/generatedApi";
import { InventoryRow } from "./InventoryTable";
import { useInventory } from "contexts/search/provider";

export const useHandleTableMutations = () => {
  const [, setInventory] = useInventory();

  const handleUpdateItem = (
    newRow: InventoryRow,
    oldRow: InventoryRow
  ): InventoryRow => {
    if (
      newRow.specific_price !== oldRow.specific_price ||
      newRow.quantity !== oldRow.quantity
    ) {
      const validatedNewItem: InventoryItem = {
        material_id: newRow.material_id,
        specific_price: newRow.specific_price,
        quantity: newRow.quantity,
        previous_quantity: null,
        deliveries_since_previous: null,
        consumption_since_previous: null,
        projected_deliveries_since_previous: null,
        projected_consumption_since_previous: null,
      };

      setInventory((current) => [
        ...current.filter(
          (item) => item.material_id !== validatedNewItem.material_id
        ),
        validatedNewItem,
      ]);

      return { ...validatedNewItem, material_name: newRow.material_name };
    } else {
      return oldRow;
    }
  };

  return { handleUpdateItem };
};
