import { Box, Stack, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { OptimisationFailureReason } from "store/api/generatedApi";

const reasonDescription: Record<
  OptimisationFailureReason,
  { shortKey: string; longKey: string }
> = {
  "Optimisation Error": {
    shortKey: "optimisationError",
    longKey: "optimisationErrorDescription",
  },
  "Infeasible Optimisation": {
    shortKey: "infeasible",
    longKey: "infeasibleDescription",
  },
  "Optimisation Timed Out": {
    shortKey: "timedOut",
    longKey: "timedOutDescription",
  },
  unacceptable_slack_variable_usage: {
    shortKey: "infeasible",
    longKey: "infeasibleDescription",
  },
};

export const FailedSearch = ({
  failureReasons,
}: {
  failureReasons: OptimisationFailureReason[];
}) => {
  const { t } = useTenantTranslation();
  const failureKeys = failureReasons.map(
    (reason) => reasonDescription[reason].shortKey
  );
  const deduplicatedFailureReasons = failureReasons.filter(
    (reason, index) =>
      failureKeys.indexOf(reasonDescription[reason].shortKey) === index
  );
  return (
    <Stack sx={{ borderRadius: 1, backgroundColor: "grey.50", p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t("sorryNoResults")}
      </Typography>
      {deduplicatedFailureReasons.length === 0 ? (
        <Typography color="text.secondary">
          {reasonDescription["Infeasible Optimisation"].longKey}
        </Typography>
      ) : deduplicatedFailureReasons.length === 1 ? (
        <Typography color="text.secondary">
          {t(reasonDescription[deduplicatedFailureReasons[0]!].longKey)}
        </Typography>
      ) : (
        <Stack spacing={2}>
          {deduplicatedFailureReasons.map((reason) => (
            <Box key={reason}>
              <Typography color="text.secondary">
                {t(reasonDescription[reason].shortKey)}
              </Typography>
              <Typography color="text.secondary">
                {t(reasonDescription[reason].longKey)}
              </Typography>
            </Box>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
