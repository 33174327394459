import {
  SearchChemistryGroups,
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import {
  useBaskets,
  useChemistryGroups,
  useProductGroups,
  useProductionSchedule,
  useSteelGrades,
  useTargetBaskets,
} from "contexts/search/provider";
import { Loaded, liftLoadedState, mapLoaded } from "models/loaded";
import { useMemo } from "react";
import { SyncedProductionPlan, TargetBasketItem } from "store/api/generatedApi";

export type TargetBasketDependencies = {
  productionSchedule: SyncedProductionPlan;
  productGroups: SearchProductGroups;
  chemistryGroups: SearchChemistryGroups;
  targetBaskets: TargetBasketItem[];
  steelGrades: SearchSteelGrades;
  maxBaskets: number;
};

export const useTargetBasketDependencies =
  (): Loaded<TargetBasketDependencies> => {
    const productGroups = useProductGroups();
    const chemistryGroups = useChemistryGroups();
    const [productionSchedule] = useProductionSchedule();
    const [targetBaskets] = useTargetBaskets();
    const steelGrades = useSteelGrades();
    const baskets = useBaskets();

    return liftLoadedState({
      productionSchedule,
      productGroups,
      chemistryGroups,
      targetBaskets,
      steelGrades,
      maxBaskets: mapLoaded(baskets, (b) => b.length),
    });
  };

/**
 * Work out which chemistry group IDs are present in the production plan.
 */
export const usePresentChemistryGroups = (
  productionPlan: SyncedProductionPlan,
  productGroups: SearchProductGroups,
  chemistryGroups: SearchChemistryGroups
): number[] =>
  useMemo(() => {
    const steelGrades = new Set(
      (productionPlan.steel_grade_items ?? [])
        .filter((item) => item.num_heats > 0)
        .map((item) => item.steel_grade_id)
        .concat(
          (productionPlan.product_group_items ?? [])
            .filter((item) => item.num_heats > 0)
            .flatMap((item) =>
              productGroups.byId[item.product_group_id]!.steel_grades.map(
                ({ id }) => id
              )
            )
        )
    );

    return chemistryGroups.byIndex
      .filter((group) =>
        group.steel_grades.some(({ id }) => steelGrades.has(id))
      )
      .map((group) => group.id);
  }, [productionPlan, productGroups, chemistryGroups]);
