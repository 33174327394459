import {
  SearchChemicalElements,
  SearchChemistryGroups,
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import { EntityMapping } from "helpers";
import {
  ProductGroup,
  ProductGroupPlanItem,
  SteelGradePlanItem,
  SyncedProductionPlan,
} from "store/api/generatedApi";

export type ProductionDependencies = {
  productionPlan: SyncedProductionPlan;
  setProductionPlan?: (plan: SyncedProductionPlan) => void;
  productGroups: SearchProductGroups | null;
  chemistryGroups: SearchChemistryGroups;
  steelGrades: SearchSteelGrades;
  chemicalElements: SearchChemicalElements;
  tappedMass: number;
};

export const useSetItems = (
  productGroups: EntityMapping<ProductGroup> | null,
  productionPlan: SyncedProductionPlan,
  setProductionPlan?: (plan: SyncedProductionPlan) => void
) => {
  const defaultProductGroupItems: ProductGroupPlanItem[] =
    productGroups === null
      ? []
      : Object.keys(productGroups.byId).map((id) => ({
          product_group_id: parseInt(id),
          num_heats: 0,
        }));

  const setItems = (
    newProductGroupItems: ProductGroupPlanItem[] | null,
    newSteelGradeItems: SteelGradePlanItem[] | null
  ) => {
    if (setProductionPlan) {
      return setProductionPlan({
        product_group_items: newProductGroupItems,
        steel_grade_items: newSteelGradeItems,
      });
    }
  };

  const resetProductGroups = () => setItems(defaultProductGroupItems, null);
  const resetProducts = () => setItems(null, []);

  // Some tenants don't have product groups, in which case we should set new
  // searches with products by default
  const uninitialised =
    !productionPlan.product_group_items && !productionPlan.steel_grade_items;
  const productGroupPlan =
    uninitialised && productGroups
      ? defaultProductGroupItems
      : productionPlan.product_group_items;
  const steelGradePlan =
    uninitialised && !productGroups ? [] : productionPlan.steel_grade_items;

  return {
    setItems,
    resetProductGroups,
    resetProducts,
    productGroupPlan,
    steelGradePlan,
  };
};
