import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useTranslation } from "react-i18next";
import { Link, Tooltip, useTheme } from "@mui/material";
import { useHeatTextFormatter } from "hooks/heats";
import { usePlanId } from "components/common/boundary/PlanId";
import { Group, usePeriodOption } from "./ProductionContext";
import { ChefGroupDetailView } from "components/common/panels/chefgroup";
import { LoadedProductGroupPanel } from "components/common/panels/productGroup";

type Props = {
  heats: number;
  name: string;
  multipleMixes: boolean;
  mix: number;
  group: number;
  groupOption: Group;
};

export const HeatRowHeader = ({
  heats,
  name,
  multipleMixes,
  mix,
  group,
  groupOption,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { format } = useNumberSerialiser();
  const planId = usePlanId();
  const period = usePeriodOption();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const heatTextFormatter = useHeatTextFormatter();
  const heatText = `${format(heats)} ${heats === 1 ? t("heat") : t("heats")}`;
  const text = heatTextFormatter(multipleMixes, name, mix);

  return (
    <>
      <Tooltip title={`${heatText} - ${text}`}>
        <Box
          sx={{
            textWrap: "wrap",
            flex: 1,
          }}
          flexDirection="column"
          justifyContent="flex-start"
          display="flex"
        >
          <Typography variant="body2" sx={{ fontWeight: 400 }}>
            {heatText}
          </Typography>{" "}
          <Link
            variant="body2"
            color={theme.palette.text.primary}
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
            onClick={() => setIsModalOpen(true)}
          >
            {text}
          </Link>
        </Box>
      </Tooltip>
      {groupOption === Group.Chef ? (
        <ChefGroupDetailView
          open={isModalOpen}
          setOpen={setIsModalOpen}
          group={group}
          mix={mix}
          multipleMixes={multipleMixes}
          planId={planId}
          period={period}
        />
      ) : (
        <LoadedProductGroupPanel
          id={group}
          open={isModalOpen}
          doClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
