import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { SearchMaterials } from "contexts/search/context";
import { useTenantTranslation } from "hooks/formatters";
import { useHandleTableMutations } from "./useHandleTableMutations";

type Props = {
  materials: SearchMaterials;
  selectedItemIds: string[];
  setSelectedItemIds: (selectedItemsIds: string[]) => void;
};

export const DeleteAvailabilitiesButton = ({
  materials,
  selectedItemIds,
  setSelectedItemIds,
}: Props) => {
  const { t } = useTenantTranslation();
  const { handleRemoveItems } = useHandleTableMutations(materials);

  return (
    <Button
      color="secondary"
      onClick={() => {
        handleRemoveItems(selectedItemIds);
        setSelectedItemIds([]);
      }}
      startIcon={<Delete />}
    >
      {t("deleteSelectedRows")} ({selectedItemIds.length})
    </Button>
  );
};
