import { InputPanelHeader } from "./InputPanelHeader";
import { InputFields } from "./InputFields";
import { Panel } from "../SearchPage";
import { Box } from "@mui/material";

type Props = {
  setPanel: (panel: Exclude<Panel, Panel.None | Panel.Constraints>) => void;
};

export const InputPanel = ({ setPanel }: Props) => {
  return (
    <Box
      sx={{
        height: "100%",
        borderRight: 1,
        overflowY: "auto",
      }}
    >
      <Box sx={{ pt: 4 }}>
        <InputPanelHeader />
        <InputFields setPanel={setPanel} />
      </Box>
    </Box>
  );
};
