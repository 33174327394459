import {
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { SearchSummary } from "store/api/generatedApi";
import { ExpandMore, RestorePage } from "@mui/icons-material";
import { MouseEventHandler } from "react";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { useTenantTranslation } from "hooks/formatters";
import { SearchStatusChip } from "../common/SearchStatus";

type Props = {
  versionNumber: number;
  search: SearchSummary;
  hasSearchChildren: boolean;
  currentSearchId: number;
  hover: boolean;
  onSelect: (search: SearchSummary) => void;
};

export const HistoryItemTitle = ({
  versionNumber,
  search,
  hasSearchChildren,
  currentSearchId,
  hover,
  onSelect,
}: Props) => {
  const { t } = useTenantTranslation();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(search);
  };

  const isCurrentSearch = currentSearchId === search.search_id;

  const formattedCreatedAt = useFormatLocalDateString("datetime")(
    search.created_at
  );

  return (
    <AccordionSummary
      expandIcon={
        <ExpandMore
          sx={{ visibility: hasSearchChildren ? undefined : "hidden" }}
        />
      }
      sx={{
        flexDirection: "row-reverse",
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: "flex-start",
        "& .MuiAccordionSummary-content": {
          marginTop: -0.5,
          marginBottom: 0.5,
          cursor: hasSearchChildren ? undefined : "default",
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            ml: 1,
          }}
        >
          <Typography variant="h6" sx={{ py: 0.5 }}>{`${t(
            "version"
          )} ${versionNumber}`}</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
            <SearchStatusChip searchStatus={search.status} />
            {isCurrentSearch ? (
              <Chip label={t("currentlyViewing")} size="small" />
            ) : null}
          </Box>
          {hover ? (
            <Tooltip
              title={t("restoreThisSearchVersion")}
              placement="left"
              arrow
            >
              <IconButton
                sx={{ position: "absolute", right: 4 }}
                size="small"
                onClick={handleClick}
              >
                <RestorePage />
              </IconButton>
            </Tooltip>
          ) : null}
          <Typography variant="body2" color="text.secondary" sx={{ pl: 1 }}>
            {formattedCreatedAt}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ pl: 1 }}>
            {search.user_email}
          </Typography>
        </Box>
      </Box>
    </AccordionSummary>
  );
};
