import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useInviteUsersMutation } from "src/store/api/generatedApi";
import { isFetchBaseQueryError } from "helpers";

export const InviteUser = ({
  tenant,
  isOpen,
  onClose,
  onError,
}: {
  tenant: string;
  isOpen: boolean;
  onClose: () => void;
  onError: (message: string) => void;
}) => {
  const [emails, setEmails] = useState<string[]>([""]);
  const [inviteUsers] = useInviteUsersMutation();

  const handleAddUser = () => {
    setEmails(() => [...emails, ""]);
  };

  const handleRemoveUser = (index: number) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleEmailChange = (index: number, email: string) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = email;
    setEmails(updatedEmails);
  };

  const handleInviteUsers = async () => {
    await inviteUsers({
      tenantName: tenant,
      userInvite: {
        emails: emails,
      },
    })
      .unwrap()
      .then(() => {
        setEmails([""]);
        onClose();
      })
      .catch((err) => {
        if (isFetchBaseQueryError(err)) {
          onError(`Could not invite users (status code: ${err.status})`);
        }
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Invite Users</DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        {emails.map((email, index) => (
          <Stack key={email} direction="row">
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              sx={{ m: 1 }}
            />
            <IconButton
              onClick={() => handleRemoveUser(index)}
              disabled={index === 0 && emails.length === 1}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        ))}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddUser}
          sx={{ m: 1, textTransform: "none", borderRadius: 0 }}
        >
          Add another
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => void handleInviteUsers()}
          sx={{ textTransform: "none", borderRadius: 0 }}
        >
          Invite Users
        </Button>
      </DialogActions>
    </Dialog>
  );
};
