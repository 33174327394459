import { Chip } from "@mui/material";
import { ChemicalConstraint, ChemicalElement } from "store/api/generatedApi";
import { chemicalExpressionFromConstraintCoefficients } from "../constraints/chemicalExpressionFromConstraintCoefficients";

/**
 * Shows each of a series of chemistry constraints in small panels.
 */
export const ChemicalConstraints = ({
  chemicalConstraints,
  elements,
}: {
  chemicalConstraints: ChemicalConstraint[];
  elements: { [id: number]: ChemicalElement };
}) => {
  return (
    <>
      {chemicalConstraints
        .filter((constraint) => constraint.weight_percent_max < 100)
        .map((constraint) => {
          const expression = chemicalExpressionFromConstraintCoefficients(
            constraint.coefficients,
            elements
          );
          return (
            <Chip
              label={`${expression} ≤ ${constraint.weight_percent_max}%`}
              size="small"
              key={expression}
            />
          );
        })}
    </>
  );
};
