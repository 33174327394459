import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { useState } from "react";
import { SearchSelector } from "../search/SearchSelector";
import { useSearch } from "contexts/search/provider";

export const SelectSearchButton = () => {
  const { t } = useTenantTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {t("seeConstraintsForDifferentSearch")}
      </Button>

      {open ? <SelectSearchModal close={() => setOpen(false)} /> : null}
    </>
  );
};

const SelectSearchModal = ({ close }: { close: () => void }) => {
  const { t } = useTenantTranslation();
  const { setSearchId } = useSearch();

  return (
    <Dialog open onClose={close} fullWidth maxWidth="md">
      <DialogTitle>{t("chooseSearch")}</DialogTitle>
      <DialogContent sx={{ height: "40vh" }}>
        <SearchSelector
          selectedSearch={null}
          setSelectedSearch={(selectedSearch) => {
            setSearchId(selectedSearch.search_id);
            close();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export const BackToCurrentConstraintsButton = ({
  searchId,
}: {
  searchId: number | null;
}) => {
  const { setSearchId } = useSearch();
  const { t } = useTenantTranslation();

  return (
    <Button
      onClick={() => {
        if (searchId !== null) {
          setSearchId(searchId);
        }
      }}
    >
      {t("backToCurrentConstraints")}
    </Button>
  );
};
