export const typeSafeObjectFromEntries = <
  Key extends string | number | symbol,
  Value,
>(
  entries: [Key, Value][]
): Record<Key, Value> => {
  let i = 0;
  const object: Record<Key, Value> = {} as Record<Key, Value>;
  while (i < entries.length) {
    object[entries[i]![0]] = entries[i]![1];
    i++;
  }
  return object;
};
