import { MaterialRead } from "src/store/api/generatedApi";
import { ScrapHeader } from "../common/ScrapHeader";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { Period } from "hooks/periodIndex";

type Props =
  | {
      type: "aggregation";
      variant: "mass" | "price";
    }
  | {
      type: "material";
      material: MaterialRead;
      period: Period;
    };

export const InventoryRowHeader = (props: Props) => {
  const { t } = useTenantTranslation();
  const unit = useUnitsFormatter(true);

  const { type } = props;
  switch (type) {
    case "aggregation": {
      const { variant } = props;
      switch (variant) {
        case "mass":
          return (
            <ScrapHeader
              type="static"
              renderValue={`${t("totalMass")}${unit("mass")}`}
            />
          );
        case "price":
          return (
            <ScrapHeader
              type="static"
              renderValue={`${t("totalPrice")}${unit("cost")}`}
            />
          );
      }
      break; // Although this will never be reached; this needs to be here to make eslint happy for the precommit checks
    }
    case "material": {
      const { material, period } = props;
      const type = (() => {
        switch (period) {
          case 1:
            return "consumption";
          default:
            return "properties";
        }
      })();
      return (
        <ScrapHeader
          type={type}
          name={material.name}
          id={material.id}
          renderValue={material.name}
          alignment="left"
          period={period}
        />
      );
    }
  }
};
