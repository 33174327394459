import {
  ExperimentalInputs,
  ObtainableBlock,
  Period,
  ProductionBlock,
} from "src/store/api/generatedApi";
import { productionActions } from "./production";
import { obtainableActions } from "./obtainable";
import { markerActions } from "./marker";
import {
  Setter,
  TimelineSettings,
  timestampPosition,
} from "../inputs/timeline";
import { ReactNode, useState } from "react";
import {
  Card,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";
import { useTimestampSerialiser } from "../timestamps";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteBlock } from "../provider";
import { useHotkeys } from "react-hotkeys-hook";

export type Block = {
  uuid: string;
  name: string | null;
  start: number;
  end: number;
  suppressed: boolean;
};

export type BlockActions<T extends Block> = {
  /** Split a block into two at the given timestamp. */
  split: (block: T, timestamp: number) => [T | null, T | null];

  /** Convert a block into its optimiser production representation. */
  resolveProduction: (block: T) => ProductionBlock | null;

  /** Convert a block into its optimiser obtainable representation. */
  resolveObtainable: (block: T) => ObtainableBlock | null;

  /** Apply outputs from the optimiser back to the original block. */
  applyResults: (block: T, results: ProductionBlock | null) => T;
};

export type ConsumptionEvent = {
  timestamp: number;
  material: string;
  mass: number;
};

export const sliceBlock = <T extends Block>(
  block: T,
  start: number,
  end: number,
  actions: BlockActions<T>
): T | null => {
  const [, inside] = actions.split(block, start);
  return inside === null ? null : actions.split(inside, end)[0];
};

export const sliceBlocks = <T extends Block>(
  blocks: T[],
  start: number,
  end: number,
  actions: BlockActions<T>
): T[] =>
  blocks
    .map((block) => sliceBlock(block, start, end, actions))
    .filter((block): block is T => block !== null);

export const sliceInputs = (
  inputs: ExperimentalInputs,
  start: number,
  end: number
): ExperimentalInputs => ({
  production: sliceBlocks(inputs.production, start, end, productionActions),
  obtainable: sliceBlocks(inputs.obtainable, start, end, obtainableActions),
  // Even if the inventory is outside the time range, we still keep it
  inventory: inputs.inventory,
  periods: inputs.periods.filter(
    (period) => period.timestamp >= start && period.timestamp < end
  ),
  markers: sliceBlocks(inputs.markers, start, end, markerActions),
});

export const splitIntoPeriods = <T,>(
  item: T,
  periods: Period[],
  split: (block: T, timestamp: number) => [T | null, T | null]
): { period: number; item: T }[] => {
  const splits = periods.reduce<{
    block: T | null;
    blocks: { period: number; item: T }[];
    index: number;
  }>(
    ({ block, blocks, index }, period) => {
      const [left, right] =
        block === null ? [null, null] : split(block, period.timestamp);
      return {
        block: right,
        blocks:
          left === null ? blocks : [...blocks, { period: index, item: left }],
        index: index + 1,
      };
    },
    {
      block: item,
      blocks: [],
      index: 1,
    }
  );
  return [
    ...splits.blocks,
    ...(splits.block === null
      ? []
      : [{ period: splits.index, item: splits.block }]),
  ];
};

export const BlockView = ({
  block,
  timeline,
  timelineContent,
  detailContent,
  offset,
}: {
  block: {
    start: number;
    end: number;
    suppressed: boolean;
  };
  timeline: TimelineSettings;
  timelineContent: ReactNode;
  detailContent: ReactNode;
  offset: number;
}) => {
  const start = timestampPosition(timeline, block.start);
  const end = timestampPosition(timeline, block.end);

  const [hovered, setHovered] = useState(false);
  const [contentHovered, setContentHovered] = useState(false);

  const borderWidth = hovered ? 4 : 2;

  return (
    <>
      <Stack
        sx={{
          position: "absolute",
          left: start,
          width: end - start,
          top: offset,
          cursor: "pointer",
          borderStyle: "solid",
          backgroundColor: colours[offset]?.background,
          borderWidth,
          height: 85,
          overflowX: "clip",
          overflowY: "clip",
          borderRadius: 0.8,
          opacity: block.suppressed ? 0.3 : undefined,
          boxSizing: "border-box",
          borderColor: colours[offset]?.border,
          padding: `${8 - borderWidth}px`,
          userSelect: "none",
        }}
        alignItems="flex-start"
        direction="row"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {timelineContent}
      </Stack>

      {hovered || contentHovered ? (
        <Stack
          sx={{
            position: "absolute",
            // This is a crude way of making sure the detail view doesn't go off
            // screen, based on the minimum width of the block detail component
            // below
            left: Math.min(start, timeline.width - 600),
            top: offset + 85,
            zIndex: contentHovered ? 1000 : 100,
          }}
          onMouseEnter={() => setContentHovered(true)}
          onMouseLeave={() => setContentHovered(false)}
        >
          {detailContent}
        </Stack>
      ) : null}
    </>
  );
};

const colours: Record<number, { background: string; border: string }> = {
  100: { background: "#b4a7d6ff", border: "#8e7cc3" },
  200: { background: "#e06666ff", border: "#990000" },
  300: { background: "#6d9eebff", border: "#4a86e8" },
  400: { background: "#93c47dff", border: "#6aa84f" },
};

export const BlockDetail = ({ children }: { children: ReactNode }) => (
  <Card
    sx={{ borderStyle: "solid", borderWidth: 1, minWidth: 600 }}
    onDoubleClick={(event) => event.stopPropagation()}
    onClick={(event) => event.stopPropagation()}
  >
    {children}
  </Card>
);

export const BlockHeader = ({
  block,
  setBlock,
  name,
}: {
  block: Block;
  setBlock: Setter<Block>;
  name: string;
}) => {
  const deleteBlock = useDeleteBlock();
  const timestampSerialiser = useTimestampSerialiser();

  const toggleSuppressed = () =>
    setBlock((current) => ({ ...current, suppressed: !current.suppressed }));

  useHotkeys("s", toggleSuppressed);
  useHotkeys("d", () => deleteBlock(block.uuid));

  const setBlockEnd = (end: number) =>
    setBlock((current) => ({ ...current, end }));
  const setBlockStart = (start: number) =>
    setBlock((current) => ({ ...current, start }));

  return (
    <>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">{name}</Typography>
          <Stack direction="row">
            <IconButton size="small" onClick={toggleSuppressed}>
              {block.suppressed ? (
                <VisibilityOff sx={{ width: 24, height: 24 }} />
              ) : (
                <Visibility sx={{ width: 24, height: 24 }} />
              )}
            </IconButton>
            <IconButton onClick={() => deleteBlock(block.uuid)}>
              <DeleteIcon sx={{ width: 24, height: 24 }} />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} direction="row" alignItems="center">
          <Typography>Start</Typography>
          <ValidatedTextField
            value={block.start}
            setValue={setBlockStart}
            serialiser={timestampSerialiser}
          />

          <Typography>End</Typography>
          <ValidatedTextField
            value={block.end}
            setValue={setBlockEnd}
            serialiser={timestampSerialiser}
          />
        </Stack>
      </DialogContent>
    </>
  );
};
