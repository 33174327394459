import { Stack, Typography } from "@mui/material";
import { Error } from "@mui/icons-material";
import { useGridApiContext } from "@mui/x-data-grid-premium";

import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { obtainableRemainingLessThanError } from "./constants";
import { PlanConsumptionAggregation } from "./inventoryContent";
import { Row } from "./types";

type Props = {
  name: string;
  planConsumptionType: keyof PlanConsumptionAggregation;
};

export const InventoryColumnHeader = ({ name, planConsumptionType }: Props) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const grid = useGridApiContext();

  const rows = [...grid.current.getRowModels().values()] as Row[];

  const hasError =
    (planConsumptionType === "obtainableRemaining" ||
      planConsumptionType === "inventoryFinal") &&
    rows.some(({ data }) => {
      const value = data[planConsumptionType];
      return value !== undefined && value < obtainableRemainingLessThanError;
    });

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Typography
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        variant="body2"
      >{`${t(name)} ${units("mass")}`}</Typography>
      {hasError ? <Error color="error" sx={{ marginLeft: "auto" }} /> : null}
    </Stack>
  );
};
