import {
  ExperimentalSearchCreate,
  useSyncExperimentalSearchMutation,
} from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import { backendApi } from "src/store/api/enhancedApi";

export const useSyncExperimentalSearch = () => {
  const [syncExperimentalSearch, { isLoading }] =
    useSyncExperimentalSearchMutation();
  const tenant = useTenant();

  return [
    (experimentalSearch: ExperimentalSearchCreate) =>
      syncExperimentalSearch({
        tenantName: tenant,
        experimentalSearchCreate: experimentalSearch,
      }).unwrap(),
    { loading: isLoading },
  ] as const;
};

export const useLoadExperimentalSearch = () => {
  const tenant = useTenant();
  const [loadExperimentalSearch] =
    backendApi.endpoints.loadExperimentalSearch.useLazyQuery();

  return (experimentalSearchId: number) =>
    loadExperimentalSearch({
      tenantName: tenant,
      experimentalSearchId,
    }).unwrap();
};
