import { useState } from "react";
import { UploadedFileMeta, UploadedFileType } from "store/api/generatedApi";
import { SelectedSearch } from "components/search/SearchSelector";

export type ImportWizardState = ImportFromFileState | ImportFromSearchState;

export type ImportFromFileState = {
  kind: "file";
  file: UploadedFileMeta | null;
  type: UploadedFileType | null;
};

export type ImportFromSearchState = {
  kind: "search";
  search: SelectedSearch | null;
};

export const emptyFileState: ImportWizardState = {
  kind: "file",
  file: null,
  type: null,
};

export const emptySearchState: ImportWizardState = {
  kind: "search",
  search: null,
};

export const useImportWizardState = () => {
  const [state, setState] = useState<ImportWizardState>(emptyFileState);
  return { state, setState };
};
