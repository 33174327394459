import { Chip } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { SearchStatus } from "store/api/generatedApi";

type Props = {
  searchStatus: SearchStatus;
  count?: number;
};
export const SearchStatusChip = ({ searchStatus, count }: Props) => {
  const { t } = useTenantTranslation();
  const label =
    count !== undefined ? `${count} ${t(searchStatus)}` : t(searchStatus);

  return (
    <Chip
      label={label}
      size="small"
      variant="outlined"
      color={
        searchStatus === "success"
          ? "success"
          : searchStatus === "failure"
          ? "error"
          : "default"
      }
    />
  );
};
