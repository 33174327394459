import { Alert, AlertColor, Snackbar } from "@mui/material";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

type StatusNotification = { type: AlertColor; text: string; seconds?: number };

const StatusNotificationsContext = createContext<
  ((statusNotification: StatusNotification) => null) | undefined
>(undefined);

export const ProvideStatusNotifications = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [statusNotifications, setStatusNotifications] = useState<
    (StatusNotification & { id: number })[]
  >([]);

  const callStatusNotification = useCallback(
    (statusNotification: StatusNotification) => {
      setStatusNotifications((current) => [
        ...current,
        {
          ...statusNotification,
          id:
            current.length === 0
              ? 0
              : Math.max(...current.map((item) => item.id)) + 1,
        },
      ]);
      return null;
    },
    []
  );

  return (
    <StatusNotificationsContext.Provider value={callStatusNotification}>
      {children}

      {statusNotifications.map(({ id, type, text, seconds }) => (
        <Snackbar
          key={id}
          open
          autoHideDuration={(seconds ?? 6) * 1000}
          onClose={() =>
            setStatusNotifications((current) =>
              current.filter((item) => item.id !== id)
            )
          }
        >
          <Alert severity={type}>{text}</Alert>
        </Snackbar>
      ))}
    </StatusNotificationsContext.Provider>
  );
};

export const useNotifyStatus = (): ((
  statusNotification: StatusNotification
) => void) => {
  const statusNotifications = useContext(StatusNotificationsContext);

  if (statusNotifications == null) {
    throw new Error("No search context has been provided");
  } else {
    return statusNotifications;
  }
};
