import { useLanguage } from "hooks/settings";
import { NumberSerialiserOptions, Serialiser } from "./types";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const defaultDecimalPlaces = 0;

export const useNumberSerialiser = <T = never>(
  defaultOptions?: NumberSerialiserOptions<T>
): Serialiser<number | T, NumberSerialiserOptions<T>> => {
  const language = useLanguage();
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      format: (value, optionsOverride) => {
        if (typeof value === "number") {
          const decimalPlaces =
            optionsOverride?.decimalPlaces ??
            defaultOptions?.decimalPlaces ??
            defaultDecimalPlaces;
          // Strings sometimes sneak in in some places this is called
          const coercedValue = parseFloat(value.toString());
          return t("number", {
            value: coercedValue,
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          });
        } else {
          // The default type - eg. `null` - has been passed in
          return (
            optionsOverride?.default ??
            defaultOptions?.default ?? { text: "" }
          ).text;
        }
      },
      parse: (text, optionsOverride) => {
        const trimmedText = text.trim();

        if (trimmedText.length === 0) {
          return {
            valid: true,
            value: (
              optionsOverride?.default ??
              defaultOptions?.default ?? { value: 0 }
            ).value,
          };
        }

        const value = parseLocalisedNumber(language, trimmedText);

        if (value == null) {
          return { valid: false };
        } else {
          return {
            valid: true,
            value: roundToDecimalPlaces(
              clamp(
                value,
                optionsOverride?.min ?? defaultOptions?.min,
                optionsOverride?.max ?? defaultOptions?.max
              ),
              optionsOverride?.decimalPlaces ??
                defaultOptions?.decimalPlaces ??
                defaultDecimalPlaces
            ),
          };
        }
      },
    };
  }, [defaultOptions, language, t]);
};

/** Build a number formatter for a particular locale.
 *
 * It appears that there isn't a really well-standardised way of implementing a
 * locale-specific number formatter that properly accounts for different ways of
 * grouping numbers (full stops, commas, etc.).  The code below is modified code
 * from a blog that appears - from some research - to be the de facto authority
 * on the topic:
 *
 * https://observablehq.com/@mbostock/localized-number-parsing.
 *
 * It's been tested against the British and German methods of formatting
 * numbers.
 */
export const parseLocalisedNumber = (locale: string, text: string) => {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
  const numerals = Array.from(
    new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)
  ).reverse();
  const index = new Map(numerals.map((d, i) => [d, i]));

  const group = new RegExp(
    `[${parts.find((d) => d.type === "group")!.value}]`,
    "g"
  );
  const decimal = new RegExp(
    `[${parts.find((d) => d.type === "decimal")!.value}]`
  );
  const numeral = new RegExp(`[${numerals.join("")}]`, "g");
  const getIndex = (d: string) => {
    const i = index.get(d);
    return i === undefined ? "" : i.toString();
  };

  const formattedOutput = text
    .trim()
    .replace(group, "")
    .replace(decimal, ".")
    .replace(numeral, getIndex);

  const parsedOutput = parseFloat(formattedOutput);

  return isNaN(parsedOutput) ? null : parsedOutput;
};

const roundToDecimalPlaces = (value: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(value * factor) / factor;
};

export const clamp = (
  value: number,
  minimum: number | null | undefined,
  maximum: number | null | undefined
) =>
  minimum !== null && minimum !== undefined && value < minimum
    ? minimum
    : maximum !== null && maximum !== undefined && value > maximum
    ? maximum
    : value;
