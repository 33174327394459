import { Box, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  topPanel?: ReactNode;
  leftPanel: ReactNode;
  rightPanel?: ReactNode;
  bottomPanel?: ReactNode;
  disabledMessage?: string;
  fullWidthLeftPanel?: boolean;
  // TODO: Eventually the left panel outline should be removed on everything, thus this prop and the style it controls should be removed from this component
  // The outline was a temporary style to help transition from the old input tables to the new design
  removeLeftPanelOutline?: boolean;
};

export const InputModalContentLayout = ({
  topPanel,
  leftPanel,
  rightPanel,
  bottomPanel,
  disabledMessage,
  fullWidthLeftPanel,
  removeLeftPanelOutline,
}: Props) => {
  if (disabledMessage) {
    return (
      <Grid container columns={8} spacing={2}>
        <Grid item xs={5}>
          <Box
            sx={{
              backgroundColor: "grey.50",
              p: 3,
            }}
          >
            <Typography color="text.secondary">{disabledMessage}</Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container columns={8} spacing={2}>
      {topPanel ? (
        <Grid item xs={8}>
          {topPanel}
        </Grid>
      ) : null}
      <Grid item xs={fullWidthLeftPanel ? 8 : 5}>
        <Box
          sx={{
            border: removeLeftPanelOutline ? undefined : 1,
            borderRadius: 1,
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          {leftPanel}
        </Box>
      </Grid>

      <Grid item xs={3}>
        {rightPanel}
      </Grid>
      {bottomPanel ? (
        <Grid item xs={8}>
          {bottomPanel}
        </Grid>
      ) : null}
    </Grid>
  );
};
