import { InvitedUser } from "store/api/generatedApi";
import { IconButton, TableCell, TableRow } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const PendingInviteTableRow = ({
  invitedUser,
  onDelete,
}: {
  invitedUser: InvitedUser;
  onDelete: () => void;
}) => {
  const invitedTime = new Date(invitedUser.invited_at);
  return (
    <TableRow>
      <TableCell>{invitedUser.email}</TableCell>
      <TableCell>{invitedTime.toUTCString()}</TableCell>
      <TableCell align="center">
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
