import { useTheme } from "@mui/material";

import { usePercentageSerialiser } from "hooks/serialisers/percentages";

import { PlainCell, StyledCell } from "../common/Cell";
import { Measure, useMeasureOption } from "./ProductionContext";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useUnitsFormatter } from "hooks/formatters";
import { UnitSuffix } from "../common/UnitSuffix";

type AggregationCellProps = {
  value: number;
  variant: "mass" | "price" | "cost";
};

export const AggregationCell = ({ value, variant }: AggregationCellProps) => {
  const { format: massFormat } = useNumberSerialiser({
    decimalPlaces: 1,
    min: 0,
  });
  const { format: priceFormat } = useNumberSerialiser({
    decimalPlaces: 2,
    min: 0,
  });
  const unit = useUnitsFormatter(false);

  const [renderValue, renderSuffix] = (() => {
    switch (variant) {
      case "mass":
        return [massFormat(value), unit("mass")];
      case "price":
        return [priceFormat(value), unit("specific_cost")];
      case "cost":
        return [priceFormat(value), unit("cost")];
    }
  })();

  return (
    <PlainCell
      value={renderValue}
      suffix={<UnitSuffix>{renderSuffix}</UnitSuffix>}
    />
  );
};

type VisualisedCellProps = {
  value: number;
  total: number;
  maximum: number;
};

const useMeasureUnit = () => {
  const measure = useMeasureOption();
  const unit = useUnitsFormatter(false);

  switch (measure) {
    case Measure.Mass:
      return unit("mass");
    case Measure.Percentage:
      return unit("mass_fraction");
  }
};

const useMeasureFormat = (value: number, total: number) => {
  const measure = useMeasureOption();
  const { format: percentageFormat } = usePercentageSerialiser(1);
  const { format: massFormat } = useNumberSerialiser({
    decimalPlaces: 1,
    min: 0,
  });

  switch (measure) {
    case Measure.Mass:
      return massFormat(value);
    case Measure.Percentage:
      return percentageFormat(value / total);
  }
};

export const VisualisedCell = ({
  value,
  total,
  maximum,
}: VisualisedCellProps) => {
  const theme = useTheme();

  const renderSuffix = useMeasureUnit();
  const renderValue = useMeasureFormat(value, total);

  const alphaValue = value / maximum;

  return (
    <StyledCell
      value={renderValue}
      suffix={<UnitSuffix>{renderSuffix}</UnitSuffix>}
      alpha={alphaValue}
      color={theme.palette.data.blue}
    />
  );
};
