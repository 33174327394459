import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { SearchMaterials } from "contexts/search/context";
import { ObtainableBundleItem } from "src/store/api/generatedApi";
import { getMicrosecondTimestamp } from "src/utils/getMicrosecondTimestamp";
import { v4 as UUID } from "uuid";
import { AvailabilityRow } from "./AvailabilitiesTable";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import { useObtainableBundles } from "contexts/search/provider";
import { usePeriodIndex } from "hooks/periodIndex";

export const useHandleTableMutations = (materials: SearchMaterials) => {
  const periodTab = usePeriodIndex();
  const [, setObtainableBundles] = useObtainableBundles(periodTab);

  const numberSerialiser = useNumberSerialiser({
    min: 0,
    default: { value: null, text: "" },
    decimalPlaces: 0,
  });
  const notifyStatus = useNotifyStatus();
  const { t } = useTenantTranslation();

  const handleAddItem = () => {
    const newAvailabilityItem: ObtainableBundleItem = {
      uuid: UUID(),
      material_id: materials.byIndex[materials.byIndex.length - 1]!.id,
      created_at: getMicrosecondTimestamp(),
      label: "",
      min_obtainable: null,
      max_obtainable: null,
      specific_price: null,
      arrival_probability: null,
    };
    setObtainableBundles((current) => [...current, newAvailabilityItem]);
  };

  const handleUpdateItem = (
    newRow: AvailabilityRow,
    oldRow: AvailabilityRow
  ): AvailabilityRow => {
    const newMaterial = materials.byIndex.find(
      (material) => material.name === newRow.material_name
    );

    const minObtainableResult = numberSerialiser.parse(
      newRow.min_obtainable?.toString() ?? ""
    );
    const maxObtainableResult = numberSerialiser.parse(
      newRow.max_obtainable?.toString() ?? ""
    );
    const specificPriceResult = numberSerialiser.parse(
      newRow.specific_price?.toString() ?? "",
      { decimalPlaces: 2 }
    );
    const arrivalProbabilityPercentResult = numberSerialiser.parse(
      typeof newRow.arrival_probability === "string"
        ? newRow.arrival_probability
        : newRow.arrival_probability === null ||
          newRow.arrival_probability === undefined
        ? ""
        : numberSerialiser.format(newRow.arrival_probability * 100),
      { decimalPlaces: 2 }
    );

    if (
      minObtainableResult.valid &&
      maxObtainableResult.valid &&
      specificPriceResult.valid &&
      arrivalProbabilityPercentResult.valid &&
      newMaterial !== undefined
    ) {
      if (
        // if min is change and min is greater than max, set max to min
        oldRow.min_obtainable !== newRow.min_obtainable &&
        maxObtainableResult.value != null &&
        minObtainableResult.value != null &&
        minObtainableResult.value > maxObtainableResult.value
      ) {
        maxObtainableResult.value = minObtainableResult.value;
      }

      if (
        // if max is changed and max is less than min, set min to max
        oldRow.max_obtainable !== newRow.max_obtainable &&
        maxObtainableResult.value != null &&
        minObtainableResult.value != null &&
        maxObtainableResult.value < minObtainableResult.value
      ) {
        minObtainableResult.value = maxObtainableResult.value;
      }

      const validatedNewItem: ObtainableBundleItem = {
        uuid: newRow.uuid,
        material_id: newMaterial.id,
        label: newRow.label,
        min_obtainable: minObtainableResult.value,
        max_obtainable: maxObtainableResult.value,
        specific_price: specificPriceResult.value,
        created_at: oldRow.created_at,
        arrival_probability:
          arrivalProbabilityPercentResult.value === null
            ? null
            : arrivalProbabilityPercentResult.value / 100,
      };

      setObtainableBundles((current) => [
        ...current.map((item) =>
          item.uuid === oldRow.uuid ? validatedNewItem : item
        ),
      ]);
      return { ...validatedNewItem, material_name: newMaterial.name };
    } else {
      notifyStatus({ text: t("invalidNumber"), type: "error" });
      return oldRow;
    }
  };

  const handleRemoveItems = (itemIds: string[]) => {
    setObtainableBundles((current) =>
      current.filter((item) => !itemIds.includes(item.uuid))
    );
    return;
  };

  return { handleAddItem, handleUpdateItem, handleRemoveItems };
};
