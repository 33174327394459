import { useUnitsFormatter } from "hooks/formatters";
import { ScrapHeader } from "../common/ScrapHeader";
import {
  Measure,
  useMeasureOption,
  usePeriodOption,
} from "./ProductionContext";

type Props = {
  name: string;
  id: number;
};

export const ScrapColumnHeader = ({ name, id }: Props) => {
  const unit = useUnitsFormatter(true);
  const measureOption = useMeasureOption();
  const period = usePeriodOption();

  const nameSuffix = (() => {
    switch (measureOption) {
      case Measure.Mass:
        return unit("mass");
      case Measure.Percentage:
        return unit("mass_fraction");
    }
  })();

  const scrapHeaderType = (() => {
    switch (period) {
      case 1:
        return "consumption";
      default:
        return "properties";
    }
  })();

  return (
    <ScrapHeader
      type={scrapHeaderType}
      renderValue={`${name}${nameSuffix}`}
      name={name}
      id={id}
      alignment="top"
      period={period}
    />
  );
};
