import {
  MixMaterialSummary,
  MixSummary,
  ProductGroupMixMaterialSummary,
  ProductGroupMixSummary,
} from "store/api/generatedApi";

export const getMixSummaryName = (
  summary:
    | ProductGroupMixSummary
    | ProductGroupMixMaterialSummary
    | MixSummary
    | MixMaterialSummary
): string => {
  if ("product_group_name" in summary) {
    return summary.product_group_name;
  } else {
    return summary.chef_group_name;
  }
};
