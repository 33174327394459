import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useSyncWithFirebaseMutation } from "store/api/generatedApi";
import { useState } from "react";
import { InviteUser } from "./inviteUser";
import { UserList } from "./userList";
import { useTenant } from "hooks/settings";
import { PendingInvitesList } from "./pendingInvites";

export const ManageUsers = () => {
  return (
    <Container sx={{ mt: 2 }}>
      <Typography variant="h4" sx={{ p: 2 }}>
        Manage users
      </Typography>
      <ManageBox />
    </Container>
  );
};

const ManageBox = () => {
  const selectedTenant = useTenant();

  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [syncDialogOpen, setSyncDialogOpen] = useState(false);

  const [syncWithFirebase] = useSyncWithFirebaseMutation();

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOnError = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleConfirmSync = () => {
    void syncWithFirebase({ tenantName: selectedTenant })
      .unwrap()
      .catch(() => handleOnError("Error syncing firebase"))
      .finally(() => {
        setSyncDialogOpen(false);
      });
  };
  const handleCancelSync = () => setSyncDialogOpen(false);

  return (
    <Container>
      <Stack direction="row" justifyContent="flex-end" sx={{ m: 1 }}>
        <Button
          sx={{ mr: 2 }}
          variant="contained"
          onClick={() => setSyncDialogOpen(true)}
        >
          Sync with Firebase
        </Button>
        <Button
          variant="contained"
          onClick={handleModalOpen}
          disabled={!selectedTenant}
          sx={{ minWidth: 130 }}
        >
          Invite Users
        </Button>
      </Stack>
      <Divider sx={{ m: 2 }} />
      {selectedTenant ? (
        <>
          <UserList tenant={selectedTenant} onError={handleOnError} />
          <InviteUser
            tenant={selectedTenant}
            isOpen={modalOpen}
            onClose={handleCloseModal}
            onError={handleOnError}
          />
          <Divider sx={{ m: 2 }} />
          <Typography sx={{ ml: 2 }} variant="h5">
            Pending Invites
          </Typography>
          <PendingInvitesList tenant={selectedTenant} onError={handleOnError} />
        </>
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
      <ConfirmSyncDialog
        open={syncDialogOpen}
        handleOk={handleConfirmSync}
        handleCancel={handleCancelSync}
      />
    </Container>
  );
};

const ConfirmSyncDialog = ({
  open,
  handleOk,
  handleCancel,
}: {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) => {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Sync with Firebase</DialogTitle>
      <DialogContent>
        Please confirm your request to sync with firebase. Note that this will
        overwrite any stale data in the db.
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
