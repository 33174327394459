import { useEffect } from "react";

export const useTabName = (name?: string | null) => {
  useEffect(() => {
    const originalName = document.title;
    document.title = name == null ? "Scrap Chef" : `${name} - Scrap Chef`;
    return () => {
      document.title = originalName;
    };
  }, [name]);
};
