import {
  PopoverProps,
  Popover,
  useTheme,
  Typography,
  Divider,
  Box,
} from "@mui/material";

export interface Props extends PopoverProps {
  title: string;
}

export const TitledPopoverTemplate = ({
  title,
  children,
  ...props
}: React.PropsWithChildren<Props>) => {
  const theme = useTheme();
  const componentProps: PopoverProps = {
    slotProps: {
      paper: {
        sx: {
          outlineWidth: "1px",
          outlineStyle: "solid",
          outlineColor: theme.palette.divider,
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
        },
      },
    },
    ...props,
  };
  return (
    <Popover {...componentProps}>
      <Typography variant="h5" sx={{ paddingX: 2, paddingY: 1 }}>
        {title}
      </Typography>
      <Divider />
      <Box sx={{ paddingX: 2, paddingY: 2 }}>{children}</Box>
    </Popover>
  );
};
