import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { EntityMapping } from "helpers";
import { ProductGroup, SyncedProductionPlan } from "store/api/generatedApi";
import { useSetItems } from "./dependencies";

type InputMode = "productionGroups" | "products";

type Props = {
  productionPlan: SyncedProductionPlan;
  setProductionPlan: (plan: SyncedProductionPlan) => void;
  productGroups: EntityMapping<ProductGroup> | null;
};

export const InputModeSelector = ({
  productGroups,
  productionPlan,
  setProductionPlan,
}: Props) => {
  const { t } = useTenantTranslation();

  const {
    resetProductGroups,
    resetProducts,
    steelGradePlan,
    productGroupPlan,
  } = useSetItems(productGroups, productionPlan, setProductionPlan);

  // Everytime we toggle the input mode, we should reset the items
  const toggleInputMode = (inputMode: InputMode) => {
    if (inputMode === "productionGroups") {
      resetProductGroups();
    } else {
      resetProducts();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography sx={{ mr: 1 }}>{t("addAs")}:</Typography>
      <RadioGroup
        row
        aria-labelledby="production-field-type-label"
        name="production-field-type"
      >
        <FormControlLabel
          value="productionGroups"
          control={<Radio color="secondary" />}
          label={t("addAsProductGroups")}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            toggleInputMode("productionGroups");
          }}
          checked={productGroupPlan !== null}
        />
        <FormControlLabel
          value="products"
          control={<Radio color="secondary" />}
          label={t("addAsProducts")}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            toggleInputMode("products");
          }}
          checked={steelGradePlan !== null}
        />
      </RadioGroup>
    </Box>
  );
};
