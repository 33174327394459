import { Stack } from "@mui/material";
import { useMiscParams } from "contexts/search/provider";
import { useTenantTranslation } from "hooks/formatters";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { LabelledSwitch, SwitchSkeleton } from "./suppressConstraints";

export const IntegerSolutionEditor = () => {
  const { t } = useTenantTranslation();
  const [miscParams, setMiscParams] = useMiscParams();

  return (
    <Stack sx={{ mt: 0.5 }} gap={0.5}>
      <LoadedContent
        data={miscParams}
        loading={<SwitchSkeleton />}
        waiting={<SwitchSkeleton />}
      >
        {(loaded) => (
          <LabelledSwitch
            label={t("integerSolution")}
            value={loaded.integer_solution}
            setValue={(update) =>
              setMiscParams((current) => ({
                ...current,
                integer_solution: update(current.integer_solution),
              }))
            }
          />
        )}
      </LoadedContent>
    </Stack>
  );
};
