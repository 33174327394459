import Decimal from "decimal.js";
import { SupportedLanguage } from "src/store/api/generatedApi";

export const formatNumber =
  (language: SupportedLanguage) =>
  (decimalPlaces: number, fixed = false) =>
  (value: number): string => {
    const decimal = new Decimal(value);
    const baseOptions = {
      maximumFractionDigits: decimalPlaces,
    };
    const options = fixed
      ? {
          minimumFractionDigits: decimalPlaces,
          ...baseOptions,
        }
      : baseOptions;

    const formatter = new Intl.NumberFormat(language, options);
    return formatter.format(
      decimal.toDecimalPlaces(decimalPlaces).toNumber() + 0
    );
  };
