import { EntityMapping } from "helpers";
import { SteelGradeSummary } from "src/store/api/generatedApi";

export const filterForSteelGrade = <
  T extends { steel_grades: SteelGradeSummary[] },
>(
  steelGradeId: number,
  productGroups: EntityMapping<T>
) => {
  return productGroups.byIndex.filter(({ steel_grades }) =>
    steel_grades.map(({ id }) => id).includes(steelGradeId)
  );
};
