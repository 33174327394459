import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { useTenantTranslation } from "hooks/formatters";
import { useState } from "react";
import { IconButton, ListItemIcon, Menu, Typography } from "@mui/material";
import {
  BugReport,
  HistoryRounded,
  MoreVert,
  TableChart,
} from "@mui/icons-material";
import { useIsAdmin } from "hooks/settings";
import { useNavigateToDebugSearch } from "hooks/navigation";
import { useSearchId } from "hooks/search";
import { Panel } from "../SearchPage";
import { useHotkeys } from "react-hotkeys-hook";
import { hotkeys } from "hooks/hotkeys";

type Props = {
  setShowHistory: (show: boolean) => void;
  setPanel: (panel: Panel) => void;
};

export const SearchActionsButton = ({ setShowHistory, setPanel }: Props) => {
  const { t } = useTenantTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isAdmin = useIsAdmin();
  const navigate = useNavigateToDebugSearch();
  const searchId = useSearchId();

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDebugPage = () => {
    if (searchId !== null) {
      navigate(searchId);
    }
    handleClose();
  };

  useHotkeys(hotkeys.debugPage, openDebugPage, { enabled: isAdmin });
  useHotkeys(hotkeys.historyPage, () => setShowHistory(true));
  useHotkeys(hotkeys.constraintsPage, () => setPanel(Panel.Constraints));

  return (
    <>
      <IconButton onClick={handleToggle} size="small">
        <MoreVert />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 0.5 }}
        MenuListProps={{ disablePadding: true }}
      >
        <MenuItem
          onClick={() => {
            setShowHistory(true);
            handleClose();
          }}
          sx={{ px: 2, py: 1 }}
        >
          <ListItemIcon sx={{ color: "text.primary", mr: -0.5 }}>
            <HistoryRounded />
          </ListItemIcon>
          <Typography variant="button">{t("history")}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPanel(Panel.Constraints);
            handleClose();
          }}
          sx={{ px: 2, py: 1 }}
        >
          <ListItemIcon sx={{ color: "text.primary", mr: -0.5 }}>
            <TableChart />
          </ListItemIcon>
          <Typography variant="button">{t("seeConstraints")}</Typography>
        </MenuItem>
        {isAdmin ? (
          <MenuItem onClick={openDebugPage} sx={{ px: 2, py: 1 }}>
            <ListItemIcon sx={{ color: "text.primary", mr: -0.5 }}>
              <BugReport />
            </ListItemIcon>
            <Typography variant="button">{t("Debug")}</Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};
