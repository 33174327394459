import { ContentCopy, ContentPaste } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Setter } from "components/experimental/inputs/timeline";
import {
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import { Maybe } from "models/maybe";
import { SyncedProductionPlan } from "src/store/api/generatedApi";

const copyProduction = (
  productionPlan: SyncedProductionPlan,
  productGroups: SearchProductGroups,
  steelGrades: SearchSteelGrades
): string =>
  JSON.stringify({
    product_group_items: productionPlan.product_group_items?.map((item) => ({
      ...item,
      product_group: productGroups.byId[item.product_group_id]!.name,
    })),
    steel_grade_items: productionPlan.steel_grade_items?.map((item) => ({
      ...item,
      steel_grade: steelGrades.byId[item.steel_grade_id]!.name,
    })),
  });

const pasteProduction = (
  productionPlan: string,
  productGroups: SearchProductGroups,
  steelGrades: SearchSteelGrades
): Maybe<SyncedProductionPlan> => {
  try {
    const plan = JSON.parse(productionPlan) as {
      product_group_items?: { product_group: string; num_heats: number }[];
      steel_grade_items?: { steel_grade: string; num_heats: number }[];
    };
    return {
      present: true,
      value: {
        product_group_items:
          plan.product_group_items?.map((item) => ({
            product_group_id: productGroups.byName[item.product_group]!.id,
            num_heats: item.num_heats,
          })) ?? null,
        steel_grade_items:
          plan.steel_grade_items?.map((item) => ({
            steel_grade_id: steelGrades.byName[item.steel_grade]!.id,
            num_heats: item.num_heats,
          })) ?? null,
      },
    };
  } catch {
    return { present: false };
  }
};

export const CopyProductionButton = ({
  productionPlan,
  productGroups,
  steelGrades,
}: {
  productionPlan: SyncedProductionPlan;
  productGroups: SearchProductGroups;
  steelGrades: SearchSteelGrades;
}) => {
  const { t } = useTenantTranslation();
  const notifyStatus = useNotifyStatus();

  return (
    <Box>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ContentCopy />}
        onClick={() =>
          navigator.clipboard
            .writeText(
              copyProduction(productionPlan, productGroups, steelGrades)
            )
            .then(() =>
              notifyStatus({
                type: "success",
                text: t("productionCopiedToClipboard"),
              })
            )
        }
      >
        {t("copyVerb")}
      </Button>
    </Box>
  );
};

export const PasteProductionButton = ({
  setProductionPlan,
  productGroups,
  steelGrades,
}: {
  setProductionPlan: Setter<SyncedProductionPlan>;
  productGroups: SearchProductGroups;
  steelGrades: SearchSteelGrades;
}) => {
  const { t } = useTenantTranslation();
  const notifyStatus = useNotifyStatus();

  return (
    <Box>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ContentPaste />}
        onClick={() =>
          navigator.clipboard
            .readText()
            .then((text) => {
              const plan = pasteProduction(text, productGroups, steelGrades);

              if (
                plan.present &&
                (plan.value.product_group_items ?? []).length +
                  (plan.value.steel_grade_items ?? []).length >
                  0
              ) {
                setProductionPlan(() => plan.value);
              } else {
                throw new Error();
              }
            })
            .catch(() =>
              notifyStatus({ type: "error", text: t("failedToReadProduction") })
            )
        }
      >
        {t("pasteVerb")}
      </Button>
    </Box>
  );
};
