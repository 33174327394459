import { Box, Stack } from "@mui/material";
import { InventoryTable } from "./table/inventory/inventoryTable";
import { ObtainableReport } from "./table/detailDrawers/ObtainableReport";
import { usePlan } from "contexts/plan";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useTenantTranslation } from "hooks/formatters";
import TableChartIcon from "@mui/icons-material/TableChart";

type Props = {
  planId: number;
};

export const InventoryTab = ({ planId }: Props) => {
  const { plan } = usePlan(planId);
  const { t } = useTenantTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ w: 1, mt: 1 }}>
        <LoadingButton
          variant="text"
          color="primary"
          onClick={() => setOpen(true)}
          sx={{ textTransform: "none" }}
          loading={plan.status !== "success"}
          startIcon={<TableChartIcon />}
        >
          {t("seeObtainableReport")}
        </LoadingButton>

        {plan.status === "success" ? (
          <ObtainableReport
            plan={plan.data}
            open={open}
            onClose={() => setOpen(false)}
          />
        ) : null}
      </Stack>
      <Box sx={{ py: 1, minHeight: 0 }}>
        <InventoryTable planId={planId} />
      </Box>
    </>
  );
};
