/** Functions for applying results back to the plant-level inputs for which they
  were produced. */

import {
  ExperimentalInputs,
  ImportedMixAllocation,
  ProductionBlock,
} from "src/store/api/generatedApi";
import { resolvedProductionBlocks } from "./resolve";
import { productionActions } from "../blocks/production";
import { useInputs } from "../provider";

export const applyMixResults = (
  inputs: ExperimentalInputs,
  mixResults: ImportedMixAllocation[]
): ExperimentalInputs => {
  const appliedProduction: Record<string, ProductionBlock> = Object.fromEntries(
    resolvedProductionBlocks(inputs)
      .map((block) => ({
        ...block,
        steel_grades: block.steel_grades.map((item) => ({
          ...item,
          mixes:
            mixResults.find(
              (mixResult) =>
                mixResult.production_uuid === block.uuid &&
                mixResult.steel_grade_name === item.steel_grade_name
            )?.mixes ?? [],
        })),
      }))
      .map((block) => [block.uuid, block])
  );

  return {
    ...inputs,
    production: inputs.production.map((block) =>
      productionActions.applyResults(
        block,
        appliedProduction[block.uuid] ?? null
      )
    ),
  };
};

export const useApplyMixResults = () => {
  const [, setInputs] = useInputs();

  return (mixResults: ImportedMixAllocation[]) =>
    setInputs((current) => applyMixResults(current, mixResults));
};
