import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { PlanItem, PlanItemDetail, PlanItemName } from "./types";
import { ReactNode, useState } from "react";
import {
  ChevronRight,
  ExpandMore,
  ArrowForward,
  Delete,
} from "@mui/icons-material";

export const PlanEditorItem = function <T = Record<never, string>>({
  item,
  removeItem,
  onSecondaryClick,
  name,
  detail,
  setSelectedItem,
  children,
}: {
  item: PlanItem<T>;
  removeItem?: () => void;
  onSecondaryClick?: (item: PlanItem<T>, target: HTMLButtonElement) => void;
  name: PlanItemName;
  detail?: PlanItemDetail<T>;
  setSelectedItem?: (item: PlanItem<T>) => void;
  children: ReactNode;
}) {
  const [hovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={(theme) => ({
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      })}
    >
      <Grid
        container
        // Used as a query selector to focus the heats input automatically
        // eslint-disable-next-line react/forbid-component-props
        className="plan-editor-item"
        sx={{ height: 50 }}
      >
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {detail && !setSelectedItem ? (
            <IconButton
              onClick={() => {
                setExpanded(!expanded);
              }}
              size="small"
            >
              {expanded ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
          ) : null}
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          {setSelectedItem ? (
            <Link
              variant="body1"
              color={theme.palette.text.primary}
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
              onClick={() => setSelectedItem(item)}
            >
              {name(item)}
            </Link>
          ) : (
            <Typography variant="body1" sx={{ userSelect: "none" }}>
              {name(item)}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {children}
        </Grid>

        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {onSecondaryClick ? (
            <IconButton
              onClick={(event) => onSecondaryClick(item, event.currentTarget)}
              size="small"
            >
              {Boolean(onSecondaryClick) && hovered ? (
                <ArrowForward fontSize="small" />
              ) : null}
            </IconButton>
          ) : null}
          {removeItem ? (
            <IconButton onClick={removeItem} size="small">
              {Boolean(removeItem) && hovered ? (
                <Delete fontSize="small" />
              ) : null}
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
      <Collapse in={expanded}>
        {detail && expanded ? (
          <Grid container>
            <Grid item xs={1} />
            <Grid
              item
              xs={10}
              sx={{
                py: 1,
              }}
            >
              {detail(item)}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        ) : null}
      </Collapse>
    </Box>
  );
};
