import { Error } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";

export const AwaitingConfirmationError = ({ close }: { close: () => void }) => {
  const { t } = useTenantTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
      }}
    >
      <Stack
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <Error sx={{ color: "error.main", height: 28, width: 28 }} />
        <Typography variant="h5" sx={{ mt: 1 }}>
          {t("errorSyncingWithLevel2")}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          {t("sorryForInconvenience")}
        </Typography>
        <Button variant="contained" onClick={close} sx={{ mt: 3 }}>
          {t("close")}
        </Button>
      </Stack>
    </Box>
  );
};
