import {
  Inventory,
  ObtainableBlock,
  ProductionBlock,
} from "src/store/api/generatedApi";
import {
  SearchMaterialPhysics,
  SearchMaterials,
} from "contexts/search/context";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useMemo, useState } from "react";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";
import {
  ConsumptionBreakdown,
  consumptionBreakdown,
} from "../processing/projection";

export type ConsumptionSummary = {
  chargedMass: number;
  tappedMass: number;
  totalPrice: number;
};

type ConsumptionFilter = {
  steelGrade: string | null;
  material: string | null;
};

export const consumptionSummary = (
  breakdown: ConsumptionBreakdown[],
  materials: SearchMaterials,
  materialPhysics: SearchMaterialPhysics,
  inventory: Inventory
): ConsumptionSummary => {
  const yieldMapping: Record<string, number> = Object.fromEntries(
    materialPhysics.byIndex.map((material) => [
      materials.byId[material.material_id]!.name,
      material.yield_percent / 100,
    ])
  );

  const priceMapping = Object.fromEntries(
    inventory.inventory.map((item) => [item.material_name, item.specific_price])
  );

  return {
    chargedMass: breakdown
      .map((item) => item.mass)
      .reduce((left, right) => left + right, 0),
    tappedMass: breakdown
      .map((item) => item.mass * (yieldMapping[item.material] ?? 1.0))
      .reduce((left, right) => left + right, 0),
    totalPrice: breakdown
      .map((item) => item.mass * (priceMapping[item.material] ?? 0))
      .reduce((left, right) => left + right, 0),
  };
};

export const ConsumptionSummary = ({
  start,
  end,
  inventory,
  resolvedProduction,
  resolvedObtainable,
  materials,
  materialPhysics,
}: {
  start: number;
  end: number;
  inventory: Inventory;
  resolvedProduction: ProductionBlock[];
  resolvedObtainable: ObtainableBlock[];
  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
}) => {
  const breakdown = consumptionBreakdown(
    resolvedProduction,
    resolvedObtainable,
    start,
    end
  );

  const [filters, setFilters] = useState<ConsumptionFilter[]>([]);
  const [filterSteelGrade, setFilterSteelGrade] = useState("");
  const [filterMaterial, setFilterMaterial] = useState("");
  const [allSteelGrades, setAllSteelGrades] = useState(false);
  const [allMaterials, setAllMaterials] = useState(false);

  const resolvedFilters = useMemo(
    () =>
      filters
        .concat(
          allSteelGrades
            ? [
                ...new Set(
                  resolvedProduction.flatMap((item) =>
                    item.steel_grades.map((grade) => grade.steel_grade_name)
                  )
                ),
              ].map((steelGrade) => ({ steelGrade, material: null }))
            : []
        )
        .concat(
          allMaterials
            ? materials.byIndex.map((material) => ({
                steelGrade: null,
                material: material.name,
              }))
            : []
        ),
    [allSteelGrades, allMaterials, resolvedProduction, filters, materials]
  );

  const serialiser = useStringSerialiser();

  return (
    <Stack>
      <ConsumptionSummaryDisplay
        // Filter for only *consumption* of scrap, excluding deliveries
        breakdown={breakdown.filter((item) => item.mass > 0)}
        materials={materials}
        materialPhysics={materialPhysics}
        inventory={inventory}
      />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography>Filter steel grade</Typography>
        <Box sx={{ w: 200 }}>
          <ValidatedTextField
            value={filterSteelGrade}
            setValue={setFilterSteelGrade}
            serialiser={serialiser}
            onEditConfirmedWithKeyboard={(text) => {
              setFilterSteelGrade("");
              setFilters((current) => [
                ...current,
                { steelGrade: text, material: null },
              ]);
            }}
          />
        </Box>

        <Typography>Filter material</Typography>
        <Box sx={{ w: 200 }}>
          <ValidatedTextField
            value={filterMaterial}
            setValue={setFilterMaterial}
            serialiser={serialiser}
            onEditConfirmedWithKeyboard={(text) => {
              setFilterMaterial("");
              setFilters((current) => [
                ...current,
                { material: text, steelGrade: null },
              ]);
            }}
          />
        </Box>

        <Typography>All steel grades</Typography>
        <Checkbox
          checked={allSteelGrades}
          onChange={(event) => setAllSteelGrades(event.target.checked)}
        />

        <Typography>All materials</Typography>
        <Checkbox
          checked={allMaterials}
          onChange={(event) => setAllMaterials(event.target.checked)}
        />
      </Stack>

      {resolvedFilters.map((filter) => (
        <Box key={`${filter.steelGrade} ${filter.material}`}>
          <Typography>{filter.steelGrade ?? filter.material}</Typography>
          <ConsumptionSummaryDisplay
            breakdown={breakdown.filter(
              (item) =>
                (filter.material === null ||
                  item.material === filter.material) &&
                (filter.steelGrade === null ||
                  item.steelGrade === filter.steelGrade)
            )}
            materials={materials}
            materialPhysics={materialPhysics}
            inventory={inventory}
          />
        </Box>
      ))}
    </Stack>
  );
};

const ConsumptionSummaryDisplay = ({
  breakdown,
  materials,
  materialPhysics,
  inventory,
}: {
  breakdown: ConsumptionBreakdown[];
  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
  inventory: Inventory;
}) => {
  const summary = consumptionSummary(
    breakdown,
    materials,
    materialPhysics,
    inventory
  );

  const { format: formatMass } = useNumberSerialiser({ decimalPlaces: 0 });
  const { format: formatPrice } = useNumberSerialiser({ decimalPlaces: 2 });

  return (
    <Stack direction="row" justifyContent="space-around">
      <Typography>Charged mass: {formatMass(summary.chargedMass)}</Typography>
      <Typography>Tapped mass: {formatMass(summary.tappedMass)}</Typography>
      <Typography>Total price: {formatPrice(summary.totalPrice)}</Typography>
      <Typography>
        Charged price: {formatPrice(summary.totalPrice / summary.chargedMass)}
      </Typography>
      <Typography>
        Tapped price: {formatPrice(summary.totalPrice / summary.tappedMass)}
      </Typography>
    </Stack>
  );
};
