import { useTenantUploadedFileTypes } from "hooks/settings";
import { Setter } from "hooks/state/syncing";
import { loadedEndpoint } from "models/loaded";
import { UploadedFileOutputType } from "store/api/generatedApi";
import { ImportFromFileState } from "../state";
import { SelectPlanFile } from "./SelectPlanFile";
import { LoadedContent } from "src/components/common/loading/loadedContent";

export const ImportFromFile = ({
  state,
  setState,
  outputTypes,
}: {
  state: ImportFromFileState;
  setState: Setter<ImportFromFileState>;
  outputTypes?: UploadedFileOutputType[];
}) => {
  return (
    <LoadedContent
      data={loadedEndpoint(useTenantUploadedFileTypes(outputTypes))}
    >
      {(fileTypes) => (
        <SelectPlanFile
          planFile={state.file}
          setPlanFile={async (file) => {
            return Promise.resolve(
              setState((current) => ({ ...current, file }))
            );
          }}
          planFileType={state.type ?? undefined}
          setUploadedFileType={(type) =>
            setState((current) => ({ ...current, type }))
          }
          planFileTypeOptions={fileTypes}
        />
      )}
    </LoadedContent>
  );
};
