import { liftLoadedState, mapLoaded, mapLoadedUnpack } from "models/loaded";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useTenantTranslation } from "hooks/formatters";
import { MixDetails as MixDetailsView } from "./mixDetails";
import {
  useChemicalElements,
  useChemistryGroups,
  useSteelGrades,
} from "contexts/search/provider";
import { PlanId } from "components/common/boundary/PlanId";
import { createMapping } from "helpers";
import { ChefGroupProperties } from "./properties";
import { Period } from "hooks/periodIndex";
import { Layout } from "../layout";
import { usePlan } from "contexts/plan";

type BaseProps = {
  group: number;
  open: boolean;
  setOpen: (open: boolean) => void;
};

type MixDetailsLayoutProps = {
  multipleMixes: boolean;
};

type MixDetailsProps = {
  mix: number;
  planId: PlanId;
  group: number;
  period: Period;
};

type PropertiesProps = {
  group: number;
};

type Props = BaseProps | (BaseProps & MixDetailsProps & MixDetailsLayoutProps);

export const ChefGroupDetailView = ({
  group,
  open,
  setOpen,
  ...props
}: Props) => {
  const { format } = useNumberSerialiser();
  const { t } = useTenantTranslation();

  const chemistryGroup = mapLoadedUnpack(
    useChemistryGroups(),
    (groups) => groups.byId[group] ?? null
  );

  if (chemistryGroup === null) {
    /* Sometimes, the data grid's data model lags behind the actual data,
      causing IDs of chef groups to be passed to the render function when
      the renderer has already switched to looking up product groups.  This
      can momentarily cause the product group to not exist in the lookup,
      which can be solved by having this fail gracefully */
    return null;
  }

  const title =
    chemistryGroup.name +
    ("multipleMixes" in props && "mix" in props && props.multipleMixes
      ? ` (${t("mix")} ${format(props.mix)})`
      : "");

  const isConsumption =
    "mix" in props && "planId" in props && "period" in props;

  const width = isConsumption ? 1200 : 500;

  return (
    <Layout
      title={title}
      open={open}
      doClose={() => setOpen(false)}
      width={width}
    >
      {isConsumption ? (
        <MixDetails
          planId={props.planId}
          mix={props.mix}
          group={group}
          period={props.period}
        />
      ) : (
        <Properties group={group} />
      )}
    </Layout>
  );
};

const MixDetails = ({ planId, mix, group, period }: MixDetailsProps) => {
  const chemistryGroups = useChemistryGroups();
  const chemicalElements = useChemicalElements();
  const steelGrades = useSteelGrades();

  const { plan } = usePlan(planId);

  const dependencies = liftLoadedState({
    chemistryGroups,
    chemicalElements,
    steelGrades,
    plan,
  });

  return (
    <LoadedContent data={dependencies}>
      {({ plan, chemistryGroups, chemicalElements, steelGrades }) => {
        const chemistryGroup = chemistryGroups.byId[group]!;
        return (
          <MixDetailsView
            period={period}
            maximumFailureRate={chemistryGroup.max_failure_rate}
            defaultTargetNumberOfBaskets={
              chemistryGroup.default_target_num_baskets
            }
            name={chemistryGroup.name}
            chemistryGroupId={chemistryGroup.id}
            mix={mix}
            summary={plan.summary}
            steelGrades={chemistryGroup.steel_grades.map(
              ({ id }) => steelGrades.byId[id]!
            )}
            elements={createMapping(
              chemicalElements.byIndex,
              (element) => element.id
            )}
            planId={plan.id as PlanId}
            chemicalConstraints={chemistryGroup.chemical_constraints}
          />
        );
      }}
    </LoadedContent>
  );
};

const Properties = ({ group }: PropertiesProps) => {
  const chemistryGroups = useChemistryGroups();
  const chemicalElements = useChemicalElements();
  const steelGrades = useSteelGrades();

  const chemistryGroup = mapLoaded(
    chemistryGroups,
    (chemistryGroups) => chemistryGroups.byId[group]!
  );

  const dependencies = liftLoadedState({
    chemicalElements,
    chemistryGroup,
    steelGrades,
  });

  return (
    <LoadedContent data={dependencies}>
      {({
        chemistryGroup,
        chemicalElements: { byId: elements },
        steelGrades,
      }) => (
        <ChefGroupProperties
          chefGroupId={group}
          maximumFailureRate={chemistryGroup.max_failure_rate}
          defaultTargetNumberOfBaskets={
            chemistryGroup.default_target_num_baskets
          }
          chemicalConstraints={chemistryGroup.chemical_constraints}
          steelGrades={chemistryGroup.steel_grades.map(
            ({ id }) => steelGrades.byId[id]!
          )}
          elements={elements}
        />
      )}
    </LoadedContent>
  );
};
