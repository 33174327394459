import { sorted } from "helpers";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { Annotations, Data } from "plotly.js";
import { materialGroupColours } from "src/constants";
import {
  GetSimilarChargedRecipesApiResponse,
  MixMaterialBasketSummary,
  MixMaterialSummary,
  MixSummary,
} from "src/store/api/generatedApi";
import { FullWidthPlot } from "src/components/common/plots";
import { recipeDecimalPlaces } from "src/constants";

export const SimilarChargedRecipesPlot = ({
  similarChargedRecipes,
  chefGroupName,
  mixNumber,
  period,
  steelGradeName,
  mixSummary,
  mixMaterialBasketSummary,
  mixMaterialSummary,
}: {
  similarChargedRecipes: GetSimilarChargedRecipesApiResponse;
  chefGroupName: string;
  mixNumber: number;
  period: number;
  steelGradeName: string;
  mixSummary: MixSummary[];
  mixMaterialBasketSummary: MixMaterialBasketSummary[];
  mixMaterialSummary: MixMaterialSummary[];
}) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);
  const { format } = useNumberSerialiser();
  const basketLayerLookup: Record<string, string> = {};

  const chefGroupSummary = mixSummary.filter(
    (summary) =>
      summary.chef_group_name === chefGroupName &&
      summary.period === period &&
      summary.mix_number === mixNumber
  )[0]!;

  let maxRecipeMass = Math.max(
    ...similarChargedRecipes.map((item) => item.summary.recipe_mass)
  );
  maxRecipeMass = Math.max(maxRecipeMass, chefGroupSummary.recipe_mass);

  const annotations: Partial<Annotations>[] = [];
  const data: Data[] = [];
  const basketLayerShown: string[] = [];

  const getRecipeAnnotation = (
    x: number,
    name: string,
    steelGradeName: string,
    date: string,
    cost: number,
    recipeMass: number,
    liquidMass: number,
    chemistry: { chemical_element_symbol: string; weight_percent: number }[]
  ): Partial<Annotations> => {
    const roundedCost = format(cost, { decimalPlaces: 0 });
    const roundedRecipeMass = format(recipeMass, { decimalPlaces: 1 });
    const roundedLiquidMass = format(liquidMass, { decimalPlaces: 1 });

    let annotationText = `<b>${name}</b><br><b>${steelGradeName}</b><br>${date}<br>${t(
      "totalCost"
    )}: ${units("cost")}${roundedCost} <br>${t(
      "mass"
    )}: ${roundedRecipeMass} ${units("mass")}<br>${t(
      "expectedLiquidMass"
    )}: ${roundedLiquidMass} ${units("mass")}`;

    sorted(chemistry, (item) => item.chemical_element_symbol).forEach(
      (item) => {
        annotationText += `<br>${item.chemical_element_symbol}: ${format(
          item.weight_percent,
          { decimalPlaces: 3 }
        )}${units("mass_fraction")}`;
      }
    );

    return {
      x: x,
      y: maxRecipeMass + 70,
      text: annotationText,
      font: { size: 11 },
      showarrow: false,
      align: "center",
      borderwidth: 2,
      bordercolor: "black",
      width: 170,
    };
  };

  for (const summary of mixMaterialBasketSummary) {
    basketLayerLookup[summary.material] = summary.basket_layer;
  }

  const filterMaterialSummaries = sorted(
    mixMaterialSummary.filter(
      (item) =>
        item.chef_group_name === chefGroupName &&
        item.period === 1 &&
        item.mix_number === mixNumber
    ),
    (item) => basketLayerLookup[item.material] ?? "dri"
  );

  annotations.push(
    getRecipeAnnotation(
      -1.0,
      chefGroupName,
      steelGradeName,
      "-",
      chefGroupSummary.cost_per_heat_materials,
      chefGroupSummary.recipe_mass,
      chefGroupSummary.display_produced_mass,
      []
    )
  );
  let showLegend;

  for (const chefGroupMaterialSummary of filterMaterialSummaries) {
    const basketLayer =
      basketLayerLookup[chefGroupMaterialSummary.material] ?? "dri";

    showLegend = false;

    if (!basketLayerShown.includes(basketLayer)) {
      showLegend = true;
      basketLayerShown.push(basketLayer);
    }

    data.push({
      x: [-1],
      y: [chefGroupMaterialSummary.recipe_mass],
      type: "bar",
      marker: {
        color: materialGroupColours[basketLayer],
        line: {
          color: "black",
          width: 2,
        },
      },
      showlegend: showLegend,
      legendgroup: t(basketLayer),
      text: [chefGroupMaterialSummary.material],
      hovertemplate: `%{text}<br>%{y:.${recipeDecimalPlaces}f}${units("mass")}`,
      name: t(basketLayer),
    });
  }

  similarChargedRecipes.forEach((item, index) => {
    const sortedMaterials = sorted(
      item.materials,
      (item) => item.basket_layer_name
    );
    const date = new Date(item.start_time);
    const dateString = date.toLocaleDateString();
    annotations.push(
      getRecipeAnnotation(
        index,
        item.customer_heat_id,
        item.steel_grade_name,
        dateString,
        item.summary.total_cost_per_heat,
        item.summary.recipe_mass,
        item.summary.tapped_mass,
        item.chemistry
      )
    );
    for (const itemMaterial of sortedMaterials) {
      showLegend = false;

      if (!basketLayerShown.includes(itemMaterial.basket_layer_name)) {
        showLegend = true;
        basketLayerShown.push(itemMaterial.basket_layer_name);
      }
      data.push({
        x: [index],
        y: [itemMaterial.mass],
        type: "bar",
        marker: {
          color: materialGroupColours[itemMaterial.basket_layer_name],
          line: {
            color: "black",
            width: 2,
          },
        },
        showlegend: showLegend,
        legendgroup: t(itemMaterial.basket_layer_name),
        text: [itemMaterial.material_name],
        hovertemplate: `%{text}<br>%{y:.${recipeDecimalPlaces}f}${units(
          "mass"
        )}`,
        name: t(itemMaterial.basket_layer_name),
      });
    }
  });
  const layout = {
    barmode: "stack",
    yaxis: {
      range: [0, maxRecipeMass + 120],
    },
    xaxis: {
      // hide xaxis ticks and labels
      showticklabels: false,
      showgrid: false,
    },
    legend: {
      orientation: "h",
      yanchor: "bottom",
      x: 0.5,
      y: -0.1,
      xanchor: "center",
    },
    height: 500,
    annotations: annotations,
    margin: {
      t: 20,
      b: 0,
      l: 20,
      r: 20,
    },
  };
  return <FullWidthPlot plotConfig={{ data: data, layout: layout }} />;
};
