import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { SyncedState, useSyncedState } from "hooks/state/syncing";
import equal from "fast-deep-equal";
import { Loaded, mapLoaded, mapLoadedUnpack } from "models/loaded";
import {
  useLoadExperimentalSearch as useLoadExperimentalSearch,
  useSyncExperimentalSearch as useSyncExperimentalSearch,
} from "./hooks";
import { useNavigateTo } from "hooks/navigation";
import {
  SyncedContextData,
  useSyncedContextState,
} from "contexts/search/context";
import {
  ExperimentalInputs,
  ExperimentalSearch,
  MarkerBlock,
  ObtainableBlock,
  ProductionBlock,
} from "src/store/api/generatedApi";
import { Setter } from "./inputs/timeline";
import { Block } from "./blocks/block";

export type SyncedExperimentalSearch = SyncedState<ExperimentalSearch> & {
  id: number | null;
  setId: (id: number) => void;
  context: Loaded<SyncedContextData>;
};

const context = createContext<SyncedExperimentalSearch | null>(null);

export const ProvideExperimentalSearch = ({
  children,
  id,
  setId,
}: {
  children: ReactNode;
  id: number | null;
  setId: (id: number) => void;
}) => {
  const loadExperimentalSearch = useLoadExperimentalSearch();
  const [syncExperimentalSearch] = useSyncExperimentalSearch();
  const navigateTo = useNavigateTo(true);

  const source = useMemo(
    () => (id === null ? null : () => loadExperimentalSearch(id)),
    [id]
  );

  const update = useCallback(
    (client: ExperimentalSearch, server: ExperimentalSearch) =>
      syncExperimentalSearch({ ...client, parent_id: server.id }),
    [syncExperimentalSearch]
  );

  const syncedState = useSyncedState<ExperimentalSearch>(
    source,
    update,
    (left, right) => {
      const modifiedLeft = { ...left, id: -1, parent_id: -1 };
      const modifiedRight = { ...right, id: -1, parent_id: -1 };

      return equal(modifiedLeft, modifiedRight);
    }
  );

  const serverId = mapLoadedUnpack(syncedState.server, ({ id }) => id);
  useEffect(() => {
    if (serverId !== null) {
      navigateTo({ params: { id: serverId.toString() } });
    }
  }, [serverId]);

  const searchContext = useSyncedContextState(
    mapLoadedUnpack(syncedState.client, ({ context_id }) => context_id)
  );

  const memoisedValue = useMemo(
    () => ({ ...syncedState, id, setId, context: searchContext }),
    [syncedState, id, setId, searchContext]
  );

  return <context.Provider value={memoisedValue}>{children}</context.Provider>;
};

export const useExperimentalSearch = (): SyncedExperimentalSearch => {
  const experimentalSearch = useContext(context);

  if (experimentalSearch === null) {
    throw new Error("No context has been provided");
  } else {
    return experimentalSearch;
  }
};

export const useInputs = () => {
  const search = useExperimentalSearch();

  return [
    mapLoaded(search.client, (client) => client.inputs),
    useCallback(
      (update: (current: ExperimentalInputs) => ExperimentalInputs) =>
        search.setClient((current) => ({
          ...current,
          inputs: update(current.inputs),
        })),
      [search.setClient]
    ),
  ] as const;
};

export const useExperimentalMaterials = () =>
  mapLoaded(useExperimentalSearch().context, ({ materials }) => materials);

export const useExperimentalSteelGrades = () =>
  mapLoaded(useExperimentalSearch().context, ({ steelGrades }) => steelGrades);

export const useExperimentalMaterialPhysics = () =>
  mapLoaded(
    useExperimentalSearch().context,
    ({ materialPhysics }) => materialPhysics
  );

export const useSetBlock = <T extends Block>(
  update: Setter<T>
): Setter<Block> => {
  return useCallback(
    (updateBlock) =>
      update((current) => ({ ...current, ...updateBlock(current) })),
    [update]
  );
};

export const useSetProduction = (uuid: string): Setter<ProductionBlock> => {
  const [, setInputs] = useInputs();

  return useCallback(
    (update) =>
      setInputs((current) => ({
        ...current,
        production: current.production.map((block) =>
          block.uuid === uuid ? update(block) : block
        ),
      })),
    [setInputs, uuid]
  );
};

export const useSetObtainable = (uuid: string): Setter<ObtainableBlock> => {
  const [, setInputs] = useInputs();

  return useCallback(
    (update) =>
      setInputs((current) => ({
        ...current,
        obtainable: current.obtainable.map((block) =>
          block.uuid === uuid ? update(block) : block
        ),
      })),
    [setInputs, uuid]
  );
};

export const useSetMarker = (uuid: string): Setter<MarkerBlock> => {
  const [, setInputs] = useInputs();

  return useCallback(
    (update) =>
      setInputs((current) => ({
        ...current,
        markers: current.markers.map((block) =>
          block.uuid === uuid ? update(block) : block
        ),
      })),
    [setInputs, uuid]
  );
};

export const useDeleteBlock = (): ((uuid: string) => void) => {
  const [, setInputs] = useInputs();

  return useCallback(
    (uuid: string) => {
      setInputs((current) => ({
        ...current,
        production: current.production.filter((block) => block.uuid !== uuid),
        obtainable: current.obtainable.filter((block) => block.uuid !== uuid),
        markers: current.markers.filter((block) => block.uuid !== uuid),
      }));
    },
    [setInputs]
  );
};
