import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { useServerIds } from "contexts/search/provider";
import { useNavigateToSearch } from "hooks/navigation";
import { useLocation } from "react-router-dom";

export const BackToResults = () => {
  const currentLocation = useLocation();
  const isPlanPeek = currentLocation.pathname.endsWith("/search");
  const navigateToSearch = useNavigateToSearch();
  const { searchId } = useServerIds();

  return (
    <IconButton
      data-testid="back-to-results"
      onClick={() => {
        if (searchId !== null) {
          navigateToSearch(searchId);
        }
      }}
      sx={{ color: "action.primary" }}
    >
      {isPlanPeek ? <Close /> : <ArrowBackIcon />}
    </IconButton>
  );
};
