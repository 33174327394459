import { Box, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ReactNode, SyntheticEvent } from "react";

export type TabSpec = {
  title: string;
  key: string;
  content: ReactNode;
};

type Props = {
  tabSpecs: TabSpec[];
  rightComponent?: JSX.Element;
  tabIndex: number;
  onChange: (index: number) => void;
};

export const TitledTabs = ({
  tabSpecs,
  rightComponent,
  tabIndex,
  onChange,
}: Props) => {
  const handleChange = (_: SyntheticEvent, newTab: number) => {
    onChange(newTab);
  };

  return (
    <>
      <Stack direction="row">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { height: "4px" } }}
        >
          {tabSpecs.map(({ title, key }) => (
            <Tab
              key={key}
              label={
                <Typography variant="button" data-testid={`tab-${key}`}>
                  {title}
                </Typography>
              }
              sx={{
                textTransform: "none",
                borderRadius: 0,
                p: 1,
                mr: 1,
                color: "action.active",
                "&.Mui-selected": { color: "primary.main" },
              }}
            />
          ))}
        </Tabs>
        {rightComponent ? (
          <Box sx={{ marginLeft: "auto" }}>{rightComponent}</Box>
        ) : null}
      </Stack>
      <Divider sx={{ mx: -3 }} />
      {tabSpecs[tabIndex] ? tabSpecs[tabIndex]!.content : null}
    </>
  );
};
