import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { ScrapHeader } from "../common/ScrapHeader";
import {
  Measure,
  useMeasureOption,
  usePeriodOption,
} from "./ProductionContext";

type Props = {
  rowId:
    | "total_mass"
    | "total_price"
    | "materials_price"
    | "electrical_energy_price"
    | number;
  id: number;
  name: string;
};

export const ScrapRowHeader = ({ rowId, name, id }: Props) => {
  const unit = useUnitsFormatter(true);
  const { t } = useTenantTranslation();
  const measureOption = useMeasureOption();
  const period = usePeriodOption();

  switch (rowId) {
    case "total_mass": {
      return (
        <ScrapHeader
          type="static"
          renderValue={`${t("totalMass")}${unit("mass")}`}
        />
      );
    }
    case "materials_price": {
      return (
        <ScrapHeader
          type="static"
          renderValue={`${t("materialsTappedPrice")}${unit("specific_cost")}`}
        />
      );
    }
    case "electrical_energy_price": {
      return (
        <ScrapHeader
          type="static"
          renderValue={`${t("electricalEnergyTappedPrice")}${unit(
            "specific_cost"
          )}`}
        />
      );
    }
    case "total_price": {
      return (
        <ScrapHeader
          type="static"
          renderValue={`${t("tappedPrice")}${unit("specific_cost")}`}
        />
      );
    }
    default: {
      const nameSuffix = (() => {
        switch (measureOption) {
          case Measure.Mass:
            return unit("mass");
          case Measure.Percentage:
            return unit("mass_fraction");
        }
      })();

      const scrapHeaderType = (() => {
        switch (period) {
          case 1:
            return "consumption";
          default:
            return "properties";
        }
      })();

      return (
        <ScrapHeader
          type={scrapHeaderType}
          renderValue={`${name}${nameSuffix}`}
          name={name}
          id={id}
          alignment="left"
          period={period}
        />
      );
    }
  }
};
