import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Stack,
} from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { Period, minimumPeriod } from "hooks/periodIndex";
import { usePeriods } from "contexts/search/provider";
import { blankPeriod } from "./tabs";
import { ValidatedTextField } from "../inputs/validatedTextField";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { useDateSerialiser } from "hooks/serialisers/dates";

export type PeriodMetadata = {
  name: string | null;
  start_timestamp: string | null;
  end_timestamp: string | null;
};

export const DeletePeriodConfirmation = ({
  periodToDelete,
  setPeriodToDelete,
  name,
}: {
  periodToDelete: Period;
  setPeriodToDelete: (period: Period | null) => void;
  name: string;
}) => {
  const { t } = useTenantTranslation();
  const [, setPeriods] = usePeriods();

  const cancel = () => setPeriodToDelete(null);
  const confirm = () => {
    setPeriods((current) =>
      current.length === 1
        ? [blankPeriod]
        : current.filter((_, index) => index + minimumPeriod !== periodToDelete)
    );
    setPeriodToDelete(null);
  };

  return (
    <Dialog open onClose={cancel} fullWidth maxWidth="sm">
      <DialogTitle>{t("deletePeriod")}</DialogTitle>

      <DialogContent>
        <Typography sx={{ pl: 1 }}>
          {t("confirmDeletePeriod", { period: name })}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={cancel}>
          {t("cancel")}
        </Button>

        <Button variant="contained" onClick={confirm}>
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditPeriodModal = ({
  metadata,
  setMetadata,
  close,
}: {
  metadata: PeriodMetadata;
  setMetadata: (metadata: PeriodMetadata) => void;
  close: () => void;
}) => {
  const { t } = useTenantTranslation();
  const serialiser = useDateSerialiser({ default: { value: null, text: "" } });

  return (
    <Dialog open onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{t("editButton")}</DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          <Stack alignItems="center" direction="row">
            <Typography sx={{ width: 150 }}>{t("name")}</Typography>
            <ValidatedTextField
              value={metadata.name ?? ""}
              setValue={(value) =>
                setMetadata({
                  ...metadata,
                  name: value.length === 0 ? null : value,
                })
              }
              serialiser={useStringSerialiser()}
            />
          </Stack>

          <Stack alignItems="center" direction="row">
            <Typography sx={{ width: 150 }}>{t("startTime")}</Typography>
            <ValidatedTextField
              value={
                metadata.start_timestamp == null
                  ? null
                  : new Date(metadata.start_timestamp)
              }
              setValue={(timestamp) =>
                setMetadata({
                  ...metadata,
                  start_timestamp: timestamp?.toISOString() ?? null,
                })
              }
              serialiser={serialiser}
            />
          </Stack>

          <Stack alignItems="center" direction="row">
            <Typography sx={{ width: 150 }}>{t("endTime")}</Typography>
            <ValidatedTextField
              value={
                metadata.end_timestamp == null
                  ? null
                  : new Date(metadata.end_timestamp)
              }
              setValue={(timestamp) =>
                setMetadata({
                  ...metadata,
                  end_timestamp: timestamp?.toISOString() ?? null,
                })
              }
              serialiser={serialiser}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" gap={2}>
          <Button variant="outlined" onClick={close}>
            {t("close")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
