import React from "react";
import { SteelGrade } from "src/store/api/generatedApi";
import { ProductGroupPanel } from "../productGroup";
import { useSteelGrades } from "contexts/search/provider";
import { SteelGradesId } from "contexts/search/context";
import { ClickableChip } from "components/common/chips/clickable";

type Props = {
  name: string;
  steelGradeIds: number[];
};

export const ProductGroupChip = ({ name, steelGradeIds }: Props) => {
  const [showPanel, setShowPanel] = React.useState(false);
  const steelGrades = useSteelGrades();
  const isLoaded = steelGrades.status === "success";
  return (
    <>
      <ClickableChip label={name} onClick={() => setShowPanel(true)} />
      {isLoaded ? (
        <ProductGroupPanel
          steelGrades={steelGradeIds
            .map((id) => {
              return steelGrades.data.byId[id] ?? null;
            })
            .filter(
              (option): option is SteelGrade & { id: SteelGradesId } =>
                option !== null
            )}
          title={name}
          open={showPanel}
          doClose={() => setShowPanel(false)}
        />
      ) : null}
    </>
  );
};
