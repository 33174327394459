import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { defaultLanguage, languages, tenantNamespaces } from "../../constants";
import { dumpLanguage, loadLanguage } from "../../store/persistence";
import { SettingsState, languageSelected } from "../../store/slices/settings";

import untypedTranslations from "../../translations_data.json";

const typedTranslations: {
  [key: string]: { [shortLanguage: string]: string } | string;
} = untypedTranslations;

/**
 * Build a translations dictionary for a particular language.
 *
 * This maps the translation key (the language-invariant descriptor of the piece
 * of text) to the version of that text in the given language.
 */
const buildTranslations = (
  shortLanguage: string
): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  for (const key in typedTranslations) {
    const translationsValue = typedTranslations[key]!;

    // Some values (eg. numeric ones) only have one translation
    if (typeof translationsValue === "string") {
      result[key] = translationsValue;
    } else {
      result[key] = translationsValue[shortLanguage] ?? key;
    }
  }

  return result;
};

void i18n.use(initReactI18next).init({
  resources: {
    "en-GB": Object.assign(
      { translation: buildTranslations("EN") },
      tenantNamespaces
    ),
    "de-DE": Object.assign(
      { translation: buildTranslations("DE") },
      tenantNamespaces
    ),
    "pl-PL": Object.assign(
      { translation: buildTranslations("PL") },
      tenantNamespaces
    ),
    "es-AR": Object.assign(
      { translation: buildTranslations("ES") },
      tenantNamespaces
    ),
    "fr-FR": Object.assign(
      { translation: buildTranslations("FR") },
      tenantNamespaces
    ),
  },
  lng: loadLanguage() ?? defaultLanguage,
  fallbackLng: defaultLanguage,
  fallbackNS: "translation",
  interpolation: {
    escapeValue: false,
  },
});

export const SelectLocale = ({ hideLabel }: { hideLabel?: boolean }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const reduxStoreLanguage = useSelector(
    (state: { settings: SettingsState }) => state.settings.locale
  );

  async function handleChange(event: SelectChangeEvent) {
    const language = event.target.value;

    await i18n.changeLanguage(language);

    dumpLanguage(language);
    dispatch(languageSelected(language));
  }

  return (
    <FormControl fullWidth size="small">
      {!hideLabel && <InputLabel>{t("language")}</InputLabel>}
      <Select
        value={reduxStoreLanguage}
        label={hideLabel ? undefined : t("language")}
        onChange={(event) => void handleChange(event)}
      >
        {Object.keys(languages).map((locale) => (
          <MenuItem key={locale} value={locale}>
            {languages[locale]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
