import { Stack, CircularProgress, Alert, Box } from "@mui/material";
import { SteelGradesId } from "contexts/search/context";
import { useTenantTranslation } from "hooks/formatters";
import { useTenant } from "hooks/settings";
import { useState } from "react";
import { Select } from "src/components/common/inputs/select";
import {
  MixMaterialBasketSummary,
  MixMaterialSummary,
  MixSummary,
  SteelGrade,
  useGetSimilarChargedRecipesQuery,
  SimilarChargedRecipesParams,
} from "store/api/generatedApi";
import { SimilarChargedRecipesPlot } from "./similarChargedRecipes";
import { SummaryStat } from "src/components/common/summaryStat";
import { PlanId } from "components/common/boundary/PlanId";

export const ChefGroupSummary = ({
  mixSummary,
  chefGroupName,
  period,
}: {
  mixSummary: MixSummary[];
  chefGroupName: string;
  period: number;
}) => {
  const { t } = useTenantTranslation();

  const summary = mixSummary.filter(
    (summary) =>
      summary.chef_group_name === chefGroupName && summary.period === period
  )[0]!;

  return (
    <Stack direction="row" spacing={4} sx={{ pt: 2, pb: 1 }}>
      <SummaryStat
        label={t("heats")}
        value={summary.num_heats}
        decimalPlaces={0}
        units={null}
      />
      <SummaryStat
        label={t("yieldPercent")}
        value={summary.yield_percent}
        decimalPlaces={1}
        units="yield"
      />
      <SummaryStat
        label={t("tappedPrice")}
        value={summary.cost_per_tonne_billets_materials}
        decimalPlaces={2}
        units="specific_cost"
      />
    </Stack>
  );
};

export const SimilarChargedRecipesTab = ({
  planId,
  mixNumber,
  period,
  chefGroupId,
  chefGroupName,
  steelGrades,
  mixSummary,
  mixMaterialBasketSummary,
  mixMaterialSummary,
}: {
  planId: PlanId;
  chefGroupId: number;
  period: number;
  chefGroupName: string;
  mixSummary: MixSummary[];
  mixMaterialBasketSummary: MixMaterialBasketSummary[];
  mixMaterialSummary: MixMaterialSummary[];
  mixNumber: number;
  steelGrades: (SteelGrade & { id: SteelGradesId })[];
}) => {
  const { t } = useTenantTranslation();
  const [steelGrade, setSteelGrade] = useState<SteelGrade | null>(null);

  const {
    data: similarChargedRecipes,
    isError,
    isFetching,
    isLoading,
  } = useGetSimilarChargedRecipesQuery({
    tenantName: useTenant(),
    similarChargedRecipesParams: {
      optimisation_result_set_id: planId,
      chef_group_id: chefGroupId,
      mix_number: mixNumber,
      period: period,
      n_recipes: 5,
      metric: "cosine",
      steel_grade_name: steelGrade ? steelGrade.name : undefined,
    } as SimilarChargedRecipesParams,
  });

  return (
    <Stack sx={{ my: 2 }}>
      <Stack direction="row" sx={{ width: 1 }} justifyContent="flex-end">
        <Box sx={{ width: 0.3 }}>
          <Select<SteelGrade | null>
            options={steelGrades}
            value={steelGrade}
            setValue={setSteelGrade}
            label={t("steelGrade")}
            format={(item) =>
              item === null
                ? { key: null, label: "" }
                : { key: item.id.toString(), label: item.name }
            }
            small
          />
        </Box>
      </Stack>

      {isLoading || isFetching || similarChargedRecipes === undefined ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : isError ? (
        <Alert severity="error">{t("errorLoadingPlot")}</Alert>
      ) : (
        <SimilarChargedRecipesPlot
          similarChargedRecipes={similarChargedRecipes}
          chefGroupName={chefGroupName}
          mixNumber={mixNumber}
          period={period}
          steelGradeName={steelGrade?.name ?? ""}
          mixSummary={mixSummary}
          mixMaterialBasketSummary={mixMaterialBasketSummary}
          mixMaterialSummary={mixMaterialSummary}
        />
      )}
    </Stack>
  );
};
