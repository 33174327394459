import { Stack, Typography, useTheme } from "@mui/material";
import { useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

type Props = {
  value: number;
  priceType: "cost" | "specific_cost";
};

export const PriceCell = ({ value, priceType }: Props) => {
  const theme = useTheme();
  const { format } = useNumberSerialiser({
    decimalPlaces: 2,
    min: 0,
  });
  const unit = useUnitsFormatter(false);
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        px: 1,
      }}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Typography variant="body1Mono">
        {format(value)}
        <Typography
          variant="body1Mono"
          sx={{ color: theme.palette.text.disabled }}
        >
          {unit(priceType)}
        </Typography>
      </Typography>
    </Stack>
  );
};
