import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { SearchChemistryGroups, SteelGradesId } from "contexts/search/context";
import { groupBy, sorted } from "helpers";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useMemo } from "react";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import {
  ChefGroup,
  MixMaterialSummary,
  MixSummary,
  SteelGrade,
} from "store/api/generatedApi";
import { ChemistryGroupCell } from "./chemistryGroupCell";
import { useSteelGrades } from "contexts/search/provider";
import { SteelGradesCell } from "./steelGradesCell";

type MaterialConsumptionRow = {
  group: ChefGroup;
  mix: number;
  consumption: number;
  heats: number;
  consumptionPerHeat: number;
  multipleMixes: boolean;
};

export const MaterialConsumption = ({
  name,
  materialSummaries,
  chemistryGroups,
  mixSummaries,
}: {
  name: string;
  materialSummaries: MixMaterialSummary[];
  chemistryGroups: SearchChemistryGroups;
  mixSummaries: MixSummary[];
}) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const rows = useConsumption(
    name,
    materialSummaries,
    chemistryGroups,
    mixSummaries
  );
  const steelGrades = useSteelGrades();

  const columns: GridColDef<MaterialConsumptionRow>[] = [
    {
      field: "chemistry_group",
      headerName: t("chemistryGroup"),
      renderCell: (params) => {
        const {
          row: {
            group: { name, id: groupId },
            multipleMixes,
            mix,
          },
        } = params;
        return groupId !== null && groupId !== undefined ? (
          <ChemistryGroupCell
            name={name}
            multipleMixes={multipleMixes}
            mix={mix}
            group={groupId}
          />
        ) : null;
      },
      width: 300,
      sortable: false,
    },
    {
      field: "consumption",
      headerName: `${t("consumption")}${units("mass")}`,
      flex: 1,
      renderCell: (params) => (
        <NumberCell value={params.row.consumption} decimalPlaces={0} />
      ),
      sortable: false,
    },
    {
      field: "heats",
      headerName: t("heats"),
      flex: 1,
      renderCell: (params) => (
        <NumberCell value={params.row.heats} decimalPlaces={0} />
      ),
      sortable: false,
    },
    {
      field: "consumptionPerHeat",
      headerName: `${t("consumptionPerHeat")}${units("mass")}`,
      flex: 1,
      renderCell: (params) => (
        <NumberCell value={params.row.consumptionPerHeat} decimalPlaces={1} />
      ),
      sortable: false,
    },
    {
      field: "steelGrades",
      headerName: t("steelGrades"),
      flex: 3,
      renderCell: (params) => {
        if (steelGrades.status === "success") {
          return (
            <SteelGradesCell
              steelGrades={params.row.group.steel_grades
                .map(({ id }) => steelGrades.data.byId[id] ?? null)
                .filter(
                  (option): option is SteelGrade & { id: SteelGradesId } =>
                    option !== null
                )}
            />
          );
        } else {
          return null;
        }
      },
      sortable: false,
    },
  ];

  return (
    <DataGridPremium
      sx={{ my: 2 }}
      rows={rows}
      columns={columns}
      getRowHeight={() => "auto"}
      getRowId={(row) => `${row.group.id}${row.mix}`}
      cellSelection
      ignoreValueFormatterDuringExport
    />
  );
};

const useConsumption = (
  name: string,
  materialSummaries: MixMaterialSummary[],
  chemistryGroups: SearchChemistryGroups,
  mixSummaries: MixSummary[]
): MaterialConsumptionRow[] => {
  return useMemo(() => {
    const groups = groupBy(
      materialSummaries.filter((item) => item.material === name),
      (item) => chemistryGroups.byName[item.chef_group_name]!.id,
      (items) =>
        groupBy(
          items,
          (item) => item.mix_number,
          (results) => {
            const mix = results[0]!.mix_number;
            const groupName = results[0]!.chef_group_name;
            const group = chemistryGroups.byName[groupName]!;

            const heats = mixSummaries.find(
              (item) =>
                item.mix_number === mix && item.chef_group_name === groupName
            )!.num_heats;

            const consumptionPerHeat = results
              .map((result) => result.recipe_mass)
              .reduce((left, right) => left + right, 0);

            return {
              group,
              mix,
              consumption: consumptionPerHeat * heats,
              heats,
              consumptionPerHeat,
              multipleMixes: items.length > 1,
            };
          }
        )
    );

    return sorted(
      Object.values(groups).flatMap((mixes) => Object.values(mixes)),
      (group) => -group.consumption
    ).filter((item) => item.consumption > 0);
  }, [name, chemistryGroups, mixSummaries, materialSummaries]);
};
