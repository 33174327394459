import { useTranslation, UseTranslationResponse } from "react-i18next";
import { tenantHeatsPerDay, tenantUnits, UnitType } from "../constants";
import { Namespace, KeyPrefix } from "i18next";
import { useNumberSerialiser } from "./serialisers/numbers";
import { useTenantHash } from "./settings";
import { useMemo } from "react";
import { Period } from "./periodIndex";

export const useTenantTranslation = (): UseTranslationResponse<
  Namespace,
  KeyPrefix<Namespace>
> => {
  // Since this can be called outside a `RequireTenant`, it must get the tenant
  // manually
  const tenantHash = useTenantHash();

  return useTranslation([tenantHash, "translation"]);
};

export const usePeriodTabTranslation = () => {
  const { t } = useTenantTranslation();

  return (period: Period) => {
    switch (period) {
      case 1: {
        return t("schedule");
      }
      default: {
        return `${t("forecastPastParticiple")} ${period - 1}`;
      }
    }
  };
};

export const useUnitsFormatter = (bracketed: boolean) => {
  // Since this can be called outside a `RequireTenant`, it must get the tenant
  // manually
  const tenantHash = useTenantHash();

  return useMemo(() => {
    return (unitType: UnitType | undefined): string => {
      if (unitType === undefined) {
        return "";
      }
      const unitsMapping = tenantUnits[unitType];
      const units = unitsMapping[tenantHash] ?? unitsMapping.default;
      if (bracketed) {
        return `\xa0(${units})`;
      } else {
        return `\xa0${units}`;
      }
    };
  }, [tenantHash, bracketed]);
};

export const useFormattedTotalHeats = (
  totalHeats: number,
  tappedMass: number
) => {
  const tenantHash = useTenantHash();
  const { t } = useTenantTranslation();
  const { format } = useNumberSerialiser({ decimalPlaces: 1 });
  const units = useUnitsFormatter(false);

  const heatsPerDay = tenantHeatsPerDay[tenantHash] ?? 24;
  const days = format(totalHeats / heatsPerDay);
  const daysTranslation = days === format(1.0) ? t("day") : t("days");

  return `~${days} ${daysTranslation} (${format(tappedMass, {
    decimalPlaces: 0,
  })}${units("mass")})`;
};
