import { useTenantTranslation } from "hooks/formatters";
import { ExportPlanState, isApproved } from "./state";
import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Error } from "@mui/icons-material";

const getActiveStep = (stage: string, previewComplete: boolean) => {
  if (stage === "preview" && !previewComplete) return 0;
  if (stage === "preview" && previewComplete) return 1;
  if (stage === "awaitingConfirmation") return 1;
  if (stage === "awaitingConfirmationError") return 1;
  if (stage === "awaitingConfirmationSuccess") return 2;
  if (stage === "sendSms") return 2;
  if (stage === "sendSmsError") return 2;
  if (stage === "sendSmsSuccess") return 3; // Setting it to 3 marks the third step "complete"
};

const getIsErrorStep = (stage: string) => {
  return stage === "awaitingConfirmationError" || stage === "sendSmsError";
};

type Props = {
  state: ExportPlanState;
};

export const ModalStepper = ({ state }: Props) => {
  const { t } = useTenantTranslation();
  const steps = [t("approveBaskets"), t("syncWithLevel2"), t("smsToYard")];

  const stage = state.stage;
  const previewComplete = state.planned.every((key) => isApproved(state, key));
  const activeStep = getActiveStep(stage, previewComplete);
  const isErrorStep = getIsErrorStep(stage);

  return (
    <Box sx={{ mx: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const isError = index === activeStep && isErrorStep;
          return (
            <Step key={label}>
              <StepLabel
                error={isError}
                StepIconComponent={isError ? CustomErrorIcon : undefined}
              >
                <Typography variant="body1">{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

const CustomErrorIcon = () => {
  return <Error color="error" />;
};
