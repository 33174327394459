import { ReactNode, useEffect } from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotFound } from "../components/notFound";
import {
  adminUpdate,
  displayNameUpdate,
  emailUpdate,
  TenantPermissions,
  tenantPermissionUpdate,
  userUidUpdate,
} from "../store/slices/settings";
import { useDispatch } from "react-redux";
import { ParsedToken, getAuth } from "firebase/auth";
import { LoadingWheel } from "src/components/common/loading/loadingWheel";
import { useHasTenant } from "hooks/permissions";

interface ParsedClaims extends ParsedToken {
  permissions?: TenantPermissions;
  admin?: string;
}

/**
 * Only render the child if a user is signed in.
 *
 * Otherwise, redirect to the sign-in page.  Calls to `useGetMeQuery` from
 * inside this component will be valid.
 */
export const RequireUser = ({ children }: { children: ReactNode }) => {
  const auth = getAuth();
  const [user, loading] = useIdToken(auth);
  const location = useLocation();
  const dispatch = useDispatch();

  // Parse custom claims from firebase identity token
  useEffect(() => {
    const setPermissions = async () => {
      const idTokenResult = await user?.getIdTokenResult();
      // Return type of claims is ParsedToken but also includes custom claims
      // https://firebase.google.com/docs/reference/js/auth.idtokenresult
      const claims: ParsedClaims | undefined = idTokenResult?.claims;
      if (claims?.admin === "admin") {
        dispatch(adminUpdate(true));
      }
      if (claims?.permissions) {
        dispatch(tenantPermissionUpdate(claims.permissions));
      }
      if (user?.uid) {
        dispatch(userUidUpdate(user.uid));
      }
      if (user?.displayName) {
        dispatch(displayNameUpdate(user.displayName));
      }
      if (user?.email) {
        dispatch(emailUpdate(user.email));
      }
    };
    setPermissions().catch(console.error);
  }, [user, dispatch]);

  // If we're running in CI then don't show the login screen.
  // Backend requests are mocked out so there is no ID token necessary.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const isTesting = (window as any).Meticulous?.isRunningAsTest;

  if (!user && !loading && !isTesting) {
    // user is not authenticated
    return <Navigate to="/sign-in" replace state={{ from: location }} />;
  } else if (!user && loading) {
    return <LoadingWheel />;
  } else {
    return children;
  }
};

/**
 * Only render the child if a tenant is in the URL.
 *
 * Otherwise, an error will be shown prompting the user to access a different
 * URL.  Calls to `useTenant` from inside this component will be valid.
 */
export const RequireTenant = ({ children }: { children: ReactNode }) => {
  const tenant = useParams().tenant;
  const hasAccess = useHasTenant(tenant ?? "");

  // We cannot use tenant-specific translation until the tenant is verified
  const { t } = useTranslation();

  if (typeof tenant !== "string") {
    return <Alert severity="error">{t("errorLoadingTenantParams")}</Alert>;
  } else if (hasAccess) {
    return children;
  } else {
    return <NotFound />;
  }
};
