import {
  Inventory,
  ObtainableBlock,
  ProductionBlock,
} from "src/store/api/generatedApi";
import { productionActions } from "../blocks/production";
import { sliceBlocks } from "../blocks/block";
import { obtainableActions } from "../blocks/obtainable";

export type ConsumptionBreakdown = {
  steelGrade: string | null;
  material: string;
  mass: number; // Negative for deliveries
};

export const consumptionBreakdown = (
  production: ProductionBlock[],
  obtainable: ObtainableBlock[],
  start: number,
  end: number
): ConsumptionBreakdown[] => [
  ...sliceBlocks(production, start, end, productionActions).flatMap(
    productionConsumptionBreakdown
  ),
  ...sliceBlocks(obtainable, start, end, obtainableActions).flatMap(
    obtainableConsumptionBreakdown
  ),
];

const productionConsumptionBreakdown = (
  production: ProductionBlock
): ConsumptionBreakdown[] =>
  production.steel_grades.flatMap((steelGradeItem) =>
    (steelGradeItem.mixes ?? []).flatMap((mix) =>
      mix.mix.totals.flatMap((mixItem) => ({
        steelGrade: steelGradeItem.steel_grade_name,
        material: mixItem.material_name,
        mass: mixItem.mass * mix.heats,
      }))
    )
  );

const obtainableConsumptionBreakdown = (
  obtainable: ObtainableBlock
): ConsumptionBreakdown[] =>
  obtainable.bundles.map((bundle) => ({
    steelGrade: null,
    material: bundle.material_name,
    mass: -(bundle.minimum_quantity ?? 0),
  }));

export const inventoryProjection = (
  inventory: Inventory,
  production: ProductionBlock[],
  obtainable: ObtainableBlock[],
  timestamp: number
): Inventory => {
  // These two blocks are basically the same, so could be condensed.  The idea
  // is that in one case the timestamp is before the inventory and so
  // consumption needs to be *added*, not subtracted (and likewise deliveries
  // need to be subtracted instead of added)
  if (timestamp < inventory.timestamp) {
    const breakdown = consumptionBreakdown(
      production,
      obtainable,
      timestamp,
      inventory.timestamp
    );
    return {
      name: null,
      timestamp,
      inventory: inventory.inventory.map((item) => ({
        material_name: item.material_name,
        mass: breakdown
          .filter(
            (breakdownItem) => breakdownItem.material === item.material_name
          )
          .map((breakdownItem) => breakdownItem.mass)
          .reduce((left, right) => left + right, item.mass ?? 0),
        specific_price: item.specific_price,
      })),
    };
  } else {
    const breakdown = consumptionBreakdown(
      production,
      obtainable,
      inventory.timestamp,
      timestamp
    );
    return {
      name: null,
      timestamp,
      inventory: inventory.inventory.map((item) => ({
        material_name: item.material_name,
        mass: breakdown
          .filter(
            (breakdownItem) => breakdownItem.material === item.material_name
          )
          .map((breakdownItem) => breakdownItem.mass)
          .reduce((left, right) => left - right, item.mass ?? 0),
        specific_price: item.specific_price,
      })),
    };
  }
};
