import Plot from "react-plotly.js";
import { useResizeDetector } from "react-resize-detector";

import { Data } from "plotly.js";

/**
 * Represents the structure of the configuration of a plotly plot.
 */
export type PlotConfig = {
  data: Data[];
  layout: object;
};

export const FullWidthPlot = ({ plotConfig }: { plotConfig: PlotConfig }) => {
  // The inclusion of the detector forces the `autosize` below to actually work
  const { ref } = useResizeDetector();

  return (
    <div ref={ref} style={{ marginBottom: 30, width: "100%" }}>
      <Plot
        // eslint-disable-next-line react/forbid-component-props
        style={{ width: "100%" }}
        data={plotConfig.data}
        layout={{ ...plotConfig.layout, autosize: true }}
      />
    </div>
  );
};
