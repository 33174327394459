import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

type Props = {
  title: string;
};

export const TitleColumnHeader = ({ title }: Props) => {
  return (
    <Stack sx={{ textWrap: "wrap" }} alignItems="flex-start">
      <Typography variant="body2">{title}</Typography>
    </Stack>
  );
};
