import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { Plan } from "store/api/generatedApi";
import { SearchChemistryGroups } from "contexts/search/context";
import { useExportPlanState } from "./state";
import { Preview } from "./preview";
import { SendSmsError } from "./sendSmsError";
import { SendSmsSuccess } from "./sendSmsSuccess";
import { SendSmsWrapper } from "./sendSms";
import { AwaitingConfirmationError } from "./awaitingConfirmationError";
import { AwaitingConfirmation } from "./awaitingConfirmation";
import { ModalStepper } from "./modalStepper";
import { AwaitingConfirmationSuccess } from "./awaitingConfirmationSuccess";

export const ExportPlanModal = ({
  open,
  close,
  plan,
  chemistryGroups,
}: {
  open: boolean;
  close: () => void;
  plan: Plan;
  chemistryGroups: SearchChemistryGroups;
}) => {
  const { t } = useTenantTranslation();

  const [state, setState] = useExportPlanState(plan, chemistryGroups);
  const stage = state.stage;

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          // Fix to always be "full height" (i.e. 90vh)
          height: "90vh",
        },
      }}
    >
      <DialogTitle>{t("deployPlan")}</DialogTitle>
      <ModalStepper state={state} />

      <DialogContent sx={{ mt: 6 }}>
        {stage === "preview" ? (
          <Preview state={state} setState={setState} />
        ) : null}
        {stage === "awaitingConfirmation" ? (
          <AwaitingConfirmation state={state} setState={setState} />
        ) : null}
        {stage === "awaitingConfirmationSuccess" ? (
          <AwaitingConfirmationSuccess setState={setState} />
        ) : null}
        {stage === "awaitingConfirmationError" ? (
          <AwaitingConfirmationError close={close} />
        ) : null}
        {stage === "sendSms" ? (
          <SendSmsWrapper state={state} setState={setState} />
        ) : null}
        {stage === "sendSmsSuccess" ? <SendSmsSuccess close={close} /> : null}
        {stage === "sendSmsError" ? <SendSmsError close={close} /> : null}
      </DialogContent>
    </Dialog>
  );
};
