import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { SyncedState } from "hooks/state/syncing";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Loaded } from "models/loaded";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { ReactNode } from "react";
import { ValidatedTextField } from "../common/inputs/validatedTextField";

export const SyncedJsonEditor = <T extends object | null>({
  state,
  section,
  children,
}: {
  state: SyncedState<T>;
  section?: string;
  children?: ReactNode;
}) => {
  const { client, setClient, server } = state;

  const content = (
    <Stack gap={2}>
      {children}

      <Stack gap={2} direction="row">
        <Box sx={{ width: 0.4 }}>
          <LoadedContent data={client}>
            {(clientData) => (
              <ValidatedTextField<T>
                value={clientData}
                setValue={(newValue) => setClient(newValue)}
                serialiser={{
                  format: (value) => JSON.stringify(value, null, 2),
                  parse: (text) => ({
                    valid: true,
                    value: JSON.parse(text) as T,
                  }),
                }}
                textFieldProps={{ multiline: true }}
              />
            )}
          </LoadedContent>
        </Box>

        <Box sx={{ width: 0.4 }}>
          <LoadedContent data={server}>
            {(serverData) => (
              <Typography
                sx={{
                  whiteSpace: "pre-wrap",
                  fontSize: 17,
                  fontFamily: "monospace",
                }}
              >
                {JSON.stringify(serverData, null, 2)}
              </Typography>
            )}
          </LoadedContent>
        </Box>
      </Stack>
    </Stack>
  );

  return section ? (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true, timeout: 200 } }}
    >
      <AccordionSummary
        disabled={client.status !== "success"}
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: "#f0f1f2" }}
      >
        {section} ({client.status})
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  ) : (
    content
  );
};

export const JsonViewer = <T,>({
  value,
  section,
  children,
}: {
  value: Loaded<T>;
  section?: string;
  children?: ReactNode;
}) => {
  const content = (
    <>
      {children}

      <LoadedContent data={value}>
        {(data) => (
          <Typography sx={{ fontFamily: "monospace" }}>
            {JSON.stringify(data)}
          </Typography>
        )}
      </LoadedContent>
    </>
  );

  return section ? (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true, timeout: 200 } }}
    >
      <AccordionSummary
        disabled={value.status !== "success"}
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: "#f0f1f2" }}
      >
        {section} ({value.status})
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  ) : (
    content
  );
};
