import { ObtainableBlock } from "src/store/api/generatedApi";
import { BlockActions } from "./block";

export const obtainableActions: BlockActions<ObtainableBlock> = {
  split: (obtainable, timestamp) => {
    if (obtainable.start >= timestamp) {
      return [null, obtainable];
    } else if (obtainable.end <= timestamp) {
      return [obtainable, null];
    } else {
      const left =
        (timestamp - obtainable.start) / (obtainable.end - obtainable.start);
      const right = 1 - left;

      return [
        {
          ...obtainable,
          end: timestamp,
          bundles: obtainable.bundles.map((bundle) => ({
            ...bundle,
            maximum_quantity:
              bundle.maximum_quantity === null
                ? null
                : bundle.maximum_quantity * left,
            minimum_quantity:
              bundle.minimum_quantity === null
                ? null
                : bundle.minimum_quantity * left,
          })),
        },
        {
          ...obtainable,
          start: timestamp,
          bundles: obtainable.bundles.map((bundle) => ({
            ...bundle,
            maximum_quantity:
              bundle.maximum_quantity === null
                ? null
                : bundle.maximum_quantity * right,
            minimum_quantity:
              bundle.minimum_quantity === null
                ? null
                : bundle.minimum_quantity * right,
          })),
        },
      ];
    }
  },

  resolveProduction: () => null,
  resolveObtainable: (block) => block,

  applyResults: (block) => block,
};
