import { Alert, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";

export const InputsOutdatedAlert = () => {
  const { t } = useTenantTranslation();

  return (
    <Alert
      severity="warning"
      sx={{
        "& .MuiAlert-action": {
          pt: "2px",
        },
      }}
    >
      <Typography variant="body1">{t("inputsOutdatedDescription")}</Typography>
    </Alert>
  );
};
