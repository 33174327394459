import { Button, Grid, IconButton, Stack } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { PlanItem, PlanItemName } from "./types";
import { RefObject, useEffect, useRef } from "react";
import { Select } from "src/components/common/inputs/select";
import CloseIcon from "@mui/icons-material/Close";
import { AddCircleOutline } from "@mui/icons-material";

export const AddItem = ({
  adding,
  setAdding,
  addItem,
  name,
  groupIds,
  parentRef,
}: {
  adding: boolean;
  setAdding: (adding: boolean) => void;
  addItem: (item: PlanItem) => void;
  name: PlanItemName;
  groupIds: number[];
  parentRef: RefObject<HTMLDivElement>;
}) => {
  const handleAddItem = (id: number) => {
    addItem({ groupId: id, heats: 0 });

    // Asynchronously (ie. after the new element has been added), focus the
    // text box for the number of heats
    setTimeout(() => {
      const div = parentRef.current;
      if (div) {
        const editors = div.querySelectorAll(".plan-editor-item");
        const addedEditor = editors.item(editors.length - 1);
        const editor = addedEditor.querySelectorAll("input").item(0);
        editor.focus();
      }
    }, 0);
  };

  return adding ? (
    <AddItemDetail
      groupIds={groupIds}
      name={name}
      cancelAdding={() => setAdding(false)}
      addItem={handleAddItem}
    />
  ) : (
    <AddItemPrompt startAdding={() => setAdding(true)} />
  );
};

const AddItemPrompt = ({ startAdding }: { startAdding: () => void }) => {
  const { t } = useTenantTranslation();

  return (
    <Stack
      justifyContent="center"
      onClick={startAdding}
      sx={(theme) => ({
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        transition: "background-color 0.2s",
        borderTop: 1,
        borderColor: "grey.100",
        height: 50,
        cursor: "pointer",
      })}
    >
      <Grid container>
        <Grid item xs={1} />{" "}
        <Grid
          item
          xs={11}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button
            startIcon={<AddCircleOutline />}
            size="small"
            // disable hover effect since the parent is a button component
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            {t("addProduct")}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

const AddItemDetail = ({
  groupIds,
  name,
  cancelAdding,
  addItem,
}: {
  groupIds: number[];
  name: PlanItemName;
  cancelAdding: () => void;
  addItem: (id: number) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Automatically focus and open the dropdown when this component appears
    const dropdown = ref.current;
    if (dropdown) {
      dropdown.focus();
    }
  }, []);

  return (
    <Grid
      container
      sx={(theme) => ({
        borderTop: 1,
        borderColor: "grey.100",
        backgroundColor: theme.palette.action.hover,
        height: 50,
      })}
    >
      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton onClick={cancelAdding} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Select
          inputRef={ref}
          small
          open
          options={groupIds}
          value={null}
          setValue={(id) => {
            cancelAdding();
            addItem(id);
          }}
          format={(id) => ({
            key: id.toString(),
            label: name({ groupId: id, heats: 0 }),
          })}
          onBlur={cancelAdding}
        />
      </Grid>
    </Grid>
  );
};
