import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import {
  SyncedSearchParameters,
  useRenameSessionMutation,
} from "src/store/api/generatedApi";
import { RenameSessionModal } from "../../searches/RenameSessionModal";
import { useTenant } from "hooks/settings";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { mapLoadedUnpack } from "models/loaded";
import { useSearch } from "contexts/search/provider";

export const SearchDetails = () => {
  const theme = useTheme();
  const formatDate = useFormatLocalDateString("datetime");
  const { t } = useTenantTranslation();
  const {
    parameters: { client, setClient },
  } = useSearch();
  const tenantName = useTenant();

  const [openModal, setOpenModal] = React.useState(false);
  const [renameSession] = useRenameSessionMutation();

  const handleUpdateName = async ({
    tenantName,
    sessionId,
    name,
  }: {
    tenantName: string;
    sessionId: number;
    name: string;
  }) => {
    setClient((search) => ({ ...search, session_name: name }));
    // The actual update happens after the client is updated
    await renameSession({ tenantName, sessionId, renameSession: { name } });
  };

  const details = mapLoadedUnpack<
    SyncedSearchParameters,
    {
      session_name: string;
      session_created_at: string;
      session_created_by_email: string;
      session_id: number;
    }
  >(
    client,
    ({
      session_name,
      session_created_at,
      session_created_by_email,
      session_id,
    }) => ({
      session_name,
      session_created_at,
      session_created_by_email,
      session_id,
    })
  );

  return details ? (
    <>
      <Box
        display="grid"
        gridTemplateColumns="max-content 1fr"
        alignItems="center"
        justifyItems="start"
        columnGap={1}
      >
        <Typography variant="body1">{details.session_name}</Typography>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => setOpenModal(true)}
          sx={{ padding: 0 }}
        >
          <EditIcon fontSize="small" sx={{ alignSelf: "center" }} />
        </IconButton>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.grey[400],
            gridColumnStart: 1,
            gridColumnEnd: -1,
            textWrap: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {t("createdAtBy", {
            time: formatDate(details.session_created_at),
            name: details.session_created_by_email,
          })}
        </Typography>
      </Box>
      <RenameSessionModal
        originalName={details.session_name}
        prefillName={details.session_name}
        open={openModal}
        close={() => setOpenModal(false)}
        rename={(name) =>
          handleUpdateName({ tenantName, sessionId: details.session_id, name })
        }
      />
    </>
  ) : null;
};
