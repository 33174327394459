import { CircularProgress, Stack } from "@mui/material";

export const LoadingWheel = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    direction="column"
    height="80%"
  >
    <CircularProgress />
  </Stack>
);
