import {
  MixMaterialSummary,
  MixSummary,
  ProductGroupMixMaterialSummary,
  ProductGroupMixSummary,
} from "store/api/generatedApi";

export const getMixSummaryNumber = (
  summary:
    | ProductGroupMixSummary
    | ProductGroupMixMaterialSummary
    | MixSummary
    | MixMaterialSummary
): number => {
  if ("mix_number" in summary) {
    return summary.mix_number;
  } else {
    return 1;
  }
};
