import { Navigate, Route, Routes } from "react-router-dom";
import { SelectTenant } from "components/settings/tenant";
import * as Sentry from "@sentry/react";
import { useTabName } from "components/common/TabTitle";
import { RequireUser, RequireTenant } from "./authentication";
import { AdminRoutes } from "./admin";
import { TenantRoutes } from "./tenant";
import { NavBarLayout } from "src/components/common/NavBarLayout";
import { SignInPage } from "components/signIn";
import { NotFound } from "components/notFound";

export const AppRoutes = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  useTabName();

  return (
    <SentryRoutes>
      <Route path="/sign-in" element={<SignInPage />} />

      <Route
        path="/"
        element={
          <RequireUser>
            <SelectTenant />
          </RequireUser>
        }
      />

      <Route
        path="/:tenant/*"
        element={
          <RequireUser>
            <RequireTenant>
              <NavBarLayout>
                <Routes>
                  <Route path="/v3/*" element={<TenantRoutes />} />

                  <Route path="/admin/*" element={<AdminRoutes />} />

                  <Route path="/" element={<Navigate to="./v3" replace />} />

                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </NavBarLayout>
            </RequireTenant>
          </RequireUser>
        }
      />
    </SentryRoutes>
  );
};
