import { Box, useTheme } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { InventoryItem, SupportedLanguage } from "store/api/generatedApi";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useHandleTableMutations } from "./useHandleTableMutations";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import { useSortDataGridByMaterial } from "src/components/common/dataGrid/utilities";
import { MaterialPropertyViewCell } from "../common/MaterialPropertyViewCell";
import {
  SearchMaterialPhysics,
  SearchMaterials,
} from "contexts/search/context";
import { formatNumber } from "src/utils/formatNumber";
import { useLanguage } from "hooks/settings";
import { MinimumExceededError, parseNumber } from "src/utils/parseNumber";
import { useNotifyStatus } from "contexts/status";

export type InventoryRow = InventoryItem & {
  material_name: string;
};

type InventoryTableProps = {
  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
  inventory: InventoryItem[];
  isFromPrevious?: boolean;
};

export const InventoryTable = ({
  materials,
  materialPhysics,
  inventory,
  isFromPrevious = false,
}: InventoryTableProps) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);
  const theme = useTheme();
  const language = useLanguage();

  const { materialNameColumnSortComparator, materialNameSortModel } =
    useSortDataGridByMaterial(materials);

  const { handleUpdateItem } = useHandleTableMutations();
  const notifyStatus = useNotifyStatus();

  const parseNumberLocalised = parseNumber(language as SupportedLanguage);
  const formatNumberLocalised = formatNumber(language as SupportedLanguage);
  const parseNumber2DP = parseNumberLocalised({ decimalPlaces: 2, minimum: 0 });
  const formatNumber2DP = formatNumberLocalised(2);
  const formatNumber2DPFixed = formatNumberLocalised(2, true);

  const rows = materials.byIndex.map((material) => {
    const item: InventoryItem = inventory.find(
      (item) => item.material_id === material.id
    ) ?? {
      material_id: material.id,
      quantity: null,
      specific_price: null,
      previous_quantity: null,
      deliveries_since_previous: null,
      consumption_since_previous: null,
      projected_consumption_since_previous: null,
      projected_deliveries_since_previous: null,
    };
    return { ...item, material_name: material.name };
  });

  const columns: GridColDef<InventoryRow>[] = [
    {
      field: "material_name",
      headerName: t("material"),
      sortable: true,
      sortComparator: materialNameColumnSortComparator,
      flex: 1,
      renderCell: (params) => (
        <MaterialPropertyViewCell
          id={params.row.material_id}
          name={params.row.material_name}
        />
      ),
      display: "flex",
    },
    {
      field: "previous_quantity",
      headerName: `${t("lastInventoryReport")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <NumberCell value={params.row.previous_quantity ?? null} />
      ),
      display: "flex",
    },
    {
      field: "deliveries_since_previous",
      headerName: `${t("recordedDeliveries")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <NumberCell value={params.row.deliveries_since_previous ?? null} />
      ),
      display: "flex",
    },
    {
      field: "consumption_since_previous",
      headerName: `${t("recordedConsumption")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <NumberCell value={params.row.consumption_since_previous ?? null} />
      ),
      display: "flex",
    },
    {
      field: "projected_deliveries_since_previous",
      headerName: `${t("assumedDeliveries")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <NumberCell
          value={params.row.projected_deliveries_since_previous ?? null}
        />
      ),
      display: "flex",
    },
    {
      field: "projected_consumption_since_previous",
      headerName: `${t("projectedConsumptionToPlan")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <NumberCell
          value={params.row.projected_consumption_since_previous ?? null}
        />
      ),
      display: "flex",
    },
    {
      field: "quantity",
      headerName: `${t("inventoryInHand")}${units("mass")}`,
      sortable: false,
      editable: true,
      flex: 1,
      cellClassName: "numeric",
      valueSetter: (value: string, row) => {
        try {
          if (value) {
            const quantity = parseNumber2DP(value);
            return {
              ...row,
              quantity,
            };
          } else {
            return {
              ...row,
              quantity: null,
            };
          }
        } catch (e) {
          switch (true) {
            case e instanceof MinimumExceededError:
              notifyStatus({ text: t("invalidNumber"), type: "error" });
          }
          return row;
        }
      },
      valueGetter: (_: unknown, row) => {
        if (row.quantity) {
          return formatNumber2DP(row.quantity);
        } else {
          return null;
        }
      },
      display: "flex",
    },
    {
      field: "specific_price",
      headerName: `${t("pricePerTonne")}${units("specific_cost")}`,
      sortable: false,
      editable: true,
      flex: 1,
      cellClassName: "numeric",
      valueSetter: (value: string, row) => {
        try {
          if (value) {
            const specific_price = parseNumber2DP(value);
            return {
              ...row,
              specific_price,
            };
          } else {
            return {
              ...row,
              specific_price: null,
            };
          }
        } catch (e) {
          switch (true) {
            case e instanceof MinimumExceededError:
              notifyStatus({ text: t("invalidNumber"), type: "error" });
          }
          return row;
        }
      },
      valueGetter: (_, row) => {
        if (row.specific_price) {
          return formatNumber2DPFixed(row.specific_price);
        } else {
          return null;
        }
      },
      display: "flex",
    },
    {
      field: "estimated_yielded_price",
      headerName: `${t("estimatedYieldedPrice")} ${units(
        "specific_cost"
      ).trim()}`,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <NumberCell
          value={
            params.row.specific_price === null
              ? null
              : params.row.specific_price /
                (materialPhysics.byId[params.row.material_id]!.yield_percent /
                  100)
          }
          decimalPlaces={2}
        />
      ),
      display: "flex",
    },
  ];

  return (
    <Box
      // Prevent the modal from closing when pressing Escape key while in edit mode in the data grid
      onKeyDown={(e) => e.key === "Escape" && e.stopPropagation()}
    >
      <DataGridPremium
        rows={rows}
        columns={columns}
        getRowId={(row: InventoryRow) => row.material_id}
        processRowUpdate={handleUpdateItem}
        sx={{
          height: 500,
          "& .MuiDataGrid-cell--editing input": {
            textAlign: "right",
            paddingX: 1,
            ...theme.typography.body1Mono,
          },
          ".numeric": {
            display: "flex",
            justifyContent: "flex-end",
            ...theme.typography.body1Mono,
          },
        }}
        initialState={{ sorting: materialNameSortModel }}
        columnVisibilityModel={{
          estimated_yielded_price: !isFromPrevious,
          previous_quantity: isFromPrevious,
          deliveries_since_previous: isFromPrevious,
          consumption_since_previous: isFromPrevious,
          projected_deliveries_since_previous: isFromPrevious,
          projected_consumption_since_previous: isFromPrevious,
        }}
        cellSelection
        ignoreValueFormatterDuringExport
      />
    </Box>
  );
};
