import { PeriodMaterialSummary } from "store/api/generatedApi";
import { useMemo } from "react";
import { SearchMaterials } from "contexts/search/context";

export type PlanTableOptions = {
  productGroups: boolean;
  percentages: boolean;
  forecast: boolean;
  steelGrades: string[];
};

export type PlanConsumptionAggregation = {
  inventoryInitial: PlanConsumption;
  consumption: PlanConsumption;
  inventoryFinal: PlanConsumption;
  obtainableRequired: PlanConsumption;
  obtainableRemaining: PlanConsumption;
};

export type PlanConsumption = {
  materials: Record<number, number>;
  total: number;
  maximum: number;
  price: number;
};

export type PlanGroup = {
  group: number;
  mix: number;
  multipleMixes: boolean;
  heats: number;
};

export type PlanMaterial =
  | { material: number; summary?: undefined }
  | { summary: "total" };

export const useInventoryContent = (
  materials: SearchMaterials,
  materialSummary: PeriodMaterialSummary[]
): PlanConsumptionAggregation => {
  return useMemo(() => {
    const consumption = {
      inventoryInitial: buildConsumption(
        materials,
        materialSummary,
        (summary) =>
          Math.round(parseFloat(summary.initial_inventory.toString()))
      ),
      consumption: buildConsumption(materials, materialSummary, (summary) =>
        Math.round(parseFloat(summary.mass_consumed.toString()))
      ),
      inventoryFinal: buildConsumption(materials, materialSummary, (summary) =>
        Math.round(parseFloat(summary.final_inventory.toString()))
      ),
      obtainableRequired: buildConsumption(
        materials,
        materialSummary,
        (summary) =>
          Math.round(parseFloat(summary.obtainable_obtained.toString()))
      ),
      obtainableRemaining: buildConsumption(
        materials,
        materialSummary,
        (summary) =>
          Math.round(parseFloat(summary.obtainable_remaining.toString()))
      ),
    };

    return consumption;
  }, [materials, materialSummary]);
};

const summarise = (materials: { [material: number]: number }) => {
  return {
    total: Object.values(materials).reduce((left, right) => left + right, 0),
    maximum: Math.max(...Object.values(materials)),
  };
};

const buildConsumption = (
  searchMaterials: SearchMaterials,
  summaries: PeriodMaterialSummary[],
  get: (summary: PeriodMaterialSummary) => number
): {
  materials: { [material: number]: number };
  total: number;
  maximum: number;
  price: number;
} => {
  const materials = Object.fromEntries(
    summaries.map((summary) => [
      searchMaterials.byName[summary.material]!.id,
      get(summary),
    ])
  );

  return { materials, ...summarise(materials), price: -1 };
};
