import { LoadedContent } from "src/components/common/loading/loadedContent";
import { liftLoadedState } from "models/loaded";
import { InputModalContentLayout } from "../common/InputModalContentLayout";
import { InputModeSelector } from "./modeSelector";
import {
  useProductionPlan,
  usePhysicalParameters,
  useProductGroups,
  useSteelGrades,
  useChemistryGroups,
  useChemicalElements,
} from "contexts/search/provider";
import { ToolsPanel } from "./tools/toolsPanel";
import { usePeriodIndex } from "hooks/periodIndex";
import { ProductionDependencies, useSetItems } from "./dependencies";
import { ProductGroupPlanEditor } from "./content/productGroupPlanEditor";
import { SteelGradePlanEditor } from "./content/steelGradePlanEditor";

export const ProductionEditor = () => {
  const periodTab = usePeriodIndex();
  const [productionPlan, setProductionPlan] = useProductionPlan(periodTab);
  const [physicalParameters] = usePhysicalParameters();

  const productGroups = useProductGroups();
  const steelGrades = useSteelGrades();

  return (
    <LoadedContent
      data={liftLoadedState({
        productionPlan,
        productGroups,
        chemistryGroups: useChemistryGroups(),
        steelGrades,
        chemicalElements: useChemicalElements(),
        physicalParameters,
      })}
    >
      {({
        productionPlan,
        productGroups,
        chemistryGroups,
        steelGrades,
        chemicalElements,
        physicalParameters,
      }) => (
        <InputModalContentLayout
          topPanel={
            <InputModeSelector
              productionPlan={productionPlan}
              setProductionPlan={(plan) => setProductionPlan(() => plan)}
              productGroups={productGroups}
            />
          }
          leftPanel={
            <ProductionEditorContent
              productGroups={productGroups}
              chemistryGroups={chemistryGroups}
              productionPlan={productionPlan}
              setProductionPlan={(plan) => setProductionPlan(() => plan)}
              steelGrades={steelGrades}
              chemicalElements={chemicalElements}
              tappedMass={physicalParameters.target_tapped_mass_lower}
            />
          }
          rightPanel={
            <ToolsPanel
              productionPlan={productionPlan}
              productGroups={productGroups}
              steelGrades={steelGrades}
            />
          }
          removeLeftPanelOutline
        />
      )}
    </LoadedContent>
  );
};

export const ProductionEditorContent = ({
  productionPlan,
  setProductionPlan,
  productGroups,
  chemistryGroups,
  steelGrades,
  chemicalElements,
  tappedMass,
}: ProductionDependencies) => {
  const { setItems, productGroupPlan, steelGradePlan } = useSetItems(
    productGroups,
    productionPlan,
    setProductionPlan
  );

  return (
    <>
      {productGroupPlan && productGroups ? (
        <ProductGroupPlanEditor
          productGroupItems={productGroupPlan}
          steelGrades={steelGrades}
          setProductGroupItems={(items) => setItems(items, null)}
          productGroups={productGroups}
          fixedRows
          uniqueRowIds
          tappedMassPerHeat={tappedMass}
          heatsDisabled={!setProductionPlan}
        />
      ) : null}
      {steelGradePlan ? (
        <SteelGradePlanEditor
          productGroups={productGroups}
          chemistryGroups={chemistryGroups}
          steelGradeItems={steelGradePlan}
          setSteelGradeItems={(items) => setItems(null, items)}
          steelGrades={steelGrades}
          uniqueRowIds
          tappedMassPerHeat={tappedMass}
          heatsDisabled={!setProductionPlan}
          fixedRows={!setProductionPlan}
          elements={chemicalElements}
        />
      ) : null}
    </>
  );
};
