import { Alert, CircularProgress } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { Loaded } from "models/loaded";
import { ReactNode } from "react";
import { Fetching } from "./fetching";

export const LoadedContent = <T,>({
  data,
  children,
  waiting,
  loading,
  error,
  disableFullWidth,
  enableFullHeight,
  disableFetching,
}: {
  data: Loaded<T>;
  children: (data: T, fetching: boolean) => ReactNode;
  waiting?: ReactNode | null;
  loading?: ReactNode | null;
  error?: ReactNode | null;
  disableFullWidth?: boolean;
  enableFullHeight?: boolean;
  disableFetching?: boolean;
}) => {
  if (disableFullWidth === undefined) {
    disableFullWidth = false;
  }

  if (enableFullHeight === undefined) {
    enableFullHeight = false;
  }

  const { t } = useTenantTranslation();

  if (data.status === "waiting") {
    return waiting !== undefined ? (
      waiting
    ) : (
      <Fetching
        fetching
        disableFullWidth={disableFullWidth}
        enableFullHeight={enableFullHeight}
      >
        <CircularProgress />
      </Fetching>
    );
  } else if (data.status === "loading") {
    return loading !== undefined ? loading : <CircularProgress />;
  } else if (data.status === "error") {
    return error !== undefined ? (
      error
    ) : (
      <Alert severity="error">{t("unknownError")}</Alert>
    );
  } else {
    return disableFetching ? (
      children(data.data, data.fetching)
    ) : (
      <Fetching
        fetching={data.fetching}
        disableFullWidth={disableFullWidth}
        enableFullHeight={enableFullHeight}
      >
        {children(data.data, data.fetching)}
      </Fetching>
    );
  }
};
