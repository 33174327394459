import { EmptyObject } from "@reduxjs/toolkit";
import {
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import { ProductGroupPlanItem } from "store/api/generatedApi";
import { PlanEditor } from "./planEditor";
import { PlanItem } from "./planEditor/types";
import React from "react";
import { ProductGroupPanel } from "components/common/panels/productGroup";

type Props = {
  productGroupItems: ProductGroupPlanItem[];
  setProductGroupItems: (productGroupItems: ProductGroupPlanItem[]) => void;
  productGroups: SearchProductGroups;
  steelGrades: SearchSteelGrades;
  fixedRows?: boolean;
  uniqueRowIds?: boolean;
  heatsDisabled?: boolean;
  tappedMassPerHeat: number;
};

export const ProductGroupPlanEditor = ({
  productGroupItems,
  setProductGroupItems,
  steelGrades,
  productGroups,
  fixedRows,
  uniqueRowIds,
  heatsDisabled,
  tappedMassPerHeat,
}: Props) => {
  const items: PlanItem[] = productGroups.byIndex.map((group) => {
    const item: ProductGroupPlanItem = productGroupItems.find(
      (item) => item.product_group_id === group.id
    ) ?? { product_group_id: group.id, num_heats: 0 };
    return {
      mixNumber: 1,
      groupId: item.product_group_id,
      heats: item.num_heats,
    };
  });

  const setItems = (newItems: PlanItem[]) => {
    setProductGroupItems(
      newItems.map((item) => ({
        product_group_id: item.groupId,
        num_heats: item.heats,
      }))
    );
  };

  const [selectedProductGroupId, setSelectedProductGroupId] = React.useState<
    number | null
  >(null);

  const selectedProductGroup = (() => {
    if (selectedProductGroupId !== null) {
      const productGroup = productGroups.byId[selectedProductGroupId];
      if (productGroup !== undefined) {
        return {
          ...productGroup,
          steelGrades: productGroup.steel_grades.map(
            ({ id }) => steelGrades.byId[id]!
          ),
        };
      }
    }
    return null;
  })();
  const isPanelOpen = selectedProductGroup !== null;

  return (
    <>
      <PlanEditor<EmptyObject>
        items={items}
        setItems={setItems}
        setSelectedItem={(item) => setSelectedProductGroupId(item.groupId)}
        name={(item) => productGroups.byId[item.groupId]!.name}
        addItem={
          fixedRows
            ? undefined
            : {
                groupIds: productGroups.byIndex.map((item) => item.id),
                populateDefaults: (item) => item,
                uniqueRowIds: Boolean(uniqueRowIds),
              }
        }
        heatsDisabled={heatsDisabled}
        tappedMassPerHeat={tappedMassPerHeat}
      />
      <ProductGroupPanel
        open={isPanelOpen}
        doClose={() => setSelectedProductGroupId(null)}
        steelGrades={selectedProductGroup?.steelGrades ?? []}
        title={selectedProductGroup?.name ?? ""}
      />
    </>
  );
};
