import { SteelGradePlanItem } from "store/api/generatedApi";
import {
  SearchChemicalElements,
  SearchChemistryGroups,
  SearchProductGroups,
  SearchSteelGrades,
  SteelGradesId,
} from "contexts/search/context";
import { PlanItem } from "./planEditor/types";
import { PlanEditor } from "./planEditor";
import React from "react";
import { usePeriods } from "contexts/search/provider";
import { filterForSteelGrade } from "src/utils/filterForSteelGrades";
import { ProductPanel } from "components/common/panels/product";
import { ProductHeatsPopover } from "components/common/popovers/productHeats";

type Props = {
  productGroups: SearchProductGroups | null;
  chemistryGroups: SearchChemistryGroups;
  steelGradeItems: SteelGradePlanItem[];
  setSteelGradeItems: (steelGradeItems: SteelGradePlanItem[]) => void;
  steelGrades: SearchSteelGrades;
  fixedRows?: boolean;
  uniqueRowIds?: boolean;
  heatsDisabled?: boolean;
  tappedMassPerHeat: number;
  elements: SearchChemicalElements;
};

export const SteelGradePlanEditor = ({
  productGroups,
  chemistryGroups,
  steelGradeItems,
  setSteelGradeItems,
  steelGrades,
  fixedRows,
  uniqueRowIds,
  heatsDisabled,
  tappedMassPerHeat,
  elements,
}: Props) => {
  const [steelGradeId, setSteelGradeId] = React.useState<SteelGradesId | null>(
    null
  );
  const [anchorElement, setAnchorElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [periods] = usePeriods();

  const items = steelGradeItems.map((item, index) => ({
    // Count the number of items before this in the list that have the same
    // product group ID, then use that as the mix number
    mixNumber:
      steelGradeItems
        .slice(index)
        .filter((priorItem) => priorItem.steel_grade_id === item.steel_grade_id)
        .length + 1,
    groupId: item.steel_grade_id,
    heats: item.num_heats,
  }));

  const setItems = (newItems: PlanItem[]) => {
    setSteelGradeItems(
      newItems.map((item) => ({
        steel_grade_id: item.groupId,
        num_heats: item.heats,
      }))
    );
  };

  const handleOnSecondaryClick = (
    item: PlanItem,
    target: HTMLButtonElement
  ) => {
    setSteelGradeId(item.groupId as SteelGradesId);
    setAnchorElement(target);
  };

  const handleCloseHeatsPanel = () => {
    setSteelGradeId(null);
    setAnchorElement(null);
  };

  const [selectedSteelGradeId, setSelectedSteelGradeId] = React.useState<
    number | null
  >(null);
  const selectedSteelGrade =
    steelGrades.byId[selectedSteelGradeId ?? -1] ?? null;

  return (
    <>
      <PlanEditor
        onSecondaryClick={handleOnSecondaryClick}
        items={items}
        setItems={setItems}
        name={(item) => steelGrades.byId[item.groupId]!.name}
        addItem={
          fixedRows
            ? undefined
            : {
                groupIds: steelGrades.byIndex.map((item) => item.id),
                populateDefaults: (item) => item,
                uniqueRowIds: Boolean(uniqueRowIds),
              }
        }
        heatsDisabled={heatsDisabled}
        tappedMassPerHeat={tappedMassPerHeat}
        setSelectedItem={(item) => setSelectedSteelGradeId(item.groupId)}
      />
      {selectedSteelGrade === null ? null : (
        <ProductPanel
          open
          doClose={() => setSelectedSteelGradeId(null)}
          title={selectedSteelGrade.name}
          productGroups={
            productGroups === null
              ? []
              : filterForSteelGrade(selectedSteelGrade.id, productGroups)
          }
          chemistryGroups={filterForSteelGrade(
            selectedSteelGrade.id,
            chemistryGroups
          )}
          chemicalConstraints={selectedSteelGrade.chemical_constraints}
          elements={elements.byId}
        />
      )}
      {steelGradeId !== null && periods.status === "success" ? (
        <ProductHeatsPopover
          productId={steelGradeId}
          periods={periods.data}
          open
          anchorEl={anchorElement}
          onClose={handleCloseHeatsPanel}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        />
      ) : null}
    </>
  );
};
