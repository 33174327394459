import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useEffect, useMemo, useState } from "react";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { ExportPlanState, isApproved, useFudgeFactor } from "./state";
import { Setter } from "hooks/state/syncing";
import { LoadingButton } from "@mui/lab";
import { LayeringKey, LayeringSummary, useLayering } from "./layerings";
import { useApprove, usePublish, useUnapprove } from "./actions";
import { Loaded } from "models/loaded";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { mapLoaded } from "models/loaded";
import { ArrowBack, ArrowForward, CheckCircle } from "@mui/icons-material";
import React from "react";
import { useMaterials } from "contexts/search/provider";
import { LayeringFudgeFactors } from "src/store/api/generatedApi";
import { SearchMaterials } from "contexts/search/context";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";
import { Select } from "src/components/common/inputs/select";
import { useIsAdmin } from "hooks/settings";
import { MaterialDetailDrawer } from "components/common/panels/material";

export const Preview = ({
  state,
  setState,
}: {
  state: ExportPlanState;
  setState: Setter<ExportPlanState>;
}) => {
  const { t } = useTenantTranslation();

  // When these buttons are shown for the first time, briefly disable them to
  // prevent users from accidentally hitting them when clicking through the
  // different layerings quickly
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const disableConfirmButton = () => {
    setConfirmButtonDisabled(true);
    setTimeout(() => setConfirmButtonDisabled(false), 600);
  };

  useEffect(disableConfirmButton, [state.approved]);
  const publish = usePublish(state, setState);

  const nextKey =
    state.planned.filter((key) => !isApproved(state, key))[0] ?? null;

  if (nextKey == null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90%",
        }}
      >
        <Stack
          sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
        >
          <CheckCircle sx={{ color: "success.main", height: 28, width: 28 }} />
          <Typography variant="h5" sx={{ mt: 1 }}>
            {`${state.planned.length}/${state.planned.length}`}{" "}
            {t("layersApproved")}.
          </Typography>
          <Button
            variant="contained"
            onClick={publish}
            sx={{ mt: 3 }}
            disabled={confirmButtonDisabled}
          >
            {t("syncWithLevel2")}
          </Button>
        </Stack>
      </Box>
    );
  } else {
    return (
      <PreviewContent
        nextKey={nextKey}
        state={state}
        setState={setState}
        confirmButtonDisabled={confirmButtonDisabled}
      />
    );
  }
};

const PreviewContent = ({
  nextKey,
  state,
  setState,
  confirmButtonDisabled,
}: {
  nextKey: LayeringKey;
  state: ExportPlanState;
  setState: Setter<ExportPlanState>;
  confirmButtonDisabled: boolean;
}) => {
  const { t } = useTenantTranslation();
  const isAdmin = useIsAdmin();

  const materials = useMaterials();
  const [fudgeFactor, setFudgeFactor] = useFudgeFactor(
    state,
    setState,
    nextKey
  );

  const approve = useApprove(setState);
  const unapprove = useUnapprove(setState);

  const plannedLayering = useLayering(nextKey);
  const fudgedLayering = useLayering(nextKey, fudgeFactor);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ border: 1, borderRadius: 1 }}>
        <Box
          sx={{
            px: 3,
            py: 1,
            borderBottom: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {state.chemistryGroups.byId[nextKey.groupId]!.name}
          </Typography>
          <Stack
            direction="row"
            gap={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <LinearProgress
              sx={{
                width: 150,
                height: 8,
                borderRadius: "10px",
                backgroundColor: "grey.100",
              }}
              variant="determinate"
              value={(state.approved.length / state.planned.length) * 100}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={unapprove}
              disabled={confirmButtonDisabled || state.approved.length === 0}
              startIcon={<ArrowBack />}
            >
              {t("previous")}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={
                fudgedLayering.status !== "success"
                  ? undefined
                  : () => approve(nextKey, fudgedLayering.data.summary)
              }
              loading={
                fudgedLayering.status !== "success" || fudgedLayering.fetching
              }
              endIcon={<ArrowForward />}
              disabled={confirmButtonDisabled}
            >
              {state.approved.length === state.planned.length - 1
                ? t("approve")
                : t("approveAndNext")}
            </LoadingButton>
          </Stack>
        </Box>
        <Stack sx={{ p: 3, pb: 8, minHeight: 300 }} gap={2}>
          {isAdmin ? (
            <Typography variant="h3">{t("originalLayering")}</Typography>
          ) : null}
          <PreviewLayering
            layering={mapLoaded(plannedLayering, ({ summary }) => summary)}
          />
        </Stack>
        {isAdmin ? (
          <>
            <Stack sx={{ p: 3, pb: 8 }} gap={2}>
              <Typography variant="h3">{t("adjustments")}</Typography>
              <LoadedContent data={materials}>
                {(loadedMaterials) => (
                  <FudgeFactorEditor
                    fudgeFactor={fudgeFactor}
                    setFudgeFactor={setFudgeFactor}
                    materials={loadedMaterials}
                  />
                )}
              </LoadedContent>
            </Stack>
            <Stack sx={{ p: 3, pb: 8, minHeight: 300 }} gap={2}>
              <Typography variant="h3">{t("adjustedLayering")}</Typography>
              <PreviewLayering
                layering={mapLoaded(fudgedLayering, ({ summary }) => summary)}
                showProgress
              />
            </Stack>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export const PreviewLayering = ({
  layering,
  showProgress,
}: {
  layering: Loaded<LayeringSummary>;
  showProgress?: boolean;
}) => {
  const { t } = useTenantTranslation();

  return (
    <LoadedContent
      data={layering}
      error={<Alert severity="error">{t("errorLoadingLayering")}</Alert>}
      loading={showProgress ? <LinearProgress /> : null}
    >
      {(loadedLayering) => (
        <Grid container spacing={3}>
          <PreviewCharge
            layering={loadedLayering}
            chargeNumber={1}
            title={t("firstBasket")}
            displayPercentage
          />
          <PreviewCharge
            layering={loadedLayering}
            chargeNumber={2}
            title={t("secondBasket")}
          />
          <PreviewCharge
            layering={loadedLayering}
            chargeNumber={3}
            title={t("thirdBasket")}
          />
        </Grid>
      )}
    </LoadedContent>
  );
};

type Row = {
  id: number;
  percentage: string;
  description: string;
  weight: null | string;
  total: null | string;
};

const PreviewCharge = ({
  layering,
  chargeNumber,
  title,
  displayPercentage,
}: {
  layering: LayeringSummary;
  chargeNumber: 1 | 2 | 3;
  title: string;
  displayPercentage?: boolean;
}) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);
  const { format } = useNumberSerialiser({
    default: { value: null, text: "" },
  });
  const materials = useMaterials();

  const rows = layering.charges[chargeNumber].map((layer) => ({
    id: layer.step,
    percentage: layer.firstInCharge
      ? format(layering.materialFractions[layer.description]! * 100 || null)
      : "",
    description: layer.description,
    weight: format(layer.weight || null),
    total: format(layer.total || null),
  }));

  const columns: GridColDef<Row>[] = [
    {
      field: "id",
      headerName: t("step"),
      flex: 1,
      sortable: false,
    },
    {
      field: "percentage",
      headerName: units("mass_fraction"),
      flex: 1,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 20,
      sortable: false,
      renderCell: (params) => {
        const {
          row: { description },
        } = params;
        if (
          materials.status === "success" &&
          materials.data.byName[description]
        ) {
          return (
            <MaterialCell
              id={materials.data.byName[description]!.id}
              name={description}
            />
          );
        } else {
          return <Typography variant="body2">{description}</Typography>;
        }
      },
    },
    {
      field: "weight",
      headerName: `${t("weight")} (lbs)`,
      flex: 10,
      sortable: false,
      renderCell: ({ value }) => (
        <Typography variant="body1Mono">{value}</Typography>
      ),
      align: "right",
      headerAlign: "right",
    },
    {
      field: "total",
      headerName: `${t("total")} (lbs)`,
      flex: 10,
      sortable: false,
      renderCell: ({ value }) => (
        <Typography variant="body1Mono">{value}</Typography>
      ),
      align: "right",
      headerAlign: "right",
    },
  ];

  return (
    <Grid item xs={4}>
      <Typography sx={{ mb: 0.5 }}>{title}</Typography>

      {rows.length === 0 ? (
        <Box
          sx={{
            height: 150,
            border: 1,
            borderRadius: 1,
            borderColor: "grey.100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.disabled">
            {t("noContents")}
          </Typography>
        </Box>
      ) : (
        <DataGridPremium
          autoHeight
          getRowHeight={() => "auto"}
          rows={rows}
          columns={columns}
          hideFooter
          initialState={{
            columns: {
              columnVisibilityModel: {
                percentage: Boolean(displayPercentage),
              },
            },
          }}
        />
      )}
    </Grid>
  );
};

type MaterialCellProps = {
  id: number;
  name: string;
};

const MaterialCell = ({ id, name }: MaterialCellProps) => {
  const theme = useTheme();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Link
        variant="body2"
        color={theme.palette.text.primary}
        fontWeight="bold"
        sx={{ cursor: "pointer" }}
        onClick={() => setShowModal(true)}
      >
        {name}
      </Link>
      <MaterialDetailDrawer
        title={name}
        id={id}
        open={showModal}
        doClose={() => setShowModal(false)}
      />
    </>
  );
};

const FudgeFactorEditor = ({
  fudgeFactor,
  setFudgeFactor,
  materials,
}: {
  fudgeFactor: LayeringFudgeFactors;
  setFudgeFactor: (
    update: (fudgeFactor: LayeringFudgeFactors) => LayeringFudgeFactors
  ) => void;
  materials: SearchMaterials;
}) => {
  const serialiser = useNumberSerialiser({
    min: 0,
    default: { value: null, text: "" },
  });
  const { t } = useTenantTranslation();

  const lookup = useMemo(
    () =>
      Object.fromEntries(
        fudgeFactor.materials.map((item) => [item.material_id, item.factor])
      ),
    [fudgeFactor]
  );

  const updateFactor = (materialId: number, factor: number | null) =>
    setFudgeFactor((current) => ({
      ...current,
      materials: [
        ...current.materials.filter((item) => item.material_id !== materialId),
        ...(factor === null ? [] : [{ material_id: materialId, factor }]),
      ],
    }));

  return (
    <Stack gap={2}>
      <Card sx={{ maxWidth: 770, p: 2, backgroundColor: "rgb(241, 243, 245)" }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography sx={{ fontWeight: 800 }}>
            {t("replacementMaterial")}
          </Typography>
          <Box sx={{ width: 300 }}>
            <Select
              options={materials.byIndex.map((material) => ({
                id: material.id,
                name: material.name,
              }))}
              value={{
                id: fudgeFactor.replacement_material_id,
                name: materials.byId[fudgeFactor.replacement_material_id]!.name,
              }}
              setValue={(value) =>
                setFudgeFactor((current) => ({
                  ...current,
                  replacement_material_id: value.id,
                }))
              }
              format={(value) => {
                return { key: value.name, label: value.name };
              }}
              small
            />
          </Box>
        </Stack>

        <Typography sx={{ pt: 1 }}>
          {t("replacementMaterialDescription")}
        </Typography>
      </Card>

      <Grid container spacing={2} columnGap={4}>
        {materials.byIndex.map((material) => (
          <Grid key={material.id} item xs={2}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ width: 250 }}>{material.name}</Typography>
              <Box sx={{ width: 80 }}>
                <ValidatedTextField
                  value={lookup[material.id] ?? null}
                  setValue={(value) => updateFactor(material.id, value)}
                  serialiser={serialiser}
                />
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
