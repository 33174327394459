import { Permission } from "../store/api/generatedApi";
import { useIsAdmin, useTenant, useTenantPermissions } from "./settings";

export const usePermission = (permission: Permission) => {
  const tenantPermissions = useTenantPermissions();
  const isAdmin = useIsAdmin();
  const tenant = useTenant();

  const permissions = tenantPermissions[tenant] ?? [];
  return isAdmin || permissions.includes(permission);
};

/**
 * List all tenants available to the user.
 *
 * If the user is an admin, this will be all tenants that exist in the database.
 * Otherwise it will be all tenants the user has permission to view.
 */
export const useHasAvailableTenants = (): {
  hasAvailableTenants: boolean;
  defaultTenant: string | null;
} => {
  const isAdmin = useIsAdmin();
  const userTenants = Object.keys(useTenantPermissions()).sort();

  return {
    hasAvailableTenants: isAdmin || userTenants.length > 0,
    defaultTenant:
      userTenants.length === 1 && !isAdmin ? userTenants[0]! : null,
  };
};

export const useHasTenant = (tenant: string) => {
  const isAdmin = useIsAdmin();
  const userTenants = Object.keys(useTenantPermissions()).sort();

  return isAdmin || userTenants.includes(tenant);
};
