import { Loaded, loadedEndpoint, mapLoaded } from "models/loaded";
import {
  BasketRead,
  ChefGroup,
  ChemicalElement,
  MaterialChemistry,
  MaterialPhysics,
  MaterialRead,
  MixMaterialLimitRead,
  ProductGroup,
  SteelGrade as SteelGradeType,
  useGetSearchContextQuery,
} from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  EntityMapping,
  createMapping,
  createNestedMapping,
  sorted,
} from "helpers";
import { useAppDispatch } from "src/store/store";
import { mixMaterialActions } from "src/store/slices/mixMaterialLimits";
import React from "react";

export type SyncedContextData = {
  productGroupsId: number | null;
  steelGradesId: number;
  materialsId: number;
  materialPhysicsId: number;

  productGroups: SearchProductGroups;
  chemistryGroups: SearchChemistryGroups;
  steelGrades: SearchSteelGrades;

  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
  materialChemistry: SearchMaterialChemistry;

  mixMaterialLimits: MixMaterialLimit[];

  baskets: SearchBaskets;
  chemicalElements: SearchChemicalElements;

  hasDefaultSets: boolean;
};

export type SyncedContextState = Loaded<SyncedContextData>;

export type ProductGroupId = number & { __productGroupId__: true };
export type SearchProductGroups = EntityMapping<
  ProductGroup & { id: ProductGroupId }
>;

export type ChemistryGroupId = number & { __chemistryGroupId__: true };
export type SearchChemistryGroups = EntityMapping<
  ChefGroup & { id: ChemistryGroupId }
>;

export type SteelGradesId = number & { __steelGradesId__: true };
export type SteelGrade = SteelGradeType & { id: SteelGradesId };
export type SearchSteelGrades = EntityMapping<SteelGrade>;

export type MaterialsId = number & { __materialsId__: true };
export type SearchMaterials = EntityMapping<
  MaterialRead & {
    id: MaterialsId;
  }
>;

export type MixMaterialLimitId = number & { __mixMaterialLimitId__: true };
export type MixMaterialLimit = MixMaterialLimitRead & {
  id: MixMaterialLimitId;
};

export type SearchMaterialPhysics = Omit<
  EntityMapping<MaterialPhysics>,
  "byName"
>;
export type SearchMaterialChemistry = {
  [materialId: number]: { [elementId: number]: MaterialChemistry };
};

export type SearchBaskets = BasketRead[];
export type SearchChemicalElements = EntityMapping<ChemicalElement>;

export const useSyncedContextState = (
  searchContextId: number | null
): SyncedContextState => {
  const tenant = useTenant();
  const dispatch = useAppDispatch();

  const contextState = mapLoaded(
    loadedEndpoint(
      useGetSearchContextQuery(
        searchContextId == null
          ? skipToken
          : {
              tenantName: tenant,
              searchContextId,
            }
      )
    ),
    ({
      has_default_sets,
      product_groups_id,
      steel_grades_id,
      materials_id,
      material_physics_id,
      product_groups,
      chef_groups,
      steel_grades,
      materials,
      material_physics,
      material_chemistry,
      mix_material_limits,
      baskets,
      chemical_elements,
      mix_material_limit_set_id,
    }) => {
      const taggedProductGroups = product_groups.map(
        ({ id, ...productGroup }) => ({
          id: id as ProductGroupId,
          ...productGroup,
        })
      );

      const taggedChemistryGroups = chef_groups.map(({ id, ...chefGroup }) => ({
        id: id as ChemistryGroupId,
        ...chefGroup,
      }));

      const taggedSteelGrades = steel_grades.map(({ id, ...steel_grade }) => ({
        id: id as SteelGradesId,
        ...steel_grade,
      }));

      const taggedMaterials = materials.map(({ id, ...materials }) => ({
        id: id as MaterialsId,
        ...materials,
      }));

      const taggedMixMaterialLimits = mix_material_limits.map(
        ({ id, ...mixMaterialLimit }) => ({
          ...mixMaterialLimit,
          id: id as MixMaterialLimitId,
        })
      );

      return {
        hasDefaultSets: has_default_sets,
        productGroupsId: product_groups_id,
        steelGradesId: steel_grades_id,
        materialsId: materials_id,
        materialPhysicsId: material_physics_id,
        mixMaterialLimitSetId: mix_material_limit_set_id,
        productGroups: {
          byIndex: sorted(taggedProductGroups, (item) => item.name),
          byId: createMapping(taggedProductGroups, (item) => item.id),
          byName: createMapping(taggedProductGroups, (item) => item.name),
          __productGroups__: true,
        },
        chemistryGroups: {
          byIndex: sorted(taggedChemistryGroups, (item) => item.name),
          byId: createMapping(taggedChemistryGroups, (item) => item.id),
          byName: createMapping(taggedChemistryGroups, (item) => item.name),
        },
        steelGrades: {
          byIndex: sorted(taggedSteelGrades, (item) => item.name),
          byId: createMapping(taggedSteelGrades, (item) => item.id),
          byName: createMapping(taggedSteelGrades, (item) => item.name),
        },
        materials: {
          byIndex: sorted(taggedMaterials, (item) => item.ordering),
          byId: createMapping(taggedMaterials, (material) => material.id),
          byName: createMapping(taggedMaterials, (material) => material.name),
        },
        materialPhysics: {
          byIndex: material_physics,
          byId: createMapping(
            material_physics,
            (material) => material.material_id
          ),
        },
        materialChemistry: createNestedMapping(
          material_chemistry,
          (item) => item.material_id,
          (item) => item.chemical_element_id
        ),
        mixMaterialLimits: taggedMixMaterialLimits,
        baskets,
        chemicalElements: {
          byIndex: chemical_elements,
          byId: createMapping(chemical_elements, (item) => item.id),
          byName: createMapping(chemical_elements, (item) => item.name),
        },
      };
    }
  );

  React.useEffect(() => {
    if (contextState.status === "success") {
      dispatch(
        mixMaterialActions.dump([
          contextState.data.mixMaterialLimitSetId,
          Object.fromEntries(
            contextState.data.mixMaterialLimits.map((mixMaterialLimit) => [
              mixMaterialLimit.id,
              mixMaterialLimit,
            ])
          ),
        ])
      );
    }
  }, [contextState]);

  return contextState;
};
