import { Loaded } from "models/loaded";
import { Maybe } from "models/maybe";
import { useEffect, useState } from "react";

export type Setter<T extends object | null> = (
  update: T | ((current: T) => T)
) => void;

export type SyncedState<T extends object | null> = {
  client: Loaded<T>;
  setClient: Setter<T>;
  server: Loaded<T>;
  setServer: Setter<T>;
};

type InternalSyncedState<T> = {
  /**
   * Most recent value provided by the user.
   *
   * This will be invalid immediately after the synced state has been
   * instantiated, until the `source` function has returned successfully.
   */
  client: Maybe<T>;

  /** Most recent value acknowledged by the server. */
  server: Maybe<T>;

  /** Whether the server is currently being updated to match the client. */
  syncing: boolean;

  /** Whether or not an error prevented the most recent value from syncing. */
  error: boolean;
};

/**
 * Wrapper around `useState` that informs the backend or changes to its value.
 *
 * The purpose of this is to take user updates made in the frontend, dispatch
 * them to the server, and have the server acknowledge receipt of the new value;
 * all in a way that is seemless to the user on the frontend.
 *
 * Two pieces of state are maintained:
 *
 * - The client state, which is updated immediately when the `setState` function
 *   is called, as would be the case with a typical `useState` hook.  The client
 *   state is waiting initially, while the initial value is being fetched
 *   (according to the `source` function passed to the hook)
 * - The server state, which is either: error, if the backend has failed to
 *   acknowledge the most recent client value; loading, if the backend is
 *   currently in the process of acknowledging a recent change to the client
 *   state; or success, with a value of the same type as the client state, which
 *   is the value the backend sent back in acknowledgement (this is useful as
 *   the backend may have populated IDs, or similar)
 *
 * If multiple changes are made to the client state in quick succession, some
 * changes may be queued up and sent together to ensure that there are never any
 * overlapping requests sent.
 *
 * Whenever the source changes, the internal state will be reset and a new value
 * will be retrieved from the source to initialise the new state.  **Note that
 * because functions are compared by reference, it will often be necessary to
 * wrap the source function in a `useMemo` or `useCallback` hook.**
 *
 * A comparison function must be provided.  This determines whether or not an
 * update request is actually sent; if the current client and server values are
 * equal - according to that function - after a new client value is set, no
 * update request will be sent.  The comparison function should compare by value
 * instead of by reference where appropriate, to prevent unnecessary syncs.
 */
export const useSyncedState = <T extends object | null>(
  source: (() => Promise<T>) | null,
  update: (client: T, server: T) => Promise<T>,
  compare: (left: T, right: T) => boolean
): SyncedState<T> => {
  // Bundle the state together to ensure it always stays in sync
  const [state, setState] = useState<InternalSyncedState<T>>({
    client: { present: false },
    server: { present: false },
    syncing: false,
    error: false,
  });

  useEffect(() => {
    if (source) {
      setState({
        client: { present: false },
        server: { present: false },
        syncing: true,
        error: false,
      });
      source()
        .then((server) =>
          setState({
            client: { present: true, value: server },
            server: { present: true, value: server },
            syncing: false,
            error: false,
          })
        )
        .catch(() =>
          setState({
            client: { present: false },
            server: { present: false },
            syncing: false,
            error: true,
          })
        );
    } else {
      setState({
        client: { present: false },
        server: { present: false },
        syncing: false,
        error: false,
      });
    }
  }, [source]);

  const setClient = (update: T | ((current: T) => T)) => {
    if (state.client.present) {
      setState((s) => ({
        ...s,
        // Do not allow updates until the initial value has been loaded
        client: s.client.present
          ? {
              present: true,
              value:
                update == null || typeof update === "object"
                  ? update
                  : update(s.client.value),
            }
          : s.client,
        error: false,
      }));
    }
  };

  useEffect(
    () => {
      // Only update the server when it is out of sync with the new client value
      if (
        !state.syncing &&
        !state.error &&
        state.client.present &&
        state.server.present &&
        !compare(state.client.value, state.server.value)
      ) {
        setState((s) => ({ ...s, syncing: true, error: false }));

        update(state.client.value, state.server.value)
          .then((server) =>
            setState((s) => ({
              client: s.client,
              server: { present: true, value: server },
              syncing: false,
              error: false,
            }))
          )
          .catch(() => {
            setState((s) => ({ ...s, syncing: false, error: true }));
          });
      }
    },
    // The server state is deliberately excluded from the effect dependencies
    // to prevent spurious syncs from being triggered
    [state.client, state.syncing, state.error]
  );

  const setServer = (update: T | ((current: T) => T)) => {
    setState((s) => ({
      ...s,
      server:
        update == null || typeof update === "object"
          ? { present: true, value: update }
          : s.server.present
          ? { present: true, value: update(s.server.value) }
          : { present: false },
    }));
  };

  return {
    client: state.client.present
      ? { status: "success", data: state.client.value, fetching: false }
      : state.error
      ? { status: "error" }
      : state.syncing
      ? { status: "loading" }
      : { status: "waiting" },
    setClient,
    server: state.syncing
      ? { status: "loading" }
      : state.error
      ? { status: "error" }
      : state.server.present
      ? { status: "success", data: state.server.value, fetching: false }
      : { status: "waiting" },
    setServer,
  };
};

export const unpackClientValue = <T extends object | null>(
  syncedState: SyncedState<T>
): T | undefined => {
  return syncedState.client.status === "success"
    ? syncedState.client.data
    : undefined;
};

export const unpackServerValue = <T extends Exclude<object, null>>(
  syncedState: SyncedState<T>
): T | undefined => {
  return syncedState.server.status === "success"
    ? syncedState.server.data
    : undefined;
};
