import { Autocomplete, TextField } from "@mui/material";
import {
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/**
 * Generate a searchable select box for arbitrary objects.
 *
 * The format function should return an object containing a user-friendly label
 * and a unique string key for a value in the list of options.  If the value
 * `null` is provided, no value will be selected initially.
 */
export const Select = <T,>({
  options,
  value,
  setValue,
  label,
  disabled,
  format,
  small,
  inputRef,
  open,
  onBlur,
  resetOnNull,
  width,
}: {
  options: T[];
  value: T | null;
  setValue: (value: T) => void;
  width?: number | string;
  label?: string;
  disabled?: boolean;
  format?: (value: T) => { key: string | null; label: string };
  small?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  open?: boolean;
  onBlur?: () => void;
  resetOnNull?: boolean;
}) => {
  const filledFormat = useCallback(
    (value: T | null): { key: string | null; label: string } => {
      if (value === null) {
        return { key: null, label: "" };
      } else if (format !== undefined) {
        return format(value);
      } else {
        return { key: value as string, label: value as string };
      }
    },
    [format]
  );

  const filledOptions = useMemo(
    () =>
      options.map((option) => ({
        value: option,
        ...filledFormat(option),
      })),
    [options, filledFormat]
  );

  const handleInputChange = (
    event: SyntheticEvent | null,
    newInputValue: string
  ) => {
    const type: string | null = event?.type ?? null;
    if (type === "change" || type === "click") {
      setInputValue(newInputValue);
    } else if (type === "blur") {
      setInputValue(filledFormat(value).label);
    }
  };

  const handleValueChange = (newValue: { value: T | null } | null) => {
    if (newValue?.value != null) {
      setValue(newValue.value);
      (document.activeElement as HTMLElement).blur();
    }
  };

  // Somehow, doing this in the callback above doesn't always work
  useEffect(() => {
    if (value !== null || resetOnNull) {
      setInputValue(filledFormat(value).label);
    }
  }, [value, filledFormat, resetOnNull]);

  const [inputValue, setInputValue] = useState(filledFormat(value).label);

  return (
    <Autocomplete
      fullWidth={!width}
      sx={{ width }}
      open={open}
      size={small ? "small" : undefined}
      disabled={disabled}
      options={filledOptions}
      value={value === null ? null : filledFormat(value)}
      onChange={(_, newValue) =>
        handleValueChange(newValue as unknown as { value: T | null })
      }
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          label={label}
          onBlur={onBlur}
          onKeyDown={(event) => {
            // When enter is pressed, select the top current option
            if (event.code === "Enter") {
              const selectedOptions = filledOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              );
              if (selectedOptions.length > 0) {
                handleValueChange(selectedOptions[0]!);
              }
            }

            if (event.code === "Escape" && inputRef?.current) {
              inputRef.current.blur();
            }
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.key === value.key}
    />
  );
};

/**
 * Display a disabled text field containing an error or loading message.
 */
export const SelectError = ({
  message,
  small,
}: {
  message: string;
  small?: boolean;
}) => {
  return (
    <TextField
      disabled
      fullWidth
      label={message}
      size={small ? "small" : undefined}
    />
  );
};
