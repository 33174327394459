import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { backendURL } from "../../constants";
import { getAuth } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { repeatParamsSerialiser } from "./serialiser";

const baseQuery = fetchBaseQuery({
  baseUrl: backendURL,
  paramsSerializer: repeatParamsSerialiser,
  prepareHeaders: async (headers) => {
    const auth = getAuth();
    const idToken = await auth.currentUser?.getIdToken();
    if (idToken) {
      headers.set("Authorization", `Bearer ${idToken}`);
      return headers;
    }
  },
});

const fetchBaseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const auth = getAuth();
  const result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    (result.error.status === 401 || result.error.status === 403)
  ) {
    await auth.currentUser?.getIdToken(true).catch((e: FirebaseError) => {
      if (e.code === "auth/user-token-expired") {
        window.location.href = "/sign-in";
      }
    });
    return baseQuery(args, api, extraOptions);
  }
  return result;
};

// Initialize an empty api service that we'll inject endpoints into later as needed
export const templateApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQueryWithReAuth,
  endpoints: () => ({}),
});
