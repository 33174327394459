import { ReactNode } from "react";

export const Fetching = ({
  fetching,
  disableFullWidth,
  enableFullHeight,
  children,
}: {
  fetching: boolean;
  disableFullWidth?: boolean;
  enableFullHeight?: boolean;
  children: ReactNode;
}) => {
  return (
    <div
      style={{
        opacity: fetching ? 0.5 : undefined,
        pointerEvents: fetching ? "none" : undefined,
        width: disableFullWidth ? undefined : "100%",
        height: enableFullHeight ? "100%" : undefined,
      }}
    >
      {children}
    </div>
  );
};
