import { Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useHasAvailableTenants } from "hooks/permissions";
import { NoTenantPage } from "../noTenant";
import { useNavigateToTenantHome } from "hooks/navigation";
import { useState } from "react";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { StringSerialiserOptions } from "hooks/serialisers/types";

export const SelectTenant = () => {
  const { hasAvailableTenants, defaultTenant } = useHasAvailableTenants();

  if (defaultTenant != null) {
    return <Navigate to={`/${defaultTenant}/`} replace />;
  } else if (hasAvailableTenants) {
    return <SelectTenantInput />;
  } else {
    return <NoTenantPage />;
  }
};

const SelectTenantInput = () => {
  const [tenant, setTenant] = useState("");
  const navigate = useNavigateToTenantHome();
  const serialiser = useStringSerialiser();

  return (
    <Box sx={{ p: 4 }}>
      <ValidatedTextField<string, StringSerialiserOptions>
        serialiser={serialiser}
        value={tenant}
        setValue={setTenant}
        label="Organisation"
        onEditConfirmedWithKeyboard={(t) => navigate(t)}
        textFieldProps={{ autoFocus: true, autoComplete: "off" }}
      />
    </Box>
  );
};
