import { LoadedContent } from "src/components/common/loading/loadedContent";
import { useTargetInventoryDependencies } from "./dependencies";
import { InputModalContentLayout } from "../common/InputModalContentLayout";
import { TargetInventoryTable } from "./table";

export const TargetInventoryEditor = () => {
  return (
    <LoadedContent data={useTargetInventoryDependencies()}>
      {({ materials, targetInventory }) => (
        <InputModalContentLayout
          leftPanel={
            <TargetInventoryTable
              materials={materials}
              targetInventory={targetInventory}
            />
          }
          rightPanel={null}
          bottomPanel={null}
          removeLeftPanelOutline
        />
      )}
    </LoadedContent>
  );
};
