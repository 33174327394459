import { useTenantHash } from "hooks/settings";
import { FeatureFlag, allFeatureFlags } from "src/constants";

export const useIsBehindFeatureFlag = (featureFlag: FeatureFlag) => {
  const tenantHash = useTenantHash();

  const tenantFeatureFlags = allFeatureFlags[tenantHash];
  const appFeatureFlags = allFeatureFlags["*"];

  return (
    tenantFeatureFlags.includes(featureFlag) ||
    appFeatureFlags.includes(featureFlag)
  );
};
