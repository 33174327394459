import { MixAllocation, ProductionBlock } from "src/store/api/generatedApi";
import { BlockActions } from "./block";

export const takeHeats = (
  heats: number,
  mixes: MixAllocation[]
): [MixAllocation[], MixAllocation[]] => {
  if (heats === 0 || mixes.length === 0) {
    return [[], mixes];
  } else {
    const { heats: heatsAvailable, mix } = mixes[0]!;
    const heatsTaken = Math.min(heats, heatsAvailable);

    const [left, right] = takeHeats(heats - heatsTaken, [
      ...(heatsAvailable - heatsTaken <= 0
        ? []
        : [{ heats: heatsAvailable - heatsTaken, mix }]),
      ...mixes.slice(1),
    ]);

    return [[{ heats: heatsTaken, mix }, ...left], right];
  }
};

export const productionActions: BlockActions<ProductionBlock> = {
  split: (production, timestamp) => {
    if (production.start >= timestamp) {
      return [null, production];
    } else if (production.end <= timestamp) {
      return [production, null];
    } else {
      const left =
        (timestamp - production.start) / (production.end - production.start);
      const right = 1 - left;

      // This implementation will give undesirable results in some edge cases,
      // and so will be replaced by a more robust one later
      return [
        {
          uuid: production.uuid,
          steel_grades: production.steel_grades.map((item) => ({
            steel_grade_name: item.steel_grade_name,
            heats: Math.round(item.heats * left),
            mixes:
              item.mixes === null
                ? null
                : takeHeats(Math.round(item.heats * left), item.mixes)[0],
          })),
          start: production.start,
          end: timestamp,
          name: production.name,
          suppressed: production.suppressed,
        },
        {
          uuid: production.uuid,
          steel_grades: production.steel_grades.map((item) => ({
            steel_grade_name: item.steel_grade_name,
            heats: Math.round(item.heats * right),
            mixes:
              item.mixes === null
                ? null
                : takeHeats(Math.round(item.heats * left), item.mixes)[1],
          })),
          start: timestamp,
          end: production.end,
          name: production.name,
          suppressed: production.suppressed,
        },
      ];
    }
  },

  resolveProduction: (block) => block,
  resolveObtainable: () => null,

  applyResults: (block, results) => results ?? block,
};
