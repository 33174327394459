import { Serialiser } from "hooks/serialisers/types";
import { useMemo } from "react";

/** Seconds since 1970, UTC. */
export const currentTimestamp = (): number => parseTimestamp(new Date());

export const formatTimestamp = (timestamp: number): Date =>
  new Date(timestamp * 1000);

export const parseTimestamp = (date: Date): number =>
  Math.floor(date.getTime() / 1000);

export const useTimestampSerialiser = (): Serialiser<number, never> => {
  return useMemo(() => {
    return {
      format: (value) => {
        const iso = formatTimestamp(
          value - 60 * new Date().getTimezoneOffset()
        ).toISOString();
        return `${iso.split("T")[0]} ${iso.split("T")[1]!.split(".")[0]}`;
      },
      parse: (text) => {
        const date = new Date(text);
        if (date.toString() === "Invalid Date") {
          return { valid: false };
        } else {
          return {
            valid: true,
            value: parseTimestamp(date),
          };
        }
      },
    };
  }, []);
};
