import { LinearProgress, Stack } from "@mui/material";
import { usePlanTableDependencies } from "../dependencies";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { ProductionContext, useIsTransposed } from "./ProductionContext";
import { SearchMaterials } from "contexts/search/context";
import { ScrapXHeatYTable } from "./ScrapXHeatYTable";
import { HeatXScrapYTable } from "./HeatXScrapYTable";
import { usePeriodIndex } from "hooks/periodIndex";

export const ProductionTable = ({ planId }: { planId: number }) => {
  const dependencies = usePlanTableDependencies(planId);
  const periodTab = usePeriodIndex();

  return (
    <LoadedContent
      data={dependencies}
      disableFetching
      loading={<LinearProgress />}
      waiting={<LinearProgress />}
    >
      {(loadedDependencies) => (
        <Stack gap={2} sx={{ height: "100%" }}>
          <ProductionContext
            period={periodTab}
            planId={planId}
            productGroups={loadedDependencies.productGroups}
            chemistryGroups={loadedDependencies.chemistryGroups}
            dependencies={loadedDependencies}
            hasProductGroups
          >
            <LoadedProductionTable materials={loadedDependencies.materials} />
          </ProductionContext>
        </Stack>
      )}
    </LoadedContent>
  );
};

const sx = {
  "& .MuiDataGrid-cell": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiDataGrid-columnHeader": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .group--header": {
    px: 1,
    py: 0.5,
  },
};

const LoadedProductionTable = ({
  materials,
}: {
  materials: SearchMaterials;
}) => {
  const isTransposed = useIsTransposed();

  if (isTransposed) {
    return <ScrapXHeatYTable materials={materials.byIndex} sx={sx} />;
  } else {
    return <HeatXScrapYTable materials={materials.byIndex} sx={sx} />;
  }
};
