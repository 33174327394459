import { Stack, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { ChemicalConstraints } from "src/components/common/chemicalConstraints";
import {
  ChemicalConstraint,
  ChemicalElement,
  SteelGrade,
} from "store/api/generatedApi";
import { MixMaterialLimits } from "./materialConstraints";
import { SteelGradeChip } from "components/common/chips/steelGrade";

export const ChefGroupProperties = ({
  chemicalConstraints,
  defaultTargetNumberOfBaskets,
  maximumFailureRate,
  elements,
  steelGrades,
  chefGroupId,
}: {
  chemicalConstraints: ChemicalConstraint[];
  steelGrades: SteelGrade[];
  defaultTargetNumberOfBaskets: number;
  maximumFailureRate: number;
  elements: { [id: number]: ChemicalElement };
  chefGroupId: number;
}) => {
  const { t } = useTenantTranslation();

  return (
    <Stack gap={3} sx={{ py: 2 }}>
      <Stack gap={1}>
        <Typography>{t("chemicalTolerances")}</Typography>
        <Stack gap={0.5} flexDirection="row" flexWrap="wrap">
          <ChemicalConstraints
            chemicalConstraints={chemicalConstraints}
            elements={elements}
          />
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography>{t("defaultNumBaskets")}</Typography>
        <Typography>{defaultTargetNumberOfBaskets}</Typography>
      </Stack>
      <Stack gap={1}>
        <Typography>{t("maximumFailureRate")}</Typography>
        <Typography>{maximumFailureRate}</Typography>
      </Stack>
      <Stack gap={1}>
        <Typography>{t("steelGrades")}</Typography>
        <Stack gap={0.5} flexDirection="row" flexWrap="wrap">
          {steelGrades.map(({ id, name, chemical_constraints }) => {
            if (id !== null && id !== undefined) {
              return (
                <SteelGradeChip
                  key={id}
                  id={id}
                  name={name}
                  chemicalConstraints={chemical_constraints}
                />
              );
            } else {
              return null;
            }
          })}
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography>{t("materialLimits")}</Typography>
        <Stack gap={0.5} flexDirection="row" flexWrap="wrap">
          <MixMaterialLimits chefGroupId={chefGroupId} />
        </Stack>
      </Stack>
    </Stack>
  );
};
