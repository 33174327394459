import { useTenant } from "hooks/settings";
import {
  useImportMixAllocationFromPlanMutation,
  useRunExperimentalSearchMutation,
} from "src/store/api/generatedApi";
import { useExperimentalSearch } from "../provider";
import { useOptimiserInputs } from "./resolve";
import { useApplyMixResults } from "./apply";
import { doLoaded } from "../helpers";

export const useRunExperimentalSearch = () => {
  const optimiserInputs = useOptimiserInputs();
  const { setClient } = useExperimentalSearch();

  const [run, { isLoading }] = useRunExperimentalSearchMutation();
  const tenant = useTenant();

  return [
    () => {
      doLoaded(optimiserInputs, (inputs) => {
        run({
          tenantName: tenant,
          optimiserInputs: inputs,
        })
          .unwrap()
          .then((searchId) =>
            setClient((current) => ({ ...current, search_id: searchId }))
          )
          .catch(console.error);
      });
    },
    { loading: isLoading },
  ] as const;
};

export const useApplyMixResultsFromPlan = () => {
  const apply = useApplyMixResults();
  const optimiserInputs = useOptimiserInputs();

  const tenant = useTenant();
  const [runImport, { isLoading: loading }] =
    useImportMixAllocationFromPlanMutation();

  return [
    (planId: number) =>
      doLoaded(optimiserInputs, (inputs) => {
        runImport({ tenantName: tenant, planId, body: inputs.production })
          .unwrap()
          .then(apply)
          .catch(console.error);
      }),
    { loading },
  ] as const;
};
