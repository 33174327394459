import { MarkerBlock } from "src/store/api/generatedApi";
import { BlockActions, BlockDetail, BlockHeader, BlockView } from "./block";
import { TimelineSettings } from "../inputs/timeline";
import { DialogContent, Stack, Typography } from "@mui/material";
import { useSetBlock, useSetMarker } from "../provider";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";

export const markerActions: BlockActions<MarkerBlock> = {
  split: (block, timestamp) => {
    if (timestamp <= block.start) {
      return [null, block];
    } else if (timestamp >= block.end) {
      return [block, null];
    } else {
      return [
        { ...block, end: timestamp },
        { ...block, start: timestamp },
      ];
    }
  },

  resolveProduction: () => null,
  resolveObtainable: () => null,

  applyResults: (block) => block,
};

export const MarkerBlockView = ({
  block,
  timeline,
}: {
  block: MarkerBlock;
  timeline: TimelineSettings;
}) => {
  return (
    <BlockView
      block={block}
      timeline={timeline}
      timelineContent={<Typography>{block.name ?? block.text}</Typography>}
      detailContent={<MarkerDetailView marker={block} />}
      offset={200}
    />
  );
};

const MarkerDetailView = ({ marker }: { marker: MarkerBlock }) => {
  const serialiser = useStringSerialiser();
  const setBlock = useSetMarker(marker.uuid);

  const setText = (value: string) =>
    setBlock((current) => ({ ...current, text: value }));

  return (
    <BlockDetail>
      <BlockHeader
        block={marker}
        setBlock={useSetBlock(useSetMarker(marker.uuid))}
        name={marker.name ?? marker.text}
      />

      <DialogContent>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h6">Text</Typography>
          <ValidatedTextField
            value={marker.text}
            setValue={setText}
            serialiser={serialiser}
          />
        </Stack>
      </DialogContent>
    </BlockDetail>
  );
};
