import { useParsedSearchParameters } from "contexts/search/wizards/uploads";
import { mapLoaded, mapLoadedUnpack } from "models/loaded";
import {
  usePeriodTabTranslation,
  useTenantTranslation,
} from "hooks/formatters";
import { Loaded, liftLoadedState } from "models/loaded";
import { useMemo, useState } from "react";
import {
  SyncedProductionPlan,
  UploadedSearchParameters,
} from "store/api/generatedApi";
import {
  NoRelevantInformation,
  ImportWizard,
} from "components/common/importWizard/ImportWizard";
import { useImportWizardState } from "components/common/importWizard/state";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { Box, Typography } from "@mui/material";
import { useSyncedParametersState } from "contexts/search/parameters";
import {
  SearchProductGroups,
  SearchSteelGrades,
  useSyncedContextState,
} from "contexts/search/context";
import {
  useChemicalElements,
  useChemistryGroups,
  usePhysicalParameters,
  useProductGroups,
  useProductionPlan,
  useSteelGrades,
} from "contexts/search/provider";
import { Period, usePeriodIndex } from "hooks/periodIndex";
import { NumberSelector } from "src/components/common/numberSelector";
import { ProductionEditorContent } from "../productionEditor";
import { ProductionDependencies } from "../dependencies";

type Props = {
  period: Period;
};

export const ProductionWizard = ({ period }: Props) => {
  const { state, setState } = useImportWizardState();
  const [importFromPeriodIndex, setImportFromPeriodIndex] = useState(period);
  const importFromPeriodName = usePeriodTabTranslation()(importFromPeriodIndex);
  const { t } = useTenantTranslation();

  const periodTab = usePeriodIndex();

  const [physicalParameters] = usePhysicalParameters();

  const [productionPlan, setProductionPlan] = useProductionPlan(periodTab);

  const steelGrades = useSteelGrades();
  const chemistryGroups = useChemistryGroups();
  const productGroups = useProductGroups();
  const chemicalElements = useChemicalElements();

  const tappedMass = mapLoaded(
    physicalParameters,
    ({ target_tapped_mass_lower }) => target_tapped_mass_lower
  );

  const {
    production: importedProduction,
    productGroups: importedProductGroups,
    chemistryGroups: importedChemistryGroups,
    steelGrades: importedSteelGrades,
    chemicalElements: importedChemicalElements,
  } = useDependencies(
    state.kind === "search" && state.search != null
      ? state.search.search_id
      : null,
    importFromPeriodIndex
  );

  const uploadedProduction = useParsedSearchParameters(
    state.kind === "file" ? state.file?.id ?? null : null
  );

  const previewInputs =
    state.kind === "search"
      ? {
          productionPlan: importedProduction,
          productGroups: importedProductGroups,
          chemistryGroups: importedChemistryGroups,
          steelGrades: importedSteelGrades,
          chemicalElements: importedChemicalElements,
          tappedMass,
        }
      : {
          productionPlan: extractUploadedProduction(
            productionPlan,
            uploadedProduction
          ),
          productGroups,
          chemistryGroups,
          steelGrades,
          chemicalElements,
          tappedMass,
        };

  const approve = useProductionPreview(
    liftLoadedState({
      productionPlan,
      productGroups,
      chemistryGroups,
      steelGrades,
      chemicalElements,
      tappedMass,
    }),
    liftLoadedState(previewInputs),
    setProductionPlan
  );

  return (
    <ImportWizard
      importName="production"
      state={state}
      setState={setState}
      preview={
        <LoadedContent data={liftLoadedState({ ...previewInputs })}>
          {(inputs) =>
            (inputs.productionPlan.product_group_items ?? []).length > 0 ||
            (inputs.productionPlan.steel_grade_items ?? []).length > 0 ? (
              <ProductionEditorContent
                productionPlan={inputs.productionPlan}
                productGroups={inputs.productGroups}
                chemistryGroups={inputs.chemistryGroups}
                steelGrades={inputs.steelGrades}
                chemicalElements={inputs.chemicalElements}
                tappedMass={inputs.tappedMass}
              />
            ) : (
              <NoRelevantInformation kind={state.kind} />
            )
          }
        </LoadedContent>
      }
      topPanel={
        state.kind === "search" ? (
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
              {t("useValuesFrom")}:
            </Typography>

            <NumberSelector
              text={importFromPeriodName}
              value={importFromPeriodIndex}
              setValue={setImportFromPeriodIndex as (value: number) => void}
              minimum={1}
              maximum={null}
            />
          </Box>
        ) : null
      }
      onConfirm={approve.status === "success" ? approve.data : null}
      outputTypes={["steel_grade_plan", "product_group_plan"]}
    />
  );
};

const extractUploadedProduction = (
  current: Loaded<SyncedProductionPlan>,
  uploaded: Loaded<UploadedSearchParameters>
): Loaded<SyncedProductionPlan> =>
  mapLoaded(liftLoadedState({ current, uploaded }), (plans) => ({
    ...plans.current,
    product_group_items: null,
    steel_grade_items: plans.uploaded.steel_grade_plan ?? [],
  }));

const useProductionPreview = (
  current: Loaded<ProductionDependencies>,
  candidate: Loaded<ProductionDependencies>,
  setProduction: (
    update: (productionPlan: SyncedProductionPlan) => SyncedProductionPlan
  ) => void
): Loaded<() => void> => {
  return useMemo(() => {
    return mapLoaded(
      liftLoadedState({ current, candidate }),
      ({ current: loadedCurrent, candidate: loadedCandidate }) => {
        const convertProductGroup = productGroupConverter(
          loadedCandidate.productGroups,
          loadedCurrent.productGroups
        );

        const convertSteelGrade = steelGradeConverter(
          loadedCandidate.steelGrades,
          loadedCurrent.steelGrades
        );

        return () =>
          setProduction((plan) => ({
            ...plan,
            product_group_items:
              loadedCandidate.productionPlan.product_group_items
                ?.map((item) => ({
                  ...item,
                  product_group_id:
                    convertProductGroup(item.product_group_id) ?? -1,
                }))
                .filter((item) => item.product_group_id >= 0) ?? null,
            steel_grade_items:
              loadedCandidate.productionPlan.steel_grade_items
                ?.map((item) => ({
                  ...item,
                  steel_grade_id: convertSteelGrade(item.steel_grade_id) ?? -1,
                }))
                .filter((item) => item.steel_grade_id >= 0) ?? null,
          }));
      }
    );
  }, [current, candidate, setProduction]);
};

const productGroupConverter = (
  candidate: SearchProductGroups | null,
  current: SearchProductGroups | null
) => {
  if (candidate == null || current == null) {
    return () => null;
  } else {
    return (candidateId: number) => {
      const name = candidate.byId[candidateId]?.name;
      return name == null ? null : current.byName[name]?.id ?? null;
    };
  }
};

const steelGradeConverter =
  (candidate: SearchSteelGrades, current: SearchSteelGrades) =>
  (candidateId: number) => {
    const name = candidate.byId[candidateId]?.name;
    return name == null ? null : current.byName[name]?.id ?? null;
  };

const useDependencies = (searchId: number | null, period: number) => {
  const parameters = useSyncedParametersState(searchId).client;
  const context = useSyncedContextState(
    mapLoadedUnpack(parameters, ({ context_id }) => context_id)
  );

  return {
    production: mapLoaded(parameters, ({ periods }) => {
      const plan = periods[period - 1]?.production_plan ?? {
        product_group_items: [],
        steel_grade_items: null,
      };
      const sourcedPlan: SyncedProductionPlan = {
        product_group_items: plan.product_group_items,
        steel_grade_items: plan.steel_grade_items,
      };
      return sourcedPlan;
    }),
    productGroups: mapLoaded(context, ({ productGroups }) => productGroups),
    chemistryGroups: mapLoaded(
      context,
      ({ chemistryGroups }) => chemistryGroups
    ),
    steelGrades: mapLoaded(context, ({ steelGrades }) => steelGrades),
    chemicalElements: mapLoaded(
      context,
      ({ chemicalElements }) => chemicalElements
    ),
  };
};
