export class InvalidDate extends Error {}

export const validateDate = (dateString: string) => {
  const date = Date.parse(dateString);
  if (isNaN(date)) {
    throw new InvalidDate(dateString);
  } else {
    return new Date(date);
  }
};
