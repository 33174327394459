import {
  OptimisationSlackVariableUsage,
  SlackVariableEnum,
} from "src/store/api/generatedApi";

export const groupOptimisationSlackVariableUsage = (
  optimisationSlackVariableUsage: OptimisationSlackVariableUsage[]
): Record<SlackVariableEnum, OptimisationSlackVariableUsage[]> => {
  return optimisationSlackVariableUsage.reduce(
    (usage, record) => {
      return {
        ...usage,
        [record.slack_variable]: usage[record.slack_variable]
          ? [...usage[record.slack_variable], record]
          : [record],
      };
    },
    {} as Record<SlackVariableEnum, OptimisationSlackVariableUsage[]>
  );
};
