import { Box, Toolbar } from "@mui/material";
import { Navbar } from "./navBar/NavBar";
import { ReactNode } from "react";
import { useAppBarHeight } from "hooks/useAppBarHeight";

type Props = {
  children: ReactNode;
};
export const NavBarLayout = ({ children }: Props) => {
  const appBarHeight = useAppBarHeight();

  return (
    <>
      <Navbar />
      <Toolbar />

      <Box
        sx={{
          height: `calc(100vh - ${appBarHeight}px)`,
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </>
  );
};
