import { Button } from "@mui/material";
import { useSearchId } from "hooks/search";
import { useNavigateToPlan } from "hooks/navigation";
import { ZoomOutMap } from "@mui/icons-material";
import { useTenantTranslation } from "hooks/formatters";
import { usePlanId } from "../../common/boundary/PlanId";

export const OpenInPage = () => {
  const { t } = useTenantTranslation();
  const navigateToPlan = useNavigateToPlan();

  const planId = usePlanId();
  const searchId = useSearchId();
  return (
    <Button
      startIcon={<ZoomOutMap />}
      onClick={() => {
        if (searchId !== null) {
          navigateToPlan(planId, searchId);
        }
      }}
    >
      {t("openInPage")}
    </Button>
  );
};
