import { Stack, Typography, useTheme, CircularProgress } from "@mui/material";

export const Deploying = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        justifyContent: "center",
        height: "100%",
        margin: "0 auto",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Stack sx={{ gap: 2, alignItems: "center", width: 1 }}>
        <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
          Deploying...
        </Typography>
        <CircularProgress />
      </Stack>
    </Stack>
  );
};
