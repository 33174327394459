import { Box, Skeleton, Stack, Typography } from "@mui/material";

export const PlanCardSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        p: 2,
        border: 1,
        borderRadius: 1,
        borderColor: "grey.100",
      }}
    >
      <Stack direction="row" justifyContent="flex-start" gap={3}>
        <SkeletonMetric />
        <SkeletonMetric />
        <SkeletonMetric />
      </Stack>
    </Box>
  );
};

const SkeletonMetric = () => {
  return (
    <Stack>
      <Typography variant="body1Mono">
        <Skeleton width={60} />
      </Typography>
      <Typography variant="body2">
        <Skeleton width={125} />
      </Typography>
    </Stack>
  );
};
