import { Accordion, AccordionDetails, Stack } from "@mui/material";
import { SearchSummary } from "store/api/generatedApi";
import { HistoryItemTitle } from "./HistoryItemTitle";
import { HistoryChildItem } from "./HistoryChildItem";
import { useState } from "react";

type Props = {
  versionNumber: number;
  search: SearchSummary;
  searchChildren: SearchSummary[];
  currentSearchId: number;
  onSelect: (search: SearchSummary) => void;
};

export const HistoryItem = ({
  versionNumber,
  search,
  searchChildren,
  currentSearchId,
  onSelect,
}: Props) => {
  const hasSearchChildren = searchChildren.length > 0;

  const [hover, setHover] = useState(false);

  return (
    <Accordion
      key={search.search_id}
      TransitionProps={{ unmountOnExit: true, timeout: 200 }}
      sx={{
        py: 1,
        "&:hover": {
          backgroundColor: "action.hover",
          borderRadius: 1,
        },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <HistoryItemTitle
        versionNumber={versionNumber}
        search={search}
        hasSearchChildren={hasSearchChildren}
        currentSearchId={currentSearchId}
        hover={hover}
        onSelect={onSelect}
      />
      {hasSearchChildren ? (
        <AccordionDetails sx={{ pt: 0, pb: 0, pl: 4 }}>
          <Stack>
            {searchChildren.map((search) => (
              <HistoryChildItem search={search} key={search.search_id} />
            ))}
          </Stack>
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};
