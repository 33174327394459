import { Loaded, liftLoadedState } from "models/loaded";
import {
  PlanMixBasket,
  NamedPlanMix,
  ProductGroup,
  SteelGrade,
  useGetPlanMixesQuery,
} from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";
import { mapLoaded } from "models/loaded";
import {
  SearchMaterials,
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import {
  useMaterials,
  useProductGroups,
  useSteelGrades,
} from "contexts/search/provider";
import { usePlanId } from "components/common/boundary/PlanId";
import { Period } from "hooks/periodIndex";

export type MaterialMass = {
  id: number;
  mass: number;
  name: string;
};

export type CandidateBasket = {
  total: number;
  materialMasses: Record<number, MaterialMass>;
};

export type CandidateMix = {
  mixId: number;
  mixName: string;
  numberOfHeats: number;
  steelGrades: SteelGrade[];
  productGroup: ProductGroup;
  dri: CandidateBasket;
  baskets: CandidateBasket[];
  totals: CandidateBasket;
  period: Period;
  pricePerTonne: number;
  targetTappedMass: number;
};

export const useCandidateMixes = (): Loaded<CandidateMix[]> => {
  const planMixes = usePlanMixesFromBackend();
  const productGroups = useProductGroups();
  const steelGrades = useSteelGrades();
  const materials = useMaterials();

  return mapLoaded(
    liftLoadedState({ planMixes, productGroups, steelGrades, materials }),
    planMixesToCandidateMixes
  );
};

const usePlanMixesFromBackend = (): Loaded<NamedPlanMix[]> => {
  const planId = usePlanId();
  const tenant = useTenant();

  const { data, isFetching, isError } = useGetPlanMixesQuery({
    tenantName: tenant,
    planId,
    period: 1,
  });

  if (isError) {
    return { status: "error" };
  } else if (data == null) {
    return { status: "loading" };
  } else {
    return { status: "success", data, fetching: isFetching };
  }
};

const planMixesToCandidateMixes = ({
  planMixes,
  productGroups,
  steelGrades,
  materials,
}: {
  planMixes: NamedPlanMix[];
  productGroups: SearchProductGroups | null;
  steelGrades: SearchSteelGrades;
  materials: SearchMaterials;
}): CandidateMix[] => {
  if (productGroups == null) {
    throw new Error(
      "Plan mixes cannot be called in contexts that have no product groups"
    );
  }

  return planMixes.map((mix) => ({
    period: mix.period as Period,
    mixId: mix.mix_id,
    mixName: mix.mix_name,
    numberOfHeats: mix.number_of_heats,
    steelGrades: mix.steel_grade_ids.map((id) => steelGrades.byId[id]!),
    productGroup: productGroups.byId[mix.product_group_id]!,
    dri: reformatBasket(mix.dri, materials),
    baskets: mix.baskets.map((basket) => reformatBasket(basket, materials)),
    totals: reformatBasket(mix.totals, materials),
    pricePerTonne: mix.display_price_per_tonne,
    targetTappedMass: mix.target_tapped_mass,
  }));
};

const reformatBasket = (
  basket: PlanMixBasket,
  materials: SearchMaterials
): CandidateBasket => {
  return {
    total: basket.total_mass,
    materialMasses: Object.fromEntries(
      Object.entries(basket.material_masses).map(
        ([materialId, materialMass]) => [
          parseInt(materialId),
          {
            id: parseInt(materialId),
            mass: materialMass,
            name: materials.byId[parseInt(materialId)]!.name,
          },
        ]
      )
    ),
  };
};
