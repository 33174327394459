import {
  ChemicalConstraintCoefficient,
  ChemicalElement,
} from "src/store/api/generatedApi";

export const chemicalExpressionFromConstraintCoefficients = (
  coefficients: ChemicalConstraintCoefficient[],
  elements: { [id: number]: ChemicalElement }
) =>
  coefficients
    .map((coefficient) =>
      coefficient.coefficient == 1
        ? elements[coefficient.chemical_element.id]!.symbol
        : `${coefficient.coefficient}${
            elements[coefficient.chemical_element.id]!.symbol
          }`
    )
    .join(" + ");
