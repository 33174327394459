import { Box, Divider, Typography } from "@mui/material";
import {
  useInputs,
  useExperimentalMaterialPhysics,
  useExperimentalMaterials,
} from "../provider";
import { liftLoadedState } from "models/loaded";
import { useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { sorted } from "helpers";
import { formatTimestamp } from "../timestamps";
import { ProductionBlockView } from "./production";
import { InventoryDetailButton, TimestampInventoryDetail } from "./inventory";
import { useHotkeys } from "react-hotkeys-hook";
import { ObtainableBlockView } from "./obtainable";
import { derivedProperties, useWindowDimensions } from "../helpers";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { ConsumptionSummary } from "../result/summarisation";
import { MarkerBlockView } from "../blocks/marker";
import { useImportFromClipboard } from "./importing";
import { LoadedContent } from "components/common/loading/loadedContent";
import { ExperimentalInputs } from "src/store/api/generatedApi";
import { inventoryProjection } from "../processing/projection";
import {
  resolvedObtainableBlocks,
  resolvedProductionBlocks,
} from "../processing/resolve";

export type Setter<T> = (update: (current: T) => T) => void;

type SelectedRange = {
  startTimestamp: number;
  endTimestamp: number;
  editing: boolean;
};

export const InputsTab = () => {
  const [customerInputs, setCustomerInputs] = useInputs();
  const materials = useExperimentalMaterials();
  const materialPhysics = useExperimentalMaterialPhysics();
  const [importFromClipboard, { loading }] = useImportFromClipboard();

  const [selectedRange, setSelectedRange] = useState<SelectedRange | null>(
    null
  );

  const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    void importFromClipboard(event);
  };

  return (
    <div onPaste={onPaste}>
      <LoadedContent data={liftLoadedState({ customerInputs })}>
        {(loaded) => (
          <Timeline
            customerInputs={loaded.customerInputs}
            setCustomerInputs={setCustomerInputs}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
        )}
      </LoadedContent>

      {loading ? <Typography>Importing...</Typography> : null}

      <InventoryDetailButton />

      <LoadedContent
        data={liftLoadedState({ customerInputs, materials, materialPhysics })}
      >
        {(loaded) =>
          selectedRange === null ? null : (
            <ConsumptionSummary
              start={Math.min(
                selectedRange.startTimestamp,
                selectedRange.endTimestamp
              )}
              end={Math.max(
                selectedRange.startTimestamp,
                selectedRange.endTimestamp
              )}
              inventory={loaded.customerInputs.inventory}
              resolvedProduction={resolvedProductionBlocks(
                loaded.customerInputs
              )}
              resolvedObtainable={resolvedObtainableBlocks(
                loaded.customerInputs
              )}
              materials={loaded.materials}
              materialPhysics={loaded.materialPhysics}
            />
          )
        }
      </LoadedContent>
    </div>
  );
};

export type TimelineSettings = {
  width: number;
  height: number;
  start: number;
  days: number;
};

export const Timeline = ({
  customerInputs,
  setCustomerInputs,
  selectedRange,
  setSelectedRange,
}: {
  customerInputs: ExperimentalInputs;
  setCustomerInputs: Setter<ExperimentalInputs>;
  selectedRange: SelectedRange | null;
  setSelectedRange: Setter<SelectedRange | null>;
}) => {
  const { format: formatMass } = useNumberSerialiser({ decimalPlaces: 0 });
  const properties = useMemo(
    () => derivedProperties(customerInputs),
    [customerInputs]
  );

  const timeline: TimelineSettings = {
    width: useWindowDimensions().width,
    height: 900,
    start: properties.start - 3600 * 6,
    days: (properties.end - properties.start) / 86400 + 0.5,
  };

  const [mouse, setMouse] = useState<{ x: number; y: number } | null>(null);
  const [inventoryTimestamp, setInventoryTimestamp] = useState<number | null>(
    null
  );

  const materials = useExperimentalMaterials();

  const ref = useRef<HTMLDivElement>();

  const periods = sorted(
    [
      {
        name: "Inventory measured",
        timestamp: customerInputs.inventory.timestamp,
        uuid: "",
      },
      ...customerInputs.periods,
    ],
    (period) => period.timestamp
  );

  useHotkeys("p", () => {
    if (mouse !== null) {
      setCustomerInputs((current) => ({
        ...current,
        production: [
          ...current.production,
          {
            start: positionTimestamp(timeline, mouse.x),
            end: positionTimestamp(timeline, mouse.x + 100),
            steel_grades: [],
            uuid: v4(),
            name: null,
            suppressed: false,
          },
        ],
      }));
    }
  });
  useHotkeys("o", () => {
    if (mouse !== null) {
      setCustomerInputs((current) => ({
        ...current,
        obtainable: [
          ...current.obtainable,
          {
            uuid: v4(),
            name: null,
            suppressed: false,
            start: positionTimestamp(timeline, mouse.x),
            end: positionTimestamp(timeline, mouse.x + 100),
            bundles: [],
          },
        ],
      }));
    }
  });
  useHotkeys("i", () => {
    if (mouse !== null) {
      setInventoryTimestamp(positionTimestamp(timeline, mouse.x));
    }
  });
  useHotkeys("escape", () => setSelectedRange(() => null));

  return (
    <Box
      sx={{
        backgroundColor: "lightgrey",
        width: timeline.width,
        height: timeline.height,
        position: "relative",
      }}
      onMouseLeave={() => setMouse(null)}
      onMouseMove={(event) => {
        if (ref.current) {
          const { left, top } = ref.current.getBoundingClientRect();
          const newMouse = { x: event.clientX - left, y: event.clientY - top };
          setMouse(newMouse);

          if (event.buttons === 1) {
            setSelectedRange((current) => ({
              startTimestamp:
                current?.editing === false
                  ? positionTimestamp(timeline, newMouse.x)
                  : current?.startTimestamp ??
                    positionTimestamp(timeline, newMouse.x),
              endTimestamp: positionTimestamp(timeline, newMouse.x),
              editing: true,
            }));
          }
        }
      }}
      onDoubleClick={() => {
        if (mouse !== null) {
          setCustomerInputs((current) => ({
            ...current,
            periods: [
              ...current.periods,
              { name: null, timestamp: positionTimestamp(timeline, mouse.x) },
            ],
          }));
        }
      }}
      onMouseUp={() =>
        setSelectedRange((current) =>
          current === null ? null : { ...current, editing: false }
        )
      }
      ref={ref}
    >
      {selectedRange === null ? null : (
        <Box
          sx={{
            backgroundColor: "lightblue",
            position: "absolute",
            left: timestampPosition(
              timeline,
              Math.min(selectedRange.startTimestamp, selectedRange.endTimestamp)
            ),
            top: 0,
            bottom: 0,
            width:
              timestampPosition(
                timeline,
                Math.max(
                  selectedRange.startTimestamp,
                  selectedRange.endTimestamp
                )
              ) -
              timestampPosition(
                timeline,
                Math.min(
                  selectedRange.startTimestamp,
                  selectedRange.endTimestamp
                )
              ),
          }}
        />
      )}

      {mouse ? (
        <>
          <Divider
            orientation="vertical"
            sx={{ position: "absolute", left: mouse.x, borderStyle: "dashed" }}
          />
          <Typography
            sx={{
              position: "absolute",
              left: mouse.x,
              ml: 1,
              bottom: 0,
              userSelect: "none",
            }}
          >
            <i>
              {formatMass(
                Object.values(
                  inventoryProjection(
                    customerInputs.inventory,
                    resolvedProductionBlocks(customerInputs),
                    resolvedObtainableBlocks(customerInputs),
                    positionTimestamp(timeline, mouse.x)
                  ).inventory.map((item) => item.mass ?? 0)
                ).reduce((left, right) => left + right, 0)
              )}
              t
            </i>
          </Typography>
        </>
      ) : null}

      {properties.dates.map((date) => (
        <Box key={date}>
          <Typography
            sx={{
              position: "absolute",
              left: timestampPosition(timeline, date),
              ml: 1,
              userSelect: "none",
            }}
            onClick={() => {
              setSelectedRange(() => ({
                startTimestamp: date,
                endTimestamp: Math.min(
                  date + 86400,
                  timeline.start + 86400 * timeline.days
                ),
                editing: false,
              }));
            }}
          >
            {formatTimestamp(date).toDateString()}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              left: timestampPosition(timeline, date),
              borderColor: "rgb(158, 154, 153)",
            }}
          />
        </Box>
      ))}

      {periods.map((period, index) => (
        <Box key={period.timestamp}>
          <Typography
            sx={{
              position: "absolute",
              left: timestampPosition(timeline, period.timestamp),
              top: 20,
              ml: 1,
              userSelect: "none",
              fontWeight: 800,
            }}
            onDoubleClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setCustomerInputs((current) => ({
                ...current,
                periods: current.periods.filter(
                  (item) => item.timestamp !== period.timestamp
                ),
              }));
            }}
            onClick={() => {
              const laterPeriods = periods.filter(
                (p) => p.timestamp > period.timestamp
              );
              setSelectedRange(() => ({
                startTimestamp: period.timestamp,
                endTimestamp:
                  laterPeriods.length === 0
                    ? timeline.start + timeline.days * 86400
                    : laterPeriods[0]!.timestamp,
                editing: false,
              }));
            }}
          >
            {period.name ?? `Period ${index + 1}`}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              left: timestampPosition(timeline, period.timestamp),
              borderColor: "rgba(68, 65, 200, 1)",
              borderWidth: 1.5,
            }}
          />
        </Box>
      ))}

      {customerInputs.production.map((production) => (
        <ProductionBlockView
          key={production.uuid}
          production={production}
          timeline={timeline}
          setCustomerInputs={setCustomerInputs}
        />
      ))}

      {customerInputs.obtainable.map((obtainable) => (
        <ObtainableBlockView
          key={obtainable.uuid}
          obtainable={obtainable}
          timeline={timeline}
          setInputs={setCustomerInputs}
        />
      ))}

      {customerInputs.markers.map((block) => (
        <MarkerBlockView key={block.uuid} block={block} timeline={timeline} />
      ))}

      {inventoryTimestamp === null ? null : (
        <LoadedContent data={materials}>
          {(loadedMaterials) => (
            <TimestampInventoryDetail
              inputs={customerInputs}
              close={() => setInventoryTimestamp(null)}
              materials={loadedMaterials}
              timestamp={inventoryTimestamp}
            />
          )}
        </LoadedContent>
      )}
    </Box>
  );
};

export const timestampPosition = (
  timeline: TimelineSettings,
  timestamp: number
): number =>
  (timestamp - timeline.start) * (timeline.width / (86400 * timeline.days));

export const positionTimestamp = (
  timeline: TimelineSettings,
  position: number
): number =>
  Math.round(
    timeline.start + timeline.days * 86400 * (position / timeline.width)
  );
