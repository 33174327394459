import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { ExportPlanState } from "./state";
import { Setter } from "hooks/state/syncing";

export const AwaitingConfirmationSuccess = ({
  setState,
}: {
  setState: Setter<ExportPlanState>;
}) => {
  const { t } = useTenantTranslation();

  const handleConfirm = () => {
    setState((current) => ({
      ...current,
      stage: "sendSms",
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
      }}
    >
      <Stack
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <CheckCircle sx={{ color: "success.main", height: 28, width: 28 }} />
        <Typography variant="h5" sx={{ mt: 1 }}>
          {t("successSyncingWithLevel2")}
        </Typography>
        <Button variant="contained" onClick={handleConfirm} sx={{ mt: 3 }}>
          {t("smsToYard")}
        </Button>
      </Stack>
    </Box>
  );
};
