import { createSlice } from "@reduxjs/toolkit";
import { loadLanguage } from "../persistence";

export type TenantPermissions = {
  [tenant_name: string]: string[];
};

export type SettingsState = {
  locale: string;
  displayName: string;
  userUid: string | null;
  email: string | null;
  tenantPermissions: TenantPermissions;
  isAdmin: boolean;
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    locale: loadLanguage() ?? navigator.language,
    tenantPermissions: {},
    displayName: "",
  } as SettingsState,
  reducers: {
    languageSelected: (state, action: { payload: string }) => {
      state.locale = action.payload;
    },
    tenantPermissionUpdate: (state, action: { payload: TenantPermissions }) => {
      state.tenantPermissions = action.payload;
    },
    adminUpdate: (state, action: { payload: boolean }) => {
      state.isAdmin = action.payload;
    },
    userUidUpdate: (state, action: { payload: string }) => {
      state.userUid = action.payload;
    },
    displayNameUpdate: (state, action: { payload: string }) => {
      state.displayName = action.payload;
    },
    emailUpdate: (state, action: { payload: string }) => {
      state.email = action.payload;
    },
  },
});

export const {
  languageSelected,
  tenantPermissionUpdate,
  adminUpdate,
  userUidUpdate,
  displayNameUpdate,
  emailUpdate,
} = settingsSlice.actions;
