import { Button, LinearProgress } from "@mui/material";
import { InsertDriveFile } from "@mui/icons-material";
import { useState } from "react";
import { useTenantTranslation } from "hooks/formatters";
import { ImportWizardLayout } from "components/common/importWizard/ImportWizard";
import { ImportFromFileState } from "components/common/importWizard/state";
import { ImportFromFile } from "components/common/importWizard/fromFile/ImportFromFile";
import { useParsedAvailability } from "contexts/search/wizards/uploads";
import { liftLoadedState, mapLoadedUnpack } from "models/loaded";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { AvailabilitiesTable } from "./AvailabilitiesTable";
import { removeMaterialsFromOtherSets } from "./preview";
import {
  useMaterialPhysics,
  useMaterials,
  useObtainableBundles,
} from "contexts/search/provider";
import { usePeriodIndex } from "hooks/periodIndex";

export const ImportAvailabilityFromFileButton = () => {
  const { t } = useTenantTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<InsertDriveFile />}
        onClick={() => setOpen(true)}
      >
        {t("importFromFile")}
      </Button>

      {open ? (
        <ImportAvailabilityFromFileModal close={() => setOpen(false)} />
      ) : null}
    </>
  );
};

const ImportAvailabilityFromFileModal = ({ close }: { close: () => void }) => {
  const { t } = useTenantTranslation();

  const [file, setFile] = useState<ImportFromFileState>({
    kind: "file",
    file: null,
    type: null,
  });

  const period = usePeriodIndex();

  const materials = useMaterials();
  const [, setObtainableBundles] = useObtainableBundles(period);

  const preview = removeMaterialsFromOtherSets(
    materials,
    useParsedAvailability(file.file?.id ?? null)
  );

  return (
    <ImportWizardLayout
      title={t("importFromFile")}
      explorer={
        <ImportFromFile
          state={file}
          setState={setFile}
          outputTypes={["material_inputs"]}
        />
      }
      preview={
        <LoadedContent
          data={liftLoadedState({
            materials,
            materialPhysics: useMaterialPhysics(),
            preview,
          })}
          waiting={null}
          loading={<LinearProgress />}
        >
          {({
            materials: loadedMaterials,
            preview: loadedPreview,
            materialPhysics,
          }) => (
            <AvailabilitiesTable
              materials={loadedMaterials}
              materialPhysics={materialPhysics}
              obtainableBundleItems={loadedPreview}
              selectedItemIds={[]}
              setSelectedItemIds={() => null}
              readOnly
            />
          )}
        </LoadedContent>
      }
      close={close}
      onConfirm={() => {
        const bundles = mapLoadedUnpack(preview, (p) => p);
        if (bundles != null) {
          setObtainableBundles(() => bundles);
        }
      }}
    />
  );
};
