import { AutoFixHigh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { EstimateProductionCallback } from "contexts/search/wizards/estimateProduction";
import { useTenantTranslation } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useState } from "react";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";

type Props = {
  estimate: EstimateProductionCallback;
  inputMode: "productGroups" | "steelGrades" | null;
};

export const ProductionEstimator = ({ estimate, inputMode }: Props) => {
  const { t } = useTenantTranslation();

  const serialiser = useNumberSerialiser({
    decimalPlaces: 0,
    min: 0,
    default: { text: "", value: null },
  });

  const [estimateProduction, { running }] = estimate;

  const [heats, setHeats] = useState<number | null>(null);

  const disabled =
    inputMode === null ||
    heats === null ||
    estimateProduction.status !== "success";
  const handleEstimate = () => {
    if (!disabled) {
      void estimateProduction.data(heats, inputMode);
      setHeats(null);
    }
  };

  return (
    <Box sx={{ border: 1, borderRadius: 1 }}>
      <Box sx={{ display: "flex", borderBottom: 1, px: 2, py: 0.5 }}>
        <AutoFixHigh sx={{ mr: 1 }} />
        <Typography>{t("quickFill")}</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography sx={{ mb: 2 }}>
          {inputMode === "steelGrades"
            ? t("estimateProductionForProducts")
            : t("estimateProductionForProductGroups")}
        </Typography>
        <Grid
          container
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <ValidatedTextField
              value={heats}
              setValue={setHeats}
              serialiser={serialiser}
              textFieldProps={{
                fullWidth: false,
                placeholder: t("totalHeats"),
                color: "secondary",
                sx: {
                  maxWidth: 120,
                },
              }}
              liveUpdate
              onEditConfirmedWithKeyboard={handleEstimate}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={disabled ? t("estimateForMeTooltip") : ""}
              arrow
              placement="bottom"
              sx={{ maxWidth: 220 }}
            >
              <Box>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                  loading={running}
                  onClick={handleEstimate}
                >
                  {t("estimateForMe")}
                </LoadingButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
