import { LoadedContent } from "src/components/common/loading/loadedContent";
import { useSessionSearches } from "./hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { SessionSummary } from "store/api/generatedApi";
import { ExpandMore } from "@mui/icons-material";
import { SelectedSearch } from ".";
import { SearchItem } from "./SearchItem";

type Props = {
  session: SessionSummary;
  selectedSearch: SelectedSearch | null;
  setSelectedSearch: ((selectedSearch: SelectedSearch) => void) | null;
};

export const SessionItem = ({
  session,
  selectedSearch,
  setSelectedSearch,
}: Props) => {
  return (
    <Accordion
      key={session.session_id}
      TransitionProps={{ unmountOnExit: true, timeout: 200 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          px: 0,
          minHeight: 32,
          "& .MuiAccordionSummary-content": {
            my: 0,
          },
        }}
      >
        <Typography variant="h5">{session.name}</Typography>
      </AccordionSummary>
      <SessionItemDetails
        session={session}
        selectedSearch={selectedSearch}
        setSelectedSearch={setSelectedSearch}
      />
    </Accordion>
  );
};

const SessionItemDetails = ({
  session,
  selectedSearch,
  setSelectedSearch,
}: Props) => {
  // This is extracted out into a separate component to prevent all the requests
  // from firing at once when a large list of sessions is loaded.  For some
  // reason, the `unmountOnExit` argument in the above accordion is not
  // sufficient to guarantee this on its own - probably because the hook is
  // already run before the children mount, even though it's *technically* only
  // called in the child
  return (
    <AccordionDetails sx={{ pt: 0, pb: 0, pl: 0.5, borderLeft: 1, pr: 0 }}>
      <LoadedContent data={useSessionSearches(session.session_id)}>
        {(searches) => (
          <Stack>
            {searches.map(({ leaf }, index) => (
              <SearchItem
                key={leaf.search_id}
                search={leaf}
                parentSessionId={session.session_id}
                versionNumber={searches.length - index}
                selectedSearch={selectedSearch}
                setSelectedSearch={setSelectedSearch}
              />
            ))}
          </Stack>
        )}
      </LoadedContent>
    </AccordionDetails>
  );
};
