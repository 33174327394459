import { Alert, Button, Typography } from "@mui/material";
import { useMiscParams } from "contexts/search/provider";
import { useTenantTranslation } from "hooks/formatters";

type InputsSuboptimalAlertProps = {
  previousTimeLimit: number;
};

export const InputsSuboptimalAlert = ({
  previousTimeLimit,
}: InputsSuboptimalAlertProps) => {
  const { t } = useTenantTranslation();
  const [, setMiscParams] = useMiscParams();

  const handleOnClick = () => {
    setMiscParams((current) => ({
      ...current,
      optimisation_time_limit: 2 * previousTimeLimit,
    }));
  };

  return (
    <Alert
      severity="warning"
      sx={{
        "& .MuiAlert-action": {
          pt: "2px",
        },
      }}
      action={
        <Button onClick={handleOnClick} color="secondary">
          {t("doubleTimeLimit")}
        </Button>
      }
    >
      <Typography variant="body1">
        {t("inputsSuboptimalDescription")}
      </Typography>
    </Alert>
  );
};
