import Decimal from "decimal.js";
import { parseLocalisedNumber } from "hooks/serialisers/numbers";
import { SupportedLanguage } from "src/store/api/generatedApi";

type ParseOptions = {
  decimalPlaces: number;
  minimum?: number;
  maximum?: number;
};

export class MinimumExceededError extends Error {
  constructor(minimum: number, value: number) {
    super(`Minimum ${minimum} exceeded by ${value}`);
  }
}

export class MaximumExceededError extends Error {
  constructor(maximum: number, value: number) {
    super(`Maximum ${maximum} exceeded by ${value}`);
  }
}

export class ParseError extends Error {
  constructor(value: string) {
    super(`${value} is not a parseable number`);
  }
}

export const parseNumber =
  (locale: SupportedLanguage) =>
  ({ decimalPlaces, minimum = -Infinity, maximum = Infinity }: ParseOptions) =>
  (value: string): number => {
    const number = parseLocalisedNumber(locale, value);
    if (number !== null) {
      if (number > maximum) {
        throw new MaximumExceededError(maximum, number);
      }
      if (number < minimum) {
        throw new MinimumExceededError(minimum, number);
      }

      // Add 0 here to make any -0 be +0
      return new Decimal(number + 0).toDecimalPlaces(decimalPlaces).toNumber();
    } else {
      throw new ParseError(value);
    }
  };
