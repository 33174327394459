/** Functions for converting plant-level inputs to optimiser-level inputs. */

import {
  ExperimentalInputs,
  ExperimentalSearch,
  ObtainableBlock,
  ObtainableBundleItem,
  OptimiserInputs,
  ProductionBlock,
  SteelGradePlanItem,
} from "src/store/api/generatedApi";
import { productionActions } from "../blocks/production";
import { SearchMaterials, SearchSteelGrades } from "contexts/search/context";
import { obtainableActions } from "../blocks/obtainable";
import { mapLoaded, Loaded, liftLoadedState } from "models/loaded";
import {
  useExperimentalMaterials,
  useExperimentalSearch,
  useExperimentalSteelGrades,
} from "../provider";
import { sorted } from "helpers";
import { inventoryProjection } from "./projection";
import { sliceBlocks } from "../blocks/block";
import { useMemo } from "react";

export type ResolvedInputs = {
  production: ProductionBlock[];
  obtainable: ObtainableBlock[];
};

export const resolvedProductionBlocks = (
  inputs: ExperimentalInputs
): ProductionBlock[] => [
  ...inputs.production
    .filter((block) => !block.suppressed)
    .map(productionActions.resolveProduction)
    .filter((block): block is ProductionBlock => block !== null),
];

export const resolvedObtainableBlocks = (
  inputs: ExperimentalInputs
): ObtainableBlock[] => [
  ...inputs.obtainable
    .filter((block) => !block.suppressed)
    .map(obtainableActions.resolveObtainable)
    .filter((block): block is ObtainableBlock => block !== null),
];

export const useResolvedInputs = (): Loaded<ResolvedInputs> => {
  const { client } = useExperimentalSearch();
  return mapLoaded(client, ({ inputs }) => ({
    production: resolvedProductionBlocks(inputs),
    obtainable: resolvedObtainableBlocks(inputs),
  }));
};

export const buildOptimiserInputs = (
  search: ExperimentalSearch,
  materials: SearchMaterials,
  steelGrades: SearchSteelGrades
): OptimiserInputs => {
  const periods = sorted(
    search.inputs.periods.map((period) => period.timestamp),
    (timestamp) => timestamp
  );

  const production = resolvedProductionBlocks(search.inputs);
  const obtainable = resolvedObtainableBlocks(search.inputs);

  const finalTimestamp =
    Math.max(
      ...production.map((block) => block.end),
      ...obtainable.map((block) => block.end)
    ) + 3600;

  const periodIntervals = periods.map((start, index) => ({
    start,
    end: periods[index + 1] ?? finalTimestamp,
  }));

  return {
    session_id: search.session_id,
    context_id: search.context_id,
    inventory: inventoryProjection(
      search.inputs.inventory,
      production,
      obtainable,
      periodIntervals[0]!.start
    )
      .inventory.filter(
        (item) => materials.byName[item.material_name] !== undefined
      )
      .map((item) => ({
        material_id: materials.byName[item.material_name]!.id,
        quantity: item.mass,
        specific_price: item.specific_price,
        previous_quantity: null,
        deliveries_since_previous: null,
        consumption_since_previous: null,
        projected_consumption_since_previous: null,
        projected_deliveries_since_previous: null,
      })),
    production: periodIntervals.flatMap(({ start, end }, index) =>
      sliceBlocks(production, start, end, productionActions).map((block) => ({
        period: index + 1,
        source_uuid: block.uuid,
        production_plan: resolveProductionToOptimiser(block, steelGrades),
      }))
    ),
    obtainable: periodIntervals.flatMap(({ start, end }, index) =>
      sliceBlocks(obtainable, start, end, obtainableActions).map((block) => ({
        period: index + 1,
        source_uuid: block.uuid,
        obtainable_bundles: resolveObtainableToOptimiser(block, materials),
      }))
    ),
  };
};

const resolveProductionToOptimiser = (
  production: ProductionBlock,
  steelGrades: SearchSteelGrades
): SteelGradePlanItem[] => {
  const steelGradeQuantity = production.steel_grades.reduce<
    Record<string, number>
  >(
    (acc, item) => ({
      ...acc,
      [item.steel_grade_name]: (acc[item.steel_grade_name] ?? 0) + item.heats,
    }),
    {}
  );

  return Object.entries(steelGradeQuantity)
    .filter(
      ([steelGradeName]) => steelGrades.byName[steelGradeName] !== undefined
    )
    .map(([steelGradeName, heats]) => ({
      steel_grade_id: steelGrades.byName[steelGradeName]!.id,
      num_heats: heats,
    }));
};

const resolveObtainableToOptimiser = (
  obtainable: ObtainableBlock,
  materials: SearchMaterials
): ObtainableBundleItem[] =>
  obtainable.bundles
    .filter((bundle) => materials.byName[bundle.material_name] !== undefined)
    .map((bundle) => {
      const materialId = materials.byName[bundle.material_name]!.id;
      const uuid = `${materialId} ${obtainable.uuid}`;
      return {
        uuid,
        material_id: materialId,
        created_at: new Date().toISOString(),
        label: uuid,
        min_obtainable: bundle.minimum_quantity,
        max_obtainable: bundle.maximum_quantity,
        specific_price: bundle.specific_price,
        arrival_probability: 1.0,
      };
    });

export const useOptimiserInputs = (): Loaded<OptimiserInputs> => {
  const { client: search } = useExperimentalSearch();
  const materials = useExperimentalMaterials();
  const steelGrades = useExperimentalSteelGrades();

  return useMemo(
    () =>
      mapLoaded(liftLoadedState({ search, steelGrades, materials }), (loaded) =>
        buildOptimiserInputs(
          loaded.search,
          loaded.materials,
          loaded.steelGrades
        )
      ),
    [search, steelGrades, materials]
  );
};
