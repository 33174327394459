import { useTranslation } from "react-i18next";
import { useNumberSerialiser } from "./serialisers/numbers";

export const useHeatTextFormatter = () => {
  const { t } = useTranslation();
  const { format } = useNumberSerialiser();

  return (multipleMixes: boolean, name: string, mix: number) => {
    return `${name} ${multipleMixes ? `(${t("mix")} ${format(mix)})` : ""}`;
  };
};
