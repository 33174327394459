import React from "react";
import { Stack, Theme, Typography, alpha, useTheme } from "@mui/material";
import { Error } from "@mui/icons-material";

type ValueOf<T> = T[keyof T];

type StyledCellProps = {
  value: string;
  suffix?: React.ReactNode;
  alpha: number;
  color: ValueOf<Theme["palette"]["data"]>;
  hasError?: boolean;
};

type PlainCellProps = {
  value: string;
  suffix?: React.ReactNode;
};

export const StyledCell = ({
  value,
  suffix,
  alpha: alpha_,
  color,
  hasError = false,
}: StyledCellProps) => {
  return (
    <Stack
      sx={{ height: "100%", backgroundColor: alpha(color, alpha_), px: 1 }}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      gap={1}
    >
      <Typography variant="body2Mono">
        {value}
        {suffix ? suffix : null}
      </Typography>
      {hasError ? <Error color="error" /> : null}
    </Stack>
  );
};

export const PlainCell = ({ value, suffix }: PlainCellProps) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        height: "100%",
        backgroundColor: theme.palette.common.white,
        px: 1,
      }}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Typography variant="body2Mono">
        {value}
        {suffix ? suffix : null}
      </Typography>
    </Stack>
  );
};
