import { Box, Typography } from "@mui/material";
import { useTenantTranslation } from "src/hooks/formatters";

export const InputPanelHeader = () => {
  const { t } = useTenantTranslation();

  return (
    <Box sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">{t("inputs")}</Typography>
      </Box>
    </Box>
  );
};
