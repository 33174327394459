import { useTenantTranslation } from "hooks/formatters";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useState } from "react";
import { usePlan } from "contexts/plan";
import { liftLoadedState } from "models/loaded";
import { ExportPlanModal } from "./modal";
import { Modal as EmailPlanModal } from "../emailPlan/Modal";
import { LoadingButton } from "@mui/lab";
import { DeployProcess } from "src/constants";
import { useDeployProcess } from "hooks/useDeployProcess";
import {
  useChemistryGroups,
  useProductionPlan,
} from "contexts/search/provider";
import { Tooltip } from "@mui/material";
import { usePlanId } from "components/common/boundary/PlanId";
import { Modal } from "../messagePlan/modal";
import { usePeriodIndex } from "hooks/periodIndex.tsx";

export const ExportPlan = () => {
  const { t } = useTenantTranslation();
  const [open, setOpen] = useState(false);
  const deployProcess = useDeployProcess();

  const { plan } = usePlan(usePlanId());
  const chemistryGroups = useChemistryGroups();
  const periodIndex = usePeriodIndex();
  const [productionPlan] = useProductionPlan(periodIndex);

  const dependencies = liftLoadedState({
    plan,
    chemistryGroups,
  });

  // disable the deployment button unless the production plan is a steel grade plan
  const buttonDisabled =
    productionPlan.status !== "success" ||
    productionPlan.data.steel_grade_items === null;

  return (
    <>
      {buttonDisabled ? (
        <Tooltip title={t("deployMixesOnly")}>
          <span>
            <LoadingButton
              variant="contained"
              startIcon={<UpgradeIcon />}
              loading={dependencies.status !== "success"}
              disabled
            >
              {t("deploy")}
            </LoadingButton>
          </span>
        </Tooltip>
      ) : (
        <LoadingButton
          variant="contained"
          startIcon={<UpgradeIcon />}
          onClick={() => setOpen(true)}
          loading={dependencies.status !== "success"}
        >
          {t("deploy")}
        </LoadingButton>
      )}
      {open && dependencies.status === "success"
        ? (() => {
            switch (deployProcess) {
              case DeployProcess.SyncAndSMS: {
                return (
                  <ExportPlanModal
                    open={open}
                    close={() => setOpen(false)}
                    plan={dependencies.data.plan}
                    chemistryGroups={dependencies.data.chemistryGroups}
                  />
                );
              }
              case DeployProcess.Email: {
                return (
                  <EmailPlanModal open={open} doClose={() => setOpen(false)} />
                );
              }
              case DeployProcess.Message: {
                return (
                  <Modal
                    open={open}
                    doClose={() => setOpen(false)}
                    planId={dependencies.data.plan.id}
                  />
                );
              }
              case DeployProcess.None: {
                return null;
              }
            }
          })()
        : null}
    </>
  );
};
