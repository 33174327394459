import { LoadedContent } from "src/components/common/loading/loadedContent";
import { useSessions } from "./hooks";
import { SearchSummary } from "store/api/generatedApi";
import { SessionItem } from "./SessionItem";
import { Box, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";

export type SearchSelectionHandler = (search: SearchSummary) => void;

export type SelectedSearch = SearchSummary & { sessionId: number };

export const SearchSelector = ({
  selectedSearch,
  setSelectedSearch,
}: {
  selectedSearch: SelectedSearch | null;
  setSelectedSearch: ((selectedSearch: SelectedSearch) => void) | null;
}) => {
  const { t } = useTenantTranslation();
  const titleHeight = 20;
  return (
    <Box sx={{ height: "100%" }}>
      <LoadedContent data={useSessions()} enableFullHeight>
        {(sessions) => (
          <Box sx={{ height: "100%" }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ height: titleHeight, pb: 1 }}
            >{`${t("chooseSearch")}:`}</Typography>
            <Box
              sx={{
                height: (theme) =>
                  `calc(100% - ${titleHeight}px - ${theme.spacing(1)})`,
                overflowY: "auto",
                overflowX: "hidden",
                borderRight: 1,
                borderColor: "grey.100",
                pr: 1,
              }}
            >
              {sessions.map((session) => (
                <SessionItem
                  key={session.session_id}
                  session={session}
                  selectedSearch={selectedSearch}
                  setSelectedSearch={setSelectedSearch}
                />
              ))}
            </Box>
          </Box>
        )}
      </LoadedContent>
    </Box>
  );
};
