import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { TargetInventoryItem } from "src/store/api/generatedApi";
import { useNotifyStatus } from "contexts/status";
import { useTenantTranslation } from "hooks/formatters";
import { TargetInventoryRow } from "./table";
import { useTargetInventory } from "contexts/search/provider";
import { usePeriodIndex } from "hooks/periodIndex";

export const useHandleTableMutations = () => {
  const periodTab = usePeriodIndex();
  const [, setTargetInventory] = useTargetInventory(periodTab);

  const numberSerialiser = useNumberSerialiser({
    min: 0,
    default: { value: null, text: "" },
    decimalPlaces: 0,
  });
  const notifyStatus = useNotifyStatus();
  const { t } = useTenantTranslation();

  const handleUpdateItem = (
    newRow: TargetInventoryRow,
    oldRow: TargetInventoryRow
  ): TargetInventoryRow => {
    const minQuantityResult = numberSerialiser.parse(
      newRow.min_quantity?.toString() ?? ""
    );
    const maxQuantityResult = numberSerialiser.parse(
      newRow.max_quantity?.toString() ?? "",
      { decimalPlaces: 2 }
    );
    const isHardRequirement = newRow.is_hard_requirement ?? true;

    if (minQuantityResult.valid && maxQuantityResult.valid) {
      if (
        // if min is change and min is greater than max, set max to min
        oldRow.min_quantity !== newRow.min_quantity &&
        maxQuantityResult.value != null &&
        minQuantityResult.value != null &&
        minQuantityResult.value > maxQuantityResult.value
      ) {
        maxQuantityResult.value = minQuantityResult.value;
      }

      if (
        // if max is changed and max is less than min, set min to max
        oldRow.max_quantity !== newRow.max_quantity &&
        maxQuantityResult.value != null &&
        minQuantityResult.value != null &&
        maxQuantityResult.value < minQuantityResult.value
      ) {
        minQuantityResult.value = maxQuantityResult.value;
      }

      const validatedNewItem: TargetInventoryItem = {
        material_id: newRow.material_id,
        min_quantity: minQuantityResult.value,
        max_quantity: maxQuantityResult.value,
        is_hard_requirement: isHardRequirement,
      };

      setTargetInventory((current) => [
        ...current.filter(
          (item) => item.material_id !== validatedNewItem.material_id
        ),
        validatedNewItem,
      ]);

      return { ...validatedNewItem, material_name: newRow.material_name };
    } else {
      notifyStatus({ text: t("invalidNumber"), type: "error" });
      return oldRow;
    }
  };

  return { handleUpdateItem };
};
