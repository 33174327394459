import { useTenantTranslation } from "hooks/formatters";
import { Period } from "hooks/periodIndex";

export const usePeriodName = () => {
  const { t } = useTenantTranslation();

  return (period: Period) => {
    switch (period) {
      case 1: {
        return t("schedule");
      }
      default: {
        return `${t("forecastPastParticiple")} ${period - 1}`;
      }
    }
  };
};
