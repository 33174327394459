import { Plan } from "store/api/generatedApi";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { Snackbar } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { FileCopy } from "@mui/icons-material";
import { Period } from "hooks/periodIndex";
import { getMixSummaryName } from "src/utils/getMixSummaryName.ts";
import { recipeDecimalPlaces } from "src/constants";

export const CopyPlanButton = ({
  plan: { summary },
  percentageBreakdown,
  period,
  groupType,
}: {
  plan: Plan;
  percentageBreakdown: boolean;
  period: Period;
  groupType: "chemistryGroups" | "productGroups";
}) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);

  const unitString: string = units(
    percentageBreakdown ? "mass_fraction" : "mass"
  );

  const { format } = useNumberSerialiser({
    decimalPlaces: recipeDecimalPlaces,
  });

  const recipeTexts: string[] = [];

  const groupSummary = {
    chemistryGroups: summary.mix_summary,
    productGroups: summary.product_group_mix_summary!,
  }[groupType];

  const groupMaterialSummary = {
    chemistryGroups: summary.mix_material_summary,
    productGroups: summary.product_group_mix_material_summary!,
  }[groupType];

  const filteredSummaries = groupSummary.filter(
    (summary) => summary.period === period
  );
  for (const production_group_summary of filteredSummaries) {
    let recipeText = getMixSummaryName(production_group_summary) + "\n";

    recipeText +=
      "-".repeat(getMixSummaryName(production_group_summary).length) + "\n";
    for (const material_production_group_summary of groupMaterialSummary) {
      if (
        getMixSummaryName(material_production_group_summary) ===
          getMixSummaryName(production_group_summary) &&
        material_production_group_summary.period === period &&
        material_production_group_summary.recipe_mass > 0
      ) {
        let recipeValue = material_production_group_summary.recipe_mass;
        if (percentageBreakdown) {
          recipeValue =
            (recipeValue / production_group_summary.recipe_mass) * 100;
        }
        recipeText += material_production_group_summary.material + ": ";
        recipeText += format(recipeValue) + unitString + "\n";
      }
    }

    recipeTexts.push(recipeText);
  }

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    void navigator.clipboard.writeText(recipeTexts.join("\n"));
  };

  return (
    <>
      <LoadingButton
        variant="text"
        color="primary"
        onClick={handleClick}
        startIcon={<FileCopy />}
        sx={{ whiteSpace: "nowrap" }}
      >
        {t("copyRecipeToClipboard")}
      </LoadingButton>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={t("recipeCopiedToClipboard")}
      />
    </>
  );
};
