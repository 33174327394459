import { Close } from "@mui/icons-material";
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  doClose: () => void;
  title: string;
  children: React.ReactNode;
  width: number;
};

export const Layout = ({
  open,
  doClose,
  title,
  children,
  width,
}: React.PropsWithChildren<Props>) => {
  const theme = useTheme();

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={(event: Event) => {
        event.stopPropagation();
        event.preventDefault();
        doClose();
      }}
      hideBackdrop
    >
      <Toolbar />
      <Box
        sx={{
          height: "100%",
          width: `${width}px`,
          maxWidth: (theme) => theme.breakpoints.values.lg,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          spacing={1}
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.common.black,
            paddingX: 3,
            paddingY: 2,
          }}
        >
          <IconButton onClick={() => doClose()}>
            <Close />
          </IconButton>
          <Typography variant="h2">{title}</Typography>
        </Stack>
        <Box
          sx={{
            paddingX: 3,
            marginY: 2,
            minHeight: "0px",
            overflowY: "scroll",
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};
