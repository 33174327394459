import { ButtonBase, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import {
  GridColDef,
  GridRenderCellParams,
  DataGridPremium,
} from "@mui/x-data-grid-premium";
import { useNavigateToPlan } from "hooks/navigation";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { DeployedPlanRead } from "src/store/api/generatedApi";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { UnitSuffix } from "../plan/table/common/UnitSuffix";

export const DeployedPlansTable = ({
  plans,
}: {
  plans: DeployedPlanRead[];
}) => {
  const { t } = useTenantTranslation();
  const formatLocalDate = useFormatLocalDateString("datetime");

  const columns: GridColDef<DeployedPlanRead>[] = [
    {
      field: "name",
      headerName: t("searchName"),
      flex: 2,
      renderCell: PlanNameButtonCell,
    },
    {
      field: "numHeats",
      headerName: t("heats"),
      flex: 1,
      renderCell: NumHeatsCell,
      align: "right",
      display: "flex",
    },
    {
      field: "tappedPrice",
      headerName: t("tappedPrice"),
      flex: 1,
      renderCell: TappedPriceCell,
      align: "right",
      display: "flex",
    },
    {
      field: "deployed_at",
      headerName: t("deployedAt"),
      flex: 1,
      display: "flex",
      valueFormatter: (value) => formatLocalDate(value as string),
    },
    {
      field: "deployed_by_email",
      headerName: t("deployedBy"),
      flex: 1,
      display: "flex",
    },
  ];

  return (
    <DataGridPremium
      rows={plans}
      columns={columns}
      getRowId={(row: DeployedPlanRead) => row.plan_id}
    />
  );
};

const PlanNameButtonCell = ({
  row: { plan_id, session_name, search_id },
}: GridRenderCellParams<DeployedPlanRead>) => {
  const navigateToPlan = useNavigateToPlan();
  return (
    <ButtonBase
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      onClick={() => navigateToPlan(plan_id, search_id)}
    >
      <Typography
        variant="button"
        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {session_name}
      </Typography>
      <ChevronRight />
    </ButtonBase>
  );
};

const NumHeatsCell = ({
  row: { first_period_summary },
}: GridRenderCellParams<DeployedPlanRead>) => {
  const { format } = useNumberSerialiser();

  return (
    <Typography variant="body1Mono" textAlign="right">
      {format(first_period_summary.num_heats)}
    </Typography>
  );
};

const TappedPriceCell = ({
  row: { first_period_summary },
}: GridRenderCellParams<DeployedPlanRead>) => {
  const units = useUnitsFormatter(false);
  const { format } = useNumberSerialiser({ decimalPlaces: 2 });

  return (
    <Typography variant="body1Mono" sx={{ textAlign: "right" }}>
      {format(first_period_summary.display_cost_per_tonne)}
      <UnitSuffix>{units("specific_cost")}</UnitSuffix>
    </Typography>
  );
};
