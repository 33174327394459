import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { useTenantTranslation } from "hooks/formatters";
import { ImportFromFile } from "./fromFile/ImportFromFile";
import { ImportWizardState, emptyFileState, emptySearchState } from "./state";
import { Setter } from "hooks/state/syncing";
import { InsertDriveFile, Search } from "@mui/icons-material";
import { SearchSelector } from "components/search/SearchSelector";
import { UploadedFileOutputType } from "store/api/generatedApi";

export type ImportWizardProps = {
  importName: string;
  state: ImportWizardState;
  setState: Setter<ImportWizardState>;
  preview: ReactNode | null;
  topPanel?: ReactNode;
  onConfirm: (() => void) | null;
  outputTypes?: UploadedFileOutputType[];
};

export const ImportWizard = ({
  importName,
  state,
  setState,
  preview,
  topPanel,
  onConfirm,
  outputTypes,
}: ImportWizardProps) => {
  const { t } = useTenantTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<InsertDriveFile />}
          onClick={() => {
            setState(emptyFileState);
            setOpen(true);
          }}
        >
          {t("importFromFile")}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Search />}
          onClick={() => {
            setState(emptySearchState);
            setOpen(true);
          }}
        >
          {t("importFromSearch")}
        </Button>
      </Stack>

      {open ? (
        <ImportWizardModal
          importName={importName}
          state={state}
          setState={setState}
          preview={preview}
          topPanel={topPanel}
          onConfirm={onConfirm}
          close={() => setOpen(false)}
          outputTypes={outputTypes}
        />
      ) : null}
    </>
  );
};

const ImportWizardModal = ({
  importName,
  state,
  setState,
  preview,
  topPanel,
  onConfirm,
  close,
  outputTypes,
}: ImportWizardProps & { close: () => void }) => {
  const { t } = useTenantTranslation();

  const dialogTitle =
    state.kind === "file"
      ? `${t("import")} ${importName} ${t("fromFileSuffix")}`
      : `${t("import")} ${importName} ${t("fromSearchSuffix")}`;

  return (
    <ImportWizardLayout
      title={dialogTitle}
      subtitle={topPanel}
      explorer={
        state.kind === "file" ? (
          <ImportFromFile
            state={state}
            setState={(update) => {
              if (typeof update === "object") {
                setState(update);
              } else {
                setState((current) =>
                  update(
                    current.kind === "file"
                      ? current
                      : {
                          kind: "file",
                          file: null,
                          type: null,
                        }
                  )
                );
              }
            }}
            outputTypes={outputTypes}
          />
        ) : (
          <SearchSelector
            selectedSearch={state.search}
            setSelectedSearch={(search) => setState({ kind: "search", search })}
          />
        )
      }
      preview={
        (state.kind === "search" &&
          state.search?.search_id &&
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          state.search.sessionId) ||
        (state.kind === "file" && state.file) ? (
          preview ?? <NoRelevantInformation kind={state.kind} />
        ) : state.kind === "search" ? (
          <Typography variant="body2" color="text.secondary">
            {t("chooseASearchToPreview")}
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            {t("chooseAFileToPreview")}
          </Typography>
        )
      }
      close={close}
      onConfirm={onConfirm}
    />
  );
};

export const ImportWizardLayout = ({
  title,
  subtitle,
  explorer,
  preview,
  close,
  onConfirm,
}: {
  title: string;
  subtitle?: ReactNode;
  explorer?: ReactNode;
  preview: ReactNode;
  close: () => void;
  onConfirm: (() => void) | null;
}) => {
  const { t } = useTenantTranslation();

  return (
    <Dialog
      open
      onClose={close}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          // Fix to always be a little less than "full height" so that the Input
          // Modal is still visible behind it
          height: "85vh",
        },
      }}
    >
      <DialogTitle>
        {title}
        {subtitle}
      </DialogTitle>

      <DialogContent>
        <Grid container columns={8} columnSpacing={3} sx={{ height: "100%" }}>
          {explorer !== undefined && (
            <Grid
              item
              xs={3}
              sx={{
                height: "100%",
                overflowY: "auto",
              }}
            >
              {explorer}
            </Grid>
          )}

          <Grid
            item
            xs={explorer === undefined ? 8 : 5}
            sx={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                borderRadius: 1,
                backgroundColor: "grey.50",
                px: 4,
                py: 1,
                height: (theme) => `calc(100% - ${theme.spacing(2)})`,
                overflowY: "auto",
              }}
            >
              <Typography color="text.secondary" sx={{ mb: 1 }}>
                {t("preview")}
              </Typography>

              {preview}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={onConfirm == null}
          onClick={() => {
            if (onConfirm) {
              onConfirm();
            }
            close();
          }}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NoRelevantInformation = ({
  kind,
}: {
  kind: "file" | "search";
}) => {
  const { t } = useTenantTranslation();
  const message =
    kind == "file"
      ? t("couldNotExtractInformationFromFile")
      : t("noSpecifiedProduction");

  return <Typography>{message}</Typography>;
};
