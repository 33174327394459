import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { Confirm } from "./confirm";
import { Deploying } from "./deploying";
import { Deployed } from "./deployed";
import { useSendPlanDeployedMessageMutation } from "src/store/api/generatedApi";
import { useTenant } from "hooks/settings";

export enum State {
  Confirm,
  Deploying,
  Deployed,
}

const stateToIndex = (state: State): 0 | 1 | 2 => {
  switch (state) {
    case State.Confirm:
      return 0;
    case State.Deploying:
      return 1;
    case State.Deployed:
      return 2;
  }
};

type Props = {
  open: boolean;
  doClose: () => void;
  planId: number;
};

export const Modal = ({ open, doClose, planId }: Props) => {
  const [state, setState] = React.useState(State.Confirm);
  const tenant = useTenant();

  const [sendMessage] = useSendPlanDeployedMessageMutation();

  const { t } = useTenantTranslation();

  const steps = ["Confirm", "Deploying", "Deployed"];

  const body = React.useMemo(() => {
    switch (state) {
      case State.Confirm:
        return (
          <Confirm
            confirm={() => {
              setState(State.Deploying);
              void sendMessage({ tenantName: tenant, planId })
                .unwrap()
                .then(() => setState(State.Deployed));
            }}
          />
        );
      case State.Deploying:
        return <Deploying />;
      case State.Deployed:
        return <Deployed doClose={doClose} />;
    }
  }, [state, doClose, planId, sendMessage, tenant]);

  return (
    <Dialog
      onClose={doClose}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ "& .MuiDialog-paper": { height: "60vh" } }}
    >
      <DialogTitle>{t("deployPlan")}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Stepper activeStep={stateToIndex(state)}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>
                  <Typography>{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {body}
      </DialogContent>
    </Dialog>
  );
};
