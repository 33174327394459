import { useInputs } from "../provider";
import { useTenant } from "hooks/settings";
import { getBase64 } from "helpers";
import { useExperimentalSearch } from "../provider";
import { mapLoadedUnpack } from "models/loaded";
import { useState } from "react";
import {
  ImportedExperimentalInputs,
  useImportInputsFromFileMutation,
  useImportInputsFromTextMutation,
} from "src/store/api/generatedApi";

export const useImportFromClipboard = () => {
  const importFiles = useImportFiles();
  const importText = useImportText();
  const importInputs = useImportInputs();
  const [loading, setLoading] = useState(false);

  return [
    async (event: { clipboardData: DataTransfer }) => {
      const files = event.clipboardData?.files ?? [];
      setLoading(true);

      try {
        if (files.length > 0) {
          const inputs = await Promise.all(importFiles([...files]));
          inputs.forEach(importInputs);
        } else {
          const inputs = await importText(await navigator.clipboard.readText());
          importInputs(inputs);
        }
      } finally {
        setLoading(false);
      }
    },
    { loading },
  ] as const;
};

export const useImportInputs = () => {
  const [, setInputs] = useInputs();

  return (inputs: ImportedExperimentalInputs) =>
    setInputs((current) => ({
      ...current,
      production: [...current.production, ...(inputs.production ?? [])],
      obtainable: [...current.obtainable, ...(inputs.obtainable ?? [])],
      markers: [...current.markers, ...(inputs.markers ?? [])],
      inventory: inputs.inventory ?? current.inventory,
    }));
};

export const useImportFiles = () => {
  const search = useExperimentalSearch();

  const contextId = mapLoadedUnpack(
    search.client,
    ({ context_id }) => context_id
  );
  const [importFromFile] = useImportInputsFromFileMutation();
  const tenant = useTenant();

  return (files: File[]): Promise<ImportedExperimentalInputs>[] => {
    if (contextId == null) {
      return [];
    }

    return files.map(async (file) => {
      const content = await getBase64(file);
      if (typeof content === "string") {
        return importFromFile({
          tenantName: tenant,
          contextId,
          uploadedFileCreate: {
            type: "unknown",
            file: {
              name: file.name,
              updated_at: new Date(file.lastModified).toISOString(),
              content,
            },
          },
        }).unwrap();
      } else {
        return {};
      }
    });
  };
};

const useImportText = () => {
  const [importText] = useImportInputsFromTextMutation();
  const tenant = useTenant();

  return (text: string): Promise<ImportedExperimentalInputs> =>
    importText({ tenantName: tenant, importedText: { text } }).unwrap();
};
