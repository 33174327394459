import React from "react";
import { ChefGroupDetailView } from "../chefgroup";
import { Period } from "hooks/periodIndex";
import { ClickableChip } from "components/common/chips/clickable";
import { PlanId } from "components/common/boundary/PlanId";

type BaseProps = {
  id: number;
  name: string;
};

type MixDetailProps = {
  planId: PlanId;
  period: Period;
  mix: number;
};

type Props = BaseProps | (BaseProps & MixDetailProps);

export const ChemistryGroupChip = ({ id, name, ...props }: Props) => {
  const [openPanel, setOpenPanel] = React.useState(false);
  const isDetailedView =
    "planId" in props && "period" in props && "mix" in props;
  return (
    <>
      <ClickableChip label={name} onClick={() => setOpenPanel(true)} />
      {isDetailedView ? (
        <ChefGroupDetailView
          mix={props.mix}
          planId={props.planId}
          period={props.period}
          group={id}
          open={openPanel}
          setOpen={setOpenPanel}
        />
      ) : (
        <ChefGroupDetailView
          group={id}
          open={openPanel}
          setOpen={setOpenPanel}
        />
      )}
    </>
  );
};
