import { Route, Routes } from "react-router-dom";

import { MixMaterialLimitsPage } from "components/searchContexts/MixMaterialLimitsPage";
import { SearchContextGroupsPage } from "components/searchContexts/SearchContextGroupsPage";
import { SearchContextGroupDetailPage } from "components/searchContexts/SearchContextGroupDetailPage";

export const SearchContextsRoutes = () => {
  return (
    <Routes>
      <Route index element={<SearchContextGroupsPage />} />
      <Route
        path="/:search_context_group_id/search_context/:search_context_id"
        element={<SearchContextGroupDetailPage />}
      />
      <Route
        path="/:search_context_group_id/search_context/:search_context_id/mix_material_limit_set/:mix_material_limit_set_id"
        element={<MixMaterialLimitsPage />}
      />
    </Routes>
  );
};
