import { TextField, TextFieldProps } from "@mui/material";
import { Serialiser } from "hooks/serialisers/types";
import { ChangeEvent, KeyboardEventHandler, useRef, useState } from "react";

export type ValidatedTextFieldProps<T, O> = {
  value: T;
  setValue: (value: T) => void;
  serialiser: Serialiser<T, O>;
  label?: string;
  disabled?: boolean;
  onEditConfirmedWithKeyboard?: (value: T) => void;
  onEditStartTransform?: (text: string) => string;
  textFieldProps?: TextFieldProps;
  liveUpdate?: boolean;
};

/**
 * Text field that only sets its value when a validation condition is met.
 *
 * When the text box is selected, the user will be allowed to freely edit the
 * text inside.  When their focus moves away from the box, or when they press
 * enter, escape, or tab, the value will be "committed".  If it meets the text
 * field's validation condition then the underlying value will be set; otherwise
 * the user's changes will be discarded.
 */
export const ValidatedTextField = <T, O = undefined>({
  value,
  setValue,
  serialiser,
  label,
  disabled,
  onEditConfirmedWithKeyboard,
  onEditStartTransform,
  textFieldProps,
  liveUpdate,
}: ValidatedTextFieldProps<T, O>) => {
  // Text is only non-`null` while the value is being edited
  const [text, setText] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const text = target.value;
    if (liveUpdate) {
      const result = serialiser.parse(text);
      if (result.valid) {
        setValue(result.value);
      }
    }
    setText(text);
  };

  const handleBlur = () => {
    if (text !== null) {
      const result = serialiser.parse(text);
      if (result.valid) {
        setValue(result.value);
      }
      setText(null);
    }
  };

  const handleKey: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      if (ref.current) {
        ref.current.blur();
      }

      const parsedValue = serialiser.parse(text ?? "");
      if (onEditConfirmedWithKeyboard && parsedValue.valid) {
        onEditConfirmedWithKeyboard(parsedValue.value);
      }
    }
  };

  return (
    <TextField
      inputRef={ref}
      fullWidth
      size="small"
      variant="outlined"
      value={text ?? serialiser.format(value)}
      onChange={handleChange}
      onFocus={() => {
        // Optionally modify the text (eg. converting "0" to an empty string)
        // when editing starts
        if (onEditStartTransform) {
          setText(onEditStartTransform(text ?? serialiser.format(value)));
        }
      }}
      onBlur={handleBlur}
      onKeyDown={handleKey}
      label={label}
      disabled={disabled === true}
      {...(textFieldProps ?? {})}
    />
  );
};
