import { Routes, Route, Navigate } from "react-router-dom";
import { DebugPage } from "src/components/debug/debugPage";
import { PlanPage } from "src/components/plan/PlanPage";
import { ProfileSettingsPage } from "src/components/profileSettings/ProfileSettingsPage";
import { SearchPage } from "src/components/search/SearchPage";
import { SearchesPage } from "src/components/searches/SearchesPage";
import { DeployedPlansPage } from "src/components/deployedPlans/deployedPlansPage";
import { ConstraintsPage } from "src/components/constraints/constraintsPage";
import { ProvideSearch } from "contexts/search/provider";
import { PlanIdBoundary } from "components/common/boundary/PlanId";
import { useIsAdmin, useTenant } from "hooks/settings";
import { PeriodTabs } from "components/common/periodTabs/tabs";
import { SearchContextsRoutes } from "./search_context";

export const TenantRoutes = () => {
  const tenant = useTenant();
  const isAdmin = useIsAdmin();

  return (
    <Routes>
      <Route index element={<SearchesPage />} />
      <Route path="deployed-plans" element={<DeployedPlansPage />} />

      <Route path="profile-settings" element={<ProfileSettingsPage />} />
      <Route
        path="constraints"
        element={
          <ProvideSearch>
            <ConstraintsPage />
          </ProvideSearch>
        }
      />

      <Route
        path="/search"
        element={
          <ProvideSearch>
            <SearchPage />
          </ProvideSearch>
        }
      />
      {isAdmin ? (
        <Route
          path="/debug-search"
          element={
            <ProvideSearch>
              <DebugPage />
            </ProvideSearch>
          }
        />
      ) : null}

      <Route
        path="/plan"
        element={
          <ProvideSearch>
            <PlanIdBoundary fallback={<Navigate to={`/${tenant}/v3`} />}>
              <PeriodTabs>
                <PlanPage />
              </PeriodTabs>
            </PlanIdBoundary>
          </ProvideSearch>
        }
      />

      <Route
        path="/search_context_group/*"
        element={<SearchContextsRoutes />}
      />
    </Routes>
  );
};
