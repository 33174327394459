import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";
import { formatTimestamp, useTimestampSerialiser } from "../timestamps";
import { Setter } from "./timeline";
import { useInputs, useExperimentalMaterials } from "../provider";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { liftLoadedState, mapLoadedUnpack } from "models/loaded";
import { Table } from "../table";
import { SearchMaterials } from "contexts/search/context";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { Inventory, ExperimentalInputs } from "src/store/api/generatedApi";
import { inventoryProjection } from "../processing/projection";
import {
  resolvedObtainableBlocks,
  resolvedProductionBlocks,
} from "../processing/resolve";

export const InventoryDetailButton = () => {
  const [open, setOpen] = useState(false);
  const [customerInputs, setCustomerInputs] = useInputs();
  const materials = useExperimentalMaterials();

  const { format } = useNumberSerialiser();

  const totalInventory = mapLoadedUnpack(customerInputs, (inputs) =>
    inputs.inventory.inventory
      .map((item) => item.mass)
      .reduce((left, right) => (left ?? 0) + (right ?? 0), 0)
  );

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Inventory{" "}
        {totalInventory !== null ? `(${format(totalInventory)}t)` : ""}
      </Button>

      <LoadedContent data={liftLoadedState({ customerInputs, materials })}>
        {(loaded) =>
          open ? (
            <InventoryDetail
              inventory={loaded.customerInputs.inventory}
              setInventory={(update) =>
                setCustomerInputs((current) => ({
                  ...current,
                  inventory: update(current.inventory),
                }))
              }
              materials={loaded.materials}
              close={() => setOpen(false)}
            />
          ) : null
        }
      </LoadedContent>
    </>
  );
};

type Column = "material" | "mass" | "specific_price";

export const InventoryDetail = ({
  inventory,
  setInventory,
  close,
  materials,
}: {
  inventory: Inventory;
  setInventory: Setter<Inventory>;
  close: () => void;
  materials: SearchMaterials;
}) => {
  const serialiser = useTimestampSerialiser();
  const massSerialiser = useNumberSerialiser({
    min: 0,
    default: { value: null, text: "" },
  });
  const priceSerialiser = useNumberSerialiser({
    decimalPlaces: 2,
    min: 0,
    default: { value: null, text: "" },
  });

  const lookup = useMemo(
    () =>
      Object.fromEntries(
        inventory.inventory.map((item) => [item.material_name, item])
      ),
    [inventory]
  );

  const rows = useMemo(() => {
    const contextMaterials = materials.byIndex.map((material) => material.name);
    const extraMaterials = inventory.inventory
      .filter((item) => !contextMaterials.includes(item.material_name))
      .map((item) => item.material_name);

    return contextMaterials.concat(extraMaterials);
  }, [materials, inventory]);

  const setRow = useCallback(
    (
      columnId: "mass" | "specific_price",
      rowId: string,
      value: number | null
    ) => {
      setInventory((current) => ({
        ...current,
        inventory: lookup[rowId]
          ? current.inventory.map((item) =>
              item.material_name === rowId
                ? { ...item, [columnId]: value }
                : item
            )
          : [
              ...current.inventory,
              {
                material_name: rowId,
                mass: null,
                specific_price: null,
                [columnId]: value,
              },
            ],
      }));
    },
    [setInventory]
  );

  const render = useCallback(
    (rowId: string, columnId: Column): ReactNode => {
      const item = lookup[rowId];
      switch (columnId) {
        case "material":
          return <Typography>{rowId}</Typography>;
        case "mass":
          return (
            <ValidatedTextField
              value={item?.mass ?? null}
              setValue={(mass) => setRow(columnId, rowId, mass)}
              serialiser={massSerialiser}
            />
          );
        case "specific_price":
          return (
            <ValidatedTextField
              value={item?.specific_price ?? null}
              setValue={(price) => setRow(columnId, rowId, price)}
              serialiser={priceSerialiser}
            />
          );
      }
    },
    [lookup, setRow]
  );

  const remove = useCallback(
    (rowId: string) =>
      setInventory((current) => ({
        ...current,
        inventory: current.inventory.filter(
          (item) => item.material_name !== rowId
        ),
      })),
    []
  );

  const append = useCallback(
    (rowId: string) => {
      if (!rows.includes(rowId)) {
        setInventory((current) => ({
          ...current,
          inventory: [
            ...current.inventory,
            {
              material_name: rowId,
              mass: 0,
              specific_price: 0,
            },
          ],
        }));
      }
    },
    [setInventory, rows]
  );

  return (
    <Dialog
      open
      onClose={close}
      maxWidth="md"
      fullWidth
      onDoubleClick={(event) => event.stopPropagation()}
    >
      <DialogTitle>Inventory</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Stack gap={2} direction="row" alignItems="center">
            <Typography>Timestamp</Typography>
            <ValidatedTextField
              value={inventory.timestamp}
              setValue={(timestamp) =>
                setInventory((current) => ({ ...current, timestamp }))
              }
              serialiser={serialiser}
            />
          </Stack>

          <Table
            rows={rows}
            columns={["material", "mass", "specific_price"]}
            render={render}
            remove={remove}
            append={append}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack alignItems="center" justifyContent="flex-end">
          <Button onClick={close}>Close</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const TimestampInventoryDetail = ({
  inputs,
  close,
  materials,
  timestamp,
}: {
  inputs: ExperimentalInputs;
  close: () => void;
  materials: SearchMaterials;
  timestamp: number;
}) => {
  const initialLookup = useMemo(
    () =>
      Object.fromEntries(
        inputs.inventory.inventory.map((item) => [
          item.material_name,
          item.mass,
        ])
      ),
    [inputs.inventory]
  );

  type Column = "material" | "initialMass" | "currentMass" | "delta";

  const currentLookup = useMemo(() => {
    const inventoryAtTimestamp = inventoryProjection(
      inputs.inventory,
      resolvedProductionBlocks(inputs),
      resolvedObtainableBlocks(inputs),
      timestamp
    ).inventory;
    return Object.fromEntries(
      inventoryAtTimestamp.map((item) => [item.material_name, item.mass])
    );
  }, [inputs, timestamp]);

  const columns: Column[] = useMemo(
    () => ["material", "initialMass", "currentMass", "delta"],
    []
  );

  const rows = useMemo(() => {
    const contextMaterials = materials.byIndex.map((material) => material.name);
    const extraMaterials = Object.entries(currentLookup)
      .filter(([material]) => !contextMaterials.includes(material))
      .map(([material]) => material);

    return contextMaterials.concat(extraMaterials);
  }, [materials, currentLookup]);

  const render = useCallback(
    (row: string, column: Column) => {
      switch (column) {
        case "material":
          return <Typography>{row}</Typography>;
        case "initialMass":
          return <NumberCell value={initialLookup[row] ?? null} />;
        case "currentMass":
          return <NumberCell value={currentLookup[row] ?? null} />;
        case "delta": {
          const delta = (currentLookup[row] ?? 0) - (initialLookup[row] ?? 0);
          return <NumberCell value={delta === 0 ? null : delta} />;
        }
      }
    },
    [initialLookup, currentLookup]
  );

  return (
    <Dialog
      open
      onClose={close}
      maxWidth="md"
      fullWidth
      onDoubleClick={(event) => event.stopPropagation()}
    >
      <DialogTitle>
        Inventory at {formatTimestamp(timestamp).toDateString()}
      </DialogTitle>
      <DialogContent>
        <Table rows={rows} columns={columns} render={render} />
      </DialogContent>
      <DialogActions>
        <Stack alignItems="center" justifyContent="flex-end">
          <Button onClick={close}>Close</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
