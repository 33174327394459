import { DialogContent, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Setter, TimelineSettings } from "./timeline";
import {
  ExperimentalInputs,
  ObtainableBlock,
} from "src/store/api/generatedApi";
import { BlockDetail, BlockHeader, BlockView } from "../blocks/block";
import { Table } from "../table";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";

export const ObtainableBlockView = ({
  obtainable,
  timeline,
  setInputs,
}: {
  obtainable: ObtainableBlock;
  timeline: TimelineSettings;
  setInputs: Setter<ExperimentalInputs>;
}) => {
  return (
    <BlockView
      block={obtainable}
      timeline={timeline}
      timelineContent={
        <Typography>
          {obtainable.name ??
            (obtainable.bundles
              .map((bundle) => bundle.material_name)
              .join(", ") ||
              "Obtainable")}
        </Typography>
      }
      detailContent={
        <ObtainableDetailView obtainable={obtainable} setInputs={setInputs} />
      }
      offset={100}
    />
  );
};

type Column =
  | "material"
  | "minimum_quantity"
  | "maximum_quantity"
  | "specific_price";

const ObtainableDetailView = ({
  obtainable,
  setInputs,
}: {
  obtainable: ObtainableBlock;
  setInputs: Setter<ExperimentalInputs>;
}) => {
  const massSerialiser = useNumberSerialiser({
    default: { value: null, text: "" },
    min: 0,
  });
  const priceSerialiser = useNumberSerialiser({
    default: { value: null, text: "" },
    min: 0,
    decimalPlaces: 2,
  });

  const setObtainable = useCallback(
    (update: (obtainable: ObtainableBlock) => ObtainableBlock) =>
      setInputs((current) => ({
        ...current,
        obtainable: [
          ...current.obtainable.filter((item) => item.uuid !== obtainable.uuid),
          update(obtainable),
        ],
      })),
    [setInputs]
  );

  const setRow = useCallback(
    (
      column: "minimum_quantity" | "maximum_quantity" | "specific_price",
      row: string,
      value: number | null
    ) =>
      setObtainable((current) => ({
        ...current,
        bundles: current.bundles.map((bundle) =>
          bundle.material_name === row ? { ...bundle, [column]: value } : bundle
        ),
      })),
    [setObtainable]
  );

  const render = useCallback(
    (row: string, column: Column) => {
      const item = obtainable.bundles.find(
        (item) => item.material_name === row
      );
      if (item === undefined) {
        return;
      }
      switch (column) {
        case "material":
          return item.material_name;
        case "minimum_quantity":
          return (
            <ValidatedTextField
              value={item.minimum_quantity}
              setValue={(value) => setRow(column, row, value)}
              serialiser={massSerialiser}
            />
          );
        case "maximum_quantity":
          return (
            <ValidatedTextField
              value={item.maximum_quantity}
              setValue={(value) => setRow(column, row, value)}
              serialiser={massSerialiser}
            />
          );
        case "specific_price":
          return (
            <ValidatedTextField
              value={item.specific_price}
              setValue={(value) => setRow(column, row, value)}
              serialiser={priceSerialiser}
            />
          );
      }
    },
    [obtainable, setRow]
  );

  const rows = useMemo(() => {
    return obtainable.bundles.map(({ material_name }) => material_name);
  }, [obtainable]);

  const columns: [
    "material",
    "minimum_quantity",
    "maximum_quantity",
    "specific_price",
  ] = useMemo(() => {
    return [
      "material",
      "minimum_quantity",
      "maximum_quantity",
      "specific_price",
    ];
  }, []);

  const append = useCallback(
    (row: string) => {
      if (
        // Don't add multiple rows with the same steel grade
        !obtainable.bundles.some((item) => item.material_name === row)
      ) {
        setObtainable((current) => ({
          ...current,
          bundles: [
            ...current.bundles,
            {
              material_name: row,
              minimum_quantity: null,
              maximum_quantity: null,
              specific_price: null,
              arrival_probability: null,
            },
          ],
        }));
      }
    },
    [setObtainable]
  );

  const remove = useCallback(
    (row: string) =>
      setObtainable((current) => ({
        ...current,
        bundles: current.bundles.filter(
          ({ material_name }) => material_name !== row
        ),
      })),
    [setObtainable]
  );

  return (
    <BlockDetail>
      <BlockHeader
        block={obtainable}
        setBlock={(update) =>
          setObtainable((current) => ({ ...current, ...update(obtainable) }))
        }
        name={
          obtainable.name ??
          (obtainable.bundles
            .map((bundle) => bundle.material_name)
            .join(", ") ||
            "Obtainable")
        }
      />

      <DialogContent>
        <Table
          rows={rows}
          columns={columns}
          render={render}
          remove={remove}
          append={append}
        />
      </DialogContent>
    </BlockDetail>
  );
};
