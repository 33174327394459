import { Close } from "@mui/icons-material";
import { Box, Button, LinearProgress, Slide, Typography } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { HistoryItemList } from "./HistoryItemList";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { backendApi } from "src/store/api/enhancedApi";
import { useSearch } from "contexts/search/provider";
import { mapLoadedUnpack } from "models/loaded";
import { useHotkeys } from "react-hotkeys-hook";
import { hotkeys } from "hooks/hotkeys";

type Props = {
  showHistory: boolean;
  setShowHistory: (showHistory: boolean) => void;
};

export const HistoryPanel = ({ showHistory, setShowHistory }: Props) => {
  const { t } = useTenantTranslation();

  const {
    setSearchId,
    parameters: { server },
  } = useSearch();
  const { session: sessionId, search: searchId } = mapLoadedUnpack(
    server,
    ({ session_id, id }) => ({ session: session_id, search: id })
  ) ?? { session: null, search: null };
  const dispatch = useDispatch();

  const handleSearchSelect = (searchId: number) => {
    setSearchId(searchId);
    setShowHistory(false);
  };

  useHotkeys(hotkeys.historyPage, () => setShowHistory(false), {
    enabled: showHistory, // This should not fire when the panel is closed
  });

  useEffect(() => {
    if (showHistory) {
      /* When a search runs, its status may change and will need to be reflected
        in the change history.  The easiest way to make sure this happens, for
        now, is to invalidate the call to the API whenever the change history
        pane is opened.  This won't cause a refresh if a search completes while
        the pane is open, however */
      dispatch(
        backendApi.util.invalidateTags([{ type: "search", id: "list" }])
      );
    }
  }, [showHistory, dispatch]);

  return (
    <Slide in={showHistory} direction="right">
      <Box
        sx={{
          height: "100%",
          width: "300px",
          borderRight: 1,
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "#ffffff",
          zIndex: 1000,
          overflowY: "auto",
        }}
      >
        <Box sx={{ px: 3, py: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 2,
            }}
          >
            <Typography variant="h5">{t("changeHistory")}</Typography>
            <Button
              onClick={() => setShowHistory(false)}
              startIcon={<Close />}
              sx={{
                color: "text.primary",
              }}
            >
              {t("close")}
            </Button>
          </Box>
          {sessionId && searchId ? (
            <HistoryItemList
              sessionId={sessionId}
              currentSearchId={searchId}
              onSelect={(searchSummary) =>
                handleSearchSelect(searchSummary.search_id)
              }
            />
          ) : (
            <LinearProgress />
          )}
        </Box>
      </Box>
    </Slide>
  );
};
