import { Stack } from "@mui/material";
import { ProductionEstimator } from "./productionEstimator";
import { CopyProductionButton, PasteProductionButton } from "./copyPaste";
import { usePeriodIndex } from "hooks/periodIndex";
import { useProductionPlan } from "contexts/search/provider";
import { useEstimateProduction } from "contexts/search/wizards/estimateProduction";
import { SyncedProductionPlan } from "src/store/api/generatedApi";
import {
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import { ProductionWizard } from "./wizard";

export const ToolsPanel = ({
  productionPlan,
  productGroups,
  steelGrades,
}: {
  productionPlan: SyncedProductionPlan;
  productGroups: SearchProductGroups;
  steelGrades: SearchSteelGrades;
}) => {
  const periodTab = usePeriodIndex();

  const [, setProductionPlan] = useProductionPlan(periodTab);
  const estimate = useEstimateProduction(setProductionPlan);

  return (
    <Stack spacing={2}>
      <ProductionWizard period={periodTab} />
      <ProductionEstimator
        estimate={estimate}
        inputMode={
          productionPlan.steel_grade_items != null
            ? "steelGrades"
            : "productGroups"
        }
      />
      <Stack direction="row" gap={1}>
        <CopyProductionButton
          productionPlan={productionPlan}
          productGroups={productGroups}
          steelGrades={steelGrades}
        />
        <PasteProductionButton
          setProductionPlan={setProductionPlan}
          productGroups={productGroups}
          steelGrades={steelGrades}
        />
      </Stack>
    </Stack>
  );
};
