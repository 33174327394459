import { Box, Typography } from "@mui/material";
import { useNumberSerialiser } from "hooks/serialisers/numbers";

type Props = {
  value?: number | null;
  typographyProps?: React.ComponentProps<typeof Typography>;
  decimalPlaces?: number;
  min?: number;
  defaultValue?: number;
  scaleBy?: number;
};

export const NumberCell = ({
  value,
  typographyProps,
  decimalPlaces,
  min,
  defaultValue,
  scaleBy = 1.0,
}: Props) => {
  const numberSerialiser = useNumberSerialiser({
    default: { value: null, text: "" },
    decimalPlaces,
    min,
  });
  const formattedNumber = numberSerialiser.format(
    value === null || value === undefined ? null : value * scaleBy
  );
  const showDefaultValue = value == null && defaultValue != null;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Typography
        variant="body1Mono"
        {...{
          sx: { color: showDefaultValue ? "grey" : undefined },
          ...typographyProps,
        }}
      >
        {showDefaultValue ? defaultValue : formattedNumber}
      </Typography>
    </Box>
  );
};

export const MonospaceTextCell = ({ value }: { value: string }) => (
  <Typography variant="body1Mono">{value}</Typography>
);

export const BoldTextCell = ({ value }: { value: string }) => (
  <Typography sx={{ fontWeight: 800 }}>{value}</Typography>
);
