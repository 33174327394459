export const repeatParamsSerialiser = (params: Record<string, unknown>) => {
  const searchParams = new URLSearchParams();
  for (const paramsKey in params) {
    const value = params[paramsKey];
    // Only add parameter if it is not null or undefined
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (v != null) {
          searchParams.append(paramsKey, String(v));
        }
      });
    } else if (value != null) {
      searchParams.append(paramsKey, String(params[paramsKey]));
    }
  }
  return searchParams.toString();
};
