import { Stack } from "@mui/material";
import { SteelGradeChip } from "components/common/chips/steelGrade";
import { SteelGrade } from "src/store/api/generatedApi";

type Props = {
  steelGrades: SteelGrade[];
};

export const SteelGradesCell = ({ steelGrades }: Props) => {
  return (
    <Stack flexWrap="wrap" gap={0.5} flexDirection="row" paddingY={1}>
      {steelGrades.map(({ id, name, chemical_constraints }) =>
        id !== null && id !== undefined ? (
          <SteelGradeChip
            key={id}
            id={id}
            name={name}
            chemicalConstraints={chemical_constraints}
          />
        ) : null
      )}
    </Stack>
  );
};
