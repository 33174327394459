import { Box, ButtonBase, Typography } from "@mui/material";
import { SearchSummary } from "store/api/generatedApi";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { useTenantTranslation } from "hooks/formatters";
import { SelectedSearch } from ".";
import { SearchStatusChip } from "../common/SearchStatus";

type Props = {
  search: SearchSummary;
  parentSessionId: number;
  versionNumber: number;
  selectedSearch: SelectedSearch | null;
  setSelectedSearch: ((selectedSearch: SelectedSearch) => void) | null;
};
export const SearchItem = ({
  search,
  parentSessionId,
  versionNumber,
  selectedSearch,
  setSelectedSearch,
}: Props) => {
  const { t } = useTenantTranslation();
  const formattedCreatedAt = useFormatLocalDateString("datetime")(
    search.created_at
  );

  return (
    <ButtonBase
      sx={{
        px: 1,
        py: 0.25,
        justifyContent: "flex-start",
        borderRadius: 1,
        backgroundColor:
          selectedSearch?.search_id === search.search_id &&
          selectedSearch.sessionId === parentSessionId
            ? "action.selected"
            : undefined,

        "&:hover": {
          backgroundColor: "action.hover",
        },
      }}
      onClick={() => {
        if (setSelectedSearch) {
          setSelectedSearch({ ...search, sessionId: parentSessionId });
        }
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
        <Typography variant="body2" sx={{ py: 0.5 }}>{`${t(
          "version"
        )} ${versionNumber} • ${formattedCreatedAt}
          `}</Typography>
        <SearchStatusChip searchStatus={search.status} />
      </Box>
    </ButtonBase>
  );
};
