import { createTheme } from "@mui/material/styles";
import { gridClasses } from "@mui/x-data-grid-premium";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";
import "@fontsource/source-sans-pro/700.css";
import "@fontsource/source-code-pro/400.css";
import "@fontsource/source-code-pro/700.css";

// Create a theme instance.
let theme = createTheme({
  typography: {
    fontFamily: "Source Sans Pro, sans-serif",
    h1: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: 25,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.6,
    },
    body1Mono: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.6,
      fontFamily: "Source Code Pro, monospace",
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.6,
    },
    body2Mono: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.6,
      fontFamily: "Source Code Pro, monospace",
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.6,
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "rgba(68, 65, 200, 1)",
      dark: "rgba(58, 54, 188, 1)",
      light: "rgba(98, 112, 220, 1)",
    },
    secondary: {
      main: "#181B20",
      dark: "#000000",
      light: "#303540",
    },
    text: {
      primary: "#181B20",
      secondary: "rgba(5, 15, 35, 0.72)",
      disabled: "rgba(4, 24, 68, 0.42)",
    },
    error: {
      main: "#DB334A",
      dark: "#B72345",
      light: "#FF4750",
    },
    warning: {
      main: "#FFC311",
      dark: "#DBA10C",
      light: "#FFD64C",
    },
    info: {
      main: "#3571FF",
      dark: "#1A3FB7",
      light: "#6799FF",
    },
    success: {
      main: "#2EA96E",
      dark: "#117056",
      light: "#43CA7C",
    },
    action: {
      active: "rgba(0, 14, 46, 0.58)",
      hover: "rgba(29, 49, 89, 0.05)",
      selected: "rgba(14, 39, 83, 0.09)",
      disabled: "rgba(3, 26, 68, 0.28)",
      disabledBackground: "rgba(0, 24, 71, 0.17)",
      focus: "rgba(33, 55, 95, 0.12)",
    },
    divider: "#303540",
    grey: {
      50: "#F1F3F5",
      100: "#E4E7EC",
      200: "#C9CED9",
      300: "#AEB6C5",
      400: "#939DB2",
      500: "#78859F",
      600: "#606A7F",
      700: "#48505F",
      800: "#303540",
      900: "#181B20",
    },
    data: {
      blue: "#838feb",
      pink: "#e159ff",
      lightblue: "#13bfcf",
      orange: "#f0ac00",
      green: "#3dd600",
      red: "#ff6459",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        borderRadius: 0.5,
        // define the typography styles for small and large buttons
        sizeLarge: {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: 1.5,
        },
        sizeSmall: {
          fontSize: 12,
          fontWeight: 700,
          lineHeight: 1.6,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: theme.spacing(0.5),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(4),
        },
      },
      defaultProps: {
        variant: "h4",
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
          paddingBottom: theme.spacing(0),
          marginBottom: theme.spacing(2),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        square: true,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.6,
          fontFamily: "Source Code Pro, monospace",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorDefault: {
          color: theme.palette.text.primary,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        colCell: {
          height: "32px",
        },
        root: {
          "& .MuiDataGrid-main": {
            "& > div:first-of-type": {
              backgroundColor: `${theme.palette.grey[50]}`,
            },
          },
          "& .MuiDataGrid-cell": {
            borderRight: `1px solid ${theme.palette.grey[100]}`,
            // TODO We don't need this now, but I'm leaving it here because it may be useful for future tables
            // "&:nth-last-child(2)": {
            //   borderRight: "none",
            // },
            "&:last-child": {
              borderRight: "none",
            },
            ...theme.typography.body1,
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: theme.palette.grey[50],
            borderRight: `1px solid ${theme.palette.grey[100]}`,
            "&:last-child": {
              borderRight: "none",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            ...theme.typography.body2,
            color: theme.palette.text.secondary,
          },
        },
      },
      defaultProps: {
        disableRowSelectionOnClick: true,
        disableColumnMenu: true,
        hideFooter: true,
        columnHeaderHeight: 28,
        rowHeight: 32,
        sx: {
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: "none",
            },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: theme.spacing(0.5),
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: "none",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          px: 1,
        },
      },
    },
  },
});

export default theme;

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body1Mono: React.CSSProperties;
    body2Mono: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1Mono?: React.CSSProperties;
    body2Mono?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body1Mono: true;
    body2Mono: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    data: {
      blue: "#838feb";
      pink: "#e159ff";
      lightblue: "#13bfcf";
      orange: "#f0ac00";
      green: "#3dd600";
      red: "#ff6459";
    };
  }

  interface PaletteOptions {
    data?: {
      blue: "#838feb";
      pink: "#e159ff";
      lightblue: "#13bfcf";
      orange: "#f0ac00";
      green: "#3dd600";
      red: "#ff6459";
    };
  }
}
