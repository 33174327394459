import { LicenseInfo } from "@mui/x-license";
import { SyncedProductionPlan } from "./store/api/generatedApi";

export const backendURL = import.meta.env.VITE_BACKEND_URL;
export const azureTenantId = import.meta.env.VITE_AZURE_TENANT_ID;
export const meticulousProjectId = import.meta.env.VITE_METICULOUS_PROJECT_ID;
export const sentryReplaySessionSampleRate = parseInt(
  import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE
);
export const sentryReplayErrorSampleRate = parseInt(
  import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE
);
export const MAX_REVISION_HISTORY_LENGTH = 100;

export const languages: { [language: string]: string } = {
  "en-GB": "English",
  "de-DE": "Deutsch",
  "pl-PL": "Polski",
  "es-AR": "Español",
  "fr-FR": "Français",
};

export const defaultLanguage = "en-GB";

export enum FeatureFlag {
  MaterialAvailabilityImport,
  SearchContextGroups,
}

export type TenantHash =
  | "04204fa0-c205-57aa-b7ed-73544bf5252e"
  | "d67efadf-8fed-54dd-944a-d605e58b4fdb"
  | "484b06cf-2b5a-5b62-abb9-4cab237eba06"
  | "091ee0dd-36b4-5177-90eb-8222d2d6ff9e"
  | "fa4a0dfb-5557-5dc8-a2ab-a302d957f6a0";

export const allFeatureFlags: Record<TenantHash | "*", FeatureFlag[]> = {
  "04204fa0-c205-57aa-b7ed-73544bf5252e": [],
  "d67efadf-8fed-54dd-944a-d605e58b4fdb": [],
  "484b06cf-2b5a-5b62-abb9-4cab237eba06": [],
  "091ee0dd-36b4-5177-90eb-8222d2d6ff9e": [],
  "fa4a0dfb-5557-5dc8-a2ab-a302d957f6a0": [],
  "*": [
    FeatureFlag.MaterialAvailabilityImport,
    FeatureFlag.SearchContextGroups,
  ],
};

export const materialGroupColours: { [group: string]: string } = {
  // `snake_case` is used to match the data returned by the python backend
  heavy_scrap: "#d7301f",
  heavy_small_scrap: "#fdcc8a",
  light_scrap: "#0570b0",
  "light_scrap/light_small_scrap": "#74a9cf",
  light_small_scrap: "#bdc9e1",
  dri: "#36454F",
};

// Configure authentication via Firebase
export const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
};

export type UnitType =
  | "mass"
  | "cost"
  | "specific_cost"
  | "electrical_energy_consumption"
  | "mass_fraction"
  | "yield"
  | "volume"
  | "density"
  | "energy"
  | "specific_energy_cost";

// Tenant-dependent strings
export const uuidNamespace = "f0020c47-d960-4e69-87a4-3cee8ef67882";
export const tenantUnits: Record<UnitType, { [tenantHash: string]: string }> = {
  mass: { default: "t" },
  cost: {
    default: "€",
    "d67efadf-8fed-54dd-944a-d605e58b4fdb": "$",
    "484b06cf-2b5a-5b62-abb9-4cab237eba06": "$",
  },
  specific_cost: {
    default: "€/t",
    "d67efadf-8fed-54dd-944a-d605e58b4fdb": "$/t",
    "484b06cf-2b5a-5b62-abb9-4cab237eba06": "$/t",
  },
  electrical_energy_consumption: { default: "kWh/t" },
  mass_fraction: { default: "%" },
  yield: { default: "%" },
  volume: { default: "m³", "484b06cf-2b5a-5b62-abb9-4cab237eba06": "ft³" },
  density: { default: "t/m³", "484b06cf-2b5a-5b62-abb9-4cab237eba06": "t/ft³" },
  energy: { default: "kWh", "484b06cf-2b5a-5b62-abb9-4cab237eba06": "MBtu" },
  specific_energy_cost: {
    default: "€/MWh",
    "484b06cf-2b5a-5b62-abb9-4cab237eba06": "$/MWh",
  },
};

export const tenantDefaultToPercentageBreakdown: {
  [tenantHash: string]: boolean;
} = {
  "484b06cf-2b5a-5b62-abb9-4cab237eba06": true,
};

export const tenantHeatsPerDay: {
  [tenantHash: string]: number;
} = {
  "d67efadf-8fed-54dd-944a-d605e58b4fdb": 40,
  "484b06cf-2b5a-5b62-abb9-4cab237eba06": 26,
  "04204fa0-c205-57aa-b7ed-73544bf5252e": 21,
  "091ee0dd-36b4-5177-90eb-8222d2d6ff9e": 18,
  "fa4a0dfb-5557-5dc8-a2ab-a302d957f6a0": 24,
};
const tenantStringReplacements: {
  [tenantHash: string]: { [original: string]: string };
} = {
  "d67efadf-8fed-54dd-944a-d605e58b4fdb": {
    tappedMass: "billetsMass",
    tappedCost: "billetsCost",
    tapped: "billets",
    tappedPrice: "billetsPrice",
    tappedLowerCase: "billetsLowerCase",
    expectedLiquidMass: "expectedBilletsMass",
  },
  "484b06cf-2b5a-5b62-abb9-4cab237eba06": {
    tappedMass: "billetsMass",
    tappedCost: "billetsCost",
    tappedPrice: "billetsPrice",
    materialsTappedPrice: "materialsBilletsPrice",
    electricalEnergyTappedPrice: "electricalEnergyBilletsPrice",
    tapped: "billets",
    tappedLowerCase: "billetsLowerCase",
    expectedLiquidMass: "expectedBilletsMass",
  },
  "04204fa0-c205-57aa-b7ed-73544bf5252e": {
    useProductGroupPlan: "useCopperGroupPlan",
    couldNotLoadProductGrouping: "couldNotLoadCopperGrouping",
    productGroup: "copperGroup",
    productGroups: "copperGroups",
    asProductGroups: "asCopperGroups",
    productGroupsPlanned: "copperGroupsPlanned",
    productGroupsForecast: "copperGroupsForecast",
    uploadInventoryReport: "uploadScrapForecast",
    orderBook: "orderBookCuGruppe",
  },
};
for (const mapping of Object.values(tenantStringReplacements)) {
  for (const [original, replacement] of Object.entries(mapping)) {
    mapping[original] = "$t(" + replacement + ")";
  }
}

export const tenantNamespaces: {
  [tenantHash: string]: { [original: string]: string };
} = tenantStringReplacements;

LicenseInfo.setLicenseKey(
  "c6200b8b64eb6e877081d0a3619158e2Tz04NDUyOSxFPTE3Mzk3OTUyNDUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export enum DeployProcess {
  SyncAndSMS,
  Email,
  Message,
  None,
}

export const deployProcess: Record<string, DeployProcess> = {
  ["484b06cf-2b5a-5b62-abb9-4cab237eba06"]: DeployProcess.SyncAndSMS,
  ["04204fa0-c205-57aa-b7ed-73544bf5252e"]: DeployProcess.Email,
  ["fa4a0dfb-5557-5dc8-a2ab-a302d957f6a0"]: DeployProcess.Message,
};

export const tenantProductionPlan: Record<string, SyncedProductionPlan> & {
  "*": SyncedProductionPlan;
} = {
  ["04204fa0-c205-57aa-b7ed-73544bf5252e"]: {
    steel_grade_items: [],
    product_group_items: null,
  },
  "*": {
    steel_grade_items: null,
    product_group_items: [],
  },
};

export const recipeDecimalPlaces = 1;
