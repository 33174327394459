import { LoadedContent } from "src/components/common/loading/loadedContent";
import { SearchSummary } from "store/api/generatedApi";
import { useSessionSearches } from "../SearchSelector/hooks";
import { HistoryItem } from "./HistoryItem";
import { LinearProgress } from "@mui/material";

type Props = {
  sessionId: number;
  currentSearchId: number;
  onSelect: (search: SearchSummary) => void;
};

export const HistoryItemList = ({
  sessionId,
  currentSearchId,
  onSelect,
}: Props) => {
  return (
    <LoadedContent
      data={useSessionSearches(sessionId)}
      disableFetching
      loading={<LinearProgress />}
    >
      {(searches) =>
        searches.map(({ leaf: search, trunk: searchChildren }, index) => (
          <HistoryItem
            key={search.search_id}
            versionNumber={searches.length - index}
            search={search}
            searchChildren={searchChildren}
            currentSearchId={currentSearchId}
            onSelect={onSelect}
          />
        ))
      }
    </LoadedContent>
  );
};
