import { Loaded } from "models/loaded";
import { useSyncedState } from "./syncing";
import { useMemo } from "react";

/**
 * Hijack the synced state hook to treat a mutation as a query.
 *
 * RTK queries can be called as hooks, which is often useful.  But RTK mutations
 * cannot.  If you want to send data to an endpoint, it needs to be a post
 * endpoint, which is automatically converted to a mutation.  By wrapping it in
 * this hook, you can treat it as if it were a mutation, but still send non-
 * trivial data to the backend.
 */
export const useMutationAsQuery = <T, R extends object | null>(
  inputs: { mutation: (input: T) => Promise<R>; arguments: T } | null
): Loaded<R> => {
  const source = useMemo(() => {
    if (inputs === null) {
      return null;
    } else {
      return () => inputs.mutation(inputs.arguments);
    }
  }, [inputs]);

  return useSyncedState(
    source,
    (client) => Promise.resolve(client),
    () => true
  ).client;
};
