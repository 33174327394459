import { GridColDef } from "@mui/x-data-grid-premium";
import { FormattedProductLimit } from "./rows";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { BoldTextCell, MonospaceTextCell } from "../common/dataGrid/NumberCell";
import {
  FormattedBasketConstraint,
  SteelGrade,
} from "src/store/api/generatedApi";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import "./constraints.css";
import { Link, Stack, useTheme } from "@mui/material";
import React from "react";
import { useSteelGrades } from "contexts/search/provider";
import { ChefGroupDetailView } from "components/common/panels/chefgroup";
import { SteelGradeChip } from "components/common/chips/steelGrade";
import { ProductGroupPanel } from "components/common/panels/productGroup";

type ChemistryGroupCellProps = {
  name: string;
  id: number;
};

type ProductGroupCellProps = {
  name: string;
  steelGradeIds: number[];
};

type SteelGradeCellProps = {
  steelGrades: SteelGrade[];
};

const ChemistryGroupCell = ({ name, id }: ChemistryGroupCellProps) => {
  const [openPanel, setOpenPanel] = React.useState(false);
  const theme = useTheme();
  return (
    <>
      <Link
        variant="body2"
        color={theme.palette.text.primary}
        fontWeight="bold"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenPanel(true)}
      >
        {name}
      </Link>
      <ChefGroupDetailView group={id} open={openPanel} setOpen={setOpenPanel} />
    </>
  );
};

const ProductGroupCell = ({ name, steelGradeIds }: ProductGroupCellProps) => {
  const [openPanel, setOpenPanel] = React.useState(false);
  const theme = useTheme();
  const steelGrades = useSteelGrades();
  return (
    <>
      <Link
        variant="body2"
        color={theme.palette.text.primary}
        fontWeight="bold"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenPanel(true)}
      >
        {name}
      </Link>
      {steelGrades.status === "success" ? (
        <ProductGroupPanel
          title={name}
          steelGrades={steelGradeIds.map((id) => steelGrades.data.byId[id]!)}
          open={openPanel}
          doClose={() => setOpenPanel(false)}
        />
      ) : null}
    </>
  );
};

const SteelGradesCell = ({ steelGrades }: SteelGradeCellProps) => {
  return (
    <Stack
      flexWrap="wrap"
      flexDirection="row"
      rowGap={0.5}
      columnGap={1}
      paddingY={1}
    >
      {steelGrades.map((steelGrade) =>
        steelGrade.id !== undefined && steelGrade.id !== null ? (
          <SteelGradeChip
            key={steelGrade.id}
            id={steelGrade.id}
            chemicalConstraints={steelGrade.chemical_constraints}
            name={steelGrade.name}
          />
        ) : null
      )}
    </Stack>
  );
};

export const useProductConstraintColumns = (
  data: FormattedProductLimit[]
): GridColDef<FormattedProductLimit>[] => {
  const { t } = useTenantTranslation();

  const materialLimits = [
    ...new Set(data.flatMap((row) => Object.keys(row.mixMaterialLimits))),
  ];
  const chemicalTolerances = [
    ...new Set(data.flatMap((row) => Object.keys(row.chemicalTolerances))),
  ];

  const highlightMatchingRows = ({ row }: { row: FormattedProductLimit }) =>
    row.matchesFilter ? "matches-filter" : undefined;

  return [
    {
      id: "chemistryGroup",
      field: "chemistryGroup",
      headerName: t("chemistryGroup"),
      width: 400,
      renderCell: ({ row: { chemistryGroup, id } }) => (
        <ChemistryGroupCell id={id} name={chemistryGroup} />
      ),
      cellClassName: highlightMatchingRows,
    },
    {
      field: "productGroup",
      headerName: t("productGroup"),
      width: 200,
      renderCell: ({ row: { productGroup } }) =>
        productGroup ? (
          <ProductGroupCell
            name={productGroup.name}
            steelGradeIds={productGroup.steel_grades.map(({ id }) => id)}
          />
        ) : null,
      cellClassName: highlightMatchingRows,
    },
    {
      field: "steelGrades",
      headerName: t("steelGrades"),
      width: 600,
      valueFormatter: (_: unknown, { steelGrades }: FormattedProductLimit) =>
        steelGrades.map((steelGrade) => steelGrade.name).join(", "),
      renderCell: ({ row: { steelGrades } }) => (
        <SteelGradesCell steelGrades={steelGrades} />
      ),
      cellClassName: highlightMatchingRows,
    },
    ...materialLimits.map((key) => ({
      field: key,
      headerName: key,
      width: 125,
      align: "right",
      headerAlign: "right",
      valueGetter: (_: unknown, { mixMaterialLimits }: FormattedProductLimit) =>
        mixMaterialLimits[key] ?? "",
      renderCell: MonospaceTextCell,
      cellClassName: highlightMatchingRows,
    })),
    ...chemicalTolerances.map((key) => ({
      field: key,
      headerName: key,
      width: 125,
      align: "right",
      headerAlign: "right",
      valueGetter: (
        _: unknown,
        { chemicalTolerances }: FormattedProductLimit
      ) => chemicalTolerances[key] ?? "",
      renderCell: MonospaceTextCell,
      cellClassName: highlightMatchingRows,
    })),
  ] as GridColDef<FormattedProductLimit>[];
};

export const useBasketConstraintColumns =
  (): GridColDef<FormattedBasketConstraint>[] => {
    const { t } = useTenantTranslation();
    const { format } = useNumberSerialiser({
      default: { value: null, text: "" },
      decimalPlaces: 0,
    });
    const units = useUnitsFormatter(false);

    return [
      {
        id: "basketNumber",
        field: "basketNumber",
        headerName: t("basket"),
        display: "flex",
        width: 150,
        renderCell: BoldTextCell,
        valueGetter: (
          _: unknown,
          { basket_number }: FormattedBasketConstraint
        ) => `${t("basket")} ${format(basket_number)}`,
      },
      {
        field: "minimumFillLevel",
        headerName: `${t("fillLevel")} (${t("min")})`,
        width: 150,
        align: "right",
        display: "flex",
        headerAlign: "right",
        valueGetter: (
          _: unknown,
          { fill_level: { minimum } }: FormattedBasketConstraint
        ) => format((minimum ?? 0) * 100) + units("yield"),
        renderCell: MonospaceTextCell,
      },
      {
        field: "maximumFillLevel",
        headerName: `${t("fillLevel")} (${t("max")})`,
        width: 150,
        align: "right",
        display: "flex",
        headerAlign: "right",
        valueGetter: (
          _: unknown,
          { fill_level: { maximum } }: FormattedBasketConstraint
        ) => format((maximum ?? 0) * 100) + units("yield"),
        renderCell: MonospaceTextCell,
      },
      {
        field: "massMinimum",
        headerName: `${t("mass")} (${t("min")})`,
        width: 150,
        align: "right",
        display: "flex",
        headerAlign: "right",
        valueGetter: (
          _: unknown,
          { mass: { minimum } }: FormattedBasketConstraint
        ) => format(minimum ?? null) + units("mass"),
        renderCell: MonospaceTextCell,
      },
      {
        field: "massMaximum",
        headerName: `${t("mass")} (${t("max")})`,
        width: 150,
        align: "right",
        display: "flex",
        headerAlign: "right",
        valueGetter: (
          _: unknown,
          { mass: { maximum } }: FormattedBasketConstraint
        ) => format(maximum ?? null) + units("mass"),
        renderCell: MonospaceTextCell,
      },
    ] as GridColDef<FormattedBasketConstraint>[];
  };
