import { Box } from "@mui/material";
import { mapLoaded } from "models/loaded";
import { useMemo } from "react";
import { PlanId } from "components/common/boundary/PlanId";
import { useLayering } from "components/plan/topButtons/exportPlan/layerings";
import { PreviewLayering } from "components/plan/topButtons/exportPlan/preview";

export const LayeringTab = ({
  planId,
  groupId,
  mixNumber,
}: {
  planId: PlanId;
  groupId: number;
  mixNumber: number;
}) => {
  const key = useMemo(
    () => ({
      planId,
      groupId,
      mixNumber,
    }),
    [planId, groupId, mixNumber]
  );

  const layering = useLayering(key, null);

  return (
    <Box sx={{ pt: 2 }}>
      <PreviewLayering
        showProgress
        layering={mapLoaded(layering, ({ summary }) => summary)}
      />
    </Box>
  );
};
