import React from "react";
import Typography from "@mui/material/Typography";
import { Link, Stack, useTheme } from "@mui/material";

import { Period } from "hooks/periodIndex";
import { MaterialDetailDrawer } from "components/common/panels/material";
import { usePlanId } from "components/common/boundary/PlanId";

export type Props =
  | {
      type: "static";
      renderValue: string;
    }
  | {
      type: "properties";
      renderValue: string;
      name: string;
      id: number;
      alignment: "top" | "left";
    }
  | {
      type: "consumption";
      renderValue: string;
      name: string;
      id: number;
      period: Period;
      alignment: "top" | "left";
    };

const getFlexDirection = (alignment: "top" | "left"): "column" | "row" => {
  switch (alignment) {
    case "top":
      return "column";
    case "left":
      return "row";
  }
};

const getAlignItems = (alignment: "top" | "left"): "flex-start" | "center" => {
  switch (alignment) {
    case "top":
      return "flex-start";
    case "left":
      return "center";
  }
};

export const ScrapHeader = ({ ...props }: Props) => {
  const theme = useTheme();
  const planId = usePlanId();
  const [openModal, setOpenModal] = React.useState(false);

  const { type } = props;
  switch (type) {
    case "properties":
    case "consumption": {
      const { name, id, alignment } = props;
      return (
        <Stack
          sx={{ height: "100%", width: "100%" }}
          flexDirection={getFlexDirection(alignment)}
          alignItems={getAlignItems(alignment)}
        >
          <Link
            variant="body2"
            color={theme.palette.text.primary}
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
            onClick={() => setOpenModal(true)}
          >
            {props.renderValue}
          </Link>
          {type === "properties" ? (
            <MaterialDetailDrawer
              title={name}
              id={id}
              doClose={() => setOpenModal(false)}
              open={openModal}
            />
          ) : (
            <MaterialDetailDrawer
              title={name}
              id={id}
              doClose={() => setOpenModal(false)}
              open={openModal}
              period={props.period}
              planId={planId}
            />
          )}
        </Stack>
      );
    }
    case "static": {
      return <Typography variant="body2">{props.renderValue}</Typography>;
    }
  }
};
