export type Loaded<T> =
  | { status: "waiting" }
  | { status: "loading" }
  | { status: "error" }
  | { status: "success"; data: T; fetching: boolean };

type LoadedObject<T extends object> = { [K in keyof T]: Loaded<T[K]> };

export const doLoaded = <T>(value: Loaded<T>, action: (value: T) => void) => {
  if (value.status === "success") {
    action(value.data);
  }
};

export const mapLoaded = <T, U>(
  value: Loaded<T>,
  mapping: (value: T) => U
): Loaded<U> => {
  if (value.status === "success") {
    return { ...value, data: mapping(value.data) };
  } else {
    return value;
  }
};

export const mapLoadedUnpack = <T, U>(
  value: Loaded<T>,
  mapping: (value: T) => U
): U | null => {
  return value.status === "success" && !value.fetching
    ? mapping(value.data)
    : null;
};

/**
 * Lift the loaded state of an object's properties to the top level.
 *
 * This takes an object, each of whose keys must be a loaded value.  It then
 * resolves to a single loaded object, where the object's values are the
 * corresponding loaded properties.  The resulting loaded object will have a
 * success state iff all of the input objects also have a success state; if not,
 * it will adopt the earliest status that is present in the object's values.
 */
export const liftLoadedState = <T extends object, K extends keyof T>(
  value: LoadedObject<T>
): Loaded<T> => {
  const statuses = Object.keys(value).map((key) => value[key as K].status);

  if (statuses.includes("error")) {
    return { status: "error" };
  } else if (statuses.includes("waiting")) {
    return { status: "waiting" };
  } else if (statuses.includes("loading")) {
    return { status: "loading" };
  } else {
    // The typescript compiler isn't capable of verifying this code, so we need
    // to override it a few times
    return {
      status: "success",
      data: Object.fromEntries(
        Object.keys(value).map((key) => [
          key,
          (value[key as keyof T] as unknown as { data: object }).data,
        ])
      ) as T,
      fetching: Object.keys(value).some(
        (key) => (value[key as keyof T] as { fetching: boolean }).fetching
      ),
    };
  }
};

export const loadedEndpoint = <T>(endpoint: {
  data?: T;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
}): Loaded<T> => {
  if (endpoint.data === undefined) {
    return { status: "waiting" };
  } else if (endpoint.isLoading) {
    return { status: "loading" };
  } else if (endpoint.isError) {
    return { status: "error" };
  } else {
    return {
      status: "success",
      data: endpoint.data,
      fetching: endpoint.isFetching,
    };
  }
};
