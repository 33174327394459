import { ChevronLeft, HistoryOutlined } from "@mui/icons-material";
import {
  Box,
  Typography,
  ButtonBase,
  Button,
  Chip,
  Stack,
  IconButton,
} from "@mui/material";
import { Layout } from "components/common/panels/layout";
import {
  useNavigateToSearchContextGroup,
  useNavigateToSearchContextGroupList,
  useNavigateToSearchContextMixMaterialLimitSet,
} from "hooks/navigation";
import { useTenant } from "hooks/settings";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  GroupedSearchContext,
  useGetSearchContextGroupQuery,
} from "src/store/api/generatedApi";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";

type HistoryPeekProps = {
  open: boolean;
  doClose: () => void;
  width: number;
  searchContextId: number;
  searchContextGroupId: number;
  searchContexts: GroupedSearchContext[];
};

const HistoryPeek = ({
  open,
  doClose,
  width,
  searchContextGroupId,
  searchContextId,
  searchContexts,
}: HistoryPeekProps) => {
  const navigateToSearchContextGroup = useNavigateToSearchContextGroup();
  return (
    <Layout open={open} doClose={doClose} width={width} title="History">
      <Stack display="grid" gridAutoRows={24}>
        {searchContexts.map((searchContext) => {
          const isInView = searchContext.id === searchContextId;
          return (
            <Stack
              key={searchContext.id}
              gap={1}
              flexDirection="row"
              width="100%"
              alignItems="center"
            >
              <Typography
                key={searchContext.id}
                variant="button"
                onClick={() =>
                  navigateToSearchContextGroup({
                    searchContextGroupId,
                    searchContextId: searchContext.id,
                  })
                }
                sx={
                  !isInView
                    ? { cursor: "pointer", textDecoration: "underline" }
                    : {}
                }
              >
                {searchContext.id}
              </Typography>
              <Typography>
                {new Date(searchContext.created_at).toLocaleString()}
              </Typography>
              {searchContext.id === searchContextId ? (
                <Chip
                  variant="outlined"
                  label="Viewing"
                  color="info"
                  size="small"
                />
              ) : null}
            </Stack>
          );
        })}
      </Stack>
    </Layout>
  );
};

type Props = {
  searchContextGroupId: number;
  searchContextId: number;
};

export const ParametisedSearchContextGroupDetailPage = ({
  searchContextGroupId,
  searchContextId,
}: Props) => {
  const tenantName = useTenant();
  const searchContextGroup = useGetSearchContextGroupQuery({
    tenantName,
    searchContextGroupId,
  });
  const navigateToSearchContextMixMaterialLimitSet =
    useNavigateToSearchContextMixMaterialLimitSet();
  const navigateToSearchContextGroupList =
    useNavigateToSearchContextGroupList();
  const [isPeekOpen, setIsPeekOpen] = React.useState(false);

  if (searchContextGroup.data) {
    const searchContexts = typeSafeObjectFromEntries(
      searchContextGroup.data.contexts.map((searchContext) => [
        searchContext.id,
        searchContext,
      ])
    );
    const currentSearchContext = searchContexts[searchContextId];
    if (currentSearchContext) {
      return (
        <>
          <Box
            sx={{
              marginX: 6,
              marginY: 3,
              display: "grid",
              gridTemplateColumns: "min-content 1fr max-content",
              columnGap: 1,
              rowGap: 2,
            }}
          >
            <IconButton
              sx={{ padding: 0, width: "fit-content" }}
              onClick={navigateToSearchContextGroupList}
            >
              <ChevronLeft />
            </IconButton>
            <Typography variant="h1">{searchContextGroup.data.name}</Typography>
            <Button
              variant="outlined"
              color="info"
              startIcon={<HistoryOutlined />}
              onClick={() => setIsPeekOpen(true)}
            >
              History
            </Button>
            <Typography gridColumn="1/-1" variant="h2">
              {searchContextId}
            </Typography>
            <ButtonBase
              sx={{
                gridColumn: "1/-1",
                width: "fit-content",
                marginLeft: 2,
                textDecoration: "underline",
              }}
            >
              <Typography
                variant="h3"
                onClick={() =>
                  navigateToSearchContextMixMaterialLimitSet({
                    searchContextGroupId,
                    searchContextId,
                    mixMaterialLimitSetId:
                      searchContexts[searchContextId]!
                        .mix_material_limit_set_id,
                  })
                }
              >
                Mix material limits
              </Typography>
            </ButtonBase>
            <ButtonBase
              sx={{
                gridColumn: "1/-1",
                width: "fit-content",
                marginLeft: 2,
                textDecoration: "underline",
              }}
            >
              <Typography variant="h3" onClick={console.log}>
                Basket constraints
              </Typography>
            </ButtonBase>
            <ButtonBase
              sx={{
                gridColumn: "1/-1",
                width: "fit-content",
                marginLeft: 2,
                textDecoration: "underline",
              }}
            >
              <Typography variant="h3" onClick={console.log}>
                Product groups
              </Typography>
            </ButtonBase>
          </Box>
          <HistoryPeek
            open={isPeekOpen}
            doClose={() => setIsPeekOpen(false)}
            width={400}
            searchContextId={searchContextId}
            searchContextGroupId={searchContextGroupId}
            searchContexts={searchContextGroup.data.contexts}
          />
        </>
      );
    } else {
      return <Navigate to={`/${tenantName}/v3/search_context_group`} />;
    }
  } else if (searchContextGroup.isError) {
    return <Navigate to={`/${tenantName}/v3/search_context_group`} />;
  } else {
    return "loading..."; // <-- this needs to be made better lol
  }
};

export const SearchContextGroupDetailPage = () => {
  const tenantName = useTenant();
  const params = useParams<{
    search_context_group_id: string;
    search_context_id: string;
  }>();

  if (
    !isNaN(Number(params.search_context_id)) &&
    !isNaN(Number(params.search_context_group_id))
  ) {
    return (
      <ParametisedSearchContextGroupDetailPage
        searchContextGroupId={Number(params.search_context_group_id)}
        searchContextId={Number(params.search_context_id)}
      />
    );
  } else {
    return <Navigate to={`/${tenantName}/v3/search_context_group`} />;
  }
};
