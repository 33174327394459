import React from "react";
import {
  ArrowBack,
  ArrowForward,
  Attachment,
  CheckCircle,
  Error,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { useShortLanguage, useTenant } from "hooks/settings";
import { backendApi } from "src/store/api/enhancedApi";
import { useExcelName } from "hooks/useExcelDownloadName";
import { SupportedLanguage } from "src/store/api/generatedApi";
import { useServerIds } from "contexts/search/provider";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { usePlanId } from "components/common/boundary/PlanId";

type Props = {
  toNextState: () => void;
  toInitialState: () => void;
};

enum State {
  Review,
  Sending,
  Success,
  Error,
}

export const ShareViaEmail = ({ toNextState, toInitialState }: Props) => {
  const { t } = useTenantTranslation();
  const [state, setState] = React.useState(State.Review);
  const { searchId } = useServerIds();
  const theme = useTheme();
  const planId = usePlanId();
  const tenantName = useTenant();
  const language = useShortLanguage() as SupportedLanguage;
  const fileName = useExcelName();
  const formatDate = useFormatLocalDateString("date");

  const tenantContacts = backendApi.endpoints.getTenantContacts.useQuery({
    tenantName,
  });
  const [emailPlan] = backendApi.endpoints.sendDeployedMixesEmail.useMutation();

  const link = `${window.location.origin}/${tenantName}/v3/search?search=${searchId}&plan=${planId}`;

  const handleSendEmail = async () => {
    setState(State.Sending);
    try {
      await emailPlan({ tenantName, planId, language }).unwrap();
      setState(State.Success);
    } catch (e) {
      setState(State.Error);
    }
  };

  switch (state) {
    case State.Review: {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            margin: "0 auto",
            width: "500px",
            marginTop: 10,
          }}
        >
          <Typography
            variant="body1"
            color={theme.palette.text.primary}
            component="span"
          >
            {t("recipients")}:{" "}
            {tenantContacts.isSuccess ? (
              <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                component="span"
              >
                {tenantContacts.data.join(", ")}
              </Typography>
            ) : (
              <>
                <Skeleton
                  sx={{ display: "inline-block" }}
                  component="span"
                  width={400}
                />
                &nbsp; &nbsp;
                <Skeleton
                  sx={{ display: "inline-block" }}
                  component="span"
                  width={250}
                />
                &nbsp; &nbsp;
                <Skeleton
                  sx={{ display: "inline-block" }}
                  component="span"
                  width={125}
                />
              </>
            )}
          </Typography>
          <Stack gap={1}>
            <Typography variant="body1" component="span">
              {t("emailSubject")}:
            </Typography>
            <Box
              sx={{
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "2px",
              }}
            >
              <Typography variant="body1">{`${t("gattierung")} (${formatDate(
                new Date().toDateString()
              )})`}</Typography>
            </Box>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1">{t("emailBody")}</Typography>
            <Box
              sx={{
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "2px",
              }}
            >
              <Typography variant="body1">
                {t("aNewGattierungIsReady")}
              </Typography>
              <br />
              <Typography
                variant="body1"
                color="primary"
                sx={{ textDecoration: "underline" }}
              >
                <a href={link}>{link}</a>
              </Typography>
            </Box>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1">{t("attachment")}</Typography>
            <Box
              sx={{
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.grey[200]}`,
                borderRadius: "2px",
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "fit-content",
              }}
            >
              <Attachment />
              <Typography variant="body1" component="span">
                {fileName}
              </Typography>
            </Box>
          </Stack>
          <Button
            variant="contained"
            sx={{ alignSelf: "start" }}
            onClick={handleSendEmail}
            disabled={!tenantContacts.isSuccess}
          >
            {t("sendEmail")}
          </Button>
        </Stack>
      );
    }
    case State.Sending: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            {t("sendingEmail")}...
          </Typography>
          <Typography variant="body1">
            {t("pleaseDoNotCloseThisWindow")}
          </Typography>
        </Stack>
      );
    }
    case State.Success: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <CheckCircle color="success" />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            {t("emailSentSuccesfully")}
          </Typography>
          <Button
            variant="contained"
            onClick={toNextState}
            endIcon={<ArrowForward />}
          >
            {t("next")}
          </Button>
        </Stack>
      );
    }
    case State.Error: {
      return (
        <Stack
          sx={{
            height: "100%",
            margin: "0 auto",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Error color="error" fontSize="large" />
          <Typography variant="body1">{t("unknownError")}</Typography>
          <Button
            variant="outlined"
            onClick={toInitialState}
            startIcon={<ArrowBack />}
          >
            {t("back")}
          </Button>
        </Stack>
      );
    }
  }
};
