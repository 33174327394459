import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import {
  MaterialRead,
  MixBasketSummary,
  MixMaterialBasketSummary,
  PlanMixBasket,
} from "src/store/api/generatedApi";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import { recipeDecimalPlaces } from "src/constants.tsx";

type Props = {
  materialBasketsSummary: MixMaterialBasketSummary[];
  basketsSummary: MixBasketSummary[];
};

type BasketRow = {
  basket_number: number;
  total: number;
  [material: string]: number;
};

export const BasketsTable = ({
  materialBasketsSummary,
  basketsSummary,
}: Props) => {
  const { t } = useTenantTranslation();
  const groupedSummary: Record<string, number> = {};
  const uniqueMaterials: string[] = [];
  const uniqueBasketNumbers: number[] = [];
  const basketTotals: Record<number, number> = {};
  const units = useUnitsFormatter(true);

  for (const item of basketsSummary) {
    basketTotals[item.basket_number] = item.recipe_mass;
  }

  materialBasketsSummary.forEach((item) => {
    // add to the grouped summary
    const key = `${item.basket_number}__${item.material}`;
    groupedSummary[key] = item.recipe_mass;

    // add to the unique materials if not already there
    if (!uniqueMaterials.includes(item.material)) {
      uniqueMaterials.push(item.material);
    }

    // add to the unique basket numbers if not already there
    if (!uniqueBasketNumbers.includes(item.basket_number)) {
      uniqueBasketNumbers.push(item.basket_number);
    }
  });

  const rows: BasketRow[] = [];

  for (const basketNumber of uniqueBasketNumbers) {
    const row: BasketRow = {
      basket_number: basketNumber,
      total: basketTotals[basketNumber]!,
    };
    for (const material of uniqueMaterials) {
      const key = `${basketNumber}__${material}`;
      row[material] = groupedSummary[key]!;
    }
    rows.push(row);
  }

  const columns: GridColDef<BasketRow>[] = [
    {
      field: "basket_number",
      headerName: t("basket_number"),
      width: 120,
    },
    {
      field: "total",
      headerName: `${t("Total")}${units("mass")}`,
      width: 120,
      renderCell: (params) => (
        <NumberCell
          value={params.row.total}
          decimalPlaces={recipeDecimalPlaces}
        />
      ),
      sortable: false,
    },
  ];

  for (const material of uniqueMaterials) {
    columns.push({
      field: material,
      headerName: `${material.replace("_", " ")}${units("mass")}`,
      renderCell: (params) => (
        <NumberCell
          value={params.row[material] ?? 0}
          decimalPlaces={recipeDecimalPlaces}
        />
      ),
      width: 100,
      sortable: false,
    });
  }

  return (
    <DataGridPremium
      sx={{ width: "100%" }}
      rows={rows}
      columns={columns}
      getRowId={(row) => row.basket_number}
      initialState={{
        pinnedColumns: { left: ["basket_number"], right: ["total"] },
      }}
      cellSelection
      ignoreValueFormatterDuringExport
      autoHeight
    />
  );
};

type Row = PlanMixBasket & { basketNumber: number | "total" };

export const PlanMixBasketsTable = ({
  baskets,
  totals,
  materialsByIndex,
  whitelistRows,
}: {
  baskets: PlanMixBasket[];
  totals: PlanMixBasket;
  materialsByIndex: MaterialRead[];
  whitelistRows?: (number | "total")[];
}) => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(true);

  const columns: GridColDef<Row>[] = [
    {
      field: "basketNumber",
      headerName: "",
      width: 120,
      valueFormatter: (value: number | "total") =>
        value === "total"
          ? `${t("total")}`
          : `${t("basketCapitalised")} ${value + 1}`,
    },
    {
      field: "total",
      headerName: `${t("Total")}${units("mass")}`,
      width: 120,
      renderCell: (params) => (
        <NumberCell value={params.row.total_mass} decimalPlaces={1} />
      ),
      sortable: false,
    },
    ...materialsByIndex
      .filter(
        (material) =>
          (totals.material_masses[material.id] ?? 0) > 0 &&
          material.addition_location === "basket"
      )
      .map((material) => ({
        field: material.id.toString(),
        headerName: `${material.name}${units("mass")}`,
        renderCell: (params: GridRenderCellParams<Row>) => {
          const mass = params.row.material_masses[material.id] ?? 0;
          return mass > 0 ? (
            <NumberCell value={mass} decimalPlaces={1} />
          ) : null;
        },
        width: 100,
        sortable: false,
      })),
  ];

  return (
    <DataGridPremium<Row>
      sx={{ width: "100%" }}
      rows={[
        ...baskets.map((basket, basketNumber) => ({ ...basket, basketNumber })),
        { ...totals, basketNumber: "total" as const },
      ].filter(
        (row) =>
          whitelistRows == null || whitelistRows.includes(row.basketNumber)
      )}
      columns={columns}
      getRowId={(row) => row.basketNumber}
      initialState={{
        pinnedColumns: { left: ["basketNumber"], right: ["total"] },
      }}
      cellSelection
      ignoreValueFormatterDuringExport
      autoHeight
    />
  );
};
