import { useSearchParams } from "react-router-dom";

export const useSessionId = (sessionId?: number): number | null => {
  const urlSessionId = parseInt(useSearchParams()[0].get("session") ?? "");
  if (typeof sessionId === "number") {
    return sessionId;
  } else if (typeof urlSessionId === "number" && !isNaN(urlSessionId)) {
    return urlSessionId;
  } else {
    return null;
  }
};

export const useSearchId = (searchId?: number): number | null => {
  const urlSearchId = parseInt(useSearchParams()[0].get("search") ?? "");
  if (typeof searchId === "number") {
    return searchId;
  } else if (typeof urlSearchId === "number" && !isNaN(urlSearchId)) {
    return urlSearchId;
  } else {
    return null;
  }
};

export const usePlanId = (): number | null => {
  const urlPlanId = parseInt(useSearchParams()[0].get("plan") ?? "");
  if (typeof urlPlanId === "number" && !isNaN(urlPlanId)) {
    return urlPlanId;
  } else {
    return null;
  }
};
