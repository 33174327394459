import { useMaterials, useTargetInventory } from "contexts/search/provider";
import { SearchMaterials } from "contexts/search/context";
import { Loaded, liftLoadedState } from "models/loaded";
import { TargetInventoryItem } from "store/api/generatedApi";
import { usePeriodIndex } from "hooks/periodIndex";

export type TargetInventoryDependencies = {
  materials: SearchMaterials;
  targetInventory: TargetInventoryItem[];
};

export const useTargetInventoryDependencies =
  (): Loaded<TargetInventoryDependencies> => {
    const periodTab = usePeriodIndex();
    const materials = useMaterials();
    const [targetInventory] = useTargetInventory(periodTab);

    return liftLoadedState({ materials, targetInventory });
  };
