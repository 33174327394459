import { useNumberSerialiser } from "./numbers";
import { Serialiser } from "./types";

export const usePercentageSerialiser = (
  decimalPlaces: number
): Serialiser<number | null, never> => {
  const { format, parse } = useNumberSerialiser({ decimalPlaces });

  return {
    format: (fraction: number | null) =>
      fraction == null ? "" : format(fraction * 100),
    parse: (text: string) => {
      const result = parse(text.replace("%", ""));

      if (result.valid) {
        return { valid: true, value: result.value / 100 };
      } else {
        return result;
      }
    },
  };
};
