import React from "react";

export enum NeverContext {
  NotFound = "__NotFound__",
}

export const createContext = <T,>() =>
  React.createContext<T | NeverContext>(NeverContext.NotFound);

export const createUseContext =
  <T,>(Context: React.Context<T | NeverContext>, name: string) =>
  () => {
    const context = React.useContext(Context);
    if (context === NeverContext.NotFound) {
      throw new Error(`Context ${name} not found`);
    }
    return context;
  };
