import { Stack, Typography, useTheme, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const Deployed = ({ doClose }: { doClose: () => void }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        justifyContent: "center",
        height: "100%",
        margin: "0 auto",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Stack sx={{ gap: 2, alignItems: "center", width: 1 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <CheckCircleIcon sx={{ color: "green" }} />
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            The plan has been successfully deployed
          </Typography>
        </Stack>
        <Button onClick={doClose} variant="contained">
          Close
        </Button>
      </Stack>
    </Stack>
  );
};
