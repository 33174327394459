import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";

import { ApprovePlan } from "./ApprovePlan";
import { Download } from "./Download";
import { ShareViaEmail } from "./ShareViaEmail";

export enum State {
  ApprovePlan,
  ShareViaEmail,
  Download,
}

const toNextState = (state: State): State.ShareViaEmail | State.Download => {
  switch (state) {
    case State.ApprovePlan:
      return State.ShareViaEmail;
    case State.ShareViaEmail:
    case State.Download:
      return State.Download;
  }
};

const stateToIndex = (state: State): 0 | 1 | 2 => {
  switch (state) {
    case State.ApprovePlan:
      return 0;
    case State.ShareViaEmail:
      return 1;
    case State.Download:
      return 2;
  }
};

type Props = {
  open: boolean;
  doClose: () => void;
};

export const Modal = ({ open, doClose }: Props) => {
  const [state, setState] = React.useState(State.ApprovePlan);

  const { t } = useTenantTranslation();

  const steps = [t("approvePlan"), t("shareViaEmail"), t("download")];

  const handleToNextState = React.useCallback(() => {
    setState(toNextState);
  }, [setState]);

  const toInitialState = () => setState(State.ApprovePlan);

  const body = React.useMemo((): JSX.Element => {
    switch (state) {
      case State.ApprovePlan:
        return <ApprovePlan toNextState={handleToNextState} />;
      case State.Download:
        return <Download doClose={doClose} />;
      case State.ShareViaEmail:
        return (
          <ShareViaEmail
            toNextState={handleToNextState}
            toInitialState={toInitialState}
          />
        );
    }
  }, [state, handleToNextState, doClose]);

  return (
    <Dialog
      onClose={doClose}
      open={open}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          // Fix to always be "full height" (i.e. 90vh)
          height: "90vh",
        },
      }}
    >
      <DialogTitle>{t("deployPlan")}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Stepper activeStep={stateToIndex(state)}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>
                  <Typography>{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {body}
      </DialogContent>
    </Dialog>
  );
};
