import { Box } from "@mui/material";

import { ProductionTable } from "./table/production/ProductionTable";

type Props = {
  planId: number;
};

export const ProductionTab = ({ planId }: Props) => {
  return (
    <Box
      sx={{
        py: 2,
        minHeight: 0,
      }}
    >
      <ProductionTable planId={planId} />
    </Box>
  );
};
