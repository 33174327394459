import { useShortLanguage, useTenant } from "hooks/settings";
import React from "react";

import { backendApi } from "src/store/api/enhancedApi";
import { SupportedLanguage } from "src/store/api/generatedApi";
import { useExcelName } from "./useExcelDownloadName";
import { Period } from "hooks/periodIndex";

export const useDownloadPlanMixes = (planId: number, period: Period | null) => {
  const tenantName = useTenant();
  const language = useShortLanguage();
  const fileName = useExcelName();

  const [download] = backendApi.endpoints.getPlanMixesExcel.useLazyQuery();
  const doDownload = React.useCallback(async () => {
    const response = await download({
      tenantName,
      planId,
      language: language as SupportedLanguage,
      period,
    });
    const blob = response.data!;
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
  }, [planId, tenantName, language, fileName, download, period]);
  return doDownload;
};
